@use "../abstracts" as *;

// input-custom
.component-input {
  &.custom-text {
    &:focus-within #maskedNumbers {
      border: 1px solid $line-000000;
    }
    .input-field {
      @include rem(min-width, 290);
      @include rem(border-radius, 4);
      &.disabled {
        background-color: $bg-e0e0e0;
      }
      input {
        border: 0;
        width: 0;
        height: 0;
        color: transparent;
        padding: 0;
      }
    }

    #maskedNumbers {
      // @include rem(min-width, 250);
      @include rem(width, 100%);
      @include rem(height, 52);
      @include rem(padding, 14 16);

      @include rem(border-radius, 4);

      cursor: text;
      display: flex;
      align-items: center;
      // justify-content: center;
      border: 1px solid $line-e5e5e5;

      &:focus,
      &:focus-visible {
        outline: none;
      }

      .masked-number {
        @include rem(width, 8);
        @include rem(height, 8);
        @include rem(margin, 0 14);
        @include rem(border-radius, 50%);
        border: 1px solid #ccc;
        font-size: 0;
        background-color: $bg-000000;
        display: inline-block;
        &:focus {
          outline: none;
          border-color: blue;
        }
      }
    }
  }
}
// component-forms
.component-form {
  .input-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .label-txt {
      flex: 1 0 auto;
      @include f16(500);
      color: $font-000000;
    }
    .label-util {
    }
    &.required {
      &:after {
        content: "";
        position: absolute;
        @include rem(right, -8);
        @include rem(top, 0);
        @include rem(width, 4);
        @include rem(height, 4);
        background-color: $bg-fe508b;
        border-radius: 50%;
      }
    }
  }
  .form-content {
  }

  // form-group-sup
  .form-group-sup {
    display: flex;
    .form-group {
      flex: 1;
      width: 100%;
      & + .form-group {
        @include rem(margin, 0 0 0 28);
      }
    }
    & + .form-group,
    & + .form-group-sup {
      @include rem(margin-top, 24);
    }

    &.address-type {
      align-items: flex-end;
      .form-group {
        & + .form-group {
          @include rem(margin-left, 8);
        }
      }
    }
  }

  // form-group
  .form-group {
    .input-label {
      display: inline-flex;
      @include rem(margin-bottom, 8);
    }
    & + .form-group-sup,
    & + .form-group {
      @include rem(margin-top, 24);
    }
    .form-element {
      // input 유효성 검사 - 에러
      &.input-invalid {
        .input-textarea-field {
          textarea {
            border: 1px solid red;
          }
        }
        .input-field {
          input {
            border: 1px solid red;
          }
        }
        .component-select {
          .select-box {
            border: 1px solid red;
          }
        }
        .input-info {
          display: block;
          color: red;
        }
      }

      &.input-valid {
        .input-info {
          display: block;
          color: $font-fe508b;
        }
      }

      .input-info {
        display: none;
        @include rem(margin-top, 8);
        @include f12(500);
      }

      // 시간 연장
      .time-extension-wrap {
        @include rem(margin-top, 16);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        p {
          @include f16(500);
          color: $font-777777;
        }
        .time-extension-btn {
          @include f16(500);
          border-bottom: 1px solid $line-000000;
        }
      }
    }

    .form-text-field {
      display: flex;
      align-items: center;
      button[class*="btn-"] {
        &:disabled {
          // pointer-events: none;
          border: 0;
          background-color: $bg-f7f8fd;
        }
      }
      .hidden-number-list,
      .component-input {
        flex: 1;
        & + .component-input {
          @include rem(margin-left, 8);
        }
        & + [class*="btn-"] {
          @include rem(margin-left, 8);
          @include rem(height, 52);
          @include rem(padding, 0 24);
          @include rem(border-radius, 4);
          border: 1px solid $line-000000;
          // flex: 1; //mo버전
          .btn-txt {
            @include f16(500);
          }
          &.btn-submit {
          }
        }
        // 주민번호 뒷자리 input
        &.ssno {
          flex: none;
          flex-shrink: 0;
          @include rem(width, 48);
        }

        // 주소 기본입력
        &.address-input {
          flex: none;
          flex-shrink: 0;
          @include rem(width, 160);
        }

        // 카드 소유자의 정보보
        &.card-name-input {
          flex: none;
          flex-shrink: 0;
          @include rem(width, 240);
        }
      }
      // 주민번호 뒷자리
      .hidden-number-list {
        // @include rem(margin-right, 8);
        ul {
          @include rem(padding, 0 20);
          @include rem(border-radius, 4);
          @include rem(margin-left, 4);
          @include rem(height, 52);
          display: flex;
          align-items: center;
          border: 1px solid $line-e0e0e0;
          li {
            @include rem(width, 8);
            @include rem(height, 8);
            @include rem(margin-left, 8);
            @include rem(border-radius, 50%);
            background-color: $bg-000000;
            &:first-child {
              margin: 0;
            }
          }
        }
      }
      .bar {
        @include rem(margin, 0 4);
        @include rem(width, 8);
        height: 1px;
        background-color: $bg-000000;
      }
      .component-select {
        // flex: 1;
        &.phone {
          @include rem(width, 120);
        }
        & + .component-input {
          @include rem(margin-left, 8);
        }

        & + .component-select {
          @include rem(margin-left, 8);
        }
      }

      & + .form-text-field {
        @include rem(margin-top, 8);
      }

      // 주민번호 + hidden-number-list + btn
      &.registration-field {
        .hidden-number-list {
          ul {
            justify-content: space-around;
          }
          & + [class*="btn-"] {
          }
        }
      }
    }
    // .notice-txt {
    //   @include rem(margin-top, 8);
    //   li {
    //     @include rem(padding-left, 10);
    //     @include f12(500);
    //     color: $font-777777;
    //     position: relative;
    //     &::before {
    //       content: "・";
    //       position: absolute;
    //       @include rem(top, 0);
    //       @include rem(left, 0);
    //     }
    //   }
    //   & + li {
    //     @include rem(margin-top, 4);
    //   }
    // }
    .form-result {
      &:not(:first-child) {
        @include rem(margin-top, 32);
      }
      > dd {
        .label-txt {
          @include f18(700);
        }
      }
      > dt {
        @include f16(500);
      }
    }
    .comm-bl-dot {
      @include rem(margin-top, 16);
      > li {
        position: relative;
        @include rem(padding-left, 8);
        @include f12(400);
        color: $font-777777;
        border: 0;
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          @include rem(top, 7);
          @include rem(width, 2);
          @include rem(height, 2);
          background: $bg-777777;
          border-radius: 50%;
        }
        // &.bold {
        //   font-weight: 700;
        // }
        &.no-dot {
          padding-left: 0;
          &::before {
            display: none;
          }
        }
        & + li {
          @include rem(margin-top, 4);
        }

        // .primary {
        //   // color: $font-333333;
        // }

        // strong {
        //   font-weight: 700;
        // }
      }
      // Dash Bullet
      .comm-bl-dash {
        // > li {
        //   position: relative;
        //   @include rem(padding-left, 10);
        //   @include f14;
        //   &::before {
        //     display: block;
        //     content: "-";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //   }
        //   &.no-dot {
        //     padding-left: 0;
        //     &::before {
        //       display: none;
        //     }
        //   }
        // }
      }
    }
    .info-txt {
      @include rem(margin-top, 8);
      @include f12(500);
      color: $font-777777;
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // component-forms : mo
  .component-input {
    &.custom-text {
      .input-field {
        @include rem(min-width, 122);
      }

      #maskedNumbers {
        @include rem(width, 100%);
        @include rem(height, 48);
        @include rem(padding, 14 16);

        .masked-number {
          @include rem(margin, 0 2.28);
        }
      }
    }
  }
  .component-form {
    .input-label {
      .label-txt {
        @include f14(500);
      }
      .label-util {
      }
      &.required {
        &:after {
          @include rem(right, -6);
          @include rem(top, 3);
          @include rem(width, 3);
          @include rem(height, 3);
        }
      }
    }
    .form-content {
    }

    // form-group-sup : mo
    .form-group-sup {
      display: block;
      .form-group {
        & + .form-group {
          @include rem(margin, 24 0 0 0);
        }
      }
      & + .form-group,
      & + .form-group-sup {
        // @include rem(margin-top, 24);
      }
      &.address-type {
        .form-group {
          & + .form-group {
            @include rem(margin, 4 0 0 0);
          }
        }
      }
    }

    // form-group : mo
    .form-group {
      .input-label {
        // @include rem(margin-bottom, 8);
        // display: inline-block;
        // @include f14;
        // color: $color-333333;
      }

      & + .form-group {
        // @include rem(margin-top, 24);
      }
      .form-element {
        // input 유효성 검사 - 에러
        &.input-invalid {
          .input-field {
            input {
            }
          }
          .component-select {
            .select-box {
            }
          }
          .input-info {
          }
        }

        .input-info {
        }

        // 시간 연장
        .time-extension-wrap {
          // @include rem(margin-top, 8);
          .time-extension-btn {
            // @include f16(500);
            // border-bottom: 1px solid $line-000000;
          }
        }
      }

      .form-text-field {
        flex-wrap: wrap;
        .component-input {
          & + .component-input {
            @include rem(margin-left, 4);
          }
          & + [class*="btn-"] {
            @include rem(margin, 8 0 0 0);
            @include rem(height, 48);
            flex: 1 1 100%;
            .btn-txt {
              @include f16(500);
            }
            &.btn-submit {
            }
            &.btn-address {
              @include rem(width, 120);
              flex: none;
              @include rem(margin, 0 0 0 8);
            }
          }
          // 주민번호 뒷자리 input
          &.ssno {
            // flex: none;
            // flex-shrink: 0;
            // @include rem(width, 48);
          }
          // 주소 기본입력
          &.address-input {
            flex: none;
            flex-shrink: 0;
            width: 100%;

            & + .component-input {
              @include rem(margin, 8 0 0 0);
            }
          }

          // 카드 소유자의 정보보
          &.card-name-input {
            flex: none;
            flex-shrink: 0;
            width: 35%;
          }

          &.inner-btn {
            & + .inner-btn {
              flex: 1 1 100%;
              @include rem(margin, 8 0 0 0);
            }
          }
        }
        // 주민번호 뒷자리
        .hidden-number-list {
          // @include rem(margin-right, 8);
          ul {
            @include rem(padding, 0);
            border: 0;
            // @include rem(margin-left, 2);
            // display: flex;
            // align-items: center;
            li {
              // @include rem(width, 8);
              // @include rem(height, 8);
              // @include rem(margin-left, 8);
              // @include rem(border-radius, 50%);
              // background-color: $bg-000000;
            }
          }
        }
        .bar {
          // @include rem(margin, 0 4);
          // @include rem(width, 8);
          // height: 1px;
          // background-color: $bg-000000;
        }
        .component-select {
          // flex: 1;
          &.phone {
            width: 100%;
          }
          // & + .component-input {
          //   @include rem(margin-left, 0);
          //   @include rem(margin-top, 8);
          // }

          & + .component-select {
            @include rem(margin-left, 0);
            @include rem(margin-top, 8);
            // z-index: 15;
          }
          &.bank {
            & + .component-input {
              @include rem(margin, 8 0 0 0);
            }
          }
          &.phone {
            & + .component-input {
              @include rem(margin, 8 0 0 0);
            }
          }
        }

        & + .form-text-field {
          // @include rem(margin-top, 4);
        }
        // mo 예외처리
        &.exception {
          flex-wrap: nowrap;
          .btn-confirm {
            @include rem(max-width, 120);
            margin: 0 0 0 8px;
            padding: 0;
          }

          .component-select {
            & + .component-select {
              @include rem(margin-left, 8);
              @include rem(margin-top, 0);
            }
          }
        }
        // 주민번호 + hidden-number-list + btn
        &.registration-field {
          .hidden-number-list {
            ul {
            }
            & + [class*="btn-"] {
              flex: 1 0 100%;
              @include rem(margin, 8 0 0 0);
            }
          }
        }
      }

      .form-result {
        > dd {
          .label-txt {
            @include f16(700);
          }
        }
        > dt {
          @include f14(500);
        }
      }
    }
  }
}
