@use "../abstracts" as *;

// list-type-line
.list-type-line {
  border-top: 1px solid $line-000000;
  li {
    @include rem(padding, 24 0);
    border-bottom: 1px solid $line-e0e0e0;
    & + li {
    }
  }
}

// round-bx
.round-bx {
  @include rem(padding, 45 32);
  @include rem(margin-top, 32);
  border: 1px solid $line-e0e0e0;
  @include rem(border-radius, 8);

  &.bg-gray {
    @include rem(padding, 24 119);
    border: 0;
    background-color: $bg-f7f8fd;
  }
  &.bg-gray02 {
    @include rem(margin, 12 0);
    @include rem(padding, 32 40);
    @include f16(500);
    border: 0;
    background-color: $bg-eff3f7;
  }
  .component-form {
    width: 100%;
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // round-bx : mo
  .round-bx {
    @include rem(padding, 32 24);
    @include rem(margin-top, 32);
    &.bg-gray {
      @include rem(padding, 24);
      border: 0;
      background-color: $bg-f7f8fd;
    }
    &.bg-gray02 {
      @include rem(margin, 12 0);
      @include rem(padding, 16);
      @include f14(500);
      border: 0;
      background-color: $bg-eff3f7;
    }
    .component-form {
      width: 100%;
      text-align: left;
    }
  }
}
