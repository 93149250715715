@use "../abstracts" as *;

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
.underline {
  text-decoration-line: underline;
}
.overline {
  text-decoration-line: overline;
}
.line-through {
  text-decoration-line: line-through;
}
.no-underline {
  text-decoration-line: none;
}
