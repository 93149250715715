@use "../abstracts" as *;

.component-modal {
  display: none;
  .modal-dimm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0.7;
    display: none;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .modal-frame {
    position: fixed;
    inset: 0 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
  }

  .modal-container {
    margin: auto;
    display: none;
    // position: fixed;
    @include rem(width, 640);
    @include rem(padding, 36 0 32);
    @include rem(border-radius, 16);
    max-height: var(--doc-height);
    // inset: 0 0 0 0;
    // z-index: 110;
    background-color: $bg-ffffff;
    @include f18(500);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;

    .modal-header {
      width: 100%;
      @include rem(padding, 0 32 24 32);
      .modal-tit {
        // @include rem(border-radius, 30);
        @include f24(500);
      }
      .modal-txt {
        @include f18;
      }
    }
    .modal-content {
      overflow: auto;
      width: 100%;
      @include rem(max-height, 512);
      @include rem(padding, 0 32 0 32);
      @include f16;
      color: $font-000000;
      // sticky btn
      &.adjustment-simplified-pop {
        .terms-area-wrap {
          @include rem(height, 95);
          @include rem(margin-bottom, 16);
          .bold {
            font-weight: 700;
          }
        }
        .component-form {
          @include rem(padding-top, 56);
        }
      }
      &.h-sticky {
        @include rem(max-height, 420);
      }
      // design scroll
      &.custom-scroll {
        &::-webkit-scrollbar-thumb {
          width: 10px;
          border-radius: 16px;
          background-color: #3074f0;
          border: 3px solid transparent;
          border-right-width: 3px;
          background-clip: content-box;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: #a3a3a3;
        }
        &::-webkit-scrollbar {
          width: 11px;
          height: 12px;
        }
        &::-webkit-scrollbar-corner {
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-button {
          background-color: #d3d3d3;
          border: 0 solid #bdbdbd;
          border-width: 0 0;
          display: none;
        }
      }
      // component-form
      .component-form {
        @include rem(padding-top, 8);
      }
      // 약관
      .terms-area-wrap {
        @include rem(padding, 32 40);
        @include rem(height, 420);
        @include rem(border-radius, 8);
        overflow: auto;
        border: 1px solid $line-e0e0e0;
        .terms-list {
          strong {
            @include rem(margin-bottom, 12);
            @include f20(500);
          }
          p {
            @include f12(500);
          }
          .item-txt {
            white-space: pre-line;
          }
        }
        p {
          @include f12(500);
        }
      }
    }

    // btn Group
    .btn-group {
      margin-top: auto;
      display: flex;
      width: 100%;
      @include rem(margin-top, 24);
      .btn {
        display: block;
        width: 100%;
        .txt {
          @include f20(500);
          line-height: 1;
          color: $font-000000;
        }
        & + .btn {
          @include rem(margin-left, 8);
        }

        &.btn-primary {
          .txt {
            color: $font-ffffff;
          }
        }
      }
    }

    // modal-sticky
    .modal-sticky {
      margin: 0 auto;
      @include rem(padding, 32 0 0 0);
      .btn-group {
        margin: 0;
      }
      .btn-group {
        margin-top: 0;
        .btn {
          @include rem(width, 220);
          @include rem(height, 60);
          @include rem(border-radius, 4);
          .txt {
            @include f20(500);
            line-height: 1;
            color: $font-000000;
          }
          & + .btn {
            @include rem(margin-left, 8);
          }

          &.btn-primary {
            .txt {
              color: $font-ffffff;
            }
          }
        }
      }
    }

    .modal-close {
      position: absolute;
      @include rem(top, 32);
      @include rem(right, 32);
      @include rem(width, 48);
      @include rem(height, 48);
      background: url("pathImages/btn/btn_modal_close.png") no-repeat 0 0 / 100%
        auto;
      pointer-events: all;
      z-index: 5;
    }
  }

  // Modal full
  &.modal-full {
    .modal-frame {
      height: 100%;
    }

    .modal-container {
      width: 100%;
      height: 100%;
      border-radius: 0;
      .modal-content {
        max-height: 100%;
      }
    }
  }
  // Modal Bottom
  &.modal-bottom {
    .modal-frame {
      inset: auto;
      width: 100%;
      bottom: 0;
    }
    .modal-container {
      @include rem(padding, 20 0);
      @include rem(border-radius, 26 26 0 0);
      width: 100%;
      max-height: 100%;

      .modal-slide {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @include rem(height, 50);
        .slide-bar {
          display: inline-block;
          @include rem(height, 50);
          @include rem(height, 10);
          @include rem(border-radius, 20);
          background-color: $bg-2b2b2b;
        }
      }
      .modal-content {
        max-height: 200px;
      }
    }
  }

  // Youtube
  // &.youtube-modal {
  //   .modal-container {
  //     @include rem(width, 1400);
  //     max-height: calc(100% - 10px);
  //     max-width: calc(100% - 120px);
  //     @include rem(padding, 0);
  //     .modal-content {
  //       overflow: hidden;
  //       max-height: 768px;
  //       @include rem(padding, 0);
  //     }
  //   }

  //   .youtube-zone {
  //     position: relative;
  //     // @include rem(height, 760);
  //     padding-top: 56.25%;
  //     iframe {
  //       position: absolute;
  //       left: 0;
  //       top: 0;
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }

  //   .modal-close {
  //     position: absolute;
  //     @include rem(top, 0);
  //     @include rem(right, -64);
  //     background-image: url("pathImages/btn/btn_youtube_modal_close.png");
  //   }
  // }

  // Event Modal
  &.event-modal {
    .modal-dimm {
    }
    .modal-frame {
      inset: auto;
      right: 0;
      bottom: 0;
    }
    .modal-container {
      @include rem(width, 640);
      @include rem(padding, 0);
      border-radius: 0;
      .modal-content {
        @include rem(max-height, 1115);
        @include rem(padding, 0);
        .component-swiper {
          position: relative;
        }
        .event-area {
          .txt-info {
            position: relative;
            @include rem(padding, 40 40 105 40);
            .slogan {
              color: $font-fe508b;
              @include f12(500);
              @include rem(margin-bottom, 8);
            }
            .tit {
              @include ellipsis(1);
              @include f24(500);
              @include rem(padding-right, 176);
              @include rem(margin-bottom, 24);
            }
            a:not(.btn-modal-view) {
              overflow: hidden;
              display: block;
              width: 90%;
              @include rem(margin-bottom, 24);
              .tit {
                @include rem(margin-bottom, 0);
                padding: 0;
              }
              &:focus-visible {
                outline: none;
              }
            }

            .txt {
              span {
                display: block;
                @include f16(500);
                color: $font-777777;
              }
            }
            .btn-modal-view {
              position: absolute;
              @include rem(right, 40);
              @include rem(top, 60);
              @include rem(width, 32);
              @include rem(height, 32);
              @include rem(margin, 0);
              background: url("pathImages/btn/btn_event_arrow.png") no-repeat
                no-repeat 0 0 / 100% auto;
            }
          }
        }
        .imgbx {
          position: relative;
          @include rem(height, 800);
          img {
            width: 100%;
          }
        }
      }
    }
    .component-swiper {
      // num paging
      .navi-num {
        display: flex;
        align-items: center;
        justify-content: center;
        position: static;
        z-index: 5;
        left: auto;
        @include rem(right, 80);
        @include rem(bottom, 40);
        @include rem(width, 72);
        @include rem(height, 32);
        border: 1px solid $line-000000;
        @include rem(border-radius, 16);
        color: $font-000000;
        @include rem(line-height, 32);
        @include f16(500);
        @include rem(margin-left, auto);
        .swiper-pagination-current {
          @include rem(margin-right, 3);
        }
        .swiper-pagination-total {
          @include rem(margin-left, 3);
        }
      }
      .swiper-controls {
        position: absolute;
        @include rem(bottom, 40);
        @include rem(padding, 0 40 0 28);
        justify-content: space-between;
        z-index: 5;
        .swiper-navigation {
          position: static;
        }
        .swiper-autoplay-wrap {
          position: static;
        }
      }
    }

    .btn-zone {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $bg-ffffff;
      @include rem(height, 48);
      border-top: 1px solid $line-e0e0e0;
      [class*="modal-"] {
        flex: 1;
        .btn-txt {
          @include f12(500);
        }
      }
      .modal-look {
        // position: absolute;
        // @include rem(left, 40);
        // @include rem(bottom, 15);
        // z-index: 5;
        @include rem(padding-left, 40);
        text-align: left;
      }
      .modal-close {
        position: static;
        @include rem(padding-right, 40);
        text-align: right;
        // position: absolute;
        // @include rem(top, 40);
        // @include rem(right, 40);
        // @include rem(width, 48);
        // @include rem(height, 48);
        // background: url("pathImages/btn/btn_evnet_modal_close.png") no-repeat 0
        //   0 / 100% auto;
        // pointer-events: all;
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .component-modal {
    .modal-dimm {
    }
    .modal-frame {
      height: 100%;
    }

    .modal-container {
      width: 100%;
      height: 100%;
      border-radius: 0;
      .modal-header {
        // width: 100%;
        @include rem(padding, 0 24 48);
        .modal-tit {
          @include rem(padding-right, 20);
          // @include f24(700);
        }
        .modal-txt {
          // @include f18;
        }
      }
      .modal-content {
        // max-height: 100%;
        @include rem(padding, 0 24);
        .terms-area-wrap {
          @include rem(padding, 0);
          @include rem(height, 500);
          border: 0;
        }
        &.adjustment-simplified-pop {
          .terms-area-wrap {
            @include rem(height, auto);
            @include rem(margin-bottom, 24);
            overflow: hidden;
          }
          .component-form {
            @include rem(padding-top, 48);
          }
        }
        &.full {
          padding: 0;
        }
      }
      .modal-sticky {
        position: static;
        @include rem(width, 100%);
        // @include rem(left, 0);
        // @include rem(right, 0);
        // @include rem(bottom, 48);
        margin-top: auto;
        .btn-group {
          @include rem(padding, 0 24);
          .btn {
            @include rem(width, 100%);
            .txt {
              @include f18(700);
            }
          }
        }
      }
      .component-form {
        padding-top: 0;
        .form-group {
          .comm-bl-dot {
            & > li {
              @include f14(500);
            }
          }
        }
      }
      // modal-sticky : mo
      .modal-sticky {
        // margin: 0 auto;
        // @include rem(padding, 24 0 0 0);
        .btn-group {
          // margin: 0;
        }
        .btn-group {
          // margin-top: 0;
          .btn {
            // @include rem(width, 220);
            // @include rem(height, 60);
            // @include rem(border-radius, 4);
            .txt {
              // @include f20(500);
              // line-height: 1;
              // color: $font-ffffff;
            }
            & + .btn {
              // @include rem(margin-left, 16);
            }
          }
        }
      }

      .modal-close {
        position: absolute;
        @include rem(top, 42);
        @include rem(right, 24);
        @include rem(width, 24);
        @include rem(height, 24);
        background-image: url("pathImages/btn/btn_modal_close_mo.png");
      }
    }
    // Youtube : mo
    // &.youtube-modal {
    //   .modal-container {
    //     justify-content: center;
    //     width: 100%;
    //     height: auto;
    //     max-height: 100%;
    //     max-width: 100%;
    //     .modal-content {
    //       height: auto;
    //     }
    //   }
    //   .youtube-zone {
    //     position: relative;
    //     padding-top: 56.25%;
    //     height: auto;
    //     iframe {
    //     }
    //   }
    //   .modal-close {
    //     position: absolute;
    //     @include rem(width, 20);
    //     @include rem(height, 20);
    //     @include rem(top, -36);
    //     @include rem(right, 24);
    //     background-image: url("pathImages/btn/btn_youtube_modal_close_mo.png");
    //   }
    // }

    // Event Modal : mo
    &.event-modal {
      .modal-dimm {
      }
      .modal-frame {
        inset: 0 0 0 0;
      }
      .modal-container {
        justify-content: flex-end;
        background-color: transparent;
        width: 100%;
        .modal-content {
          max-height: 100%;

          .swiper-wrapper {
            align-items: flex-end;
          }
          .event-area {
            .txt-info {
              display: none;
            }
          }
          .imgbx {
            @include rem(height, 320);
            img {
              @include rem(border-radius, 24 24 0 0);
              object-position: 50% 0;
            }
          }
        }
      }

      .component-swiper {
        // num paging
        .navi-num {
          background: $bg-ffffff;
          .swiper-pagination-total {
            // @include rem(margin-left, 3);
          }
        }
        .swiper-controls {
          @include rem(bottom, 24);
          .swiper-navigation {
            display: none;
          }
          .swiper-autoplay-wrap {
            background: $bg-ffffff;
            border-radius: 100%;
          }
        }
      }

      .btn-zone {
        align-items: center;
        @include rem(height, 48);
        [class*="modal-"] {
          height: 100%;
          padding: 0;
          text-align: center;
          .btn-txt {
            @include f14(500);
          }
        }
        .modal-look {
          .btn-txt {
            display: inline-block;
            width: 100%;
            @include f14(500);
            border-right: 1px solid $line-e0e0e0;
          }
        }
        .modal-close {
          background: none;
          // .hide-txt {
          //   position: static;
          //   clip: auto;
          //   width: auto;
          //   height: auto;
          //   margin: 0;
          //   @include f14(500);
          // }
        }
      }
    }
  }
}
