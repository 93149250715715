@use "../abstracts" as *;

/* component input */
.component-input {
  // input 공통 - label 영역
  // .input-label {
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   flex-wrap: wrap;
  //   .label-txt {
  //     flex: 1 0 auto;
  //     @include f16(500);
  //   }
  // }
  // .input-sub-txt {
  //   width: 100%;
  // }
  // .input-label {
  //   @include rem(margin-top, 8);
  //   @include rem(margin-bottom, 0);
  //   .label-sub-txt {
  //     @include f12(500);
  //     color: $font-777777;
  //   }
  // }

  // input[type="text|search|password|email|tel|number"] 타입 - 필드 영역
  .input-field {
    position: relative;
    display: flex;
    align-items: center;
    @include rem(height, 52);
    background-color: $bg-ffffff;
    box-sizing: content-box;

    & + .input-label {
      @include rem(margin-top, 24);
    }
    input {
      border: 1px solid $line-e5e5e5;
      width: 100%;
      height: 100%;
      @include rem(border-radius, 4);
      @include rem(padding, 14 16);
      @include f16(500);
      color: $font-000000;
      &::placeholder {
        color: $font-ababab;
        font-weight: 500;
      }
      &[type="search"] {
        &::-webkit-search-cancel-button {
          appearance: none;
        }
        &::-ms-clear {
          display: none;
        }
      }
      &[type="password"] {
      }
      &[type="email"] {
      }
      &[type="tel"] {
      }
      &[type="number"] {
      }
      &:focus,
      &:focus-visible {
        outline: none;
      }
      & + .input-field-btn {
        @include rem(margin-left, 8);
      }
      &:disabled {
        border: 0;
        background-color: $bg-e0e0e0;
        color: $font-000000;
      }
      &:read-only {
        border: 0;
        background-color: $bg-e0e0e0;
        color: $font-000000;
      }
    }

    // 금액
    .input-won {
      position: absolute;
      @include rem(right, 16);
      top: 50%;
      transform: translateY(-50%);
      @include f16(500);
      color: $font-000000;
    }

    // Time
    .input-time {
      position: absolute;
      @include rem(right, 16);
      @include rem(top, 16);
      @include f13(400);
      color: $font-fe508b;
    }
  }
  .input-file-wrap {
    @include rem(height, 52);
    display: flex;
    align-items: center;
    .input-field-btn {
      @include rem(margin-left, 8);
    }
  }
  // input[type="file"] 타입 - 필드 영역
  .input-file-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include rem(height, 52);
    border: none;
    background-color: $bg-ffffff;
    overflow: hidden;
    width: 100%;
    position: relative;
    cursor: pointer;
    input[type="file"] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
      &:disabled {
        & + .input-file-txt {
          border: 0;
          background-color: $bg-f7f8fd;

          &.line {
            text-decoration: underline;
            text-underline-offset: 4px;
            color: $font-000000;
          }
        }
      }
    }
  }

  // textarea 타입 - 필드 영역
  .input-textarea-field {
    position: relative;
    display: flex;
    align-items: center;
    @include rem(height, 240);
    background-color: $bg-ffffff;
    overflow: hidden;

    textarea {
      width: 100%;
      height: 100%;
      resize: none;
      border: 1px solid $line-e0e0e0;
      @include rem(border-radius, 4);
      @include rem(padding, 14 16);
      @include f16(500);
      color: $font-000000;
      &:focus,
      &:focus-visible {
        outline: none;
      }
      &:disabled {
        border: 0;
        background-color: $bg-f7f8fd;
      }
      &:read-only {
        border: 0;
        background-color: $bg-f7f8fd;
      }
      &::placeholder {
        color: $font-ababab;
      }
    }
    .textarea-count {
      // display: none;
      position: absolute;
      @include rem(right, 12);
      @include rem(bottom, 12);
      @include f14;
      line-height: 1;
      color: $font-999999;
      opacity: 0;
      transition: opacity 0.2s;

      .textarea-count-num {
        color: $font-000000;
        .over {
          color: $font-000000;
        }
      }
    }
  }

  // input[type="text|search|password|email|tel|number"] 타입 - 버튼 영역
  .input-field-btn {
    flex: 1;
    display: block;
    height: 100%;
    @include rem(padding, 0 8);
    @include rem(border-radius, 4);
    @include f16(500);

    // 검색창 input
    &.search {
      @include rem(width, 40);
      @include rem(height, 40);
      padding: 0;
      .ico-search-header {
        @include rem(width, 40);
        @include rem(height, 40);
      }
    }
    &.clear {
      display: none;
      width: auto;
      height: auto;
      @include rem(margin-right, 8);
      @include rem(padding, 0);
    }
    &.calendar-state {
      display: flex;
      align-items: center;
      pointer-events: none;
      .ico-calendar-state {
        background: url("pathImages/icon/ico_calendar.png") no-repeat center
          center;
        background-size: 100%;
      }
    }

    &.password-state {
      .hide-txt.show {
        display: none;
      }
      .ico-password-state {
        background: url("pathImages/icon/ico_password_on.png") no-repeat center
          center;
        background-size: 100%;
      }
      &.show {
        .ico-password-state {
          background: url("pathImages/icon/ico_password_off.png") no-repeat
            center center;
          background-size: 100%;
        }
        .hide-txt.hide {
          display: none;
        }
        .hide-txt.show {
          display: inline-block;
        }
      }
    }
  }

  // input[type="file"] 타입 - 버튼 영역
  .input-file-btn {
    @include rem(width, 104);
    @include rem(padding, 0 8);
    @include rem(margin-left, 8);
    @include rem(border-radius, 4);
    @include f16(700);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #000000;
    color: #000000;
  }

  // input[type="text|search|password|email|tel|number"] 타입 - 아이콘 영역
  .input-field-ico {
    display: inline-flex;
    align-items: center;
    @include rem(width, 24);
    height: 100%;
    background-size: 100% 100%;
    @include rem(margin, 0 8);
    &.login-id {
      @include ico-login-id;
    }
    &.login-pw {
      @include ico-login-pw;
    }
    &.spinner {
      display: none;
      @include ico-spinner;
      animation: ico-spinner 0.6s linear infinite;
    }
  }

  // input[type="text|search|password|email|tel|number"] 타입 - 텍스트 영역
  .input-field-txt {
    display: inline-flex;
    align-items: center;
    height: 100%;
    @include f18();
    @include rem(padding, 0 8);
  }

  // input[type="file"] 타입 - 텍스트 영역
  .input-file-txt {
    @include rem(padding, 14 16);
    @include rem(border-radius, 4);
    @include f16(500);
    color: $font-ababab;
    border: 1px solid $line-e0e0e0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  // input 공통 - 정보 영역
  .input-info {
    display: none;
    @include f12();
  }

  // input 컨텐츠 간격
  .input-label {
    // & + .input-field {
    //   @include rem(margin-top, 8);
    // }
    // & + .input-file-field {
    //   @include rem(margin-top, 8);
    // }
    // & + .input-textarea-field {
    //   @include rem(margin-top, 8);
    // }
  }
  .input-field {
    & + .input-info {
      @include rem(margin-top, 8);
    }
  }
  .input-file-field {
    & + .input-info {
      @include rem(margin-top, 8);
    }
  }
  .input-textarea-field {
    & + .input-info {
      @include rem(margin-top, 8);
    }
  }
  // input가없고 버튼만 있는 상태
  &.input-hide {
    .input-field {
      input {
        display: none;
      }
    }
    & + button {
      margin: 0 !important;
    }
  }
  // input 상태 - 포커스
  &:focus-within {
    input {
      border: 1px solid $line-000000;
    }
    // .input-file-field {
    //   border: 1px solid $line-000000;
    // }
    textarea {
      border: 1px solid $line-000000;
    }
  }

  // input 상태 - 비활성화
  &.input-disabled {
    .input-field {
      input {
        background: $bg-f7f8fd;
      }
    }
  }

  // input 상태 - 읽기 전용
  &.input-readonly {
    .input-field {
      input {
        background: $bg-f7f8fd;
      }
    }
    .input-file-field {
      background: $bg-f7f8fd;
    }
  }

  // input 유효성 검사 - 성공
  &.input-valid {
    .input-field {
      input {
        border: 1px solid green;
      }
    }
    .input-info {
      display: block;
      color: green;
    }
  }

  // input 유효성 검사 - 에러
  &.input-invalid {
    .input-field {
      textarea,
      input {
        border: 1px solid red;
      }
    }
    .input-info {
      display: block;
      color: red;
    }
  }

  // input 유효성 검사 - 필수 입력
  // &.input-required {
  //   .ico-required-mark {
  //     color: red;
  //   }
  // }

  // Input 안에 button design 경우
  &.inner-btn {
    .input-field-btn {
      &[class*="-state"] {
        position: absolute;
        @include rem(right, 1);
        @include rem(padding, 0 12);
        border: 0;
      }
    }
    input {
      @include rem(padding-right, 50);
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /* component input : mo */
  .component-input {
    // input 공통 - label 영역 : mo
    .input-label {
      .label-txt {
        @include f16(500);
      }

      .label-util {
      }

      .label-sub-txt {
        @include f14(500);
      }
    }
    .input-sub-txt {
      width: 100%;
    }
    .input-file-txt {
      @include f14(500);
    }

    .input-field {
      @include rem(height, 48);

      & + .input-label {
        @include rem(margin-top, 24);
      }
      input {
        @include f14(500);
        ::placeholder {
          color: $font-999999;
        }
        &:focus,
        &:focus-visible {
        }
        & + .input-field-btn {
          @include rem(margin-left, 8);
          &.password-state {
            position: absolute;
            @include rem(right, 5);
            border: 0;
          }
        }
      }

      // Time
      .input-time {
        position: absolute;
        @include rem(right, 16);
        @include rem(top, 16);
        @include f13(400);
        color: $font-fe508b;
      }
    }

    .input-file-field {
      @include rem(height, 48);
    }

    .input-field-btn {
      // flex: 1;
      // display: block;
      // height: 100%;
      // @include rem(padding, 0 8);
      // @include rem(border-radius, 4);
      // @include f16(500);

      // 검색창 input
      &.search {
        // border-radius: 100%;
        // @include rem(width, 40);
        // @include rem(height, 40);
        // background-color: $bg-000000;
        .ico-search-header {
          // @include rem(width, 24);
          // @include rem(height, 24);
        }
      }
      &.clear {
        .ico-clear {
          @include rem(width, 20);
          @include rem(height, 20);
        }
      }
      &.calendar-state {
        .ico-calendar-state {
          @include rem(width, 20);
          @include rem(height, 20);
          background: url("pathImages/icon/ico_calendar.png") no-repeat center
            center;
          background-size: 100%;
        }
      }

      &.password-state {
        .hide-txt.show {
        }
        .ico-password-state {
        }
        &.show {
          .ico-password-state {
          }
          .hide-txt.hide {
          }
          .hide-txt.show {
          }
        }
      }
    }

    // input-file-btn 타입 : mo
    .input-file-btn {
      @include rem(max-width, 120);
      width: 100%;
      @include f14(500);
    }

    // textarea 타입 - 필드 영역 : mo
    .input-textarea-field {
      margin: 0;
      @include rem(height, 160);
      textarea {
        &:focus,
        &:focus-visible {
          // outline: none;
        }
        &:disabled {
        }
        &:read-only {
        }
        &::placeholder {
          @include f14(500);
        }
      }
      .textarea-count {
        .textarea-count-num.over {
        }
      }
    }
  }
}
