@use "../abstracts" as *;

.allmenu {
  display: none;
}
// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .allmenu {
    position: fixed;
    // overflow: auto;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    @include rem(padding, 56 0 0 0);
    background-color: $bg-ffffff;

    .allmenu-area {
      overflow: auto;
      height: calc(100% - 90px);
      @include rem(padding-bottom, 27);
      .allmenu-list {
        @include rem(margin, 0 24);
        border-top: 1px solid $line-000000;
      }
      .one-depth {
        overflow: hidden;
        border-bottom: 1px solid $line-e0e0e0;
        & > a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include rem(padding, 24 0);
          @include f18(700);
          .ico-allmenu-arrow {
            transform: rotate(0);
            display: none;
          }
          &::before {
            right: 0;
          }
        }
        .two-depth-bx {
          // display: none;
          .two-depth {
            a {
              @include f16(400);
            }
            & + .two-depth {
              @include rem(margin-top, 8);
            }
          }
        }
        &.open {
          @include rem(padding-bottom, 32);
          .ico-allmenu-arrow {
            transform: rotate(180deg);
          }
          .two-depth-bx {
            display: block;
          }
        }
      }
    }
    .btn-logout-area {
      @include rem(margin-top, 11);
      text-align: center;
      .btn-logout {
        @include rem(padding, 5);
        @include f14(500);
        color: $font-999999;
      }
    }

    .btn-app-download {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 4.8rem);
      @include rem(margin, 33 24 0 24);
      @include rem(padding, 20 24);
      @include rem(border-radius, 8);
      background-color: $bg-335891;
      .txt {
        @include f16(500);
        color: $font-ffffff;
      }
    }
    .btn-allmenu-login {
      display: flex;
      align-items: center;
      @include rem(margin-bottom, 24);
      @include rem(margin-left, 24);
      .btn-txt {
        @include f24(500);
      }
      [class^="ico-"] {
        @include rem(margin-left, 8);
      }
    }
    .btn-allmenu-close {
      position: absolute;
      @include rem(right, 24);
      @include rem(top, 16);
      @include rem(width, 32);
      @include rem(height, 32);
      background: url("pathImages/btn/btn_allmenu_close.png") no-repeat 0 0 /
        100% auto;
    }

    .allmenu-list {
      &.component-accordion {
        .accordion-item {
          .collapse-bx {
            display: flex;
            justify-content: space-between;
            background-color: $bg-ffffff;
            .collapse-tit {
              width: 15%;
              @include rem(padding, 24 0);
              border: 0;
              &::before {
                right: 0;
                @include rem(width, 24);
                @include rem(height, 24);
                background-image: url("pathImages/btn/btn_allmenu_arrow.png");
              }
            }
            .collapse-link {
              width: 85%;
              @include rem(padding, 24 0);
              @include f18(700);
              text-align: left;
            }
          }
          .collapse-content {
            height: auto;
            @include rem(padding, 0 0 32 0);
            border-bottom: 0;
          }
        }
      }
    }
  }
}
