@use "../abstracts" as *;

/* component tab */
.component-tab {
  // tab 공통 -  head 영역
  .tab-head {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $line-e5e5e5;
  }

  // tab 공통 -  label 영역
  .tab-label {
    position: relative;
    @include rem(padding, 18 20);
    @include f24;
    color: $font-555555;
    text-align: center;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      @include rem(height, 2);
      transform: scaleX(0);
      transform-origin: 50% 0%;
      transition: transform 0.3s;
    }

    &[aria-selected="true"] {
      @include f24(700);
      color: $font-111111;
      transition: all 0.3s;
      &::after {
        position: absolute;
        transform: scaleX(1);
        transition: transform 0.3s;
        background-color: $line-000000;
      }
    }
  }

  // tab 공통 -  body 영역
  .tab-body {
    position: relative;
    @include rem(margin-top, 32);
  }

  // tab 공통 -  content 영역
  .tab-content {
    display: none;
    @include f16;
    color: $font-000000;
    &.show {
      display: block;
    }
    .content-inner {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // @include rem(padding, 40);
    }
  }

  // 라우드 타입(우측에 있을때)
  &.tab-round {
    @include rem(margin-top, 96);
    .tab-head {
      border: 0;
      justify-content: flex-start;
    }
    &.sm-type {
      .tab-label {
        @include f16(500);
      }
    }
    .tab-label {
      @include rem(height, 43);
      @include rem(padding, 8 20);
      @include f18(500);
      @include rem(border-radius, 4);
      border: 1px solid $line-e0e0e0;
      color: $font-777777;
      background-color: $bg-ffffff;
      line-height: 1;
      &::after {
        display: none;
      }
      &[aria-selected="true"] {
        border-color: $line-000000;
        color: $font-ffffff;
        background-color: $bg-000000;
      }

      & + .tab-label {
        @include rem(margin-left, 8);
      }
    }
    &.reset {
      margin-top: 0;
    }
  }

  // 텍스트 타입(중간 탭메뉴)
  &.tab-bar {
    @include rem(margin-top, 96);
    .tab-head {
      border: 0;
      justify-content: flex-start;
    }
    .tab-label {
      display: flex;
      align-items: center;
      @include rem(height, 44);
      @include rem(padding, 0);
      @include f24(700);
      color: $font-c0c0c0;
      line-height: 1;
      &::after {
        display: none;
      }
      &[aria-selected="true"] {
        color: $font-000000;
      }

      & + .tab-label {
        @include rem(margin-left, 24);
        &::before {
          content: "";
          display: inline-block;
          width: 1px;
          @include rem(height, 16);
          @include rem(margin-right, 24);
          background-color: $bg-e0e0e0;
        }
      }
    }
    &.reset {
      margin-top: 0;
    }

    // // tab 공통 -  body 영역
    // .tab-body {
    //   position: relative;
    //   @include rem(margin-top, 48);
    // }

    .board-list {
      table {
        border-top: 0;
      }
    }
    .board-write {
      @include rem(margin-top, 48);
    }
  }

  // line style
  &.tab-line {
    > .tab-head {
      border: 0;
      > .tab-label {
        @include rem(height, 68);
        @include rem(padding, 0);
        @include f40(700);
        width: auto;
        color: $font-999999;
        &::after {
          background-color: $bg-fe508b;
          bottom: 0;
          height: 2px;
        }
        &[aria-selected="true"] {
          color: $font-000000;
        }
        & + .tab-label {
          @include rem(margin-left, 40);
          &::before {
            content: "";
            position: absolute;
            @include rem(left, -20);
            top: 50%;
            transform: translateY(-50%);
            @include rem(width, 4);
            @include rem(height, 4);
            border-radius: 100%;
            background-color: $bg-e0e0e0;
          }
        }
      }
    }

    .tab-body {
      position: relative;
      @include rem(margin-top, 80);
    }
    &.sm-type {
      @include rem(margin-top, 96);
      > .tab-head {
        border: 0;
        justify-content: flex-start;
        .tab-label {
          @include rem(height, 56);
          @include f32(700);
        }
      }
    }
  }
  &.tab-line02 {
    @include rem(margin, 40 0 120);
    .tab-label {
      @include rem(width, 100%);
      @include f24(500);
      &[aria-selected="true"] {
        color: $font-fe508b;
        &::after {
          background-color: $line-fe508b;
        }
      }
    }
  }
  // 텍스트만 있는타입
  &.tab-txt {
    .tab-head {
      border: none;
      justify-content: flex-start;
      .tab-label {
        padding: 0;
        color: $font-c0c0c0;
        &[aria-selected="true"] {
          color: $font-000000;
          &::after {
            display: none;
          }
        }
        & + .tab-label {
          @include rem(padding-left, 24);
        }
      }
    }
  }
  // 초기화
  &.reset {
    margin: 0;
  }

  // tab scroll
  &.tab-scroll {
    .tab-head {
      overflow-x: scroll;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        display: none;
      }
      .tab-label {
        // @include rem(min-width, 100);
        white-space: nowrap;
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /* component tab : mo */
  .component-tab {
    // line style : mo
    &.tab-line {
      > .tab-head {
        justify-content: flex-start;
        border: 0;
        .tab-label {
          @include rem(height, 34);
          @include rem(padding, 0);
          @include f20(500);
          color: $font-999999;
          white-space: nowrap;
          &::after {
          }
          &[aria-selected="true"] {
          }
          & + .tab-label {
            @include rem(margin-left, 40);
            white-space: nowrap;
            &::before {
            }
          }
        }
      }

      // tab 공통 -  body 영역 : mo
      .tab-body {
        @include rem(margin-top, 40);
      }

      &.sm-type {
        @include rem(margin-top, 40);
        > .tab-head {
          border: 0;
          justify-content: flex-start;
          .tab-label {
            width: auto;
            @include rem(height, 38);
            @include f20(500);
          }
        }
      }
    }

    // 라우드 타입(우측에 있을때) : mo
    &.tab-round {
      @include rem(margin-top, 0);
      .tab-head {
      }
      &.sm-type {
        .tab-label {
          @include f16(500);
        }
      }
      .tab-label {
        @include rem(height, 36);
        @include rem(padding, 6 12);
        @include f16(400);
        &::after {
          display: none;
        }
        &[aria-selected="true"] {
        }
      }
    }

    // 텍스트 타입(중간 탭메뉴) : mo
    &.tab-bar {
      @include rem(margin-top, 40);
      .tab-head {
      }
      .tab-label {
        @include rem(height, 32);
        @include rem(padding, 0);
        @include f18(700);
        &[aria-selected="true"] {
        }
        & + .tab-label {
          @include rem(margin-left, 16);
          &::before {
            @include rem(margin-right, 16);
            background-color: $bg-e0e0e0;
          }
        }
      }
      // tab 공통 -  body 영역
      .tab-body {
        position: relative;
        @include rem(margin-top, 32);
      }

      .board-list {
        table {
          border-top: 0;
          tr:first-child {
            td {
              padding-top: 0;
            }
          }
        }
      }

      .board-write {
        @include rem(margin-top, 0);
      }
    }
    // tab scroll : mo
    &.tab-scroll {
      .tab-head {
        @include rem(padding, 0 24);
        &::-webkit-scrollbar {
          display: none;
          .tab-label {
          }
        }
      }
    }
    //tab-txt : mo
    &.tab-txt {
      .tab-head {
        .tab-label {
          @include f18(700);
          & + .tab-label {
            @include rem(padding-left, 16);
          }
        }
      }
    }
  }
}
