@use "../abstracts" as *;
@forward "missionary";
@forward "forum";
@forward "member";
@forward "branch";
@forward "intro";
@forward "event";
@forward "etc";

#content {
  max-width: 1920px;
  margin: 0 auto;
  @include rem(padding, 100 0 0 0);
  max-width: calc(100% - 20px);
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // body {
  //   &::after {
  //     content: "Mo";
  //     position: fixed;
  //     left: 0;
  //     top: 0;
  //     width: 50px;
  //     height: auto;
  //     font-weight: bold;
  //     color: white;
  //     background-color: red;
  //     z-index: 1000;
  //     text-align: center;
  //   }
  // }

  #content {
    max-width: 1920px;
    margin: 0 auto;
    @include rem(padding, 80 0 0 0);
  }
}
