@use "../abstracts" as *;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  // @include rem(height, 100);
  // transition:
  //   background-color 0.3s ease,
  //   color 0.3s ease;
  // // border-bottom: 1px solid $line-e0e0e0;
  background-color: $bg-ffffff;
  z-index: 25;
  // &.on {
  //   transform: translate3d(0, -110%, 0);
  //   .header-inner {
  //   }
  // }
  .header-inner {
    width: 100%;
    // transition: all 0.3s ease;
  }

  // Header content
  .header-content {
    position: relative;
    width: 100%;
  }

  // Gnb
  .gnb-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1920px;
    margin: 0 auto;
    @include rem(height, 100);
    z-index: 5;

    // logo
    .gnb-logo {
      position: absolute;
      @include rem(left, 64);
      top: 50%;
      transform: translateY(-50%);
      .logo {
        display: inline-block;
        @include rem(width, 122);
        @include rem(height, 37);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("pathImages/logo_febc.png");
        background-size: 100%;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        margin: 0;
      }
    }

    // gnb
    .gnb-area {
      // margin-left: auto;
      .gnb-list {
        position: relative;
        display: flex;
        .one-depth {
          > a {
            position: relative;
            display: block;
            @include rem(padding, 37 52 36 52);
            // transition: all 0.3s;
            span {
              position: relative;
              @include f18(700);
              &::after {
                content: "";
                position: absolute;
                left: 0;
                @include rem(bottom, -10);
                // @include rem(bottom, 0);
                width: 100%;
                @include rem(height, 2);
                transform: scaleX(0);
                transform-origin: 50% 0%;
                transition: transform 0.3s;
              }
            }

            &.on,
            &:focus,
            &:hover {
              span {
                color: $font-fe508b;
                &::after {
                  position: absolute;
                  transform: scaleX(1);
                  transition: transform 0.3s;
                  background-color: $line-fe508b;
                }
              }
            }
          }
          .two-depth-list {
            display: none;
            overflow: hidden;
            position: absolute;
            @include rem(top, 90);
            left: 0;
            width: 100%;
            @include rem(padding-top, 25);
            text-align: center;
            .two-depth {
              a {
                padding: 0;
                span {
                  @include f16(400);
                  color: $font-000000;
                }
              }
              & + .two-depth {
                @include rem(margin-top, 16);
              }
            }
          }
        }

        @media (max-width: 1450px) {
          .one-depth {
            > a {
              @include rem(padding, 37 22 36 22);
            }
          }
        }
      }
      .bg-bar {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        @include rem(top, 101);
        background-color: $bg-ffffff;
        @include rem(height, 225);
        max-width: 1920px;
        // border-top: 1px solid $line-e0e0e0;
      }
    }

    // branch
    .branch-area {
      position: absolute;
      @include rem(left, 218);
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      .btn-branch {
        display: flex;
        align-items: center;
        @include rem(height, 30);
        @include rem(padding, 6 12);
        border: 1px solid $line-e0e0e0;
        @include rem(border-radius, 15);
        @include f12(500);
        line-height: 1;
        color: $font-000000;
        .ico-branch-arrow {
          @include rem(width, 8);
          @include rem(height, 5);
          @include rem(margin-left, 12);
          background-image: url("pathImages/icon/ico_branch_arrow.png");
          transition: transform 0.35s;
        }
      }

      .collapse-content {
        position: absolute;
        left: 0;
        @include rem(top, 38);
        @include rem(width, 320);
        height: auto;
        @include rem(border-radius, 8);
        box-shadow: 30px 30px 80px rgba(0, 0, 0, 0.25);
      }
      &[data-state="open"] {
        .ico-branch-arrow {
          transform: rotate(180deg);
        }
      }

      .branch-cont {
        @include rem(width, 320);
        @include rem(padding, 24);
        @include rem(border-radius, 8);
        background-color: $bg-ffffff;
        .branch-list {
          li {
            display: flex;
            flex: 1;
            span {
              display: inline-block;
              @include rem(width, 128);
              & + span {
                @include rem(margin-left, 16);
              }
            }
            a {
              display: flex;
              // justify-content: space-between;
              @include f12(500);
              &::after {
                content: "";
                display: inline-block;
                @include rem(width, 20);
                @include rem(height, 20);
                margin-left: auto;
                background: url("pathImages/btn/btn_branch_arrow.png") no-repeat
                  0 0 / 100% auto;
              }
            }
            & + li {
              @include rem(margin-top, 9);
            }
          }
        }
      }
    }

    // User
    .user {
      position: absolute;
      @include rem(right, 64);
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      padding: 0;
      .join-area {
        button[class*="btn-"] {
          position: relative;
          @include rem(padding, 10 8);
          @include f12(500);
          color: $font-000000;
        }
      }

      .btn-onair {
        display: flex;
        align-items: center;
        @include rem(padding, 0 12);
        .component-lottie {
          display: none;
          @include rem(width, 30);
          @include rem(height, 30);
          &[data-props-name="onair_pink"] {
            display: block;
          }
        }

        .txt {
          @include f16(500);
          color: $font-fe508b;
        }
      }
      .btn-search {
        @include rem(margin-left, 8);
      }
      .btn-allmenu {
        display: none;
      }
    }
    // Icon
    i[class*="ico-"] {
      display: inline-block;
      overflow: hidden;
      @include rem(width, 32);
      @include rem(height, 32);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      color: transparent;
      font-size: 1rem;
      white-space: nowrap;
      vertical-align: middle;
      margin: 0;
      &.ico-login {
        background-image: url("pathImages/icon/ico_login.png");
        background-size: 50%;
      }
      &.ico-allmenu {
        background-image: url("pathImages/icon/header_allmenu.png");
      }
      &.ico-header-search {
        @include rem(width, 20);
        @include rem(height, 20);
        background-image: url("pathImages/icon/ico_header_search.png");
      }
      &.ico-header-onair {
        @include rem(width, 94);
        @include rem(height, 32);
        background-image: url("pathImages/icon/ico_header_onair.png");
      }
    }
  }
  // Main Header
  &.shine {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    border-bottom: 0;
    .header-inner {
    }
    .gnb-wrap {
      // logo
      .gnb-logo {
        .logo {
          @include rem(height, 48);
          background-image: url("pathImages/logo_febc_white.png");
        }
      }
      // gnb
      .gnb-area {
        .gnb-list {
          .one-depth {
            .two-depth-list {
            }
            a {
              span {
                color: $font-ffffff;
              }
              &.on,
              &:focus,
              &:hover {
                span {
                }
                & + .two-depth-list {
                  span {
                  }
                }
              }
            }
          }
        }
      }

      // branch
      .branch-area {
        .btn-branch {
          border: 1px solid $line-ffffff;
          color: $font-ffffff;
          .ico-branch-arrow {
            background-image: url("pathImages/icon/ico_branch_arrow_white.png");
          }
        }
      }
      // User
      .user {
        .join-area {
          button[class*="btn-"] {
            color: $font-ffffff;
            & + [class*="btn-"] {
              // &::before {
              //   background-color: $bg-ffffff;
              //   opacity: 0.15;
              // }
            }
          }
        }
        .btn-onair {
          .component-lottie {
            display: none;
            &[data-props-name="onair_white"] {
              display: block;
            }
          }
          .txt {
            color: $font-ffffff;
          }
        }
      }
    }
    // Icon
    i[class*="ico-"] {
      &.ico-header-search {
        background-image: url("pathImages/icon/ico_header_search_white.png");
      }
      &.ico-header-onair {
        background-image: url("pathImages/icon/ico_header_onair_white.png");
      }
    }
  }

  // theme-white
  &.theme-white {
    background-color: $bg-ffffff;
    border-bottom: 1px solid $line-e0e0e0;
    .gnb-wrap {
      // logo
      .gnb-logo {
        .logo {
          background-image: url("pathImages/logo_febc.png");
        }
      }
      // gnb
      .gnb-area {
        .gnb-list {
          .one-depth {
            position: relative;
            .two-depth-list {
              // display: block;
            }
            a {
              span {
                color: $font-000000;
              }
              &.on,
              &:focus,
              &:hover {
                span {
                  color: $font-fe508b;
                }
                & + .two-depth-list {
                  span {
                  }
                }
              }
            }
          }
        }
        .bg-bar {
          // display: block;
        }
      }
      // branch
      .branch-area {
        .btn-branch {
          border: 1px solid $line-e0e0e0;
          color: $font-000000;
          .ico-branch-arrow {
            background-image: url("pathImages/icon/ico_branch_arrow.png");
          }
        }
      }
      // User
      .user {
        .join-area {
          button[class*="btn-"] {
            color: $font-000000;
            & + [class*="btn-"] {
              // &::before {
              //   background-color: $bg-ffffff;
              //   opacity: 0.15;
              // }
            }
          }
        }
        .btn-onair {
          .component-lottie {
            display: block;
            &[data-props-name="onair_pink"] {
              display: block;
            }
            &[data-props-name="onair_white"] {
              display: none;
            }
          }
          .txt {
            color: $font-fe508b;
          }
        }
      }
    }
    // Icon
    i[class*="ico-"] {
      &.ico-header-search {
        background-image: url("pathImages/icon/ico_header_search.png");
      }
      &.ico-header-onair {
        background-image: url("pathImages/icon/ico_header_onair.png");
      }
    }
  }

  //open
  &.open {
    background-color: $bg-ffffff;
    border-bottom: 1px solid $line-e0e0e0;
    .gnb-wrap {
      // logo
      .gnb-logo {
        .logo {
          background-image: url("pathImages/logo_febc.png");
        }
      }
      // gnb
      .gnb-area {
        .gnb-list {
          .one-depth {
            position: relative;
            .two-depth-list {
              display: block;
            }
            > a {
              span {
                color: $font-000000;
                @include f18(700);
              }
              &.on,
              &:focus,
              &:hover {
                span {
                  color: $font-fe508b;
                }
                & + .two-depth-list {
                  span {
                  }
                }
              }
            }
          }
        }
        .bg-bar {
          display: block;
        }
      }
      // branch
      .branch-area {
        .btn-branch {
          border: 1px solid $line-e0e0e0;
          color: $font-000000;
          .ico-branch-arrow {
            background-image: url("pathImages/icon/ico_branch_arrow.png");
          }
        }
      }
      // User
      .user {
        .join-area {
          button[class*="btn-"] {
            color: $font-000000;
            & + [class*="btn-"] {
              // &::before {
              //   background-color: $bg-ffffff;
              //   opacity: 0.15;
              // }
            }
          }
        }
        .btn-onair {
          .component-lottie {
            display: block;
            &[data-props-name="onair_pink"] {
              display: block;
            }
            &[data-props-name="onair_white"] {
              display: none;
            }
          }
          .txt {
            color: $font-fe508b;
          }
        }
      }
    }
    // Icon
    i[class*="ico-"] {
      &.ico-header-search {
        background-image: url("pathImages/icon/ico_header_search.png");
      }
      &.ico-header-onair {
        background-image: url("pathImages/icon/ico_header_onair.png");
      }
    }
  }
}
// 검색 레이어
.header-search-wrap {
  overflow: hidden;
  position: relative;
  top: -1px;
  display: none;
  width: 100%;
  height: 100% !important;
  // max-width: 1920px;
  margin: 0 auto;
  background-color: $bg-ffffff;
  .search-dimm {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    // z-index: 99;
    z-index: 100;
  }
  .search-inner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include rem(width, 1108);
    // @include rem(height, 80);
    // @include rem(padding, 40 0);
    margin: 0 auto;
    z-index: 101;
  }
  .component-input {
    width: 100%;
    @include rem(height, 80);
    height: 80px;
    margin: 0 auto;

    .input-field {
      border: 0;
      @include rem(height, 80);
      @include rem(padding, 20 32);
      @include rem(border-radius, 4);
      box-sizing: border-box;
      input {
        border: 0;
        padding: 0;
        margin: 0;
        @include f20(500);
        &::placeholder {
          color: $font-000000;
          @include f20(500);
          line-height: 4;
        }
      }
    }
  }
  .btn-search-close {
    position: absolute;
    @include rem(right, 0);
    @include rem(top, -64);
    @include rem(width, 48);
    @include rem(height, 48);
    background: url("pathImages/btn/btn_search_close.png") no-repeat 0 0 / 100%
      auto;
  }
  &.on {
    display: block;
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .header {
    border-bottom: 0;
    background-color: $bg-ffffff;
    // z-index: 25;
    z-index: 100;

    .header-inner {
      @include rem(padding, 0 24 0 24);
    }

    // Header content : mo
    .header-content {
      position: static;
    }

    // Gnb : mo
    .gnb-wrap {
      position: static;
      justify-content: flex-start;
      align-items: center;
      @include rem(height, 80);
      // logo : mo
      .gnb-logo {
        position: static;
        transform: none;
        .logo {
          @include rem(width, 88);
          @include rem(height, 26);
          background-image: url("pathImages/logo_febc_mo.png");
          background-size: 100%;
        }
      }

      // gnb : mo
      .gnb-area {
        display: none;
      }

      // branch : mo
      .branch-area {
        position: static;
        transform: none;
        @include rem(margin-left, 12);
        .btn-branch {
          @include rem(padding, 6 0);
          @include f16(500);
          color: $font-000000;
          border: 0;
          strong {
            display: none;
          }
          .ico-branch-arrow {
            background-image: url("pathImages/icon/ico_branch_arrow.png");
          }
        }

        .collapse-content {
          position: absolute;
          width: 100%;
          @include rem(top, 80);
          @include rem(left, 0);
          border-radius: 0;
          strong {
            display: block;
          }
        }
        &[data-state="open"] {
          .ico-branch-arrow {
          }
        }

        .branch-cont {
          overflow-y: scroll;
          width: 100%;
          border-radius: 0;
          @include rem(max-height, 320);
          // height: 100vh;
          padding-top: 0;
          .branch-list {
            li {
              display: block;
              width: 100%;
              span {
                display: block;
                width: 100%;
                // @include rem(padding, 24 0);
                border-bottom: 1px solid $line-e0e0e0;
                & + span {
                  @include rem(margin-left, 0);
                }
              }
              a {
                display: flex;
                // justify-content: space-between;
                @include f16(700);
                @include rem(padding, 24 0);
                strong {
                  @include f16(700);
                }
              }
              & + li {
                @include rem(margin-top, 0);
              }
            }
          }
        }
      }

      // User : mo
      .user {
        position: static;
        transform: none;
        margin-left: auto;
        // @include rem(right, 0);
        .join-area {
          display: none;
          button[class*="btn-"] {
          }
        }

        .btn-onair {
          display: none;
        }
        .btn-search {
          @include rem(margin-right, 16);
        }
        .btn-allmenu {
          display: block;
        }
      }
      // Icon : mo
      i[class*="ico-"] {
        @include rem(width, 24);
        @include rem(height, 24);
        &.ico-login {
        }
        &.ico-allmenu {
        }
        &.ico-header-onair {
        }

        &.ico-header-calendar {
          @include rem(width, 24);
          @include rem(height, 24);
          background-image: url("pathImages/icon/ico_header_calendar.png");
        }
        &.ico-header-search {
          @include rem(width, 24);
          @include rem(height, 24);
          background-image: url("pathImages/icon/ico_header_search_mo.png");
        }
      }
    }
    // Main Header : mo
    &.shine {
      z-index: 20;
      background-color: $bg-ffffff;
      .gnb-wrap {
        .gnb-logo {
          .logo {
            background-image: url("pathImages/logo_febc_mo.png");
          }
        }
        // branch
        .branch-area {
          .btn-branch {
            border: 0;
            @include f16(500);
            color: $font-000000;
            .ico-branch-arrow {
              background-image: url("pathImages/icon/ico_branch_arrow.png");
            }
          }
        }
        // User
        .user {
          .join-area {
            button[class*="btn-"] {
              & + [class*="btn-"] {
              }
            }
          }
        }
      }
      // Icon
      i[class*="ico-"] {
        &.ico-header-search {
          background-image: url("pathImages/icon/ico_header_search.png");
        }
        &.ico-header-onair {
          background-image: url("pathImages/icon/ico_header_onair.png");
        }
      }
    }

    // theme-white
    &.theme-white {
      background-color: $bg-ffffff;
      .gnb-wrap {
        // logo
        .gnb-logo {
          .logo {
            background-image: url("pathImages/logo_febc.png");
          }
        }
        // branch
        .branch-area {
          .btn-branch {
            border: 0;
            color: $font-000000;
            .ico-branch-arrow {
              background-image: url("pathImages/icon/ico_branch_arrow.png");
            }
          }
        }
        // User
        .user {
          .join-area {
            button[class*="btn-"] {
              color: $font-000000;
              & + [class*="btn-"] {
                // &::before {
                //   background-color: $bg-ffffff;
                //   opacity: 0.15;
                // }
              }
            }
          }
        }
      }
      // Icon
      i[class*="ico-"] {
        &.ico-header-search {
          background-image: url("pathImages/icon/ico_header_search.png");
        }
        &.ico-header-onair {
          background-image: url("pathImages/icon/ico_header_onair.png");
        }
      }
    }

    //open
    &.open {
      background-color: $bg-ffffff;
      border-bottom: 1px solid $line-4051b8;
      .gnb-wrap {
        // logo
        .gnb-logo {
          .logo {
            background-image: url("pathImages/logo_febc.png");
          }
        }
        // branch
        .branch-area {
          .btn-branch {
            .ico-branch-arrow {
              background-image: url("pathImages/icon/ico_branch_arrow.png");
            }
          }
        }
        // User
        .user {
          .join-area {
            button[class*="btn-"] {
              & + [class*="btn-"] {
              }
            }
          }
        }
      }
      // Icon
      i[class*="ico-"] {
        &.ico-header-search {
          background-image: url("pathImages/icon/ico_header_search.png");
        }
        &.ico-header-onair {
          background-image: url("pathImages/icon/ico_header_onair.png");
        }
      }
    }
  }

  // 검색 레이어 : mo
  .header-search-wrap {
    .search-inner {
      width: 100%;
    }
    .component-input {
      width: 100%;
      @include rem(height, 56);
      .input-field {
        @include rem(margin, 0 24);
        padding: 0;
        @include rem(height, 56);
        input {
          @include f16(500);
          @include rem(padding-left, 15);
          &::placeholder {
            @include f16(500);
          }
        }
        .input-field-btn {
          &.search {
            .ico-search-header {
              background-image: url("pathImages/icon/ico_search_header_mo.png");
              @include rem(background-size, 20 20);
            }
          }
        }
      }
    }
    .btn-search-close {
      position: absolute;
      @include rem(right, 24);
      @include rem(top, -30);
      @include rem(width, 24);
      @include rem(height, 24);
      background: url("pathImages/btn/btn_search_close.png") no-repeat 0 0 /
        100% auto;
    }
    &.on {
    }
  }
  // 검색결과 : mo
  .search-result-wrap {
    .hgroup-component {
      .hgroup-tit {
        @include f24(700);
      }
      @include rem(margin-bottom, 15);
    }
    .component-input {
      .input-field {
        @include rem(border-bottom, 2px solid $bg-000000);
        input {
          border: 0;
          @include f16(500);
          padding-left: 0;
        }
      }
      .input-field-btn {
        &.search {
          border-radius: 0;
          background-color: $bg-ffffff;
          width: auto;
          height: auto;
          padding: 0;
          margin: 0;
          .ico-search-header {
            @include rem(width, 32);
            @include rem(height, 32);
            background-image: url("pathImages/icon/ico_search_header_mo.png");
          }
        }
      }
    }
    .result-msg {
      @include rem(margin-top, 8);
      @include f14(500);
      .txt-point {
        @include f14(500);
        color: $font-fe508b;
      }
    }
    .search-result-area {
      @include rem(margin-top, 64);
      .collapse-tit {
        @include rem(height, 72);
        @include rem(padding, 24 24 24 0);
        span {
          @include rem(margin-left, 3);
        }
      }

      .accordion-item {
        .collapse-content {
          @include rem(padding, 16 0 32 0);
        }
      }

      .result-info {
        li {
          a {
            display: block;
          }
          & + li {
            @include rem(margin-top, 32);
          }
        }
        .img-zone {
          overflow: hidden;
          @include rem(margin-bottom, 16);
          @include rem(border-radius, 8);
        }
        .txt-info {
          @include f14(500);
          span {
            & + span {
              &::before {
                content: "";
                display: inline-block;
                width: 1px;
                @include rem(height, 11);
                @include rem(margin, 0 8);
                background-color: $bg-e0e0e0;
              }
            }
          }
          .tit {
            @include f16(500);
            @include rem(margin-bottom, 16);
            .txt-point {
              color: $font-fe508b;
              @include f16(700);
              @include rem(margin-left, 3);
            }
          }
          .txt-day {
            @include rem(margin-bottom, 4);
          }
          .txt-produce {
          }
        }
      }
    }
  }
}
