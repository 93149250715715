@use "../abstracts" as *;

.datepicker {
  padding: 0;
  .datepicker-picker {
    @include rem(border-radius, 8);
    @include rem(width, 100%);
    @include rem(padding, 40 32);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  }
  .datepicker-header {
    @include rem(margin-bottom, 32);
    .datepicker-controls {
      padding: 0;
      position: relative;
      display: block;
      .button.view-switch {
        height: auto;
        padding: 0;
        justify-content: flex-start;
        // pointer-events: none;
      }
      .prev-button {
        @include rem(width, 20);
        @include rem(height, 20);
        @include rem(right, 32);
        @include rem(top, 50%);
        position: absolute;
        transform: translateY(-50%);
        background: url("pathImages/btn/btn_swiper_sub_l.png") no-repeat;
        background-size: 20px 20px;
        font-size: 0;
        text-indent: -9999px;
      }
      .next-button {
        @include rem(width, 20);
        @include rem(height, 20);
        @include rem(right, 0);
        @include rem(top, 50%);
        position: absolute;
        transform: translateY(-50%);
        background: url("pathImages/btn/btn_swiper_sub_r.png") no-repeat;
        background-size: 20px 20px;
        font-size: 0;
        text-indent: -9999px;
      }
      .button {
        @include f24(400 !important);
        padding: 0;
        background-color: transparent;
      }
    }
  }
  .datepicker-main {
    padding: 0;
    width: 100%;
  }
  .datepicker-view {
    @include rem(min-width, 264);
    width: 100%;
    .days-of-week {
      @include rem(margin-bottom, 8);
      span.dow:first-child,
      span.dow:last-child {
        color: $font-777777;
      }
    }
  }
}
.datepicker-cell:not(.day) {
  @include rem(height, 65);
}
.datepicker .dow {
  @include rem(width, 40);
  @include rem(height, 40);
  @include f16(500);
}
.datepicker span {
  @include rem(width, 40);
  @include rem(height, 40);
  @include f16(500);
}
.datepicker-cell.day.highlighted {
  color: $font-777777;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(
    .disabled
  ):hover {
  background-color: $bg-ffffff;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: $bg-ffffff;
}
.datepicker-cell.next:not(.disabled),
.datepicker-cell.prev:not(.disabled) {
  color: $font-000000;
  opacity: 0.3;
}
.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  @include rem(border-radius, 100%);
  background-color: $bg-fe508b !important;
  color: $font-ffffff !important;
}
.datepicker-cell.focused:not(.selected) {
  @include rem(border-radius, 100% !important);
  background-color: $bg-fe508b !important;
  color: $font-ffffff;
}
.datepicker-cell:not(.disabled):hover {
  background-color: $bg-ffffff;
}
