.datepicker {
  width: -moz-min-content;
  width: min-content
}

.datepicker:not(.active) {
  display: none
}

.datepicker-dropdown {
  padding-top: 4px;
  position: absolute;
  z-index: 20
}

.datepicker-dropdown.datepicker-orient-top {
  padding-bottom: 4px;
  padding-top: 0
}

.datepicker-picker {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px hsla(0, 0%, 4%, .1), 0 0 0 1px hsla(0, 0%, 4%, .1)
}

.datepicker-main {
  flex: auto;
  padding: 2px
}

.datepicker-footer {
  background-color: #f5f5f5;
  box-shadow: inset 0 1px 1px hsla(0, 0%, 4%, .1)
}

.datepicker-title {
  background-color: #f5f5f5;
  box-shadow: inset 0 -1px 1px hsla(0, 0%, 4%, .1);
  font-weight: 700;
  padding: .375rem .75rem;
  text-align: center
}

.datepicker-controls {
  display: flex
}

.datepicker-header .datepicker-controls {
  padding: 2px 2px 0
}

.datepicker-controls .button {
  align-items: center;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  box-shadow: none;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: center;
  line-height: 1.5;
  margin: 0;
  padding: calc(.375em - 1px) .75em;
  position: relative;
  text-align: center;
  vertical-align: top;
  white-space: nowrap
}

.datepicker-controls .button:active,
.datepicker-controls .button:focus {
  outline: none
}

.datepicker-controls .button:hover {
  border-color: #b8b8b8;
  color: #363636
}

.datepicker-controls .button:focus {
  border-color: #3273dc;
  color: #363636
}

.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 .125em rgba(50, 115, 220, .25)
}

.datepicker-controls .button:active {
  border-color: #474747;
  color: #363636
}

.datepicker-controls .button[disabled] {
  cursor: not-allowed
}

.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: 700
}

.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9
}

.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2
}

.datepicker-footer .datepicker-controls .button {
  border-radius: 2px;
  flex: auto;
  font-size: .75rem;
  margin: calc(.375rem - 1px) .375rem
}

.datepicker-controls .view-switch {
  flex: auto
}

.datepicker-controls .next-button,
.datepicker-controls .prev-button {
  flex: 0 0 14.2857142857%;
  padding-left: .375rem;
  padding-right: .375rem
}

.datepicker-controls .next-button.disabled,
.datepicker-controls .prev-button.disabled {
  visibility: hidden
}

.datepicker-grid,
.datepicker-view {
  display: flex
}

.datepicker-view {
  align-items: stretch;
  width: 15.75rem
}

.datepicker-grid {
  flex: auto;
  flex-wrap: wrap
}

.datepicker .days {
  display: flex;
  flex: auto;
  flex-direction: column
}

.datepicker .days-of-week {
  display: flex
}

.datepicker .week-numbers {
  display: flex;
  flex: 0 0 9.6774193548%;
  flex-direction: column
}

.datepicker .weeks {
  align-items: stretch;
  display: flex;
  flex: auto;
  flex-direction: column
}

.datepicker span {
  -webkit-touch-callout: none;
  align-items: center;
  border-radius: 4px;
  cursor: default;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.datepicker .dow {
  font-size: .875rem;
  font-weight: 700;
  height: 1.5rem
}

.datepicker .week {
  color: #b8b8b8;
  flex: auto;
  font-size: .75rem
}

.datepicker .days .dow,
.datepicker-cell {
  flex-basis: 14.2857142857%
}

.datepicker-cell {
  height: 2.25rem
}

.datepicker-cell:not(.day) {
  flex-basis: 25%;
  height: 4.5rem
}

.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer
}

.datepicker-cell.focused:not(.selected) {
  background-color: #e9e9e9
}

.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: #3273dc;
  color: #fff;
  font-weight: 600
}

.datepicker-cell.disabled {
  color: #dcdcdc
}

.datepicker-cell.next:not(.disabled),
.datepicker-cell.prev:not(.disabled) {
  color: #7a7a7a
}

.datepicker-cell.next.selected,
.datepicker-cell.prev.selected {
  color: #e6e6e6
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: #f5f5f5;
  border-radius: 0
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #efefef
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e9e9e9
}

.datepicker-cell.today:not(.selected) {
  background-color: #00d1b2
}

.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff
}

.datepicker-cell.today.focused:not(.selected) {
  background-color: #00ccad
}

.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #b8b8b8;
  color: #fff
}

.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #b3b3b3
}

.datepicker-cell.range-start:not(.range-end) {
  border-radius: 4px 0 0 4px
}

.datepicker-cell.range-end:not(.range-start) {
  border-radius: 0 4px 4px 0
}

.datepicker-cell.range {
  background-color: #dcdcdc;
  border-radius: 0
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d7d7d7
}

.datepicker-cell.range.disabled {
  color: #c6c6c6
}

.datepicker-cell.range.focused {
  background-color: #d1d1d1
}

.datepicker-input.in-edit {
  border-color: #276bda
}

.datepicker-input.in-edit:active,
.datepicker-input.in-edit:focus {
  box-shadow: 0 0 .25em .25em rgba(39, 107, 218, .2)
}