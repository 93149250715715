@use "../abstracts" as *;

.p-0 {
  padding: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.ps-0 {
  padding-inline-start: 0;
}
.pe-0 {
  padding-inline-end: 0;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
