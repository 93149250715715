@use "../abstracts" as *;

// 서브메인Pc

.febc-introduction-wrap {
  .details-info-bx {
    @include rem(max-width, 1400);
    @include rem(margin, 80 auto 0);
    .details-info-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border-top: 1px solid $line-e0e0e0;
      border-bottom: 1px solid $line-e0e0e0;
      .details-info-item {
        @include rem(padding, 64 28 64 38);
        @include rem(height, 430);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-right: 1px solid $line-e0e0e0;
        &:last-child {
          border: none;
        }
        .tit {
          @include f24(700);
          & + .txt {
            @include rem(margin-top, 24);
          }
        }
        .txt {
          @include f18(400);
          display: block;
        }
        .desc {
          @include f16(500);
          color: $font-777777;
          &.pink-txt {
            @include f16(500);
            color: $font-fe508b;
          }
        }
      }
    }
  }
  .fixed-tab {
    @include rem(width, 100%);
    @include rem(bottom, 10);
    background-color: $bg-ffffff;
    position: absolute;
    left: 0;
    right: 0;
    &.on {
      position: fixed;
      bottom: 1rem;
    }
    .tab-head {
      @include rem(height, 90);
      justify-content: space-between;
      .tab-label {
        @include rem(width, 100%);
        @include rem(height, 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        & + .tab-label {
          padding: 0;
        }
        &[aria-selected="true"] {
          position: relative;
          &::before {
            content: "";
            display: block;
            position: absolute;
            @include rem(top, 15);
            @include rem(left, 50%);
            @include rem(width, 8);
            @include rem(height, 8);
            @include rem(border-radius, 50%);
            background-color: $bg-000000;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  .strength-progress-bar {
    @include rem(width, 100%);
    @include rem(height, 10);
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 5;
    background-color: $bg-ffffff;
    &.on {
      bottom: 0;
      position: fixed;
    }
    .strength-progress-bar-on {
      // @include rem(width, 24%);
      @include rem(height, 10);
      background-color: $bg-335891;
      left: 0;
      bottom: 0;
      position: absolute;
      // transition: width 0.5s ease;
    }
  }
  // 회사개요 : pc
  .febc-company-overview-wrap {
    .hgroup-component {
      @include rem(max-width, 1400);
      margin: 0 auto;
    }
    .febc-visual-wrap {
      @include rem(margin-top, 80);
      position: relative;
      img {
        @include rem(width, 100%);
        @include rem(height, 100%);
      }
      .hgroup-component {
        @include rem(max-width, 1920);
        @include rem(width, 100%);
        @include rem(top, 120);
        position: absolute;
        left: 0;
        .tit-info-bx {
          @include rem(max-width, 1400);
          margin: auto;
          .hgroup-tit,
          .hgroup-txt {
            color: $font-ffffff;
          }
        }
      }
    }
    .febc-company-overview-info {
      @include rem(margin-top, 160);
      .hgroup-sub-tit {
        @include rem(margin-bottom, 48);
      }
      .hgroup-desc {
        & + .hgroup-desc {
          @include rem(margin-top, 24);
        }
      }
    }
    .details-info-bx {
      .details-info-list {
        .details-info-item {
          @include rem(padding, 40 38);
          @include rem(height, 320);
          .tit {
            @include f40(700);
          }
          .desc {
            @include f18(400);
          }
        }
      }
    }
    .febc-successive-ministers-wrap {
      @include rem(max-width, 1920);
      @include rem(height, 856);
      @include rem(margin, 80 auto 0);
      background: url("pathImages/contents/febc_successive_bg.png") no-repeat
        center center;
      background-size: 100%;
      .component-swiper {
        @include rem(max-width, 1400);
        margin: 0 auto;
        position: relative;
      }
      .swiper-container {
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .info-bx {
            .label-tag.round-type.block {
              background-color: $bg-000000;
              color: $font-ffffff;
            }
            .name {
              @include rem(margin, 24 0 16);
              @include f40(700);
            }
            .year {
              @include f24(400);
            }
          }
          .img-bx-motion {
            @include rem(width, 856);
            @include rem(height, 856);
            @include rem(margin-right, 20);
            @include rem(border-radius, 50%);
            position: relative;
            img {
              @include rem(width, 640);
              @include rem(height, 640);
              @include rem(top, 50%);
              @include rem(left, 50%);
              transform: translate(-50%, -50%);
              @include rem(border-radius, 50%);
              position: absolute;
              box-shadow: 10px 0 10px rgba(0, 0, 0, 0.2);
              z-index: 3;
            }
            // .out-line,
            // .in-line {
            //   position: absolute;
            //   top: 50%;
            //   left: 50%;
            //   transform: translate(-50%, -50%);
            //   border-radius: 100%;
            //   background-color: $bg-transparent;
            //   @include rem(border, 2 solid #f8dbdb);
            //   animation: circle-wave 3s infinite;
            // }
            // .out-line {
            //   animation-delay: 0.4s;
            //   @include rem(border-width, 6);
            // }
          }
        }
        // 20241121 backup
        .swiper-wrapper {
          position: relative;
          .out-line,
          .in-line {
            position: absolute;
            top: 50%;
            left: 68%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            background-color: $bg-transparent;
            @include rem(border, 2 solid #f8dbdb);
          }
          .in-line {
            animation: circle-wave 2s infinite linear;
          }
          .out-line {
            animation: circle-wave 2s 1s infinite linear;
          }
        }
      }

      .info-bx {
        @include rem(width, 37%);
      }
      .swiper-pagination {
        &.swiper-pagination-fraction {
          @include rem(top, 63%);
          position: absolute;
          left: 0;
        }
      }
      .swiper-autoplay-wrap {
        @include rem(top, 63%);
        @include rem(left, 6%);
        position: absolute;
        z-index: 5;
      }
      .swiper-navigation {
        .swiper-button-prev {
          @include rem(width, 20);
          @include rem(height, 20);
          @include rem(top, 64.7%);
          @include rem(left, 30%);
          background: url("pathImages/btn/btn_swiper_sub_l.png") no-repeat;
          background-size: 20px 20px;
        }
        .swiper-button-next {
          @include rem(width, 20);
          @include rem(height, 20);
          @include rem(top, 64.7%);
          @include rem(left, 34%);
          background: url("pathImages/btn/btn_swiper_sub_r.png") no-repeat;
          background-size: 20px 20px;
        }
      }
      .swiper-autoplay {
        border: 0;
      }
    }
    .febc-promotion-info-wrap {
      @include rem(padding, 160 0 320);
      background-color: $bg-f7f8fd;
      .febc-promotion-info-bx {
        @include rem(max-width, 1400);
        margin: 0 auto;
        .hgroup-component {
          @include rem(margin-bottom, 48);
        }

        img {
          @include rem(width, 100%);
          @include rem(height, 100%);
        }
      }
    }
    .febc-promotional-speech-swiper-wrap {
      @include rem(top, -200);
      position: relative;
      .swiper-backface-hidden {
        .swiper-slide {
          @include rem(padding, 10);
          .info-bx {
            @include rem(height, 510);
            @include rem(padding, 56 64 80 64);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background-color: $bg-ffffff;
            .tit-bx {
              @include rem(margin-bottom, 40);
              display: flex;
              align-items: center;
              justify-content: space-between;
              .tit {
                @include f32(700);
              }
              i {
                @include rem(width, 80);
                @include rem(height, 80);
              }
              .ico-promotional-01 {
                background: url("pathImages/icon/ico_promotional_01.png")
                  no-repeat;
                background-size: 80px 80px;
              }
              .ico-promotional-02 {
                background: url("pathImages/icon/ico_promotional_03.png")
                  no-repeat;
                background-size: 80px 80px;
              }
              .ico-promotional-03 {
                background: url("pathImages/icon/ico_promotional_02.png")
                  no-repeat;
                background-size: 80px 80px;
              }
            }
            .txt {
              @include f18(400);
              & + .txt {
                @include rem(margin-top, 24);
              }
            }
          }
        }
      }
      .swiper-navigation {
        .swiper-button-prev {
          @include rem(left, 22%);
        }
        .swiper-button-next {
          @include rem(right, 22%);
        }
      }
    }
    .febc-network-wrap {
      .network-img-bx {
        @include rem(max-width, 1400);
        @include rem(margin, 40 auto 0);
        position: relative;
        img {
          @include rem(width, 100%);
          @include rem(height, 100%);
        }
        .ico-network {
          @include rem(width, 64);
          @include rem(height, 64);
          @include rem(top, 0);
          @include rem(right, 0);
          position: absolute;
          background: url("pathImages/icon/ico_network.png") no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  // 홍보센터 : pc
  .febc-public-relations-wrap {
    .btn-area {
      @include rem(margin-top, 80);
    }
    .hgroup-component {
      @include rem(max-width, 1400);
      margin: 0 auto;
    }

    .febc-public-relations-info-bx {
      @include rem(margin, 240 auto 0);
      @include rem(max-width, 1400);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img-bx {
        @include rem(width, 57%);
        img {
          @include rem(width, 100%);
          @include rem(height, 100%);
        }
      }
      .hgroup-component {
        @include rem(width, 40%);
        margin: 0;
      }
      &.swiper-bx {
        @include rem(margin-top, 120);
        align-items: flex-start;
        .hgroup-component {
          @include rem(width, 42%);
        }
        .hgroup-desc + .hgroup-desc {
          @include rem(margin-top, 24);
        }
        .febc-introduction-swiper-bx {
          display: flex;
          position: relative;

          .febc-introduction-swiper-01,
          .febc-introduction-swiper-03 {
            @include rem(width, 329);
            @include rem(height, 452);
            @include rem(margin-top, 174);
          }
          .febc-introduction-swiper-02,
          .febc-introduction-swiper-04 {
            @include rem(width, 329);
            @include rem(height, 452);
            @include rem(margin-left, 28);
          }
          .swiper-container {
            @include rem(height, 100%);
            .swiper-slide {
              img {
                @include rem(width, 100%);
                @include rem(height, 100%);
              }
            }
          }
          .swiper-button-prev {
            @include rem(width, 20);
            @include rem(height, 20);
            @include rem(right, 10%);
            left: auto;
            top: auto;
            bottom: 0;
            background-image: url("pathImages/btn/swiper_arrow_prev.png");
            background-size: 100%;
          }
          .swiper-button-next {
            @include rem(width, 20);
            @include rem(height, 20);
            @include rem(right, 0%);
            left: auto;
            top: auto;
            bottom: 0;
            background-image: url("pathImages/btn/swiper_arrow_next.png");
            background-size: 100%;
          }
          .swiper-autoplay.play {
            display: none;
          }
        }
      }
      &.type02 {
        @include rem(margin-top, 240);
        flex-direction: row-reverse;
      }
    }
    // 홍보센터 상세 : pc
    &.details {
      .hgroup-component {
        @include rem(max-width, 1400);
        margin: 0 auto;
      }
      .febc-public-relations-info-bx {
        @include rem(max-width, 1400);
        @include rem(margin, 120 auto 0);
        align-items: flex-start;
        .hgroup-component {
          width: 100%;
          margin: 0;
        }
        .img-bx {
          @include rem(width, 100%);
        }
      }
      .febc-history-info-bx {
        @include rem(margin-top, 160);
        @include rem(padding, 160 0);
        background-color: $bg-f7f8fd;
        .inner {
          @include rem(max-width, 1400);
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          .history-info {
            .hgroup-component {
              @include rem(margin, 0 0 64 0);
              @include rem(width, 100%);
            }
            .address {
              dl {
                & + dl {
                  @include rem(margin-top, 40);
                }
                dt {
                  @include rem(margin-bottom, 16);
                  @include f18(700);
                }
                dd {
                  @include f18(400);
                  & + dd {
                    @include rem(margin-top, 8);
                  }
                  &.subway,
                  &.bus {
                    span {
                      @include rem(padding-left, 28);
                      position: relative;
                    }
                  }
                  &.subway {
                    span {
                      background: url("pathImages/icon/ico_subway_block.png")
                        no-repeat left center;
                      background-size: 20px 20px;
                    }
                  }
                  &.bus {
                    span {
                      background: url("pathImages/icon/ico_bus_block.png")
                        no-repeat left center;
                      background-size: 20px 20px;
                    }
                  }
                  &.area {
                    @include rem(padding-left, 15);
                    @include f16(400);
                    position: relative;
                    color: $font-999999;
                    &::before {
                      content: "*";
                      display: block;
                      position: absolute;
                      @include rem(top, 10%);
                      @include rem(left, 0);
                    }
                  }
                }
              }
            }
          }
          .history-img {
            @include rem(width, 50%);
            img {
              @include rem(width, 100%);
              @include rem(height, 100%);
            }
          }
        }
      }
    }
  }
  // 회사연혁 : pc
  .febc-company-history-wrap {
    position: relative;

    .hgroup-component {
      @include rem(max-width, 1400);
      @include rem(margin, 0 auto 80);
    }
    .febc-company-history-content {
      @include rem(margin-top, 80);
      @include rem(max-width, 1400);
      margin: 0 auto;
      .strength-progress-bar {
        .strength-progress-bar-on {
          @include rem(width, 16.5%);
        }
      }
      .fixed-tab {
        position: static;
        &.on {
          position: static;
        }
        .tab-head {
          position: absolute;
          bottom: 10px;
          left: 0;
          right: 0;
          z-index: 5;
          background-color: $bg-ffffff;
          &.on {
            position: fixed;
            bottom: 1rem;
          }
        }

        .content-inner {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          position: relative;
          @include rem(padding-bottom, 100);

          .content-info {
            position: sticky;
            top: 0;
            background-color: $bg-ffffff;
            &::before {
              content: "";
              display: block;
              position: absolute;
              @include rem(top, 524);
              @include rem(left, -250);
              @include rem(width, 470);
              @include rem(height, 316);
              background: url("pathImages/contents/febc_intro_bg.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
      .component-tab {
        .tab-body {
          margin: 0;
        }
      }
      .sub-txt {
        @include rem(margin-bottom, 40);
        @include f40(700);
        color: $font-c0c0c0;
      }
      // .content-info {
      //   position: sticky;
      //   @include rem(top, -15);
      //   background-color: $bg-ffffff;
      // }
      .years {
        @include f104(600);
        display: flex;
        ul {
          position: relative;
          li {
            @include rem(width, 68);
            @include rem(height, 0);
            opacity: 0;
            text-align: center;
            transition:
              opacity 0.3s ease,
              transform 0.3s ease;
            &.before {
              transform: translateY(-100px);
            }
            &.active {
              @include rem(height, auto);
              opacity: 1;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              transform: translateY(0);
            }
            &.after {
              transform: translateY(100px);
            }
          }
        }
      }
      .details {
        @include rem(margin-top, 165);
        @include rem(width, 924);

        ul {
          &.year-item {
            transition: opacity 0.3s ease;
            opacity: 0.2;
            &.active {
              opacity: 1;
              li {
                &:first-child {
                  @include rem(padding-top, 40);
                  border-top: 1px solid $line-e0e0e0;
                }
              }
            }
            &:nth-child(1) {
              &.active {
                li {
                  &:first-child {
                    @include rem(padding-top, 0);
                    border-top: 0;
                  }
                }
              }
            }
          }
          li {
            @include rem(padding, 40 0);
            display: flex;
            align-items: flex-start;
            & + li {
              border-top: 1px solid $line-e0e0e0;
            }
            &:first-child {
              padding-top: 0;
            }
          }
        }
        .year {
          @include rem(width, 155);
          @include f20(500);
          display: block;
        }
        .details-info {
          flex: 1;
          .txt {
            @include f20(500);
            & + .desc {
              @include rem(margin-top, 8);
            }
          }
          .desc {
            display: block;
            @include f16(500);
            color: $font-777777;
            & + .desc {
              @include rem(margin-top, 8);
            }
          }
        }
      }
    }
  }
  // 인사말 : pc
  .febc-greeting-wrap {
    .hgroup-component {
      @include rem(max-width, 1400);
      margin: 0 auto;
    }
    .febc-greeting-visual-wrap {
      @include rem(max-width, 1920);
      @include rem(height, 640);
      background-color: $bg-f7f8fd;
      .inner {
        @include rem(max-width, 1400);
        @include rem(width, 100%);
        @include rem(height, 100%);
        margin: 0 auto;
        background: url("pathImages/contents/febc_greeting_bg.png") no-repeat
          left 140px;
        position: relative;
        img {
          @include rem(left, 120);
          position: absolute;
          bottom: 0;
        }
        .hgroup-component {
          @include rem(width, 610);
          @include rem(top, 50%);
          transform: translateY(-50%);
          right: 0;
          position: absolute;
          text-align: center;
          .hgroup-tit {
            color: $font-335891;
          }
          i {
            @include rem(width, 48);
            @include rem(height, 42);
          }
          .ico-quotation-01 {
            @include rem(margin-bottom, 40);
            background: url("pathImages/icon/ico_quotation_01.png") no-repeat;
            background-size: 100%;
          }
          .ico-quotation-02 {
            @include rem(margin-top, 40);
            background: url("pathImages/icon/ico_quotation_02.png") no-repeat;
            background-size: 100%;
          }
        }
      }
    }
    .febc-introduction-minister-wrap {
      @include rem(max-width, 1400);
      @include rem(padding, 80 120);
      @include rem(top, -50);
      position: relative;
      margin: 0 auto;
      box-shadow: 7px 10px 20px rgba(0, 0, 0, 0.2);
      background-color: $bg-ffffff;
      .hgroup-component {
        .hgroup-sub-tit {
          @include rem(margin-bottom, 40);
          .pink-txt {
            color: $font-fe508b;
          }
        }
        .hgroup-sub-txt {
          @include f20(500);
          & + .hgroup-sub-txt {
            @include rem(margin-top, 24);
          }
        }
      }
      .ico-febc-signature {
        @include rem(width, 176);
        @include rem(height, 56);
        @include rem(right, 120);
        @include rem(bottom, 80);
        position: absolute;
        background: url("pathImages/contents/febc_signature.png") no-repeat;
        background-size: 100%;
      }
    }
    .febc-strength-wrap {
      .inner {
        @include rem(max-width, 1220);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .strength-contents {
          @include rem(padding-bottom, 120);
          position: relative;
          .history-bar {
            @include rem(width, 1);
            height: calc(100% - 268px);
            @include rem(left, 50%);
            @include rem(top, 50);
            position: absolute;
            transform: translateX(-50%);
            background-color: $bg-e0e0e0;
            .history-bar-on {
              @include rem(width, 1);
              // 스크립트 들어가면 삭제
              @include rem(height, 0);
              background-color: $bg-fe508b;
              position: relative;
              .pointer {
                @include rem(width, 16);
                @include rem(height, 16);
                @include rem(bottom, 0);
                @include rem(left, 50%);
                @include rem(border-radius, 50%);
                transform: translateX(-50%);
                position: absolute;
                background-color: $bg-fe508b;
                z-index: 2;
                &::before,
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  @include rem(border, 8 solid $line-fe508b);
                  border-radius: 50%;
                  z-index: -1;
                  animation: pin-wave 2s infinite;
                }
                &::after {
                  animation-delay: 0.4s;
                }
              }
            }
          }
          .year-item {
            display: flex;
            justify-content: center;
            transition: scroll-behavior 0.5s ease-in-out;

            & + .year-item {
              @include rem(margin-top, 240);
            }
            > div:nth-child(odd) {
              @include rem(padding-right, 80);
              @include rem(width, 50%);
              text-align: right;
            }
            > div:nth-child(even) {
              @include rem(padding, 40 0 0 80);
              @include rem(width, 50%);
            }
            .year-txt {
              span {
                @include f104(600);
                display: inline-block;
                &.on {
                  color: $font-fe508b;
                }
              }
            }
            .year-info {
              img + span {
                @include rem(margin-top, 24);
              }
              span {
                @include f16(500);
                display: block;
                color: $font-777777;
              }
              p {
                @include rem(padding, 8 0 24);
                @include f20(500);
                & + p {
                  @include rem(margin-top, -24);
                }
                & + span {
                  @include rem(padding-top, 40);
                }
              }
            }
          }
        }
      }
      // .component-tab {
      //   @include rem(bottom, 10);
      //   position: absolute;
      //   bottom: 10px;
      //   left: 0;
      //   right: 0;
      //   &.on {
      //     position: fixed;
      //     bottom: 1rem;
      //   }
      //   .tab-head {
      //     @include rem(height, 90);
      //     justify-content: space-between;
      //     background-color: $bg-ffffff;
      //     .tab-label {
      //       @include rem(width, 100%);
      //       & + .tab-label {
      //         padding: 0;
      //       }
      //       &[aria-selected="true"] {
      //         position: relative;
      //         &::before {
      //           content: "";
      //           display: block;
      //           position: absolute;
      //           @include rem(top, -6);
      //           @include rem(left, 50%);
      //           @include rem(width, 8);
      //           @include rem(height, 8);
      //           @include rem(border-radius, 50%);
      //           background-color: $bg-000000;
      //           transform: translateX(-50%);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  // 지사장인사말 : pc
  .branch-manager {
    @include rem(max-width, 1400);
    margin: 0 auto;

    .msg-tit {
      dt {
        display: flex;
        align-items: center;
        span {
          display: block;
          @include f32(700);
          @include rem(margin-left, 8);
        }
      }
      dd {
        @include rem(margin-top, 24);
        @include f56(700);
      }
    }

    .msg-cont {
      @include rem(margin-top, 88);
      @include rem(padding-top, 104);
      border-top: 1px solid $line-e0e0e0;

      .txt-slogan {
        @include rem(margin-bottom, 48);
        @include f32(500);
      }
      p {
        @include f20(500);
        & + p {
          @include rem(margin-top, 32);
        }
      }
    }

    .msg-user {
      @include rem(margin-top, 80);
      dt {
        @include rem(margin-bottom, 24);
        @include f24(400);
        color: $font-777777;
      }
      dd {
        display: flex;
        align-items: end;
        span {
          display: block;
          @include f20(500);
          @include rem(margin-left, 24);
        }
      }
    }
  }

  // 선교소식지 : pc
  .febc-mission-newsletter-wrap {
    @include rem(margin-top, 96);
    .mission-newsletter-list-bx {
      @include rem(margin-top, 40);
      @include rem(column-gap, 148);
      @include rem(row-gap, 80);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .mission-newsletter-item {
        display: flex;
        align-items: center;
        > a {
          display: flex;
          align-items: center;
        }
        img {
          @include rem(width, 210);
          @include rem(height, 266);
        }
        .mission-newsletter-info {
          @include rem(margin-top, 24);
          @include rem(padding-left, 28);

          .txt-bx {
            @include rem(margin-bottom, 16);
            @include rem(padding-bottom, 72);
            border-bottom: 1px solid $line-e0e0e0;
            .tit {
              @include rem(margin-bottom, 8);
              @include f24(700);
            }
            .txt {
              @include rem(margin-bottom, 32);
              @include f18(700);
            }
            .desc {
              @include f18(400);
            }
          }
          .preview-more {
            @include rem(padding-left, 32);
            position: relative;
            display: inline-block;
            &::before {
              @include rem(width, 24);
              @include rem(height, 24);
              @include rem(top, 50%);
              @include rem(left, 0);
              content: "";
              display: block;
              position: absolute;
              transform: translateY(-50%);
              background: url("pathImages/icon/ico_preview.png") no-repeat left
                center;
              background-size: 24px 24px;
            }
            span {
              @include f12(500);
            }
          }
        }
      }
    }
  }
  // 전국 지사 안내 : pc
  .febc-branch-wrap {
    @include rem(margin-top, 96);
    .febc-branch-map {
      display: flex;
      justify-content: space-between;
      &.mo-show {
        display: none;
      }
      .febc-map-point-list {
        @include rem(width, 50%);
        @include rem(height, 917);
        display: block;
      }
      .febc-map-point-list {
        position: relative;
        border: none;
        img {
          @include rem(width, 684);
          @include rem(height, 100%);
          position: absolute;
          top: 0;
          left: 0;
        }
        .febc-map-name {
          @include rem(padding, 16 75 16 58);
          @include rem(top, 40);
          @include rem(left, 40);
          @include rem(border-radius, 8);
          @include f18(500);
          background-color: $bg-ffffff;
          border: 1px solid $line-e0e0e0;
          position: absolute;

          &::before {
            content: "";
            display: block;
            position: absolute;
            @include rem(width, 14);
            @include rem(height, 14);
            @include rem(top, 50%);
            @include rem(left, 35);
            @include rem(border-radius, 50%);
            transform: translateY(-50%);
            background-color: $bg-c0c0c0;
          }
          em {
            @include f16(500);
          }
        }
      }
      .point {
        @include rem(width, 32);
        @include rem(height, 32);
        position: absolute;
        border: 0;
        padding: 0;
        background: url("pathImages/icon/febc_map_point_disabled.png") no-repeat;
        background-size: 100%;
        span {
          @include rem(width, 50);
          @include rem(top, 25);
          @include rem(left, 50%);
          @include f12(500);
          display: block;
          position: relative;
          transform: translateX(-50%);
          color: $font-777777;
        }
        &[aria-selected="true"] {
          background: url("pathImages/icon/febc_map_point.png") no-repeat;
          background-size: 100%;
          &::after {
            display: none;
          }
        }
        &.point-01 {
          @include rem(top, 240);
          @include rem(left, 210);
        }
        &.point-02 {
          @include rem(top, 520);
          @include rem(left, 210);
        }
        &.point-03 {
          @include rem(top, 665);
          @include rem(left, 240);
        }
        &.point-04 {
          @include rem(top, 625);
          @include rem(left, 390);
        }
        &.point-05 {
          @include rem(top, 200);
          @include rem(left, 395);
        }
        &.point-06 {
          @include rem(top, 440);
          @include rem(left, 240);
        }
        &.point-07 {
          @include rem(top, 820);
          @include rem(left, 190);
        }
        &.point-08 {
          @include rem(top, 665);
          @include rem(left, 135);
        }
        &.point-09 {
          @include rem(top, 530);
          @include rem(left, 395);
        }
        &.point-10 {
          @include rem(top, 470);
          @include rem(left, 500);
        }
        &.point-11 {
          @include rem(top, 615);
          @include rem(left, 465);
        }
        &.point-12 {
          @include rem(top, 575);
          @include rem(left, 490);
        }
        &.point-13 {
          @include rem(top, 630);
          @include rem(left, 175);
        }
      }
    }
  }
}
// FEBC 선교소식지 : pc
.great-books-layer {
  .preview-popup-container {
    width: 100%;
    position: relative;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    @include rem(margin-top, 40);
    opacity: 0;
    transition: opacity 0.2s ease-out;
    &.init-done {
      opacity: 1;
    }
    @media (max-width: 1023px) {
      .preview-popup-content {
        position: relative;
        height: 100%;

        @media (orientation: landscape) {
          img {
            max-height: 100%;
            min-height: 200px;
          }
        }
        @media (orientation: portrait) {
          img {
            max-width: 100%;
            min-height: 200px;
          }
        }
        .video-box {
          position: relative;
          text-align: center;
          padding-top: 55.9%;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
        }
        iframe,
        embed {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        video {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        .page {
          background-color: #ffffff;
          display: flex;
          align-items: center;
        }
        .page-book-container {
          position: relative;
          z-index: 1;
          display: flex;
          // align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          @media (orientation: landscape) {
            align-items: stretch;
          }
          @media (orientation: portrait) {
            align-items: center;
          }
        }
        .ui-page-book {
          transition: margin 0.2s ease-in-out;
          // min-width:100%;
          z-index: 3;
          min-width: 300px;
          min-height: 300px;
        }
      }
    }
    @media (min-width: 1024px) {
      .preview-popup-content {
        position: relative;
        height: 100%;
        &.video-type {
          @include rem(width, 800px);
          max-width: 100%;
        }
        img {
          max-height: calc(100vh - 400px);
          max-width: calc(50vw - 120px);
          min-height: 200px;
        }
        .video-box {
          position: relative;
          text-align: center;
          padding-top: 55.9%;
          width: 100%;
        }
        iframe,
        embed {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        video {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        .page {
          background-color: #ffffff;
          display: flex;
          align-items: center;
        }
        .page-book-container {
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        .ui-page-book {
          transition: margin 0.2s ease-in-out;
          // min-width:100%;
          z-index: 3;
          min-width: 300px;
          min-height: 300px;
        }
      }
    }

    .preview-controler-area {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 20px;
      z-index: 2;
      @media (orientation: landscape) {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        &.visible {
          opacity: 1;
        }
      }

      .page-type-box {
        display: flex;
        & + .page-audio-control {
          margin-left: 15px;
        }
        .btn {
          width: 50%;
          flex: 1 1 auto;
          font-size: 13px;
          background-color: #ffffff;
          border: 1px solid #222222;
          border-radius: 5px 0 0 5px;
          padding: 7px 13px;
          color: #222222;
          transition:
            color 0.3s ease-in-out,
            background-color 0.3s ease-in-out;
          &:hover,
          &:focus {
            background-color: #dddddd;
          }
          & + .btn {
            border-radius: 0 5px 5px 0;
          }
          &.on {
            background-color: #222222;
            color: #ffffff;
          }
        }
      }
      .left-area {
        display: flex;
      }
      .right-area {
        display: flex;
      }
      .prev-btn {
        background-image: url(../images/common/ico_page_btn@2x.png);
        width: 25px;
        height: 30px;
        background-size: 7px 12px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
        border: 1px solid #dddddd;
        display: inline-block;
      }
      .next-btn {
        background-image: url(../images/common/ico_page_btn@2x.png);
        transform: rotate(180deg);
        width: 25px;
        height: 30px;
        background-size: 7px 12px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
        border: 1px solid #dddddd;
        display: inline-block;
      }
      .drop-down-container {
        margin: 0 5px;
      }
      .drop-down-list {
        position: absolute;
        top: calc(100% - 3px);
        @media (orientation: landscape) {
          top: calc(100% - 6px);
        }
        background-color: #f5f5f5;
        border: 1px solid #222222;
        border-top: 0;
        width: calc(100% + 2px);
        overflow: auto;
        margin-left: -1px;
        @include rem(max-height, 140px);

        .drop-down-txt {
          font-size: 13px;
        }
        .drop-down-link {
          padding: 0 9px;
          height: 28px;
        }
      }
      .drop-down-btn {
        padding: 0;
        padding: 0 9px;
        height: 28px;
        min-width: 75px;
        background-color: #f5f5f5;
        white-space: nowrap;

        .drop-down-ico {
          background-image: url(../images/common/ico_select_arrow@2x.png);
          width: 7px;
          background-size: 7px 5px;
        }
      }
      .drop-down-index {
        font-size: 13px;
        font-weight: 500;
        color: #222222;
      }
    }
    .show-current-page {
      position: absolute;
      @include rem(bottom, 32);
      @include rem(min-width, 72);
      @include rem(padding, 4 12);
      @include rem(height, 32);
      display: flex;
      left: calc(50% - 3.6rem);
      background-color: #fff;
      @include rem(border-radius, 16);
      @include rem(font-size, 16);
      font-weight: medium;
      align-items: center;
      justify-content: center;
      color: #dddddd;
      z-index: 5;
      border: 1px solid #000000;
      .current,
      .total,
      .txt {
        color: #000000;
      }
      .txt {
        @include rem(padding, 0 4);
      }
    }
    .btn-box {
      position: absolute;
      z-index: 15;
      top: calc(50% - 4rem);
      left: 0;
      width: 100%;
      height: 0;
      font-size: 0;

      button {
        @include rem(width, 40);
        @include rem(height, 40);
        @include rem(top, 50%);
        @include rem(border-radius, 40);
        position: absolute;
        display: inline-block;
        transform: translateY(-50%);
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        border: 0;
        background-color: rgba(255, 255, 255, 0.8);
        transition: opacity 0.3s ease-in-out;
        z-index: 3;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 0;
        &:hover,
        &:focus {
          opacity: 1;
        }
        &.visible {
          opacity: 1;
        }
      }
      i {
        display: flex;
        transition:
          background-color 0.25s ease-out,
          opacity 0.25s ease-out;
        background-size: 11px 19px;
      }
      .preview-prev {
        @include rem(left, 32);
        background-image: url("pathImages/btn/btn_swiper_sub_l.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px 20px;
      }
      .preview-next {
        @include rem(right, 32);
        background-image: url("pathImages/btn/btn_swiper_sub_r.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px 20px;
      }
    }

    .preview-popup-tit {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: #222222;
      letter-spacing: -0.025em;
    }

    .preview-popup-footer {
      .btn-container {
        padding: 50px 20px 45px 20px;
      }
    }

    .btn-close-popup {
      position: absolute;
      top: 0;
      right: 0;
      padding: 18px 17px 18px 17px;

      .ico-close {
        display: inline-block;
        position: relative;
        width: 33px;
        height: 33px;
        background: url("../images/common/btn_popup_close@2x.png") no-repeat
          center center;
        background-size: 33px 33px;
      }
    }
  }

  .modal-container {
    // @include rem(width, 1200);
    @media (max-width: 1023px) {
      width: 100%;
    }
    width: auto;
    max-height: none;
    padding-bottom: 0;
    .modal-content {
      overflow: visible;
    }
    .page-book-container {
      overflow: visible;
      @include rem(padding-bottom, 80);
    }
    .modal-header {
      .modal-tit {
        text-align: center;
      }
    }
    .modal-content {
      // @include rem(max-height, 962);
      max-height: none;
      padding: 0;
    }
    .preview-controler-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include rem(padding, 0 32);

      .page-btn-awrp {
        ul {
          display: flex;
          align-items: center;

          .page-btn {
            @include rem(width, 20);
            @include rem(height, 20);
          }
          .prev {
            @include rem(width, 20);
            @include rem(height, 20);
            background: url("pathImages/btn/btn_swiper_sub_l.png") no-repeat
              center center;
            background-size: 100%;
          }
          .next {
            @include rem(width, 20);
            @include rem(height, 20);
            background: url("pathImages/btn/btn_swiper_sub_r.png") no-repeat
              center center;
            background-size: 100%;
          }
          .page-number {
            @include rem(width, 100);
            @include rem(height, 52);
            @include rem(font-size, 16);
            @include rem(margin, 0 16);
            @include rem(padding, 0 16);
            @include rem(border-radius, 4);
            border: 1px solid $line-e0e0e0;
            text-align: center;
          }
        }
      }
      .page {
        background-color: #fff;
        display: flex;
        align-items: center;
      }
    }

    .preview-current-page {
      @include rem(width, 72);
      @include rem(padding, 4 12);
      @include rem(border-radius, 40);
      margin: 0 auto;
      border: 1px solid $line-000000;
      text-align: center;
    }
  }
}
// 전국 지사 안내 info : pc
.febc-reanch-map-info {
  @include rem(width, 40%);
  margin: 0 !important;
  .hgroup-component {
    .desc-bx {
      @include rem(margin, 8 0 32);
      ul {
        margin: 0;
        border: 0;
        li {
          display: block;
          padding: 0;
          border: 0;
        }
      }

      span {
        @include f18(400);
        color: $font-000000;
        & + span {
          @include rem(margin-left, 32);
          position: relative;
          &::before {
            content: "";
            display: block;
            position: absolute;
            @include rem(width, 1);
            @include rem(height, 12);
            @include rem(top, 50%);
            @include rem(left, -16);
            transform: translateY(-50%);
            background-color: $bg-e0e0e0;
          }
        }
      }
    }
  }
  img {
    @include rem(width, 100%);
    @include rem(height, 100%);
  }
  ul {
    @include rem(margin-top, 30);
    border-top: 1px solid $line-000000;
    border-bottom: 1px solid $line-000000;
    li {
      @include rem(padding, 32 0);
      display: flex;
      align-items: center;
      & + li {
        border-top: 1px solid $line-e0e0e0;
      }
      span {
        @include rem(width, 100);
        @include f20(500);
        color: $font-777777;
      }
      p {
        @include f20(500);
        flex: 1;
      }
    }
    .address-list {
      > div {
        display: flex;
        align-items: flex-start;
        & + div {
          @include rem(margin-top, 32);
        }
        span {
          @include rem(padding-left, 28);
          color: $font-000000;
          &.subway {
            background: url("pathImages/icon/ico_subway_gray.png") no-repeat
              left center;
            background-size: 20px 20px;
          }
          &.bus {
            background: url("pathImages/icon/ico_bus_gray.png") no-repeat left
              center;
            background-size: 20px 20px;
          }
          &.car {
            background: url("pathImages/icon/ico_car_gray.png") no-repeat left
              center;
            background-size: 20px 20px;
          }
          &.walk {
            background: url("pathImages/icon/ico_walk_gray.png") no-repeat left
              center;
            background-size: 20px 20px;
          }
        }
        p {
          @include f18(400);
        }
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // 서브메인Mo
  .febc-introduction-wrap {
    .details-info-bx {
      @include rem(margin-top, 72);
      @include rem(padding, 0 24);
      @include rem(width, 100%);

      .details-info-list {
        display: block;
        .details-info-item {
          @include rem(padding, 24 16);
          height: auto;
          justify-content: baseline;
          border-bottom: 1px solid $line-e0e0e0;
          border-right: none;
          &:last-child {
            border: none;
          }
          .tit {
            @include f18(700);
            & + .txt {
              @include rem(margin-top, 16);
            }
          }
          .txt {
            @include f16(400);
          }
          .desc {
            @include rem(margin-top, 32);
            @include f14(500);
            &.pink-txt {
              @include f14(500);
            }
          }
        }
      }
    }

    // 회사개요 : mo
    .febc-company-overview-wrap {
      .hgroup-component {
        @include rem(max-width, 100%);
        @include rem(width, 100%);
        @include rem(padding, 0 24);
        margin: 0 !important;
      }
      .febc-visual-wrap {
        @include rem(margin-top, 40);
        .hgroup-component {
          @include rem(width, 100%);
          @include rem(top, 72);
          @include rem(padding, 0 24 0 24);
          left: 0;
          margin: 0;
          .tit-info-bx {
            @include rem(width, 312);
            margin: 0;
          }
        }
      }
      .febc-company-overview-info {
        @include rem(margin-top, 72);
        @include rem(padding, 0 24);
        .hgroup-component {
          padding: 0;
        }
        .hgroup-sub-tit {
          @include rem(margin-bottom, 32);
          @include f32(700);
        }
        .hgroup-desc {
          @include f16(400);
          & + .hgroup-desc {
            @include rem(margin-top, 24);
          }
        }
      }
      .details-info-bx {
        @include rem(margin-top, 40);
        .details-info-list {
          .details-info-item {
            @include rem(padding, 32 0);
            @include rem(height, auto);
            flex-direction: unset;
            align-items: center;
            .txt-bx {
              .tit {
                @include f32(700);
                margin: 0;
              }
            }
            .desc {
              @include f16(500);
              margin: 0;
            }
          }
        }
      }
      .febc-successive-ministers-wrap {
        @include rem(max-width, 100%);
        @include rem(padding, 124 0 72);
        @include rem(margin, 56 0 0 0);
        @include rem(height, 746);
        background: url("pathImages/contents/febc_successive_bg_mo.png")
          no-repeat top left;
        position: relative;
        .swiper-container {
          .swiper-wrapper {
            .in-line {
              animation: circle-wave-mo 3s infinite;
              @include rem(left, 74%);
              @include rem(top, 65%);
              @include rem(width, 360);
              @include rem(height, 360);
            }
            .out-line {
              animation: circle-wave-mo 3s infinite;
              animation-delay: 0.4s;
              @include rem(left, 74%);
              @include rem(top, 65%);
              @include rem(width, 420);
              @include rem(height, 420);
              @include rem(border-width, 4);
            }
          }
          .swiper-slide {
            display: block;
            text-align: right;

            &.swiper-slide-prev {
              .img-bx-motion {
                overflow: hidden;
                // img,
                .in-line,
                .out-line {
                  // animation: circle-wave-mo 3s infinite;
                }
              }
            }
            .info-bx {
              @include rem(width, 100%);
              @include rem(padding, 0 24);
              @include rem(margin-bottom, 40);
              text-align: left;
              .name {
                @include rem(margin, 24 0 16);
                @include f32(700);
              }
              .year {
                @include f20(500);
              }
            }
            .img-bx-motion {
              @include rem(width, 420);
              @include rem(height, 420);
              @include rem(margin-right, 13);
              img {
                @include rem(left, 65%);
                @include rem(width, 320);
                @include rem(height, 320);
              }
              // .in-line {
              //   animation: circle-wave-mo 3s infinite;
              //   @include rem(left, 65%);
              //   @include rem(width, 360);
              //   @include rem(height, 360);
              // }
              // .out-line {
              //   animation: circle-wave-mo 3s infinite;
              //   animation-delay: 0.4s;
              //   @include rem(left, 65%);
              //   @include rem(width, 420);
              //   @include rem(height, 420);
              //   @include rem(border-width, 4);
              // }
            }
          }
        }
        .info-bx {
          @include rem(width, 37%);
        }
        .swiper-pagination {
          &.swiper-pagination-fraction {
            @include rem(top, 35%);
            @include rem(left, 5%);
            position: absolute;
          }
        }
        .swiper-autoplay-wrap {
          @include rem(top, 35%);
          @include rem(left, 27%);
          position: absolute;
          z-index: 5;
        }
        .swiper-navigation {
          .swiper-button-prev {
            @include rem(top, 37.5%);
            @include rem(right, 15%);
            left: auto;
          }
          .swiper-button-next {
            @include rem(top, 37.5%);
            @include rem(right, 5%);
            left: auto;
          }
        }
      }
      .febc-promotion-info-wrap {
        @include rem(padding, 72 0 260);
        .febc-promotion-info-bx {
          @include rem(max-width, 100%);
          margin: 0;
          .hgroup-component {
            @include rem(margin-bottom, 40 !important);
          }
          .vidio-wrap {
            .vidio-player {
              @include rem(padding, 0 24);
            }
          }
          .ico-play-bx {
            @include rem(padding, 0 24);
            img {
              @include rem(border-radius, 8);
            }
          }
        }
      }
      .febc-promotional-speech-swiper-wrap {
        .swiper-backface-hidden {
          .swiper-slide {
            .info-bx {
              @include rem(height, 630);
              @include rem(padding, 32 24);
              .tit-bx {
                @include rem(margin-bottom, 32);
                .tit {
                  @include f20(700);
                }
                i {
                  @include rem(width, 40);
                  @include rem(height, 40);
                }
                .ico-promotional-01,
                .ico-promotional-02,
                .ico-promotional-03 {
                  background-size: 40px 40px;
                }
              }
              .txt {
                @include f16(400);
                & + .txt {
                  @include rem(margin-top, 24);
                }
              }
            }
          }
        }
        .swiper-navigation {
          display: none;
        }
      }
      .febc-network-wrap {
        @include rem(margin-top, -72);
        .network-img-bx {
          @include rem(max-width, 100%);
          @include rem(width, 100%);
          margin: 0;
          overflow-y: hidden;
          overflow-x: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none; /* Chrome , Safari , Opera */
          }
          img {
            @include rem(width, 1067);
            @include rem(height, 100%);
          }
          .ico-network {
            @include rem(width, 128);
            @include rem(height, 32);
            @include rem(top, 230);
            @include rem(right, 150);
            position: absolute;
            background: url("pathImages/icon/ico_network.png") no-repeat;
            background-size: 100%;
            transition: opacity 0.5s;
          }
        }
      }
    }
    // 홍보센터 : mo
    .febc-public-relations-wrap {
      br {
        display: block !important;
      }
      .btn-area {
        @include rem(margin-top, 56);
      }
      .febc-public-relations-info-bx {
        @include rem(margin-top, 72);
        @include rem(padding, 0 24);
        display: flex;
        flex-direction: column-reverse;
        &.type02 {
          @include rem(margin-top, 72 !important);
        }
        &.type03 {
          flex-direction: column-reverse;
          .hgroup-component {
            .hgroup-sub-tit {
              margin-top: 0;
            }
          }
        }
        .img-bx {
          @include rem(width, 100%);
        }
        .hgroup-component {
          @include rem(width, 100%);
          @include rem(margin-bottom, 40);
          .hgroup-sub-tit {
            @include rem(margin-top, 24);
            & + .hgroup-desc {
              @include rem(margin-top, 18);
            }
          }
        }
        &.swiper-bx {
          display: block;
          margin: 0;
          padding: 0;
          .hgroup-component {
            @include rem(padding, 0 24);
            width: 100%;
          }
          .hgroup-desc + .hgroup-desc {
            @include rem(margin-top, 8);
          }
          .febc-introduction-swiper-bx {
            display: flex;
            position: relative;

            .febc-introduction-swiper-01,
            .febc-introduction-swiper-03 {
              @include rem(width, 100%);
              @include rem(height, 315);
              @include rem(margin-top, 0);
              .swiper-container {
                @include rem(padding, 0 20);
              }
            }

            .swiper-button-prev,
            .swiper-button-next {
              display: none;
            }
          }
        }
      }
      // 홍보센터 상세 : mo
      &.details {
        .hgroup-component {
          @include rem(padding, 0 24);
          width: 100%;
          // margin: 0 auto;
        }
        .febc-public-relations-info-bx {
          @include rem(margin, 40 0 0 0);
          padding: 0;
          width: 100%;
          display: block;
          .hgroup-component {
            @include rem(padding, 0 24);
          }
          .img-bx {
            @include rem(margin-top, 56);
          }
        }

        .febc-history-info-bx {
          @include rem(margin-top, 72);
          @include rem(padding, 72 24);
          .inner {
            @include rem(width, 100%);
            margin: 0;
            flex-direction: column-reverse;
            .hgroup-component {
              @include rem(margin-bottom, 40);
              padding: 0;
            }
            .history-info {
              .hgroup-component {
                @include rem(margin, 0 0 64 0);
                @include rem(width, 100%);
              }
              .address {
                @include rem(margin-top, 24);
                dl {
                  & + dl {
                    @include rem(margin-top, 32);
                  }
                  dt {
                    @include rem(margin-bottom, 16);
                    @include f16(700);
                  }
                  dd {
                    @include f16(400);
                    & + dd {
                      @include rem(margin-top, 8);
                    }
                    &.subway,
                    &.bus {
                      span {
                        @include rem(margin-bottom, 8);
                        display: block;
                      }
                    }
                    &.area {
                      @include f14(500);
                      &::before {
                        @include rem(top, 3%);
                      }
                    }
                  }
                }
              }
            }
            .history-img {
              @include rem(width, 100%);
            }
          }
        }
      }
    }
    // 회사연혁 : mo
    .febc-company-history-wrap {
      @include rem(max-width, 100%);
      margin: 0;

      .febc-company-history-content {
        @include rem(margin-top, 40);
        @include rem(max-width, 100%);
        .fixed-tab {
          .tab-head {
            .tab-label + .tab-label {
              @include rem(margin-left, 24);
            }
          }
          .content-inner {
            @include rem(padding, 0 24 100);
            display: block;

            .content-info {
              position: sticky;
              @include rem(top, -15);
              background-color: $bg-ffffff;
              z-index: 3;
              &::before {
                display: none;
              }
            }
            .hgroup-component {
              margin: 0;
            }
          }
        }

        .sub-txt {
          @include rem(margin-bottom, 8);
          @include f24(500);
        }

        .years {
          @include f32(700);
          ul {
            li {
              @include rem(width, 22);
            }
          }
        }
        .details {
          @include rem(margin-top, 40);
          @include rem(width, 100%);
          ul {
            &.year-item {
              &.active {
                opacity: 1;
                li {
                  &:first-child {
                    @include rem(padding-top, 24);
                  }
                }
              }
              &:nth-child(1) {
                &.active {
                  li {
                    &:first-child {
                      // @include rem(padding-top, 0);
                      // border-top: 0;
                    }
                  }
                }
              }
            }
            li {
              @include rem(padding, 24 0);
              display: block;
            }
          }

          .year {
            @include rem(margin-bottom, 16);
            @include rem(width, 155);
            @include f18(700);
          }
          .details-info {
            flex: 1;
            .txt {
              @include f16(400);
            }
            .desc {
              @include f14(500);
            }
          }
        }
      }
    }
    // 인사말 : mo
    .febc-greeting-wrap {
      .hgroup-component {
        @include rem(max-width, 100%);

        margin: 0;
      }
      .febc-greeting-visual-wrap {
        @include rem(max-width, 100%);
        @include rem(height, 826);
        .inner {
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          @include rem(max-width, 100%);
          background: url("pathImages/contents/febc_greeting_bg_02.png")
            no-repeat center 70%;
          img {
            @include rem(padding, 0 24);
            // @include rem(width, 100%);
            // @include rem(height, 366);
            position: static;
            margin: 0 auto;
          }
          .hgroup-component {
            @include rem(padding, 80 24 0);
            @include rem(width, 100%);
            margin: 0;
            transform: none;
            position: static;
            .hgroup-tit {
            }
            i {
              @include rem(width, 32);
              @include rem(height, 28);
            }
            .ico-quotation-01 {
              @include rem(margin-bottom, 24);
            }
            .ico-quotation-02 {
              @include rem(margin-top, 24);
            }
          }
        }
      }
      .febc-introduction-minister-wrap {
        @include rem(max-width, 100%);
        @include rem(padding, 72 24 32);
        top: 0;
        margin: 0;
        box-shadow: none;
        .hgroup-component {
          margin: 0;
          .hgroup-sub-tit {
            @include rem(margin-bottom, 32);
          }
          .hgroup-sub-txt {
            @include f16(400);
            & + .hgroup-sub-txt {
              @include rem(margin-top, 16);
            }
          }
        }
        .ico-febc-signature {
          @include rem(width, 101);
          @include rem(height, 32);
          @include rem(right, 24);
          @include rem(bottom, 0);
        }
      }
      .febc-strength-wrap {
        .inner {
          @include rem(max-width, 100%);
          margin: 0;
          .strength-contents {
            @include rem(padding-bottom, 40);
            .history-bar {
              @include rem(left, 24);
              height: calc(100% - 110px);
              transform: none;
              .history-bar-on {
                .pointer {
                  &::before {
                    // @include rem(width, 24);
                    // @include rem(height, 24);
                  }
                }
              }
            }
            .year-item {
              @include rem(padding, 0 24 0 64);
              flex-direction: column;
              & + .year-item {
                @include rem(margin-top, 80);
              }
              &:nth-child(odd) {
                display: flex;
                flex-direction: column-reverse;
              }
              > div:nth-child(odd) {
                @include rem(width, 100%);
                text-align: left;
                padding: 0;
              }
              > div:nth-child(even) {
                @include rem(width, 100%);
                padding: 0;
              }
              .year-txt {
                @include rem(margin-bottom, 40);
                span {
                  @include f48(600);
                }
              }
              .year-info {
                span {
                  @include f14(500);
                  color: $font-777777;
                }
                p {
                  @include f16(400);
                  & + span {
                    @include rem(padding-top, 32);
                  }
                }
              }
            }
          }
        }
      }
    }

    // 지사장인사말 : mo
    .branch-manager {
      width: 100%;
      @include rem(padding, 0 24);
      .msg-tit {
        dt {
          img {
            @include rem(height, 17);
          }
          span {
            display: block;
            @include f14(700);
            @include rem(margin-left, 4);
          }
        }
        dd {
          @include rem(margin-top, 16);
          @include f24(500);
        }
      }

      .msg-cont {
        @include rem(margin-top, 40);
        @include rem(padding-top, 40);

        .txt-slogan {
          @include rem(margin-bottom, 24);
          @include f18(700);
        }
        p {
          @include f16(400);
          & + p {
            @include rem(margin-top, 24);
          }
        }
      }

      .msg-user {
        @include rem(margin-top, 48);
        dt {
          @include rem(margin-bottom, 16);
          @include f16(400);
          color: $font-777777;
        }
        dd {
          display: flex;
          align-items: end;
          img {
            @include rem(height, 35);
          }
          span {
            display: block;
            @include f16(400);
            @include rem(margin-left, 16);
          }
        }
      }
    }

    // 선교소식지 : mo
    .febc-mission-newsletter-wrap {
      margin-top: 0;

      .component-form {
        @include rem(margin, 24 0 0 0);
      }
      .mission-newsletter-list-bx {
        display: block;
        @include rem(margin-top, 40);
        .mission-newsletter-item {
          display: block;
          text-align: center;
          > a {
            display: block;
            text-align: center;
          }
          & + .mission-newsletter-item {
            @include rem(margin-top, 46);
          }
          img {
            @include rem(width, 146);
            @include rem(height, 185);
          }
          .mission-newsletter-info {
            @include rem(padding-left, 0);
            margin: 0 auto;
            .txt-bx {
              @include rem(padding-bottom, 20);
              @include rem(margin-bottom, 8);
              word-break: break-all;
              .tit {
                @include rem(margin-top, 24);
                @include f18(700);
              }
              .txt {
                @include rem(margin-bottom, 16);
                @include f14(500);
              }
              .desc {
                @include f14(500);
                color: $font-777777;
              }
            }
            .preview-more {
              display: inline-block;
              @include rem(padding-left, 28);
              &::before {
                background-size: 20px 20px;
              }
              span {
                @include f14(500);
              }
            }
          }
        }
      }
    }
    // 전국지사 안내 : mo
    .febc-branch-wrap {
      @include rem(margin-top, 40);
      .febc-branch-map {
        .febc-map-point-list {
          @include rem(width, 100%);
          @include rem(height, 489);
          text-align: center;
          background-color: $bg-f7f8fd;
          // background: url("pathImages/contents/febc_branch_map_mo.png") center
          //   center $bg-f7f8fd;
          // background-size: 100% 100%;
          .img-btn-bx {
            @include rem(width, 312);
            @include rem(height, 100%);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
          .febc-map-name {
            @include rem(padding, 12 63 12 48);
            @include rem(top, 16);
            @include rem(left, 16);
            @include rem(border-radius, 8);
            @include f18(500);
            background-color: $bg-ffffff;
            border: 1px solid $line-e0e0e0;
            position: absolute;

            &::before {
              content: "";
              display: block;
              position: absolute;
              @include rem(width, 14);
              @include rem(height, 14);
              @include rem(top, 50%);
              @include rem(left, 24);
              @include rem(border-radius, 50%);
              transform: translateY(-50%);
              background-color: $bg-c0c0c0;
            }
            em {
              @include f16(500);
            }
          }
          img {
            @include rem(width, auto);
            position: static;
          }
          .point {
            @include rem(width, 24);
            @include rem(height, 24);
            position: absolute;
            border: 0;
            padding: 0;
            background: url("pathImages/icon/febc_map_point_disabled.png")
              no-repeat;
            background-size: 100%;
            span {
              @include rem(width, 50);
              @include rem(top, 25);
              @include rem(left, 50%);
              @include f12(500);
              display: block;
              position: relative;
              transform: translateX(-50%);
              color: $font-777777;
            }
            &.on {
              background: url("pathImages/icon/febc_map_point.png") no-repeat;
              background-size: 100%;
              &::after {
                display: none;
              }
            }
            &.point-01 {
              @include rem(top, 28%);
              @include rem(left, 26%);
            }
            &.point-02 {
              @include rem(top, 56%);
              @include rem(left, 20%);
            }
            &.point-03 {
              @include rem(top, 74%);
              @include rem(left, 40%);
            }
            &.point-04 {
              @include rem(top, 68%);
              @include rem(left, 55%);
            }
            &.point-05 {
              @include rem(top, 25%);
              @include rem(left, 57%);
            }
            &.point-06 {
              @include rem(top, 49%);
              @include rem(left, 30.5%);
            }
            &.point-07 {
              @include rem(top, 88%);
              @include rem(left, 22.5%);
            }
            &.point-08 {
              @include rem(top, 73%);
              @include rem(left, 14%);
            }
            &.point-09 {
              @include rem(top, 58%);
              @include rem(left, 55%);
            }
            &.point-10 {
              @include rem(top, 53%);
              @include rem(left, 71%);
            }
            &.point-11 {
              @include rem(top, 68%);
              @include rem(left, 64%);
            }
            &.point-12 {
              @include rem(top, 63%);
              @include rem(left, 69%);
            }
            &.point-13 {
              @include rem(top, 69%);
              @include rem(left, 21%);
            }
          }
        }
      }
    }
    // 전국 지사 안내 pop: mo
    .febc-map-popup {
      overflow: visible;
    }
  }
  // FEBC 선교소식지 : mo
  .great-books-layer {
    .modal-container {
      overflow-y: scroll;
      overflow-x: hidden;
      .preview-controler-area {
        @include rem(padding, 0 24);
        .page-btn-awrp {
          ul {
            .page-number {
              @include rem(width, 80);
              @include rem(height, 48);
              @include rem(margin, 0 8);
            }
          }
        }
        .component-tab.tab-scroll {
          .tab-head {
            padding: 0;
          }
        }
      }
    }
  }
  // 전국 지사 안내 pop info : mo
  .febc-reanch-map-info-mo {
    @include rem(width, 100%);
    @include rem(margin, 0 0 0 0);
    .hgroup-component {
      .desc-bx {
        @include rem(margin, 8 0 24);
        ul {
          margin: 0;
          border: 0;
          li {
            display: block;
            padding: 0;
            border: 0;
          }
        }
        span {
          @include f14(500);
          position: relative;
          color: $font-000000;
          & + span {
            @include rem(margin-left, 16);
            &::before {
              content: "";
              display: block;
              position: absolute;
              @include rem(width, 1);
              @include rem(height, 12);
              @include rem(top, 50%);
              @include rem(left, -8);
              transform: translateY(-50%);
              background-color: $bg-e0e0e0;
            }
          }
          &:nth-child(3) {
            &::before {
              @include rem(top, 7%);
              transform: translateY(0);
            }
          }
        }
      }
    }
    img {
      @include rem(width, 100%);
      @include rem(height, 100%);
    }
    ul {
      @include rem(margin-top, 32);
      border-top: 1px solid $line-000000;
      border-bottom: 1px solid $line-000000;
      li {
        @include rem(padding, 24 6 24 0);
        display: flex;
        align-items: flex-start;
        & + li {
          border-top: 1px solid $line-e0e0e0;
        }
        span {
          @include rem(width, 100);
          @include f16(400);
        }
        p {
          @include f16(400);
          flex: 1;
        }
      }
      .address-list {
        > div {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          & + div {
            @include rem(margin-top, 16);
          }
          span {
            @include rem(padding-left, 28);

            @include f16(700);
            color: $font-000000;
            &.subway {
              background: url("pathImages/icon/ico_subway_gray.png") no-repeat
                left 0;
              background-size: 20px 20px;
            }
            &.bus {
              background: url("pathImages/icon/ico_bus_gray.png") no-repeat left
                0;
              background-size: 20px 20px;
            }
            &.car {
              background: url("pathImages/icon/ico_car_gray.png") no-repeat left
                0;
              background-size: 20px 20px;
            }
            &.walk {
              background: url("pathImages/icon/ico_walk_gray.png") no-repeat
                left 0;
              background-size: 20px 20px;
            }
          }
          p {
            @include rem(margin-top, 8);
            @include f16(400);
          }
        }
      }
    }
  }
}

@keyframes pin-wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
@-webkit-keyframes pin-wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

// @keyframes circle-wave {
//   0% {
//     @include rem(width, 600);
//     @include rem(height, 600);
//     opacity: 1;
//   }
//   100% {
//     @include rem(width, 856);
//     @include rem(height, 856);
//     opacity: 0;
//   }
// }

// @-webkit-keyframes circle-wave {
//   0% {
//     @include rem(width, 600);
//     @include rem(height, 600);
//     opacity: 1;
//   }
//   100% {
//     @include rem(width, 856);
//     @include rem(height, 856);
//     opacity: 0;
//   }
// }
// 20241121 backup
@keyframes circle-wave {
  0% {
    @include rem(width, 640);
    @include rem(height, 640);
    @include rem(border-width, 6);
    opacity: 1;
  }
  100% {
    @include rem(width, 856);
    @include rem(height, 856);
    @include rem(border-width, 1);
    opacity: 0;
  }
}

@-webkit-keyframes circle-wave {
  0% {
    @include rem(width, 640);
    @include rem(height, 640);
    @include rem(border-width, 6);
    opacity: 1;
  }
  100% {
    @include rem(width, 856);
    @include rem(height, 856);
    @include rem(border-width, 1);
    opacity: 0;
  }
}

@keyframes circle-wave-mo {
  0% {
    @include rem(width, 320);
    @include rem(height, 320);
    opacity: 1;
  }
  100% {
    @include rem(width, 420);
    @include rem(height, 420);
    opacity: 0;
  }
}

@-webkit-keyframes circle-wave-mo {
  0% {
    @include rem(width, 320);
    @include rem(height, 320);
    opacity: 1;
  }
  100% {
    @include rem(width, 420);
    @include rem(height, 420);
    opacity: 0;
  }
}
