@use "../abstracts" as *;

/* S : Select-base */
.component-select {
  position: relative;
  width: 100%;
  // z-index: 20;
  .select-list {
    width: 100%;
    @include rem(height, 52);
    @include rem(padding, 0 16);
    background-color: $bg-ffffff;
    @include rem(border, 1px solid $line-e0e0e0);
    @include rem(border-radius, 4);
    color: $font-000000;
    @include f16(500);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    optgroup {
      color: $font-000000;
    }
    option {
      color: $font-000000;
      &[value=""][disabled] {
        display: none;
      }

      &:disabled {
        color: gray; /* 텍스트 색상 변경 */
        background-color: #f0f0f0;
      }
    }
    &:required:invalid {
      color: $font-999999;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    @include rem(right, 15);
    @include rem(width, 16);
    @include rem(height, 16);
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2724%27%20height%3D%2724%27%20viewBox%3D%270%200%2024%2024%27%20fill%3D%27none%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M4%208L12%2016L20%208%27%20stroke%3D%27black%27%20stroke-width%3D%272%27%20stroke-linecap%3D%27square%27%20stroke-linejoin%3D%27round%27%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    @include rem(background-size, 16 auto);
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }

  &.show {
    &::after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  /* S : Select-custom */
  &.type-custom {
    &::after {
      display: none;
    }
    .combo-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 1 0 auto;
      @include f16(500);
      @include rem(margin-bottom, 8);
    }

    .select-box {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      @include rem(height, 52);
      @include rem(padding, 0 15);
      @include rem(border-radius, 4);
      background-color: $bg-ffffff;
      @include rem(border, 1px solid $line-e0e0e0);
      color: $font-000000;
      @include f16(500);
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        @include rem(right, 16);
        @include rem(width, 20);
        @include rem(height, 20);
        background-image: url("pathImages/icon/ico_select_arrow.png");
        background-repeat: no-repeat;
        @include rem(background-size, 20 auto);
        transform: translateY(-50%);
        transition: transform 0.3s ease;
      }

      &[disabled] {
        border: 0;
        background-color: $bg-e5e5e5;
        .btn-txt {
          color: $font-999999;
        }
      }
    }
    // line-type
    &.line-type {
      .select-box {
        @include rem(height, 48);
        @include rem(padding, 0 8);
        border-radius: 0;
        border: 0;
        @include rem(border-bottom, 2px solid $line-000000);
        @include f18(400);
      }
      .option {
        @include rem(padding, 10 8);
      }
    }
    .select-options {
      overflow: auto;
      position: absolute;
      display: none;
      width: 100%;
      background-color: $bg-ffffff;
      @include rem(border, 1px solid $line-e0e0e0);
      z-index: 5;
      @include rem(max-height, 160);
    }

    /* 스크롤 바 전체에 대한 스타일 지정 */
    .select-options::-webkit-scrollbar {
      @include rem(width, 8);
      @include rem(height, 8);
      background-color: $bg-eeeeee;
    }

    /* 스크롤 바의 색상 지정 */
    .select-options::-webkit-scrollbar-thumb {
      background-color: $bg-999999;
      @include rem(border-radius, 8);
    }

    /* 스크롤 바 호버 시 색상 지정 */
    .select-options::-webkit-scrollbar-thumb:hover {
      background-color: $bg-e5e5e5;
    }

    .option {
      @include rem(padding, 10 15);
      width: 100%;
      // height: 100%;
      text-align: left;
      cursor: pointer;
      @include f16(500);
      color: $font-000000;
    }

    &.show {
      .select-box {
        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
      .select-options {
        display: block;
      }
    }
    &.phone {
      @include rem(width, 120);
    }
    &.bank {
      @include rem(width, 173);
    }
    &.bank02 {
      flex-shrink: 0;
      @include rem(width, 240);
    }
    &.money {
      width: 80%;
    }
    // disabled test
    &.disabled {
      .select-box {
        border: 0;
        background-color: $bg-e5e5e5;
        pointer-events: none;
        .btn-txt {
          color: $font-999999;
        }
      }
    }
  }

  /* E : Select-custom */
}
/* E : Select-base */

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /* S : Select-base : mo*/
  .component-select {
    z-index: inherit;
    .select-list {
    }
    // select-custom : mo
    &.type-custom {
      &::after {
        display: none;
      }
      .combo-label {
        @include f14(500);
        @include rem(margin-bottom, 8);
      }

      .select-box {
        @include rem(height, 48);
        @include f14(500);
        cursor: pointer;
        &::after {
        }
      }
      .select-options {
      }

      .option {
        @include rem(padding, 10 15);
      }
      // line-type
      &.line-type {
        .select-box {
          @include f16(500);
          @include rem(height, 40);
          @include rem(padding, 0);

          &::after {
            @include rem(right, 0);
          }
        }
        .option {
          @include rem(padding, 10 8);
        }
      }
      &.show {
        .select-box {
          &::after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
        .select-options {
          display: block;
        }
      }
      &.bank {
        width: 100%;
      }
      &.money {
        width: 100%;
      }
      &.bank02 {
        width: 100%;
      }
    }
  }
}
