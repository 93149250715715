@use "../abstracts" as *;

/*========================================================================
	메뉴 : 영상
========================================================================*/

.febc-video-wrap {
  .section-inner {
    max-width: 1680px;
    @include rem(margin, 160 auto 0 auto);
  }
  .hgroup-component {
    @include rem(margin-bottom, 40);
    .hgroup-tit {
      @include f40(700);
    }
  }

  .video-visual-area {
    position: relative;
    overflow: hidden;
    @include rem(height, 800);
    .section-inner {
      position: relative;
      z-index: 5;
      height: 100%;
      margin: 0 auto;
      @include rem(padding, 200 0 0 0);
      .hgroup-component {
        @include rem(margin-bottom, 64);
        .hgroup-tit {
          text-align: center;
          color: $font-ffffff;
          transition: all 1.3s;
          filter: blur(10px);
          opacity: 0;
        }
      }

      .video-visual-swiper {
        @include rem(width, 602);
        margin: 0 auto;
        .swiper-container {
          position: static;
          transform: translateY(30%);
          opacity: 0;
        }
        .swiper-item {
          position: relative;
          @include rem(padding, 56 24);

          p {
            @include f24(400);
            color: $font-ffffff;
            text-align: center;
          }

          .btn {
            background-color: $bg-transparent;
            .btn-txt {
              color: $font-ffffff;
            }
            .ico-detail-arrow {
              background-image: url("pathImages/icon/ico_detail_view_white.png");
            }
            &:focus,
            &:hover {
              // background: $font-ffffff;
              .btn-txt {
                // color: $font-4051b8;
              }
              .ico-detail-arrow {
                background-image: url("pathImages/icon/ico_detail_view_white.png");
              }
            }
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $line-ffffff;
            @include rem(border-radius, 8);
            opacity: 0.4;
            pointer-events: none;
          }
        }

        .swiper-controls {
          position: absolute;
          justify-content: flex-end;
          right: 0;
          @include rem(bottom, 56);
          opacity: 0;

          // 자동설정 버튼
          .swiper-autoplay-wrap {
            @include rem(left, 200);
            @include rem(bottom, 88);
            z-index: 5;
            .swiper-autoplay {
              background-image: url("pathImages/btn/btn_swiper_main_pause.png");
              &.stop {
                background-image: url("pathImages/btn/btn_swiper_main_play.png");
              }
            }
          }
          .swiper-navigation {
            display: none;
          }

          // 숫자 버튼
          .swiper-pagination {
            display: block;
            position: static;
            border: 1px solid $line-ffffff;
            @include rem(border-radius, 16);
            color: $font-ffffff;
          }
        }
        .component-btns {
          @include rem(margin-top, 24);
        }
      }
    }
    .visual-img {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      @include rem(max-width, 1920);
      overflow: hidden;
      img {
        // transition: all 8s;
      }
    }
  }

  .video-take-look {
    .section-inner {
      max-width: 1720px;
      .hgroup-component {
        @include rem(padding, 0 20);
      }
    }
    .new-video-swiper {
      .swiper-container {
        @include rem(padding, 0 20 25 20);
      }
      .swiper-item {
        display: block;
        overflow: hidden;
        @include rem(border-radius, 8);
        box-shadow: 2px 10px 20px 0px rgba(24, 32, 58, 0.2);
        .img-zone {
          @include rem(max-height, 302);
          img {
            @include rem(max-height, 302);
          }
        }
      }
      .txt-info {
        @include rem(padding, 32 32 40 32);
      }
      .video-tit {
        @include f24(700);
        @include rem(margin-bottom, 40);
        @include ellipsis(1);
      }
      .video-txt {
        display: flex;
        justify-content: space-between;
        .txt {
          flex: 1;
          @include f18(400);
          @include ellipsis(2);
          @include rem(height, 48);
        }
        .btn-area {
          flex-shrink: 0;
        }
      }
    }
  }

  .video-program-intro {
    .hgroup-component {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .list-comm-wrap {
      margin: 0;
    }
    .nodata-wrap {
      border-top: 0;
      border-bottom: 0;
    }
    .gallary-type {
      // iOS Safari: grid-template-columns 10.3부터 지원
      // @include rem(gap, 36);
      @include rem(column-gap, 36);
      @include rem(row-gap, 64);
      // iOS Safari: gap iOS 12.2부터 지원
      .img-zone {
        // @include rem(border-radius, 8);
        position: relative;
        img {
          @include rem(height, 200);
          object-fit: cover;
        }
      }
      .list-tit {
        @include rem(padding, 24 12 0 12);
        @include f24(400);
        @include ellipsis(2);
        text-align: center;
      }
      .label-tag {
        @include rem(padding, 4 12);
        @include rem(right, 24);
        @include rem(top, 24);
        @include f16(500);
        @include rem(border-radius, 8);
        display: block;
        position: absolute;
        color: $font-ffffff;
      }
      a:focus,
      a:hover {
        .img-zone {
          img {
            transform: scale(1);
          }
        }
      }
    }
  }

  .video-febc-amu {
    .gallary-type {
      // @include rem(gap, 36);
      @include rem(column-gap, 36);
      @include rem(row-gap, 36);
      .img-zone {
        @include rem(border-radius, 8);
      }
      .list-tit {
        @include rem(padding, 16 16 0 16);
        @include ellipsis(2);
        @include f18(400);
        color: $font-2c2c2c;
      }
    }
  }

  .video-channel {
    .video-channel-swiper {
      position: relative;
      @include rem(padding, 0 105);
    }
    .swiper-container {
      @include rem(padding, 1);
    }

    .swiper-item {
      display: flex;
      align-items: center;
      @include rem(padding, 24);
      border: 1px solid $line-e0e0e0;
      @include rem(border-radius, 8);
    }

    .img-zone {
      flex-shrink: 0;
      @include rem(width, 72);
      @include rem(height, 72);
      @include rem(margin-right, 24);
      @include rem(border-radius, 8);
    }

    dl {
      dt {
        @include rem(margin-bottom, 8);
        @include f20(500);
      }
      dd {
        @include f16(500);
        color: $font-777777;
      }
    }

    // 좌, 우 버튼 기본
    .swiper-navigation {
      [class^="swiper-button"] {
      }
      .swiper-button-prev {
        @include rem(left, 18);
      }
      .swiper-button-next {
        @include rem(right, 18);
      }
    }
  }
}

/*========================================================================
	메뉴 : 시청자센터
========================================================================*/

// 공지사항 List
.viewers-notice-wrap {
  @include rem(margin-top, 96);
  .table-custom {
    .tbl-count {
      & + ul {
        @include rem(margin-top, 16);
      }
    }
  }
}

// 기타신청
.other-proposal-wrap {
  @include rem(margin-top, 96);

  .other-list {
    > li {
      position: relative;
      border-bottom: 1px solid $line-e0e0e0;
      @include rem(padding, 64 0);
      .txt-info {
        position: relative;
        @include rem(padding, 14 0 0 88);

        .tit {
          @include f24(700);
          @include rem(margin-bottom, 30);
        }
        .txt {
          p {
            @include f18(400);
            strong {
              font-weight: 700;
            }
            & + p {
              @include rem(margin-top, 12);
            }
          }
          .comm-bl-dot {
            @include rem(margin-top, 16);
          }
          .call-number {
            @include rem(margin-top, 16);
            @include f18(700);
            color: $font-335891;
          }
        }
        .btn-line-arrow {
          position: absolute;
          @include rem(right, 0);
          @include rem(bottom, 0);
        }
        .btn-area {
          position: absolute;
          @include rem(right, 0);
          @include rem(top, 0);
        }
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }

  .icon-area {
    position: absolute;
    @include rem(left, 0);
    top: 0;
    [class^="ico-"] {
      display: block;
      @include rem(width, 64);
      @include rem(height, 64);

      &.ico-car {
        background-image: url("pathImages/icon/ico_car.png");
      }
      &.ico-book {
        background-image: url("pathImages/icon/ico_book.png");
      }
      &.ico-volunteer {
        background-image: url("pathImages/icon/ico_volunteer.png");
      }
      &.ico-support {
        background-image: url("pathImages/icon/ico_support.png");
      }
      &.ico-radio {
        background-image: url("pathImages/icon/ico_radio.png");
      }
    }
  }
}
// 미리보기 팝업
.car-preview {
  text-align: center;
  img {
    width: 100%;
  }
}

// 고충처리신청
.personal-area {
  .round-bx {
    @include rem(padding, 24);
    @include rem(margin-top, 0);
    ul {
      display: flex;
      flex-direction: column;
      @include rem(gap, 10);

      li {
        display: flex;
        @include f16(500);
        color: $font-777777;
        .tit {
          @include rem(width, 140);
          color: $font-000000;
        }
        .txt {
          @include rem(margin-left, 16);
        }
      }
    }
  }
  .info-txt {
  }
  .component-radio-sup {
    display: flex;
    @include rem(margin-top, 16);
    .component-radio {
      & + .component-radio {
        @include rem(margin-left, 32);
      }
    }
  }
}

// 무료광고신청, 고충처리 게시판
.application-method {
  @include rem(margin, 96 0 80);

  .component-accordion {
  }
  .accordion-item {
    @include rem(border-radius, 8);
    background-color: $bg-f7f8fd;
    overflow: hidden;
    .collapse-tit {
      @include rem(padding, 32 40);
      @include f24(400);
      border-bottom: 0;
      background-color: $bg-f7f8fd;
      &[aria-expanded="true"] {
      }
      &::before {
        @include rem(right, 40);
      }
    }
    .collapse-content {
      @include rem(padding, 0 40 32 40);
      border: 0;
      .comm-bl-dot {
        @include rem(padding-left, 32);
        li {
          color: $font-000000;
          &::before {
            background-color: $bg-000000;
          }
          & + li {
            @include rem(margin-top, 10);
          }
        }
      }
    }

    & + .accordion-item {
      @include rem(margin-top, 16);
    }
  }

  // icon
  [class^="ico-"] {
    @include rem(width, 24);
    @include rem(height, 24);
    @include rem(margin-right, 16);
  }
  .ico-method {
    background-image: url("pathImages/icon/ico_method.png");
  }
  .ico-standard {
    background-image: url("pathImages/icon/ico_standard.png");
  }
}

/*========================================================================
	메뉴 : 라디오
========================================================================*/
.last-radio-sermon-wrap {
  @include rem(padding-left, 120);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .hgroup-component {
    @include rem(margin-top, 80);
    @include rem(margin-bottom, 24);
    flex-shrink: 0;
    @include rem(width, 390);

    .hgroup-sub-tit + .btn-txt-more {
      @include rem(margin-top, 24);
    }
  }
  .sermon-list {
    // @include rem(max-width, 1170);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;

    @include rem(column-gap, 36);
    @include rem(row-gap, 36);

    .sermon-item {
      @include rem(max-width, 393);
      @include rem(width, 100%);
      overflow: hidden;
      & + .sermon-item {
        // @include rem(padding-left, 36);
      }
      a {
        display: inline-block;
        outline-offset: -1px;
        @include rem(margin-bottom, 24);
      }
      img {
        @include rem(width, 100%);
        @include rem(height, 488);
        object-fit: cover;
      }
      .sermon-info {
        .link-bx {
          overflow: hidden;
          display: flex;
          align-items: center;
          @include rem(margin, 4 0 16);
          .btn {
            overflow: hidden;
            display: flex;
            height: auto;
            margin-bottom: 0;
            .btn-txt-more {
              flex-shrink: 0;
            }
          }
          .tit {
            display: block;
            @include f24(400);
            @include ellipsis(1);
          }
          .link-btn-group {
            @include rem(margin-left, 24);
            display: flex;
            align-items: center;
            a {
              @include rem(width, 40);
              @include rem(height, 40);
              @include rem(border-radius, 50%);
              display: block;
              &.home {
                background: url("pathImages/icon/ico_breadcrumb_home_white.png")
                  no-repeat center center $bg-335891;
                background-size: 20px 20px;
              }
              &.play {
                @include rem(margin-left, 8);
                background: url("pathImages/icon/ico_play.png") no-repeat center
                  center $bg-335891;
                background-size: 10px 14px;
              }
            }
          }
        }
      }
      span {
        @include f16(500);
      }
      .img-txt {
        // @include rem(max-width, 290);
        // @include rem(width, 375);
        @include rem(margin-bottom, 4);
        @include f24(400);
        @include ellipsis(1);
        display: block;
      }
      .church-link {
        margin: 0;
      }
      .church + .pastor {
        @include f16(500);
        @include rem(margin-left, 0);
        position: relative;
        display: block;
        // &::before {
        //   @include rem(top, 4);
        //   @include rem(left, -8);
        //   @include rem(width, 1);
        //   @include rem(height, 12);
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   background-color: $bg-e0e0e0;
        // }
      }
      .day {
        @include rem(margin-top, 24);
        @include f16(500);
        color: $font-999999;
      }
    }
  }
}
// 라디오 서브메인 : pc
.radio-page-container-main {
  // @include rem(padding-top, 160);
  .hgroup-component {
    @include rem(margin-bottom, 40);
  }
  .radio-main-visual-wrap {
    position: relative;
    // 스와이퍼 js 수정후 삭제
    // .main-visual-swiper {
    //   visibility: unset;
    // }
    .label-group {
      @include rem(top, 40);
      @include rem(right, 40);
      position: absolute;
      z-index: 5;
      a {
        @include rem(height, 100%);
        display: inline-block;
      }
      .label-tag {
        @include rem(padding, 4 8 4 28);
        @include f12(500);
        background:
          url("pathImages/icon/ico_on_air.png") 8px center/16px 16px no-repeat,
          linear-gradient(90deg, #fe508b 0%, #4c3ca5 100%);
      }
    }
    .hgroup-component {
      @include rem(top, 80);
      @include rem(left, 80);
      position: absolute;
      z-index: 2;
      .day-time {
        @include rem(margin-bottom, 8);
        @include f16(500);
        color: $font-ffffff;
        span {
          position: relative;
          & + span {
            @include rem(margin-left, 24);
            &::before {
              content: "";
              display: block;
              position: absolute;
              @include rem(width, 1);
              @include rem(height, 12);
              @include rem(top, 50%);
              @include rem(left, -12);
              background-color: $bg-ffffff;
              opacity: 0.5;
              transform: translateY(-50%);
            }
          }
        }
      }
      .hgroup-tit {
        display: flex;
        flex-direction: column;
        @include f40(700);
        color: $font-ffffff;
      }
    }
    .navi-num {
      @include rem(bottom, 122);
      @include rem(left, 325px);
      border-color: $line-ffffff;
      color: $font-ffffff;
    }
    .swiper-slide {
      &.swiper-slide-prev,
      &.swiper-slide-next {
        opacity: 0.4;
      }
      a {
        @include rem(width, 100%);
        @include rem(height, 520);
        position: relative;
        display: block;
      }
      .img-zone {
        @include rem(height, 520);
      }
      img {
        @include rem(width, 100%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 2;
        &.template-img {
          z-index: 1;
        }
      }
    }

    // .swiper-autoplay-wrap {
    //   @include rem(bottom, 122);
    //   @include rem(left, 405px);
    //   position: absolute;
    //   z-index: 4;
    //   .swiper-autoplay {
    //     background-image: url("pathImages/btn/btn_swiper_main_pause.png");

    //     &.stop {
    //       background-image: url("pathImages/btn/btn_swiper_main_play.png");
    //     }
    //   }
    //   .swiper-autoplay.play,
    //   .swiper-autoplay.stop {
    //     border: none;
    //   }
    // }
    // 좌, 우 버튼
    .swiper-navigation {
      position: absolute;
      @include rem(right, 120);
      top: 50%;
      display: flex;
      flex-direction: column-reverse;
      z-index: 5;
      transform: translate(0, -50%);
      [class^="swiper-button"] {
        position: static;
        @include rem(width, 48);
        @include rem(height, 48);
        margin: 0;
        transform: none;
      }
      .swiper-button-prev {
        background-image: url("pathImages/btn/btn_swiper_visual_arrow_l.png");
        @include rem(margin-top, 16px);
      }
      .swiper-button-next {
        background-image: url("pathImages/btn/btn_swiper_visual_arrow_r.png");
      }
    }

    .swiper-navigation {
      &.ani-custom {
        position: absolute;
        @include rem(right, 120);
        top: 50%;
        display: flex;
        flex-direction: column-reverse;
        z-index: 5;
        transform: translate(0, -50%);
        span,
        i {
          pointer-events: none;
        }
        [class^="swiper-button"] {
          position: static;
          @include rem(width, 48);
          @include rem(height, 48);
          margin: 0;
          transform: none;
        }
        .button-icon {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .swiper-button-prev {
          @include rem(margin-top, 16px);
          background-image: none;
          &::before {
            display: none;
          }
          .button-icon {
            background-image: url("pathImages/btn/btn_swiper_visual_arrow_r.png");
          }
        }
        .swiper-button-next {
          margin-top: 0;
          background-image: none;
          &::before {
            display: none;
          }
          .button-icon {
            background-image: url("pathImages/btn/btn_swiper_visual_arrow_l.png");
          }
        }

        .ani-i {
          display: block;
          position: absolute;
          opacity: 1;
          width: 100%;
          height: 100%;
          border: 1px solid $line-ffffff;
          border-radius: 50%;
          -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
        }
      }
    }
    .swiper-navigation.ani-custom {
      @include rem(right, 280);
    }
    .component-tab {
      // @include rem(padding, 24 80);
      @include rem(bottom, 0);
      @include rem(left, 0);
      @include rem(right, 0);
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 2;
      .tab-head {
        justify-content: flex-start;
        border: none;
        .tab-label {
          @include rem(width, 100%);
          @include rem(padding, 16 auto);
          @include f16(500);
          @include ellipsis(1);
          flex: 1 0 25%;
          color: $font-ffffff;
          border-bottom: none;
          &.active,
          &[aria-selected="true"] {
            background-color: rgba(0, 0, 0, 0.5);
            &::after {
              display: none;
            }
          }
          .time {
            @include f12(500);
            color: $font-ffffff;
            opacity: 0.5;
          }
        }
      }
    }
  }
  section {
    @include rem(max-width, 1920);
    margin: 0 auto;
    & + section {
      @include rem(margin, 160 auto 0);
    }
    &.radio-sub-main-visual-wrap {
      .section-inner {
        .hgroup-component {
          @include rem(max-width, 1680);
          @include rem(margin, 0 auto 16);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .hgroup-tit {
            @include rem(margin, 0);
          }
        }

        .tab-body-wrap {
          @include rem(max-width, 1680);
          margin: 0 auto;

          .tab-head {
            justify-content: flex-end;
          }

          .tab-list-area {
            // @include rem(gap, 36);
            @include rem(column-gap, 36);
            @include rem(row-gap, 40);
            @include rem(margin-top, 16);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            a {
              overflow: hidden;
              position: relative;
              display: block;
              text-align: center;
              img {
                @include rem(width, 100%);
                vertical-align: top;
                @include rem(height, 275);
                object-fit: cover;
              }
              p {
                @include rem(margin, 24 0 8);
                @include f24(400);
                @include ellipsis(1);
              }
              span {
                @include f16(500);
                position: relative;
                color: $font-999999;
                @include ellipsis(2);
                &.time {
                  @include rem(margin-left, 12);
                  &::before {
                    @include rem(width, 1);
                    @include rem(height, 12);
                    @include rem(top, 50%);
                    @include rem(left, -6);
                    content: "";
                    display: block;
                    position: absolute;
                    transform: translateY(-50%);
                    background-color: $bg-e0e0e0;
                  }
                }
              }
              .label-tag {
                @include rem(padding, 4 12);
                @include rem(right, 24);
                @include rem(top, 24);
                @include f16(500);
                @include rem(border-radius, 8);
                display: block;
                position: absolute;
                color: $font-ffffff;
              }
            }
          }
        }
        // .radio-swiper {
        //   max-width: 1680px;
        //   @include rem(margin, 0 auto);
        //   text-align: center;

        //   // .swiper-slide {
        //   //   img {
        //   //     @include rem(max-height, 278);
        //   //     object-fit: fill;
        //   //   }
        //   // }
        //   p {
        //     @include rem(margin, 24 0 8);
        //     @include f24(400);
        //     @include ellipsis(1);
        //   }
        //   span {
        //     @include ellipsis(2);
        //     @include f16(500);
        //     color: $font-999999;
        //     & + span {
        //       @include rem(margin-left, 16);
        //       position: relative;
        //       &:before {
        //         @include rem(width, 1);
        //         @include rem(height, 12);
        //         @include rem(top, 50%);
        //         @include rem(left, -6);
        //         content: "";
        //         display: block;
        //         position: absolute;
        //         background-color: $bg-e0e0e0;
        //         transform: translateY(-50%);
        //       }
        //     }
        //   }

        //   .swiper-navigation {
        //     .swiper-button-prev {
        //       @include rem(left, 20);
        //       @include rem(top, 35%);
        //     }
        //     .swiper-button-next {
        //       @include rem(right, 20);
        //       @include rem(top, 35%);
        //     }
        //   }
        // }
      }
    }
    &.last-radio-listening-wrap {
      @include rem(max-width, 1680);
      .listening-list {
        @include rem(border-top, 1px solid $line-e0e0e0);
        @include rem(border-bottom, 1px solid $line-e0e0e0);
        display: flex;
        align-items: center;
        .listening-item {
          @include rem(width, 25%);
          @include rem(padding, 40);
          & + .listening-item {
            @include rem(border-left, 1px solid $line-e0e0e0);
          }
          .btn-listening {
            @include rem(width, 100%);
            @include rem(height, 100%);
            display: block;
            i {
              @include rem(width, 80);
              @include rem(height, 80);
            }
          }
          p {
            @include rem(margin, 80 0 16);
            @include f24(500);
            @include ellipsis(1);
          }
          span {
            @include f20(500);
            color: $font-999999;
          }
        }
      }
    }
    &.febc-amu-wrap {
      @include rem(max-width, 1680);
      .hgroup-component {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .gallary-type {
        grid-template-columns: repeat(3, 1fr);
        @include rem(column-gap, 36);
        @include rem(row-gap, 36);
        li {
          &:last-child {
            margin: 0;
          }
          .img-zone {
            img {
              object-fit: cover;
            }
          }
          .list-tit {
            width: 100%;
            @include rem(padding, 16 0 0 0);
            @include f24(400);
            @include ellipsis(2);
            color: $font-000000;
          }
        }
      }
    }
    &.visual-radio-bottom-wrap {
      @include rem(max-width, 1680);
      .visual-item {
        a {
          @include rem(padding, 72 64);
          display: block;
          .desc {
            @include f24(400);
            color: $font-ffffff;
          }
          .txt {
            @include f32(700);
            color: $font-ffffff;
          }
        }
        &:nth-child(1) {
          a {
            background: url("pathImages/contents/radio_banner_01.png") no-repeat
              center center;
            background-size: 100% 100%;
          }
        }
        &:nth-child(2) {
          @include rem(margin-top, 24);
          display: flex;
          align-items: center;
          .visual-left {
            flex: 1;
            a {
              @include rem(padding, 56 64);
              display: flex;
              align-items: center;
              justify-content: space-between;
              background: url("pathImages/contents/radio_banner_02.png")
                no-repeat center center;
              background-size: 100% 100%;
              .bottom-visual-play {
                @include rem(width, 40);
                @include rem(height, 40);
                background: url("pathImages/btn/btn_radio_play.png") no-repeat
                  center center;
                background-size: 100%;
              }
            }
          }
          .visual-right {
            flex: 1;
            @include rem(margin-left, 24);
            a {
              @include rem(padding, 56 64);
              background: url("pathImages/contents/radio_banner_03.png")
                no-repeat center center;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
// 서브메인 상세 : pc
.radio-details-program-wrap {
  .tab-group {
    @include rem(margin, 96 0 40);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .component-tab {
      margin: 0;
    }
  }
  .tab-list {
    @include rem(column-gap, 28);
    @include rem(row-gap, 80);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    a {
      overflow: hidden;
      position: relative;
      display: block;
      text-align: center;
      img {
        @include rem(width, 100%);
        @include rem(height, 230);
        object-fit: cover;
      }
      p {
        @include rem(margin, 24 0 8);
        @include f24(400);
        @include ellipsis(1);
      }
      span {
        @include f16(500);
        position: relative;
        color: $font-999999;
        @include ellipsis(2);
        &.time {
          @include rem(margin-left, 12);
          &::before {
            @include rem(width, 1);
            @include rem(height, 12);
            @include rem(top, 50%);
            @include rem(left, -6);
            content: "";
            display: block;
            position: absolute;
            transform: translateY(-50%);
            background-color: $bg-e0e0e0;
          }
        }
      }
      .label-tag {
        @include rem(padding, 4 12);
        @include rem(right, 24);
        @include rem(top, 24);
        @include f16(500);
        @include rem(border-radius, 8);
        display: block;
        position: absolute;
        color: $font-ffffff;
      }
    }
  }
}
// 라디오 프로그램, 영상 프로그램 : pc
.radio-sub-wrap {
  .hgroup-component {
    @include rem(margin-bottom, 40);
    &.more-view-type {
      .btn-tbl-more {
        padding: 0;
        i {
          margin: 0;
        }
      }
    }
    .hgroup-sub-tit {
      @include f32(500);
    }
  }
  .radio-program-introduction-wrap {
    max-width: 1400px;
    @include rem(margin, 80 auto 0);
    .program-introduction-bx {
      @include rem(width, 100%);
      @include rem(max-height, 232);
      @include rem(padding, 16);
      @include rem(border-radius, 8);
      background-color: $bg-f7f8fd;
    }
    ul {
      @include rem(height, 100%);
      @include rem(max-height, 210);
      @include rem(padding, 24 40 24);
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        @include rem(width, 4);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $bg-999999;
      }
      &::-webkit-scrollbar-track {
        background-color: $bg-e0e0e0;
      }
      li {
        & + li {
          @include rem(margin-top, 24);
        }
      }
    }
    dl {
      display: flex;
      align-items: flex-start;
      dt,
      dd {
        @include f18(400);
      }
      dt {
        @include rem(width, 100);
        color: $font-999999;
      }
      dd {
        flex: 1;
      }
    }
  }
  .radio-program-notice-wrap {
    max-width: 1400px;
    @include rem(margin, 80 auto 0);
    &.radio-video-type {
      .info-list-box {
        ul {
          border-top: 1px solid $line-000000;
          li {
            &:nth-child(1) {
              @include rem(padding-top, 32);
            }
          }
        }
      }
    }
    .info-list-box {
      ul {
        li {
          @include rem(padding, 32 16);
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $line-e0e0e0;
          &:nth-child(1) {
            padding-top: 0;
          }
          .tit-bx {
            @include rem(width, 90%);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            i {
              @include rem(margin-left, 4);
              flex-shrink: 0;
            }
          }
          p {
            @include f20(500);
            overflow: hidden;

            a {
              display: block;
              @include ellipsis(1);
              outline-offset: 0;
            }
          }
          span {
            @include rem(margin-left, 20);
            @include f18(400);
            flex-shrink: 0;
          }
        }
      }
    }
  }
  .radio-program-selection-wrap {
    max-width: 1400px;
    @include rem(margin, 80 auto 0);
    .selection-bx {
      display: flex;
      align-items: flex-start;
    }
    .selection-music {
      @include rem(width, 448);
      @include rem(height, 448);
      img {
        @include rem(width, 100%);
        height: 100%;
        box-shadow: 10px 3px 30px rgba(0, 0, 0, 0.2);
      }
    }
    .selection-list {
      @include rem(margin-left, 64);
      & + .selection-list {
        @include rem(margin-top, 16);
      }
      .txt-bx {
        .tit {
          @include rem(margin-bottom, 8);
          @include f32(500);
        }
        .txt {
          @include f16(500);
          color: $font-999999;
        }
      }
      ul {
        @include rem(margin-top, 100);
      }
    }
    .music-item-bx {
      @include rem(padding, 32);
      @include rem(border-radius, 16);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid transparent;
      & + .music-item-bx {
        @include rem(margin-top, 16);
      }
      &:hover {
        border: 1px solid $line-fe508b;
      }
      &.on {
        .txt-bx {
          flex: 1;
          p {
            color: $font-000000;
          }
          span {
            color: $font-000000;
          }
        }
        .time {
          color: $font-000000;
        }
      }
      .num {
        @include f20(500);
        color: $font-999999;
      }
      .txt-bx {
        @include rem(max-width, 70%);
        p {
          @include rem(max-width, 550);
          @include ellipsis(1);
          @include f20(500);
          color: $font-777777;
        }
        span {
          @include f16(500);
          color: $font-777777;
        }
      }
      .time {
        @include f16(500);
        color: $font-777777;
      }
    }
  }
  .radio-program-preaching-information-wrap {
    @include rem(margin-top, 80);
    @include rem(padding, 80 0);
    background-color: $bg-f7f8fd;
    .inner {
      max-width: 1400px;
      @include rem(margin, 0 auto);
    }
    .hgroup-component {
      &.tab-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .component-tab {
      &.tab-round {
        .tab-label {
          @include rem(padding, 6 10);
          @include rem(border-radius, 4);
          @include f16(500);
          height: auto;
          border: 0;
          color: $font-000000;
          background-color: $bg-transparent;
          &[aria-selected="true"] {
            background-color: $bg-fe508b;
            color: $font-ffffff;
          }
        }
      }
    }
    .preaching-info-bx {
      @include rem(padding, 24 24 24 64);
      @include rem(border-radius, 16);
      background-color: $bg-ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .txt-bx {
        p {
          @include rem(margin-bottom, 8);
          @include f32(500);
        }
        span {
          @include f18(400);
        }
      }
      .address {
        @include rem(margin-top, 56);
        display: flex;
        align-items: center;
        dl {
          dt {
            @include rem(margin-bottom, 4);
            @include f16(500);
            color: $font-999999;
          }
          dd {
            @include f18(400);
          }
          & + dl {
            @include rem(margin-left, 40);
          }
        }
      }
    }
    img {
      @include rem(width, 258);
      @include rem(height, 258);
      @include rem(border-radius, 8);
    }
  }
  .radio-program-listening-wrap {
    max-width: 1400px;
    @include rem(margin, 80 auto 0);
    .info-list-box {
      li {
        @include rem(padding, 32 24);
        display: flex;
        align-items: center;
        justify-content: space-between;
        & + li {
          border-top: 1px solid $line-e0e0e0;
        }
        &:nth-child(1) {
          padding-top: 0;
        }
        .txt-bx {
          flex: 1;
          p {
            @include ellipsis(2);
            @include rem(margin-bottom, 8);
            @include f20(500);
          }
          span {
            @include f18(400);
            color: $font-999999;
          }
        }
        .play-pup {
          @include rem(width, 40);
          @include rem(height, 40);
          @include rem(border-radius, 100%);
          border: 1px solid $line-000000;
          display: block;
          background: url("pathImages/icon/ico_play_black.png") no-repeat center
            center;
          background-size: 10px 12px;
        }
      }
    }
  }
  .radio-video-wrap {
    max-width: 1400px;
    @include rem(margin, 80 auto 0);
    .video-list {
      display: flex;
      li {
        @include rem(width, 32%);
        a {
          display: block;
          img {
            @include rem(width, 100%);
            @include rem(height, 280);
            object-fit: cover;
          }
        }
        + li {
          margin-left: 2%;
        }
      }
      p {
        width: 100%;
        @include ellipsis(1);
        @include rem(padding, 16 16 8);
        @include f24(400);
      }
      span {
        @include rem(padding, 0 16);
        @include f18(400);
      }
    }
  }
}
// tab-scroll-btn : pc
.component-tab {
  &.tab-scroll-btn {
    @include rem(margin, 0 0 80);
    position: relative;
    .tab-list {
      display: flex;
      // overflow: hidden;
    }
    .tab-head {
      @include rem(margin-right, 80 !important);
      justify-content: flex-start;
    }
    .tab-label {
      @include f20(500);
      color: $font-999999;
      &::after {
        @include rem(height, 3);
      }
      &[aria-selected="true"] {
        color: $font-000000;
      }
    }

    .tab-btn-controller {
      @include rem(width, 80);
      @include rem(height, 100%);
      @include rem(top, 0);
      @include rem(right, 0);
      border-bottom: 1px solid $line-e0e0e0;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: space-between;
      background-color: $bg-ffffff;

      button {
        @include rem(width, 40);
        @include rem(height, 40);
        display: block;
        &.prev {
          background: url("pathImages/btn/btn_swiper_sub_l.png") no-repeat
            center center;
          background-size: 20px 20px;
        }
        &.arrow {
          background: url("pathImages/btn/btn_swiper_sub_r.png") no-repeat
            center center;
          background-size: 20px 20px;
        }
      }
    }
  }
}
// 라디오 프로그램 상세 : pc
.radio-wrap {
  @include rem(margin-top, 80);
  .label-tag {
    &.round-type {
      @include rem(margin-bottom, 8);
    }
  }
  .component-tab {
    @include rem(margin-bottom, 32);
    .tab-head {
      justify-content: flex-end;
    }
  }
  .details-view-wrap {
    @include rem(margin-top, 0);
  }
  .textarea-btn-wrap {
    @include rem(margin-top, 32);
  }
  .comments-wrap {
    @include rem(margin-top, 96);
    border-top: 1px solid $line-000000;
    .comments-list {
      @include rem(margin-top, 16);
    }
    .comments-item {
      @include rem(padding, 32 0);
      @include rem(border-bottom, 1px solid $line-e0e0e0);
      position: relative;
      &:last-child {
        border-bottom: 0;
      }
      span {
        @include f16(500);
        position: relative;
        color: $font-999999;
        &:nth-child(n + 2) {
          @include rem(margin-left, 24);
        }
        &.day::before {
          content: "";
          display: block;
          position: absolute;
          @include rem(width, 1);
          @include rem(height, 12);
          @include rem(top, 50%);
          @include rem(left, -12);
          transform: translateY(-50%);
          background-color: $bg-e0e0e0;
        }
      }
      .txt {
        @include rem(width, 80%);
        @include rem(margin-top, 8);
        @include f16(500);
        br {
          display: block !important;
        }
        &.elimination {
          @include rem(padding-left, 24);
          position: relative;
          color: $font-777777;
          &::before {
            content: "";
            display: block;
            position: absolute;
            @include rem(width, 20);
            @include rem(height, 20);
            @include rem(top, 50%);
            @include rem(left, 0);
            transform: translateY(-50%);
            background: url("pathImages/icon/icon_notice.png") no-repeat center
              center;
            background-size: 100% 100%;
          }
        }
      }
      .btn {
        @include rem(top, 32);
        @include rem(right, 0);
        position: absolute;
        .btn-txt {
          color: $font-000000;
        }
        &.crystal {
          @include rem(right, 44);
        }
      }
    }
    .component-btns {
      @include rem(margin-top, 24);
    }
  }
  // 선곡표 : pc
  .calendar-component {
    @include rem(margin-bottom, 32);
    .calendar-list {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-prev,
      .btn-next {
        @include rem(margin, 0 32);
        button {
          @include rem(width, 20);
          @include rem(height, 20);
        }
      }
      .btn-prev {
        button {
          background: url("pathImages/btn/btn_swiper_sub_l.png") no-repeat;
          background-size: 100%;
        }
      }
      .btn-next {
        button {
          background: url("pathImages/btn/btn_swiper_sub_r.png") no-repeat;
          background-size: 100%;
        }
      }
      .btn-calendar {
        label {
          font-size: 0;
        }
        .input-calendar-btn {
          background: url("pathImages/icon/ico_calendar.png") no-repeat center
            center;
          background-color: $bg-f7f8fd;
          background-size: 20px 20px;
        }
      }
      .day-data {
        @include rem(width, 170);
        @include rem(margin-left, 16);
        @include f32(500);
      }
    }
  }
  // 영상 : pc
  .radio-detail-video-wrap {
    @include rem(margin-top, 32);
    @include rem(column-gap, 28);
    @include rem(row-gap, 40);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    a {
      overflow: hidden;
      display: block;
    }
    img {
      @include rem(width, 100%);
      // @include rem(height, 280);
    }
    .txt-info {
      @include rem(padding, 16 16 0 16);
    }
    .tit {
      // @include rem(max-width, 395);
      // @include rem(width, 390);
      width: 100%;
      @include ellipsis(1);
      @include rem(margin-bottom, 8);
      @include f24(400);
    }
    .day {
      @include f18(400);
    }
    .txt {
      @include ellipsis(2);
      @include rem(margin-top, 24);
      @include f18(400);
      word-break: break-word;
      color: $font-777777;
    }
  }
}
// writing-wrap : pc
.writing-wrap {
  .component-checkbox {
    @include rem(padding-top, 8);
    text-align: right;
    justify-content: flex-end;
  }
}

// 보이는 라디오 : pc
.radio-visible-wrap {
  .hgroup-component {
    max-width: 1400px;
    @include rem(margin, 160 auto 96);
  }
  section {
    .hgroup-component {
      @include rem(margin, 160 auto 40);
    }
  }
  .radio-studio-wrap {
    .img-box {
      @include rem(height, 580);
      display: flex;
      img {
        &:nth-child(1) {
          @include rem(width, 70%);
          @include rem(padding-right, 28);
        }
        &:nth-child(2) {
          @include rem(width, 30%);
        }
      }
    }
    p {
      max-width: 1400px;
      @include rem(margin, 64 auto 0);
      @include f24(400);
    }
  }
  .radio-visible-swiper-wrap {
    max-width: 1400px;
    @include rem(margin, 0 auto);
    .component-swiper {
      @include rem(padding, 0 120);
      .swiper-navigation .swiper-button-prev,
      .swiper-navigation .swiper-button-next {
        @include rem(top, 30%);
      }
    }
    a {
      @include rem(height, 100%);
      display: block;
      text-align: center;
      position: relative;
      .label-tag {
        @include rem(padding, 4 16);
        @include f20(500);
        @include rem(border-radius, 4);
        @include rem(top, 16);
        @include rem(right, 16);
        position: absolute;

        display: block;
        color: $font-000000;
      }
      img {
        @include rem(height, 200);
        object-fit: cover;
      }
    }
    p {
      @include rem(margin, 24 0 8);
      @include f24(500);
    }
    span {
      @include f16(500);
      position: relative;
      color: $font-999999;
      & + span {
        @include rem(margin-left, 16);
        &::before {
          @include rem(width, 1);
          @include rem(height, 12);
          @include rem(top, 50%);
          @include rem(left, -8);
          content: "";
          display: block;
          position: absolute;
          transform: translateY(-50%);
          background-color: $bg-e0e0e0;
        }
      }
    }
  }
  .radio-returm-wrap {
    max-width: 1400px;
    @include rem(margin, 0 auto);
    .returm-list {
      @include rem(column-gap, 28);
      @include rem(row-gap, 40);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      a {
        @include rem(height, 250);
        display: block;
        img {
          @include rem(width, 100%);
          @include rem(height, 100%);
        }
      }
      p {
        @include rem(margin, 16 0 8);
        @include ellipsis(2);
        @include f24(400);
      }
      span {
        @include f18(400);
        color: $font-999999;
      }
    }
  }
}
// 편성표,설교 : pc
.programming-calendar-swiper {
  @include rem(margin-top, 96);
  .component-swiper {
    position: relative;
    .swiper-container {
      @include rem(margin, 0 80);
      .swiper-wrapper {
        .swiper-slide {
          text-align: center;
          button {
            @include rem(padding, 40 20.5);
            @include rem(border-radius, 8);
            .day {
              @include f24(700);
              display: block;
            }
            .week {
              @include f18(400);
            }
            &.on {
              color: $font-ffffff;
              background-color: $bg-fe508b;
            }
          }
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
      @include rem(width, 24);
      @include rem(height, 24);
      background-image: none;
      &::before {
        content: "";
        display: block;
        @include rem(width, 24);
        @include rem(height, 24);
      }
    }
    .swiper-button-prev::before {
      background: url("pathImages/icon/ico_allmenu_arrow_r.png");
      background-size: 100%;
      transform: rotate(180deg);
    }
    .swiper-button-next::before {
      background: url("pathImages/icon/ico_allmenu_arrow_r.png");
      background-size: 100%;
    }
  }
}
.select-tab-bx {
  @include rem(margin, 32 0 96);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .component-select {
    @include rem(width, 210);
  }
  .calendar-btn-group {
    display: flex;
    align-items: center;
    .component-calendar {
      position: relative;
      label {
        @include rem(width, 100%);
        @include rem(height, 52);
        @include rem(padding, 14 24 14 52);
        @include rem(border-radius, 4);
        @include f16(500);
        display: block;
        background-color: $bg-000000;
        cursor: pointer;
        span {
          color: $font-ffffff;
        }
      }

      .input-calendar-btn {
        @include rem(width, 100%);
        @include rem(height, 100%);
        @include rem(top, 50%);
        left: 0;
        background-position: 25px center;
        transform: translateY(-50%);
        position: absolute;
        background-color: transparent;
      }
      & + .d-day {
        @include rem(margin-left, 8);
      }
    }
    .d-day {
      @include rem(height, 52);
      @include rem(padding, 13 23 13 51);
      @include rem(border-radius, 4);
      border: 1px solid $line-000000;
      background-color: $bg-ffffff;
      position: relative;
      span {
        @include f16(500);
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        @include rem(width, 20);
        @include rem(height, 20);
        @include rem(top, 50%);
        @include rem(left, 23%);
        transform: translateY(-50%);
        background: url("pathImages/icon/ico_calendar_today.png") no-repeat
          center center;
        background-size: 100%;
        // filter: invert(1);
      }
    }
  }
}
// 편성표 : pc
.radio-calendar-wrap {
  .radio-broadcasting-accordions-wrap {
    .component-accordion {
      @include rem(margin-left, 64);
      @include rem(padding-top, 40);
      border-top: 1px solid $line-000000;
      position: relative;
      .accordion-item.no-data {
        .info-wrap {
          .collapse-tit {
            display: none;
          }
        }
        .collapse-content {
          display: none;
        }
      }
      .church-link {
        @include rem(margin-top, 8);
        display: inline-block;
        .desc {
          @include f18(400);
          color: $font-777777;
          position: relative;
          display: inline-block;
          & + .desc {
            @include rem(margin-left, 32);
            &::before {
              content: "";
              display: block;
              position: absolute;
              @include rem(width, 1);
              @include rem(height, 16);
              @include rem(top, 50%);
              @include rem(left, -16);
              transform: translateY(-50%);
              background-color: $bg-e0e0e0;
            }
          }
        }
      }

      .accordions-link-area {
        @include rem(padding, 40 0);
        border-bottom: 1px solid $line-e0e0e0;
        .area-txt {
          display: flex;
          align-items: center;
          .time {
            @include f24(700);
          }
          .label-tag {
            @include rem(padding, 4 8 4 28);
            @include rem(margin-left, 16);
            @include f12(500);

            background:
              url("pathImages/icon/ico_on_air.png") 8px center/16px 16px
                no-repeat,
              linear-gradient(90deg, #fe508b 0%, #4c3ca5 100%);
          }
        }
        .accordions-link-bx {
          @include rem(margin-top, 32);
          position: relative;
          display: flex;
          align-items: center;
          .img-bx {
            @include rem(width, 185);
            @include rem(height, 95);
            position: relative;
            img {
              @include rem(width, 100%);

              @include rem(height, 100%);
            }
            // .rehearsal-btn {
            //   @include rem(width, 32);
            //   @include rem(height, 32);
            //   @include rem(top, 50%);
            //   @include rem(left, 50%);
            //   transform: translate(-50%, -50%);
            //   position: absolute;
            //   .ico-play {
            //     @include rem(width, 100%);
            //     @include rem(height, 100%);
            //     background: url("pathImages/icon/ico_video_play.png") no-repeat;
            //     background-size: 100% auto;
            //   }
            // }
            .onair-btn {
              @include rem(width, 40);
              @include rem(height, 40);
              @include rem(bottom, 8);
              @include rem(right, 8);
              position: absolute;
              .ico-headset {
                @include rem(width, 100%);
                @include rem(height, 100%);
                background: url("pathImages/icon/ico_headset.png") no-repeat;
                background-size: 100% auto;
              }
            }
          }
          .info-wrap {
            @include rem(width, 73%);
            @include rem(margin-left, 64);
            .top-txt-bx {
              display: flex;
              align-items: center;
              .tit-bx {
                display: flex;
                align-items: center;
                overflow: hidden;
                // width: 100%;
                // flex: 1;
                & + .label-group.pc-show {
                  display: flex;
                  @include rem(margin-left, 16);
                }
              }
              .tit {
                @include f24(700);
                @include ellipsis(1);
                // flex: 1;
                & + .home-btn {
                  @include rem(margin, 0 8);
                }
              }
              .home-btn {
                @include rem(width, 24);
                @include rem(height, 24);
                i {
                  @include rem(width, 24);
                  @include rem(height, 24);
                  background: url("pathImages/icon/ico_home_blcok.png")
                    no-repeat center;
                  background-size: 100%;
                }
              }
            }

            .bot-txt-bx {
              @include rem(margin-top, 16);
              &:empty {
                margin-top: 0;
              }
              .vido-btn {
                @include rem(width, auto);
                display: inline-flex;
                align-items: center;
                .ico-vido {
                  @include rem(margin-right, 8);
                  @include rem(width, 20);
                  @include rem(height, 14);
                  background: url("pathImages/icon/ico_visible_radio_sm.png")
                    no-repeat;
                  background-size: 100% 100%;
                }
              }
              .desc {
                @include f16(500);
              }
            }
            .rehearsal-btn {
              @include rem(width, 40);
              @include rem(height, 40);
              @include rem(right, 48);
              @include rem(top, 50%);
              position: absolute;
              transform: translateY(-50%);
              background: url("pathImages/icon/ico_radio_play.png") no-repeat
                center center;
              background-size: 100%;
              display: block;
              padding: 0;
              border: none;
              // .ico-play {
              //   @include rem(width, 40);
              //   @include rem(height, 40);
              //   background: url("pathImages/icon/ico_radio_play.png") no-repeat
              //     center center;
              //   background-size: 100%;
              // }
            }
            .collapse-tit {
              @include rem(width, 48);
              @include rem(height, 48);
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              border: 0;
              padding: 0;
              &[aria-expanded="true"] {
                border-bottom: none;
              }
              &::before {
                @include rem(width, 24);
                @include rem(height, 24);
                @include rem(left, 50%);
                right: 0;
                transform: translate(-50%, -50%);

                background: url("pathImages/btn/btn_accordions_arrow.png")
                  no-repeat center center;
                background-size: 100%;
                background-size: auto;
              }
            }
          }
        }
      }
      .accordion-item {
        position: relative;
        &[data-state="open"] {
          .collapse-tit {
            &::before {
              transform: translate(-50%, -50%) rotate(180deg) !important;
            }
          }
        }
        &:first-child {
          .accordions-link-area {
            padding-top: 0;
          }
        }
        & + .accordion-item {
          &::before {
            @include rem(top, 55);
          }
        }
        &:nth-last-child(2)::after {
          @include rem(width, 1);
          @include rem(top, 60);
          @include rem(left, -60);
          height: calc(100% - 110px);
          content: "";
          position: absolute;
          display: block;
          background-color: $line-ffffff;
          z-index: 1;
        }
        &::before {
          content: "";
          @include rem(width, 10);
          @include rem(height, 10);
          @include rem(border-radius, 50%);
          @include rem(top, 15);
          @include rem(left, -64);
          position: absolute;
          display: block;

          background-color: #000;
          z-index: 2;
        }

        .collapse-content {
          padding: 0;
          ul {
            @include rem(padding, 56 48);
            background-color: $bg-f7f8fd;
            li {
              display: flex;
              align-items: flex-start;
              &:first-child {
                .item-info-bx {
                  padding-top: 0;
                }
              }
              &:last-child {
                .time-label-bx {
                  @include rem(padding-bottom, 0);
                }
                .item-info-bx {
                  padding-bottom: 0;
                }
              }
              & + li {
                .time-label-bx {
                  @include rem(padding, 32 0);
                }
                .item-info-bx {
                  border-top: 1px solid $line-e0e0e0;
                }
              }
              .time {
                @include f24(700);
              }
              .item-info-bx {
                @include rem(width, 100%);
                @include rem(padding, 32 0);
                @include rem(margin-left, 86);
                display: flex;
                align-items: center;
                overflow: hidden;
                flex: 1;
                .info-txt-bx {
                  @include rem(width, 93%);
                }
                .tit-group {
                  display: flex;
                  align-items: center;
                  .tit-bx {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                  }
                  .tit {
                    @include f18(700);
                    @include ellipsis(1);
                    // flex: 1;
                  }
                  .home-btn {
                    @include rem(margin-left, 8);
                    @include rem(width, 24);
                    @include rem(height, 24);
                    i {
                      @include rem(width, 24);
                      @include rem(height, 24);
                      background: url("pathImages/icon/ico_home_blcok.png")
                        no-repeat;
                      background-size: 100% 100%;
                      vertical-align: sub;
                    }
                    &:empty {
                      margin-left: 0;
                    }
                  }
                  .label-group {
                    @include rem(margin-left, 16);
                  }
                }

                .link-btn-wrp {
                  @include rem(margin-left, 24);
                  @include rem(width, 40);
                  @include rem(height, 40);
                  @include rem(border-radius, 50%);
                  background: url("pathImages/icon/ico_play.png") no-repeat
                    center center $bg-335891;
                  background-size: 10px 14px;
                  display: block;
                  border: 0;
                  padding: 0;
                }
                .bot-txt-bx {
                  @include rem(margin-top, 16);
                  &:empty {
                    margin-top: 0;
                  }
                  .vido-btn {
                    @include rem(width, auto);
                    display: inline-flex;
                    align-items: center;
                    .ico-vido {
                      @include rem(margin-right, 8);
                      @include rem(width, 20);
                      @include rem(height, 14);
                      background: url("pathImages/icon/ico_visible_radio_sm.png")
                        no-repeat;
                      background-size: 100% 100%;
                    }
                  }
                  .desc {
                    @include f16(500);
                  }
                }
              }
            }
          }
        }
        &:first-of-type {
          & + .bg-line-bar {
            display: none;
          }
        }
      }

      .bg-line-bar {
        @include rem(left, -60);
        @include rem(top, 60);
        @include rem(width, 1);
        position: absolute;
        height: calc(100% - 205px);
        background-color: #e0e0e0;
      }
    }
  }
}

// table-blit-wrap : pc
.table-blit-wrap {
  max-width: 1400px;
  // margin: 0 auto;
  @include rem(margin, 80 auto 0 auto);
  .table-blit-item {
    @include rem(padding, 60 0);
    @include rem(margin-left, 240);
    position: relative;
    &:first-child {
      padding-top: 0;
      &::before {
        @include rem(top, 38%);
      }
      .pastor-list {
        .txt-info-bx {
          .pastor-info {
            .day-week {
              @include rem(top, 70);
            }
          }
        }
      }
    }
    & + .table-blit-item {
      border-top: 1px solid $line-e0e0e0;
      &::after {
        content: "";
        display: block;
        position: absolute;
        @include rem(width, 1);
        @include rem(height, 100%);
        @include rem(top, -135);
        @include rem(left, -77);
        background-color: $bg-e0e0e0;
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      @include rem(width, 7);
      @include rem(height, 7);
      @include rem(top, 50%);
      @include rem(left, -80);
      @include rem(border-radius, 50%);
      transform: translateY(-50%);
      background-color: $bg-000000;
      z-index: 1;
    }
  }
  .pastor-item {
    display: flex;
    align-items: center;
    img {
      @include rem(width, 160);
      @include rem(height, 160);
      @include rem(border-radius, 16);
      object-fit: cover;
    }
  }
  .pastor-list {
    @include rem(width, 100%);
    .txt-info-bx {
      @include rem(margin-left, 77);
      display: flex;
      align-items: center;
      .pastor-info {
        .day-week {
          @include rem(top, 120);
          @include rem(left, -240);
          @include f24(400);
          position: absolute;
        }
        span {
          @include f20(500);
        }
        p {
          @include f24(400);
        }
      }
      .tell {
        @include rem(margin-left, 128);
        @include f20(500);
        color: $font-777777;
      }
    }
    .btn-list {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-item {
        & + .btn-item {
          @include rem(margin-left, 24);
        }
      }
      .component-tooltip {
        @include rem(width, auto);
      }
      .btn {
        @include rem(width, 40);
        @include rem(height, 40);
        @include rem(border-radius, 50%);
        padding: 0;
        border-color: $line-1b5f84;
        &.home-btn {
          background: url("pathImages/icon/ico_breadcrumb_home_white.png")
            no-repeat center center $bg-1b5f84;
          background-size: 20px 20px;
        }
        &.sharing-btn {
          background: url("pathImages/icon/ico_sharing_white.png") no-repeat
            center center $bg-1b5f84;
          background-size: 20px 20px;
        }
        &.play-btn {
          background: url("pathImages/icon/ico_play.png") no-repeat center
            center $bg-1b5f84;
          background-size: 10px 14px;
        }
      }
    }
  }
}

// onair
.onair_player {
  @include rem(width, 100% !important);
  .header {
    @include rem(width, 100%);
    position: static;
    .header-inner {
      // @include rem(height, 80);
      @include rem(padding, 22 16);
      background-color: $bg-000000;
      .logo {
        @include rem(width, 68);
        @include rem(height, 20);
        display: inline-block;
        background: url("pathImages/contents/on_air_logo.png");
        background-size: 100%;
      }
    }
  }
  .onair_player_top {
    @include rem(height, 100%);
    @include rem(padding, 0 16 16);
    margin-top: -1px;
    display: flex;
    align-items: center;
    background-color: $bg-000000;
    .ico-on-air-img {
      @include rem(width, 75);
      @include rem(height, 26);
      background: url("pathImages/icon/ico_on_air_img.png") no-repeat left
        center;
      background-size: 100%;
    }
    .component-select {
      @include rem(margin-left, 8);
      @include rem(width, 74);
      .select-box {
        @include rem(padding, 0 5);
        @include rem(height, auto);
        background-color: $bg-000000;
        color: $font-ffffff;
        border: none;
        &::after {
          @include rem(width, 8);
          @include rem(height, 5);
          @include rem(right, 24);
          background: url("pathImages/btn/btn_onair_select.png") no-repeat
            center center;
          background-size: 100%;
        }
      }
      .select-options {
        z-index: 12;
      }
    }
    .frequency {
      @include rem(margin-left, auto);
      @include f14(500);
      color: $font-ffffff;
    }
  }
  .onair-player-control {
    position: relative;
    .component-jplayer {
      z-index: 5;
    }
    .onair-video-wrap {
      .video-js {
        @include rem(width, 100%);
        @include rem(min-height, 400);
        position: relative;
        padding-top: 59.25%;
        .vjs-big-play-button {
          @include rem(width, 64);
          @include rem(height, 64);
          @include rem(top, 50%);
          @include rem(left, 50%);
          transform: translate(-50%, -50%);
          border: 0;
          background: url("pathImages/btn/btn_radio_play.png") no-repeat center
            center;
          background-size: 100%;

          .vjs-icon-placeholder:before {
            display: none;
          }
        }
        .vjs-control-bar {
          @include rem(padding, 12 16);
          z-index: 11;
          height: auto;
        }
        .vjs-fullscreen-control {
          display: none;
        }
        .vjs-control {
          @include rem(width, 24);
          @include rem(height, 28);
          align-items: center;
          &.vjs-paused,
          &.vjs-playing,
          &.vjs-volume-panel-horizontal {
            @include rem(margin-right, 8);
          }
          &.vjs-picture-in-picture-control {
            display: none;
          }
          &.vjs-progress-control {
            display: none;
          }
          &.vjs-remaining-time {
            display: flex;
            width: auto;
            margin-left: auto;
            display: none;
          }
        }
        .vjs-mute-control {
          .vjs-icon-placeholder:before {
            content: "";
            background: url("pathImages/icon/ico_onair_sound.png") no-repeat
              center center;
            background-size: 24px 24px;
          }
        }
        .vjs-picture-in-picture-control {
          .vjs-icon-placeholder:before {
            display: none;
            // content: "";
            // background: url("pathImages/icon/ico_onair_subtitle.png") no-repeat
            //   center center;
            // background-size: 24px 24px;
          }
        }
        .vjs-fullscreen-control {
          .vjs-icon-placeholder:before {
            content: "";
            background: url("pathImages/icon/ico_onair_screen.png") no-repeat
              center center;
            background-size: 24px 24px;
          }
        }
        .vjs-button > .vjs-icon-placeholder:before {
          line-height: 1.6;
          font-size: 1.8rem;
        }
        .vjs-live-control {
          line-height: 2.4rem;
          justify-content: end;
        }
      }
      .video-js
        .vjs-volume-panel.vjs-hover
        .vjs-volume-control.vjs-volume-horizontal,
      .video-js
        .vjs-volume-panel:active
        .vjs-volume-control.vjs-volume-horizontal,
      .video-js
        .vjs-volume-panel:focus
        .vjs-volume-control.vjs-volume-horizontal,
      .video-js
        .vjs-volume-panel
        .vjs-volume-control:active.vjs-volume-horizontal,
      .video-js
        .vjs-volume-panel.vjs-hover
        .vjs-mute-control
        ~ .vjs-volume-control.vjs-volume-horizontal,
      .video-js
        .vjs-volume-panel
        .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
        width: 5em;
        height: 2.4em;
        margin-right: 0;
        align-items: center;
      }
      .video-js
        .vjs-volume-panel.vjs-hover
        .vjs-volume-control.vjs-volume-vertical,
      .video-js
        .vjs-volume-panel:active
        .vjs-volume-control.vjs-volume-vertical,
      .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-vertical,
      .video-js
        .vjs-volume-panel
        .vjs-volume-control:active.vjs-volume-vertical,
      .video-js
        .vjs-volume-panel.vjs-hover
        .vjs-mute-control
        ~ .vjs-volume-control.vjs-volume-vertical,
      .video-js
        .vjs-volume-panel
        .vjs-volume-control.vjs-slider-active.vjs-volume-vertical {
        left: -3.5em;
        transition: left 0s;
      }
      .video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover,
      .video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active,
      .video-js
        .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
        width: 8em;
        transition: width 0.1s;
      }
      .video-js .vjs-volume-level {
        background-color: $bg-fe508b;
        &:before {
          color: $bg-fe508b;
        }
      }

      .vjs-has-started .vjs-poster {
        display: block;
        background-size: cover;
        background-position-y: bottom;
        background-position-x: center;
      }
      .vjs-poster {
        // background-size: 100% 100%;
        background-size: cover;
        background-position-y: bottom;
        background-position-x: center;
      }
    }
    img {
      @include rem(width, 100%);
    }
    .info-bx {
      position: absolute;
      @include rem(bottom, 72);
      @include rem(left, 32);
      color: $font-ffffff;
      z-index: 6;
      .time,
      .state,
      .name {
        @include f14(500);
      }
      .tit {
        @include rem(margin, 16 0 6);
        @include f24(500);
      }
      .name {
        @include rem(margin-left, 16);
        position: relative;
        &::before {
          @include rem(width, 1);
          @include rem(height, 10);
          @include rem(top, 50%);
          @include rem(left, -8);
          content: "";
          display: block;
          position: absolute;
          transform: translateY(-50%);
          background-color: $bg-ffffff;
          opacity: 0.2;
        }
      }
    }
    .home-btn {
      position: absolute;
      display: block;
      @include rem(width, 24);
      @include rem(height, 24);
      @include rem(right, 32);
      @include rem(top, 32);
      background: url("pathImages/icon/ico_sub_visual_home.png") no-repeat
        center center;
      background-size: 100%;
      z-index: 6;
    }

    .player-controller {
      @include rem(width, 100%);
      @include rem(height, 60);
      @include rem(bottom, 0);
      @include rem(left, 0);
      @include rem(right, 0);
      position: absolute;
    }
  }
  .onair_player_bottom {
    @include rem(width, 100%);
    @include rem(height, 100%);
    @include rem(padding, 16);
    background-color: $bg-000000;

    ul {
      @include rem(border-radius, 8);
      @include rem(height, 48);
      margin: 0 auto;
      @include rem(width, 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $bg-444444;
      li {
        @include rem(width, 50%);
        position: relative;
        text-align: center;
        & + li {
          &::before {
            content: "";
            display: block;
            position: absolute;
            @include rem(width, 1);
            @include rem(height, 16);
            @include rem(top, 50%);
            @include rem(left, 0);
            transform: translateY(-50%);
            background-color: $bg-777777;
          }
        }
        a {
          @include rem(width, 100%);
          @include rem(height, 24);
          @include rem(padding-left, 32);
          @include f14(500);
          color: $font-ffffff;
          display: block;
          span {
            position: relative;
          }
          &.schedule {
            span {
              &::before {
                @include rem(width, 24);
                @include rem(height, 24);
                @include rem(top, 50%);
                @include rem(left, -30);
                content: "";
                display: block;
                position: absolute;
                transform: translateY(-50%);
                background: url("pathImages/icon/ico_onair_calendar.png") center
                  center no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          &.faq {
            span {
              &::before {
                @include rem(width, 24);
                @include rem(height, 24);
                @include rem(top, 50%);
                @include rem(left, -30);
                content: "";
                display: block;
                position: absolute;
                transform: translateY(-50%);
                background: url("pathImages/icon/ico_onair_talk.png") center
                  center no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }
  }

  .banner-area {
    @include rem(padding, 0 16 16 16);
    background-color: $bg-000000;
    a {
      @include rem(padding, 14 0);
      @include rem(width, 100%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      & + a {
        @include rem(border-top, 1px solid $line-555555);
      }
      .txt-bx {
        @include rem(padding-left, 16);
        .desc {
          @include f12(500);
          color: $font-999999;
          & + .txt {
            @include rem(margin-top, 4);
          }
        }
        .txt {
          @include f16(500);
          color: $font-ffffff;
        }
      }
      .img-bx {
        @include rem(padding-right, 16);
        img {
          @include rem(width, 64);
          @include rem(height, 56);
        }
      }
    }
  }
  // .banner-area {
  //   @include rem(margin-top, -3);
  // }
}

/*========================================================================
	메뉴 : 설교
========================================================================*/
// 설교 서브메인 : pc
.sermon-sub-main-wrap {
  .sermon-contents-wrap {
    @include rem(margin-top, 160);
    .hgroup-component {
      max-width: 1680px;
      @include rem(margin, 0 auto 40);
    }
    .sermon-swiper {
      @include rem(max-width, 1920);
      @include rem(padding-left, 120);
      margin: 0 auto;
      // img {
      //   @include rem(max-width, 536);
      //   @include rem(max-height, 300);
      //   object-fit: contain;
      // }
      .tit-bx {
        @include rem(padding, 24 0 32);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $line-e0e0e0;
        .tit {
          @include f24(700);
          @include ellipsis(1);
          @include rem(margin-right, 10);
        }
        .label-group {
          flex-shrink: 0;
        }
      }
      .desc-bx {
        @include rem(padding-top, 16);
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          @include f18(400);
          color: $font-777777;
          &.church {
            @include rem(margin-left, 16);
            position: relative;
            &::before {
              content: "";
              display: block;
              position: absolute;
              @include rem(width, 1);
              @include rem(height, 12);
              @include rem(top, 50%);
              @include rem(left, -6);
              transform: translateY(-50%);
              background-color: $bg-e0e0e0;
            }
          }
        }
      }
    }
  }
  .sermon-list-wrap {
    max-width: 1680px;
    @include rem(margin, 160 auto 40);
    .table-custom {
      @include rem(margin-top, 48);
    }
  }
  .last-radio-sermon-wrap {
    @include rem(max-width, 1920);
    @include rem(margin, 0 auto 0);
    .hgroup-component {
      .hgroup-sub-tit {
        @include rem(margin-bottom, 16);
      }
      .hgroup-txt {
        @include rem(margin, 0 0 80 0);

        color: $font-999999;
      }
    }
    .sermon-list {
      align-items: flex-start;
      .sermon-item {
        .sermon-info {
          @include rem(margin-top, 24);
        }
        // img {
        //   @include rem(height, 600);
        // }
        a {
          display: inline-block;
        }
        .desc-bx {
          margin: 0;
        }
        .church,
        .pastor {
          color: $font-999999;
        }
        .listen {
          @include rem(margin-top, 24);
          .btn.btn-primary-line {
            .btn-txt {
              color: $font-fe508b;
            }
          }
        }
      }
    }
  }
}
// 설교 상세 : pc
.sermon-details-wrap {
  @include rem(max-width, 1400);
  @include rem(margin, 160 auto 0);
  &.reset {
    @include rem(margin, 0 auto);
  }
  .radio-details-program-wrap {
    .tbl-count.first {
      .component-form {
        @include rem(margin, 96 auto 0);
      }
    }
    .tab-group {
      @include rem(margin-top, 80);
    }
  }
  .sermon-play-info-wrap {
    .sermon-play-info-bx {
      @include rem(margin-top, 40);
      display: flex;
      align-items: flex-start;

      .play-bx {
        // @include rem(height, 486);
        button {
          @include rem(width, 860);
          @include rem(height, 100%);
          display: block;
          .ico-play-bx {
            padding-top: 56.25%;
          }
          img {
            @include rem(width, 100%);
            @include rem(height, 100%);
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .txt-info-bx {
        @include rem(width, 100%);
        @include rem(margin-left, 80);
        @include rem(padding, 40 0 40);
        border-top: 1px solid $line-000000;
        border-bottom: 1px solid $line-000000;
        .desc {
          @include rem(margin, 24 0 8);
          @include f24(400);
        }
        .tit {
          @include f40(700);
        }
        .name {
          @include rem(margin, 16 0 40);
          @include f20(500);
          color: $font-777777;
        }
        .txt {
          @include rem(margin-bottom, 16);
          @include f20(500);
          color: $font-777777;
        }
      }
    }
  }
  .sermon-table-list-wrap {
    @include rem(margin-top, 160);
    .component-tab {
      @include rem(margin-bottom, 32);
    }
  }
  .sermon-schedule-list-wrap {
    .sermon-schedule-list {
      .sermon-schedule-item {
        @include rem(padding, 40 0);
        @include rem(margin-left, 71);
        border-bottom: 1px solid $line-e0e0e0;
        position: relative;
        &:first-child {
          padding-top: 0;
          &::before {
            @include rem(top, 15);
          }
          // &::after {
          //   display: none;
          // }
        }
        &::before {
          @include rem(width, 7);
          @include rem(height, 7);
          @include rem(top, 52);
          @include rem(left, -71);
          @include rem(border-radius, 50%);
          content: "";
          display: block;
          position: absolute;
          background-color: $bg-000000;
          z-index: 1;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          @include rem(width, 1);
          height: calc(100% + 45px);
          @include rem(top, 15);
          @include rem(left, -68);
          background-color: $bg-e0e0e0;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        .info-bx {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .txt-bx {
            .time {
              @include f24(700);
            }
            .tit {
              @include rem(margin, 40 0 8);
              @include f24(700);
            }
            .church-link {
              display: inline-block;
            }
            .church {
              @include f18(400);
              color: $font-777777;
            }
            .pastor {
              @include rem(margin-left, 32);
              @include f18(400);
              color: $font-777777;
              position: relative;
              &::before {
                content: "";
                display: block;
                position: absolute;
                @include rem(width, 1);
                @include rem(height, 12);
                @include rem(top, 50%);
                @include rem(left, -16);
                transform: translateY(-50%);
                background-color: $bg-e0e0e0;
              }
            }
          }
          .btn-bx {
            display: flex;
            align-items: center;
            a {
              display: block;
              @include rem(width, 40);
              @include rem(height, 40);
              @include rem(border-radius, 50%);
              &.home {
                @include rem(margin-left, 24);
                background: url("pathImages/icon/ico_breadcrumb_home_white.png")
                  no-repeat center center $bg-335891;
                background-size: 20px 20px;
              }
              &.play {
                @include rem(margin-left, 24);
                background: url("pathImages/icon/ico_play.png") no-repeat center
                  center $bg-335891;
                background-size: 10px 14px;
                padding: 0;
                border: 0;
              }
            }
          }
        }
      }
    }
  }
  .sermon-map-wrap {
    @include rem(max-width, 1400);
    @include rem(margin, 0 auto);
    .tbl-count {
      @include rem(margin-top, 96);
      .component-form {
        @include rem(width, 100%);
        margin: 0;
        justify-content: center;
        .select-bx {
          display: flex;
          .component-select {
            & + .component-select {
              @include rem(margin-left, 24);
            }
          }
        }
      }
    }
    .sermon-map-info-bx {
      @include rem(margin-top, 48);

      display: flex;

      .sermon-map-list-bx {
        @include rem(width, 40%);
        a {
          // @include rem(width, 100%);
          @include rem(padding, 32 0);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .txt {
          @include f18(400);
        }
        .info {
          @include rem(padding, 8 0 24);
          display: flex;
          align-items: center;
          .tit {
            @include ellipsis(1);
            @include f24(400);
          }
          img {
            @include rem(width, 24);
            @include rem(height, 24);
            @include rem(margin-left, 8);
            object-fit: contain;
          }
        }
        .pastor,
        .address,
        .tell {
          @include f16(500);
          color: $font-777777;
        }
        .address {
          @include rem(margin-left, 16);
          position: relative;
          &::before {
            content: "";
            display: block;
            position: absolute;
            @include rem(width, 1);
            @include rem(height, 10);
            @include rem(top, 50%);
            @include rem(left, -8);
            transform: translateY(-50%);
            background-color: $bg-e0e0e0;
          }
        }
        .tell {
          @include rem(margin-top, 8);
          display: block;
          pointer-events: none;
        }
      }
      .sermon-map-active {
        @include rem(padding, 32 56);
        @include rem(height, 215);
        @include rem(margin-bottom, 16);
        @include rem(border-radius, 10);
        border: 1px solid $line-fe508b;
        display: none;
        a {
          padding: 0 !important;
        }
        &.on {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & + .sermon-map-list {
            overflow: hidden;
            .inner {
              @include rem(height, 100%);
              @include rem(max-height, 525);
            }
          }
        }

        .marker {
          display: block;
          .ico-marker {
            @include rem(width, 40);
            @include rem(height, 40);
            @include rem(border-radius, 50%);
            background: url("pathImages/btn/btn_day_next.png") no-repeat center
              center $bg-fe508b;
            background-size: 20px 20px;
          }
        }
      }
      .sermon-map-list {
        @include rem(padding, 24 16 16);
        @include rem(border-radius, 10);
        border: 1px solid $line-e0e0e0;
        position: relative;
        overflow: hidden;
        .inner {
          @include rem(max-height, 758);
          @include rem(height, 100%);
          @include rem(padding, 0 36 0);
          overflow-y: scroll;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            @include rem(width, 4);
          }
          &::-webkit-scrollbar-thumb {
            background-color: $bg-999999;
          }
          &::-webkit-scrollbar-track {
            background-color: $bg-e0e0e0;
          }
        }
        .txt-bx {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .count {
          @include f18(400);
        }
        .km-txt {
          @include f18(400);
        }
        ul {
          @include rem(margin-top, 16);
          @include rem(margin-bottom, 48);
          border-bottom: 1px solid $line-e0e0e0;
          li {
            border-top: 1px solid $line-e0e0e0;
            &:first-child {
              border-top: 1px solid $line-000000;
            }
            &:last-child {
              @include rem(padding-bottom, 30);
            }
            > a {
              @include rem(min-height, 151);
            }
          }
        }
        .component-pagination {
          @include rem(padding, 20 96);
          background-color: $bg-ffffff;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          position: absolute;

          left: 0;
          bottom: 0;
          right: 0;
          a {
            padding: 0;
            justify-content: center;
          }
        }
      }
      .map-wrap {
        position: relative;
        @include rem(margin-left, 28);
        @include rem(width, 60%);
        .kakao-map-bx {
          @include rem(width, 100%);
          @include rem(min-height, 800);
          .map-marker-point {
            position: absolute;
            @include rem(width, 24);
            @include rem(height, 24);
            @include rem(top, 50%);
            @include rem(left, 50%);
            background: url("pathImages/icon/marker_point.png") no-repeat center
              center;
            background-size: 100%;
            &.disabled {
              background-image: url("pathImages/icon/marker_point_disabled.png");
            }
          }
          img {
            @include rem(width, 100%);
            @include rem(height, 800);
          }
        }
        .btn-location {
          @include rem(width, 40);
          @include rem(height, 40);

          @include rem(right, 40);
          @include rem(bottom, 25);
          @include rem(border-radius, 50%);

          position: absolute;

          background: url("pathImages/icon/icon_location.png") no-repeat center
            center $bg-ffffff;
          background-size: 24px 24px;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
          z-index: 10;
        }
      }
    }
  }
}
// 설교 상세 비쥬얼영역 : pc
.sermon-visual-wrap {
  max-width: 1920px;
  @include rem(height, 544);
  margin: 0 auto;
  background: url("pathImages/contents/sermon_visual_img.png") no-repeat center
    center;
  background-size: 100% 100%;
  .sermon-visual-bx {
    @include rem(max-width, 1400);
    @include rem(margin, 0 auto);
    @include rem(height, 100%);
    position: relative;
  }
  .sermon-visual-info {
    @include rem(padding, 136 0 82);
    .sermon-visual-tit-bx {
      @include rem(margin-bottom, 16);
      display: flex;
      align-items: center;
      .sermon-visual-tit {
        @include f56(700);
      }
      .sermon-home-btn {
        @include rem(margin-left, 8);
        @include rem(width, 40);
        @include rem(height, 40);
        flex-shrink: 0;

        .ico-sermon-home {
          @include rem(width, 40);
          @include rem(height, 40);
          background: url("pathImages/icon/icon_sermon_home.png") no-repeat
            center center;
          background-size: 100% 100%;
          vertical-align: super;
        }
      }
    }
    .sermon-visual-txt {
      @include f24(400);
    }
  }
  .sermon-visual-address {
    display: flex;
    align-items: flex-start;
    dl {
      & + dl {
        @include rem(margin-left, 40);
      }
      dt {
        @include rem(margin-bottom, 8);
        @include f16(500);
        opacity: 0.5;
      }
      dd {
        @include f18(400);
      }
    }
  }
  .component-tooltip {
    @include rem(top, 160);
    @include rem(right, 0);
    position: absolute;
    z-index: 6;
    .tooltip-btn-trigger {
      @include rem(width, 100%);
      @include rem(height, 24);
      @include f16(700);
      position: relative;
      color: $font-000000;
      padding: 0;
      border: none;
      background: none;
      flex-shrink: 0;
      white-space: nowrap;
      &::before {
        @include rem(top, 50%);
        @include rem(left, -30);
        @include rem(width, 20);
        @include rem(height, 20);
        content: "";
        display: block;
        position: absolute;
        background: url("pathImages/icon/ico_tooltip_sm.png") no-repeat center
          center;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
  }
  .character-list {
    @include rem(top, 200);
    @include rem(right, 0);
    position: absolute;
    display: flex;
    align-items: center;
    .character-item {
      img {
        @include rem(width, 160);
        @include rem(height, 160);
        object-fit: cover;
      }
      .txt-bx {
        @include rem(margin-top, 8);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      p {
        @include f18(700);
      }
      span {
        @include f18(400);
      }
      & + .character-item {
        @include rem(margin-left, 24);
      }
    }
  }
}
// ############################################# Mo #############################################
@media (max-width: 1500px) {
  // 라디오 서브메인
  .radio-page-container-main {
    .radio-main-visual-wrap {
      .navi-num {
        @include rem(left, 275);
      }
      .swiper-autoplay-wrap {
        @include rem(left, 355);
      }
      .swiper-navigation.ani-custom {
        @include rem(right, 250);
      }
    }
  }
}
@media (max-width: 1250px) {
  // 라디오 서브메인
  .radio-page-container-main {
    .radio-main-visual-wrap {
      .navi-num {
        @include rem(left, 250);
      }
      .swiper-autoplay-wrap {
        @include rem(left, 330);
      }
      .swiper-navigation.ani-custom {
        @include rem(right, 220);
      }
    }
  }
}
@media (max-width: 1023px) {
  /*========================================================================
	메뉴 : 영상 : mo
========================================================================*/
  .febc-video-wrap {
    .section-inner {
      max-width: 100%;
      width: 100%;
      @include rem(margin, 72 auto 0 auto);
    }
    .hgroup-component {
      @include rem(margin-bottom, 24);
      .hgroup-tit {
        @include f24(500);
      }
    }

    .video-visual-area {
      height: auto;
      .section-inner {
        position: absolute;
        @include rem(top, 0);
        height: 100%;
        @include rem(padding, 80 24 0 24);
        .hgroup-component {
          @include rem(margin-bottom, 40);
          .hgroup-tit {
            @include f24(700);
            @include rem(margin, 0);
          }
        }

        .video-visual-swiper {
          width: 100%;
          .swiper-container {
            // position: static;
            // transform: translateY(-80%);
            // opacity: 0;
          }
          .swiper-item {
            position: relative;
            @include rem(padding, 32 32);

            p {
              @include f18(400);
            }
          }

          .swiper-controls {
            position: absolute;
            @include rem(bottom, 40);
            @include rem(padding, 0 24);
          }
          .component-btns {
            @include rem(margin-top, 16);
          }
        }
      }
      .visual-img {
        position: static;
        left: 50%;
        top: 0;
        transform: none;
        max-width: auto;
        overflow: hidden;
        img {
          // transition: all 8s;
          width: 100%;
        }
      }
    }

    .video-take-look {
      .section-inner {
        width: 100%;
        .hgroup-component {
          @include rem(padding, 0 24);
        }
      }
      .new-video-swiper {
        .swiper-container {
          @include rem(padding, 0 20 25 20);
        }
        .swiper-item {
          .img-zone {
            max-height: max-content;
          }
        }
        .txt-info {
          @include rem(padding, 16 24 24 24);
        }
        .video-tit {
          @include f18(700);
          @include rem(margin-bottom, 32);
          @include ellipsis(2);
          white-space: normal;
        }
        .video-txt {
          .txt {
            @include f14(500);
            @include ellipsis(2);
          }
          .btn-area {
            flex-shrink: 0;
          }
        }
      }
    }

    .video-program-intro {
      .section-inner {
        @include rem(padding, 0 24);
      }
      .hgroup-component {
        display: block;
        .component-tab {
          .tab-head {
            @include rem(margin-top, 24);
            padding: 0;
          }
        }
      }
      .list-comm-wrap {
        margin: 0;
      }
      .gallary-type {
        .img-zone {
          img {
            height: auto;
          }
        }
        .list-tit {
          text-align: left;
        }
      }
    }

    .video-febc-amu {
      .section-inner {
        @include rem(padding, 0 24);
      }
      .gallary-type {
        .img-zone {
        }
        .list-tit {
        }
      }
    }

    .video-channel {
      .section-inner {
        // @include rem(padding, 0 24);
        overflow: hidden;
        .hgroup-component {
          @include rem(padding, 0 24);
        }
      }
      .video-channel-swiper {
        position: relative;
        @include rem(padding, 0);
      }
      .swiper-container {
        @include rem(padding, 0 24);
      }
      .swiper-item {
        @include rem(padding, 16);
      }
      .img-zone {
        @include rem(width, 56);
        @include rem(height, 56);
        @include rem(margin-right, 16);
      }

      dl {
        dt {
          @include f16(500);
        }
        dd {
          @include f14(500);
        }
      }

      // 좌, 우 버튼 기본
      .swiper-navigation {
        display: none;
      }
    }
  }

  // 서브메인Mo

  // 2024-06-12
  // 라디오 서브메인 방영 중 프로그램 스와이퍼 테스트
  // 후에 삭제 필요
  .swiper-navigation {
    &.custom-class {
      display: none;
    }
  }
  /*========================================================================
	메뉴 : 시청자센터 : mo
========================================================================*/

  // 공지사항 List : mo
  .viewers-notice-wrap {
    @include rem(margin-top, 40);
  }

  // 기타신청 : mo
  .other-proposal-wrap {
    @include rem(margin-top, 40);
    .other-list {
      > li {
        @include rem(padding, 24 0);
        .txt-info {
          @include rem(padding, 0);

          .tit {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include rem(margin-bottom, 24);
            @include f20(500);
            .icon-area {
              position: static;
              @include rem(margin-right, 16);
            }
          }
          .txt {
            p {
              @include f16(500);
              strong {
                font-weight: 700;
              }
              & + p {
                @include rem(margin-top, 12);
              }
            }
            .comm-bl-dot {
            }
            .call-number {
              @include f16(700);
            }
          }
          .btn-line-arrow {
            @include rem(bottom, 10);
          }
          .btn-area {
            position: static;
            @include rem(margin-top, 32);
          }
        }
      }
    }

    .icon-area {
      @include rem(left, 24);
      top: 0;
      [class^="ico-"] {
        display: block;
        @include rem(width, 48);
        @include rem(height, 48);

        &.ico-car {
          background-image: url("pathImages/icon/ico_car_mo.png");
        }
        &.ico-book {
          background-image: url("pathImages/icon/ico_book_mo.png");
        }
        &.ico-volunteer {
          background-image: url("pathImages/icon/ico_volunteer_mo.png");
        }
        &.ico-support {
          background-image: url("pathImages/icon/ico_support_mo.png");
        }
        &.ico-radio {
          background-image: url("pathImages/icon/ico_radio_mo.png");
        }
      }
    }
  }

  // 무료광고신청, 고충처리 게시판
  .application-method {
    @include rem(margin, 40 0 32);

    .component-accordion {
    }
    .accordion-item {
      @include rem(border-radius, 8);
      background-color: $bg-f7f8fd;
      overflow: hidden;
      .collapse-tit {
        @include rem(padding, 16);
        @include f16(500);
        &[aria-expanded="true"] {
        }
        &::before {
          @include rem(right, 16);
        }
      }
      .collapse-content {
        @include rem(padding, 4 24 16 24);
        border: 0;
        .comm-bl-dot {
          @include rem(padding-left, 0);
          li {
            &::before {
            }
            & + li {
              @include rem(margin-top, 8);
            }
          }
        }
      }

      & + .accordion-item {
        @include rem(margin-top, 8);
      }
    }

    // icon
    [class^="ico-"] {
      @include rem(margin-right, 10);
    }
    .ico-method {
      background-image: url("pathImages/icon/ico_method.png");
    }
    .ico-standard {
      background-image: url("pathImages/icon/ico_standard.png");
    }
  }

  /*========================================================================
	메뉴 : 라디오 : mo
========================================================================*/

  // 라디오 서브메인 : mo
  .radio-page-container-main {
    // @include rem(padding-top, 24);
    .hgroup-component {
      .hgroup-sub-tit {
        @include f24(500);
        @include rem(padding-right, 30);
        br {
          display: block !important;
        }
      }
    }
    .radio-main-visual-wrap {
      .hgroup-component {
        @include rem(margin, 16 0 0 0);
        position: static;
        display: flex;
        flex-direction: column-reverse;
        .day-time {
          @include rem(margin, 16 0 0 0);
          @include f14(500);
          color: $font-999999;
        }
        .hgroup-tit {
          @include f20(700);
          color: $font-000000;
        }
      }
      .swiper-container {
        background-color: $bg-ffffff;
      }
      .swiper-slide {
        a {
          // @include rem(width, 100%);
          @include rem(height, 242);
          // position: relative;/
          // display: block;
        }
        .img-zone {
          @include rem(height, 100%);
        }
        img {
          @include rem(height, 242);
          top: auto;
        }
        &.swiper-slide-active {
          .hgroup-component {
            overflow: unset;
            .day-time {
              span {
                & + span::before {
                  opacity: 1;
                  background-color: $bg-e0e0e0;
                }
              }
            }
          }
        }
        &.swiper-slide-prev,
        &.swiper-slide-next {
          scale: 0.9;
          .hgroup-component {
            .day-time,
            .hgroup-tit {
              overflow: hidden;
              position: absolute;
              width: 1px;
              height: 1px;
              font-size: 1px;
              line-height: 0;

              clip: rect(0 0 0 0);
              white-space: nowrap;
              margin: -1px;
              padding: 0;
              border: 0;
            }
          }
        }
      }
      .navi-num {
        @include rem(top, 370);
        @include rem(left, 20);
        border: 1px solid $line-000000;
        color: $font-000000;
      }
      // .swiper-autoplay-wrap {
      //   @include rem(top, 370);
      //   @include rem(left, 100);
      //   bottom: 0;
      //   .swiper-autoplay {
      //     background-image: url("pathImages/btn/btn_swiper_pause_round.png");
      //     &.stop {
      //       background-image: url("pathImages/btn/btn_swiper_pause_play.png");
      //     }
      //   }
      // }
      .swiper-navigation.ani-custom {
        display: none;
        // @include rem(top, 428);
        // @include rem(right, 24);
        // display: flex;
        // position: absolute;
        // align-items: center;
        // flex-direction: row;
        // justify-content: flex-end;
        // .ani-i {
        //   display: none;
        // }
        // [class^="swiper-button"] {
        //   @include rem(width, 20);
        //   @include rem(height, 20);
        // }
        // .swiper-button-prev {
        //   margin: 0;
        //   .button-icon {
        //     background-image: url("pathImages/btn/btn_swiper_sub_l.png");
        //   }
        // }
        // .swiper-button-next {
        //   @include rem(margin-left, 16);
        //   .button-icon {
        //     background-image: url("pathImages/btn/btn_swiper_sub_r.png");
        //   }
        // }
      }
      .label-group {
        @include rem(top, 16);
        @include rem(right, 16);
        .label-tag {
          // @include rem(padding, 4 8);
          // @include f12(700);
          // border-radius: 0;
          // background: $bg-335891;
        }
      }
      .component-tab {
        .tab-head {
          .tab-label {
            @include rem(width, 100%);
            @include rem(padding, 12 24);
            @include f14(500);
            border: 0;
            overflow: unset;
            color: rgba(255, 255, 255, 0.5);
            &[aria-selected="true"] {
              color: $font-ffffff;
              &::after {
                display: none;
              }
              .time {
                color: $font-ffffff;
                opacity: 1;
              }
            }
            // & + .tab-label {
            //   &::before {
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     @include rem(width, 1);
            //     @include rem(height, 22);
            //     @include rem(top, 50%);
            //     @include rem(left, 0);
            //     transform: translateY(-50%);
            //     background-color: $bg-e0e0e0;
            //     opacity: 0.5;
            //   }
            // }
            .time {
              @include f14(500);
            }
          }
        }
      }
    }

    section {
      & + section {
        @include rem(margin-top, 72);
      }
      &.radio-sub-main-visual-wrap {
        @include rem(margin-top, 128);
        .section-inner {
          .hgroup-component {
            @include rem(width, 100%);
            @include rem(padding, 0 24);
            @include rem(margin-bottom, 24);
          }
          .tab-body-wrap {
            @include rem(padding, 0 24);
            .tab-head {
              justify-content: flex-start;
            }
            .tab-list-area {
              @include rem(row-gap, 24);
              grid-template-columns: repeat(1, 1fr);
              a {
                text-align: center;
                img {
                  height: auto;
                }
                p {
                  @include rem(margin, 16 0 8);
                  @include f18(400);
                }
                span {
                  @include f14(500);
                }
              }
            }
          }
          // .radio-swiper {
          //   max-width: 100%;
          //   .swiper-container {
          //     @include rem(padding, 0 24);
          //   }
          //   // img {
          //   //   @include rem(max-width, 312);
          //   //   @include rem(max-height, 312);
          //   // }
          //   p {
          //     @include rem(margin, 16 0 4);
          //     @include f18(700);
          //   }
          //   span {
          //     @include f14(500);
          //   }
          // }
          .swiper-controls {
            display: none;
          }
        }
      }
      &.last-radio-listening-wrap {
        @include rem(max-width, 100%);
        @include rem(padding, 0 24);
        .hgroup-component {
          @include rem(margin-bottom, 24);
        }
        .listening-list {
          flex-direction: column;
          border: 0;
          .listening-item {
            @include rem(width, 100%);
            @include rem(padding, 24 0);
            display: flex;
            align-items: center;
            & + .listening-item {
              border-left: none;
              border-top: 1px solid $line-e0e0e0;
            }
            .btn-listening {
              flex-shrink: 0;
              @include rem(width, 78);
            }
            button {
              @include rem(width, 64);
              @include rem(height, 64);
            }
            .txt-bx {
              @include rem(padding-left, 16);
              @include rem(width, 75%);
              p {
                @include rem(margin, 0 0 8 0);
                @include f16(500);
              }
              span {
                @include f16(500);
              }
            }
          }
        }
      }
      &.last-radio-sermon-wrap {
        @include rem(padding, 0 24);
        flex-direction: column;
        .hgroup-component {
          @include rem(width, 100%);
          @include rem(margin, 0 0 40 0);
          .btn-txt-more {
            margin: 0;
            flex-shrink: 0;
          }
        }
        .sermon-list {
          @include rem(width, 100%);
          display: block;
          flex-direction: column;
          padding: 0;
          .sermon-item {
            @include rem(max-width, 100%);
            @include rem(width, 100%);
            display: flex;
            align-items: center;
            & + .sermon-item {
              padding-left: 0;

              @include rem(padding-top, 24);
              border-top: 1px solid $line-e0e0e0;
            }
            img {
              @include rem(width, 120);
              @include rem(height, 184);
            }
            .sermon-info {
              @include rem(max-width, 100%);
              @include rem(padding-left, 16);
              .img-txt {
                @include rem(max-width, 100%);
                @include rem(width, 100%);
                @include f18(400);
                white-space: normal;
              }
            }
          }
        }
      }
      &.febc-amu-wrap {
        @include rem(padding, 0 24);
        max-width: 100%;
        .hgroup-component {
          @include rem(margin-bottom, 24);
        }
        .gallary-type {
          display: block;
          li {
            @include rem(width, 100%);
            .img-zone {
              img {
                @include rem(height, 100%);
              }
            }
            .list-tit {
              @include rem(padding, 16 0 0 0);
              @include rem(width, 95%);
              @include f16(400);
              margin: 0;
            }
          }
        }
      }
      &.visual-radio-bottom-wrap {
        .visual-item {
          @include rem(margin-top, 0);
          flex-direction: column;
          &:nth-child(1) {
            a {
              background: url("pathImages/contents/radio_banner_mo.png")
                no-repeat center center;
              background-size: 100% 100%;
            }
          }
          &:nth-child(2) {
            margin: 0;
            .visual-left,
            .visual-right {
              @include rem(width, 100%);
              margin: 0;
              a {
                @include rem(padding, 27 24);
                .bottom-visual-play {
                  @include rem(width, 24);
                  @include rem(height, 24);
                }
              }
            }
          }
          a {
            @include rem(width, 100%);
            @include rem(padding, 16 24);
            .desc {
              @include f18(700);
            }
            .txt {
              @include f16(500);
            }
          }
        }
      }
    }
  }
  // 라디오 서브메인 상세 : mo
  .radio-details-program-wrap {
    .tab-group {
      @include rem(margin-top, 40);
      flex-direction: column;
      align-items: flex-start;
      .component-tab:nth-child(1) {
        @include rem(margin-bottom, 34);
      }
      .component-tab:nth-child(2) {
        .tab-head {
          padding: 0;
        }
      }
    }
    .tab-list {
      @include rem(row-gap, 32);
      grid-template-columns: repeat(1, 1fr);
      a {
        text-align: left;
        .img-zone {
          position: relative;
          padding-top: 56.25%;
          img {
            position: absolute;
            left: 0;
            top: 0;
            @include rem(border-radius, 8);
            height: 100%;
          }
        }
        p {
          @include rem(margin, 16 0 8);
          @include f16(500);
        }
        span {
          @include f14(500);
        }

        .label-tag {
          @include rem(top, 16);
          @include rem(right, 16);
        }
      }
    }
  }
  // tab-scroll-btn : mo
  .component-tab {
    &.tab-scroll-btn {
      @include rem(margin, 0 0 48);
      &.tab-scroll {
        .tab-head {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
      .tab-label {
        @include f16(500);
        color: $font-999999;
        &[aria-selected="true"] {
          @include f16(500);
          color: $font-000000;
        }
      }
      .tab-list {
        @include rem(width, 100%);
        @include rem(padding, 0 24);
        // overflow: scroll;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
      }
      .tab-btn-controller {
        display: none;
      }
    }
  }

  // 라디오 프로그램 상세 : mo
  .radio-wrap {
    @include rem(margin-top, 56);
    @include rem(padding, 0 24);
    .details-view-wrap {
      .view-date {
        @include rem(margin-top, 24);
      }
    }
    .component-tab {
      .tab-head {
        padding: 0;
        justify-content: flex-start;
      }
    }
    .calendar-component {
      @include rem(margin-bottom, 40);
      .calendar-list {
        .btn-prev,
        .btn-next {
          @include rem(margin, 0 16);
        }
        .day-data {
          @include rem(width, 110);
          @include rem(margin-left, 8);
          @include f20(500);
        }
      }
    }
    .textarea-btn-wrap {
      @include rem(margin-top, 24);
    }
    .comments-wrap {
      @include rem(padding-top, 24);
      @include rem(margin-top, 56);
      border-top: 2px solid $line-000000;
      display: flex;
      flex-direction: column-reverse;
      .comments-list {
        @include rem(margin-top, 8);
        .comments-item {
          @include rem(padding, 24 0);
          display: flex;
          flex-direction: column-reverse;
          .info-bx {
            @include rem(margin-top, 14);
            @include rem(margin-bottom, 58);
            span {
              @include f14(500);
              & + span {
                @include rem(margin-left, 16);
                &::before {
                  @include rem(left, -8);
                }
              }
            }
          }
          .txt {
            @include rem(width, 100%);
            @include rem(margin-top, 0);
          }
          .btn {
            @include rem(bottom, 24);
            left: 0;
            top: unset;
            right: unset;
            &.crystal {
              @include rem(left, 44);
              right: unset;
            }
          }
        }
      }
    }
    .component-tab {
      @include rem(margin-bottom, 16);
    }
    // radio-detail-video-wrap : mo
    .radio-detail-video-wrap {
      display: block;
      // @include rem(gap, 32);
      // grid-template-columns: repeat(1, 1fr);
      > a {
        & + a {
          @include rem(margin-top, 32);
        }
      }
      img {
        // @include rem(height, 160);
        height: auto;
      }
      .txt-info {
        @include rem(padding, 16 0 0 0);
      }
      .tit {
        @include rem(max-width, 100%);
        @include rem(width, 100%);
        @include f16(500);
      }
      .day {
        @include f14(500);
      }
      .txt {
        @include rem(margin-top, 16);
        @include f14(500);
      }
    }
  }
  // 라디오 프로그램 : mo
  .radio-sub-wrap {
    section {
      .hgroup-component {
        @include rem(margin, 0 0 24 !important);
        .hgroup-sub-tit {
          @include f24(500);
        }
        &.more-view-type {
          .hgroup-tit {
            @include f24(500);
          }
          .btn-tbl-more {
            i {
              @include rem(width, 24);
              @include rem(height, 24);
            }
          }
        }
      }
    }
    .radio-program-introduction-wrap {
      @include rem(padding, 0 24);
      @include rem(margin-top, 48);
      .program-introduction-bx {
        @include rem(max-height, 320);
        @include rem(padding, 32 16);
        ul {
          @include rem(padding, 0 8);
        }
      }
      dl {
        display: inline-flex;
        dt,
        dd {
          @include f16(500);
          word-break: break-all;
        }
        dt {
          @include rem(width, 70);
        }
      }
    }
    .radio-program-notice-wrap {
      @include rem(padding, 0 24);
      @include rem(margin-top, 72);
      .info-list-box {
        ul {
          li {
            @include rem(padding, 24 8);
            flex-direction: column;
            align-items: flex-start;
            .tit-bx {
              @include rem(width, 100%);

              align-items: flex-start;
            }
            p {
              @include rem(width, 90%);
              @include rem(margin-bottom, 32);
              @include f18(700);
              padding: 0;

              a {
                @include ellipsis(2);
                white-space: unset;
                word-break: break-all;
              }
            }
            span {
              margin-left: 0;
              @include f14(500);
              color: $font-777777;
            }
          }
        }
      }
    }
    .radio-program-selection-wrap {
      @include rem(padding, 0 24);
      @include rem(margin-top, 72);
      .selection-bx {
        flex-direction: column;
        .selection-music {
          @include rem(width, 100%);
          @include rem(height, 100%);
        }
      }
      .selection-list {
        @include rem(margin, 24 0 0 0);
        @include rem(width, 100%);
        .txt-bx {
          .tit {
            @include f20(500);
            @include ellipsis(1);
          }
        }
        ul {
          @include rem(margin-top, 24);
        }
      }
      .music-item-bx {
        @include rem(padding, 24 0);
        align-items: flex-start;
        border-radius: 0;
        border: none;
        &:hover {
          border: none;
        }
        & + .music-item-bx {
          margin: 0;
          border-top: 1px solid $line-e0e0e0;
        }
        .txt-bx {
          p,
          span {
            color: $font-000000;
          }
          p {
            @include f16(500);
          }
          span {
            @include f14(500);
          }
        }
        .num {
          @include f18(400);
        }
        .time {
          @include f14(500);
          color: $font-000000;
        }
      }
    }
    .radio-program-preaching-information-wrap {
      @include rem(padding, 40 24);
      @include rem(margin-top, 72);
      .hgroup-component {
        &.tab-type {
          @include rem(margin-bottom, 16 !important);
          flex-direction: column;
          align-items: flex-start;
          .hgroup-sub-tit {
            @include rem(margin-bottom, 24);
          }
        }
      }
      .component-tab {
        width: 100%;
        .tab-head {
          // justify-content: space-between;
          justify-content: end;
          padding: 0 !important;
          .tab-label {
            @include rem(border-radius, 8);
          }
        }
      }
      .preaching-info-bx {
        @include rem(padding, 24);
        @include rem(border-radius, 8);
        position: relative;
        align-items: flex-start;
        img {
          @include rem(top, 24);
          @include rem(right, 24);
          position: absolute;
          @include rem(width, 88);
          @include rem(height, 88);
        }
        .txt-bx {
          @include rem(padding-top, 13);
          p {
            @include f20(500);
          }
          span {
            @include f16(500);
          }
          .address {
            flex-direction: column;
            align-items: flex-start;
            dl {
              & + dl {
                @include rem(margin, 16 0 0 0);
              }
              dt {
                @include rem(margin-bottom, 4);
                @include f14(500);
              }
              dd {
                @include f16(500);
              }
            }
          }
        }
      }
    }
    .radio-program-listening-wrap {
      @include rem(padding, 0 24);
      @include rem(margin-top, 48);
      .info-list-box {
        li {
          @include rem(padding, 24 0);
          &:last-child {
            border-bottom: 1px solid $line-e0e0e0;
          }
          .txt-bx {
            @include rem(margin-right, 24);
            p {
              @include f16(500);
            }
            span {
              @include f14(500);
            }
          }
        }
      }
    }
    .radio-video-wrap {
      @include rem(padding, 0 24);
      .video-list {
        display: block;
        li {
          @include rem(width, 100%);
          & + li {
            @include rem(margin, 32 0 0 0);
          }
          a {
            img {
              @include rem(width, 100%);
              // @include rem(height, 160);
              height: auto;
            }
          }
        }

        p {
          @include rem(padding, 16 0 4);
          @include f16(500);
          width: auto;
        }
        span {
          @include f14(500);
          padding: 0;
        }
      }
    }
  }
  //  writing-wrap : mo
  .writing-wrap {
    @include rem(padding, 0 24);
    .component-checkbox {
      @include rem(padding-top, 16);
      justify-content: flex-start;
      .checkbox-txt {
        @include f14(500);
        color: $font-777777;
      }
    }
    .component-input {
      .input-label {
        .label-txt {
          @include f14(500);
        }
      }
    }
  }
  // 보이는 라디오 : mo
  .radio-visible-wrap {
    .hgroup-component {
      @include rem(max-width, 100%);
      @include rem(padding, 0 24);
      margin: 0 !important;
    }
    .radio-studio-wrap {
      @include rem(margin-top, 40);
      .img-box {
        @include rem(height, 100%);
        flex-direction: column;
        img {
          &:nth-child(1) {
            @include rem(width, 100%);
            @include rem(height, 171);
            @include rem(margin-bottom, 8);
            padding: 0;
            object-fit: cover;
          }
          &:nth-child(2) {
            @include rem(width, 100%);
            @include rem(height, 348);
            object-fit: cover;
          }
        }
      }
      p {
        @include rem(width, 100%);
        @include rem(padding, 0 24);
        @include rem(margin-top, 24);
        @include f16(500);
      }
    }
    .radio-visible-swiper-wrap {
      @include rem(margin-top, 72);
      .component-swiper {
        padding: 0;
        .swiper-navigation .swiper-button-prev,
        .swiper-navigation .swiper-button-next {
          display: none;
        }
      }
      .swiper-container {
        @include rem(padding, 0 24);
      }
      .content-inner {
        @include rem(margin-top, 24);
      }
      .swiper-controls {
        display: none;
      }
      a {
        .label-tag {
          @include rem(padding, 4 12);
          @include f16(400);
          @include rem(top, 16);
          @include rem(right, 16);
        }
        img {
          object-fit: cover;
          // height: auto;
        }
      }
      p {
        @include rem(margin, 16 0 4);
        @include f18(700);
      }
      span {
        @include f14(500);
      }
      .nodata-wrap {
        @include rem(margin, 24);
        p {
          @include f16(500);
        }
      }
    }
    .radio-returm-wrap {
      @include rem(margin-top, 72);
      display: block;
      .btn.btn-txt-more {
        flex-shrink: 0;
      }
      .returm-list {
        @include rem(margin-top, 24);
        @include rem(padding, 0 24);
        display: block;
        .returm-item {
          & + .returm-item {
            @include rem(margin-top, 32);
          }
        }
        a {
          @include rem(height, auto);
        }
        p {
          @include f16(400);
        }
        span {
          @include f14(500);
        }
      }
    }
  }
  // 편성표,설교 : mo
  .programming-calendar-swiper {
    @include rem(margin-top, 40);
    .component-swiper {
      .swiper-container {
        @include rem(margin, 0 30);
        .swiper-wrapper {
          .swiper-slide {
            button {
              @include rem(padding, 16 8);
              @include rem(border-radius, 4);
              .day {
                @include f18(700);
              }
              .week {
                @include f16(400);
              }
            }
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: block;
        @include rem(width, 20);
        @include rem(height, 20);
        background-image: none;
        &::before {
          @include rem(width, 20);
          @include rem(height, 20);
        }
      }
      .swiper-button-prev::before {
        background: url("pathImages/icon/ico_more_arrow.png");
        background-size: 100%;
        transform: rotate(180deg);
      }
      .swiper-button-next::before {
        background: url("pathImages/icon/ico_more_arrow.png");
        background-size: 100%;
      }
    }
  }
  .select-tab-bx {
    @include rem(margin, 56 0 40);
    padding: 0;
    border-top: 0;
    flex-direction: column;
    .component-select {
      @include rem(width, 100%);
    }
    .calendar-btn-group {
      @include rem(margin-top, 16);
      @include rem(width, 100%);
      .component-calendar {
        @include rem(width, 50%);
        position: relative;
        & + .d-day {
          @include rem(margin-left, 8);
        }
        .input-calendar-btn {
          @include rem(top, 52%);
          background-position: 40px center;
        }
        label {
          @include rem(width, 100%);
          @include rem(height, 48);
          @include f16(400);
          text-align: center;
        }
        .datepicker {
          left: 50% !important;
          z-index: 100;
        }
      }
      .d-day {
        @include rem(width, 50%);
        @include rem(height, 48);
        @include rem(border-radius, 4);
        &::before {
          @include rem(left, 44);
          @include rem(top, 52%);
        }
        span {
          @include f16(400);
        }
      }
    }
  }
  // 편성표 : mo
  .radio-calendar-wrap {
    .datepicker {
      &.datepicker-orient-bottom,
      &.datepicker-orient-left,
      &.datepicker-orient-top,
      &.datepicker-orient-right {
        left: 50% !important;
      }
    }
    .radio-broadcasting-accordions-wrap {
      .component-accordion {
        padding: 0;
        margin: 0;
        border-top: 0;
        .church-link {
          .desc {
            @include f14(500);
            & + .desc {
              @include rem(margin-left, 16);
              &::before {
                @include rem(left, -8);
              }
            }
          }
        }
        .accordions-link-area {
          @include rem(padding, 32 0);
          // border-bottom: 1px solid $line-e0e0e0;
          .area-txt {
            justify-content: space-between;
            .time {
              @include f18(700);
            }
            .label-tag {
              @include f14(500);
            }
          }
          .accordions-link-bx {
            @include rem(margin-top, 16);
            flex-direction: column;
            .img-bx {
              @include rem(width, 100%);
              @include rem(height, 100%);
              img {
                @include rem(width, 100%);
              }

              .onair-btn {
                @include rem(bottom, 16);
                @include rem(right, 16);
              }
              .label-group {
                @include rem(top, 16);
                @include rem(left, 16);
                position: absolute;
              }
            }
            .info-wrap {
              @include rem(width, 100%);
              @include rem(margin, 16 0 0 0);
              .top-txt-bx {
                position: relative;
                .tit-bx {
                  // width: auto;
                }
                .tit {
                  @include rem(max-width, 80%);
                  @include f18(700);
                  @include ellipsis(2);
                  white-space: normal;
                  flex: none;
                  word-break: break-all;
                  flex-shrink: 0;
                }
                .home-btn {
                  @include rem(width, 20);
                  @include rem(height, 20);
                  i {
                    @include rem(width, 20);
                    @include rem(height, 20);
                    // background: url("pathImages/icon/ico_home_blcok.png")
                    //   no-repeat center;
                    // background-size: 100% 100%;
                  }
                }
              }
              .bot-txt-bx {
                @include rem(margin-top, 8);
                &:empty {
                  // margin-top: 0;
                }
                // .vido-btn {
                //   .ico-vido {
                //     // @include rem(margin-right, 8);
                //     // @include rem(width, 20);
                //     // @include rem(height, 14);
                //     // background: url("pathImages/icon/ico_visible_radio_sm.png")
                //     //   no-repeat;
                //     // background-size: 100% 100%;
                //   }
                // }
                // .desc {
                //   // @include f16(500);
                // }
              }
              .rehearsal-btn {
                @include rem(width, 32);
                @include rem(height, 32);
                @include rem(right, 0);
                position: absolute;
                .ico-play {
                  @include rem(width, 32);
                  @include rem(height, 32);
                  // background: url("pathImages/icon/ico_radio_play.png")
                  //   no-repeat center center;
                  // background-size: 100%;
                }
              }
              .collapse-tit {
                @include rem(width, 20);
                @include rem(height, 20);

                &::before {
                  @include rem(width, 20);
                  @include rem(height, 20);
                  background: url("pathImages/btn/btn_radio_accordions.png")
                    no-repeat center center;
                  background-size: 100% 100%;
                }
              }
            }
          }
        }

        .accordion-item {
          &::before {
            display: none;
          }
          .info-wrap {
            @include rem(margin, 32 0 0 0);

            .info-bx {
              @include rem(width, 100%);

              .txt-bx {
                @include rem(width, 100%);
                .tit-group {
                  flex-direction: column;
                  align-items: flex-start;
                  .tit-bx {
                    @include rem(width, 100%);
                    @include rem(margin-right, 0);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .tit {
                      @include f18(700);
                    }
                  }
                  .label-link-bx {
                    // @include rem(margin-top, 16);
                    @include rem(width, 100%);
                    justify-content: space-between;

                    .label-group {
                      &:empty {
                        & + .icon-link-bx {
                          margin-left: 0;
                        }
                      }
                      & + .icon-link-bx {
                        @include rem(margin-left, 48);
                      }
                    }
                    .icon-link-bx {
                      display: flex;
                      a {
                        // @include rem(width, 40);
                        // @include rem(height, 40);
                        // @include rem(border-radius, 50%);
                        & + a {
                          @include rem(margin-left, 8);
                        }
                      }
                    }
                  }
                }

                .desc {
                  @include f14(500);
                  color: $font-777777;
                  & + .desc {
                    @include rem(margin-left, 16);
                    &::before {
                      @include rem(height, 16);

                      @include rem(left, -8);
                    }
                  }
                }
              }
            }
            // .collapse-tit {
            //   @include rem(width, 40);
            //   @include rem(height, 40);
            //   position: relative;
            //   transform: none;
            //   &::before {
            //     @include rem(width, 20);
            //     @include rem(height, 20);
            //     background: url("pathImages/icon/ico_accordion_arrow.png")
            //       center center no-repeat;
            //     background-size: 100%;
            //   }
            // }
          }
          .collapse-content {
            ul {
              @include rem(padding, 32 16);
              li {
                @include rem(margin-left, 24);
                @include rem(padding, 0 0 32 0);
                flex-direction: column;
                position: relative;
                &::before {
                  content: "";
                  display: block;
                  position: absolute;
                  @include rem(width, 7);
                  @include rem(height, 7);
                  @include rem(top, 7);
                  @include rem(left, -22);
                  @include rem(border-radius, 50%);
                  background-color: $bg-000000;
                  z-index: 2;
                }
                &::after {
                  @include rem(width, 1);
                  @include rem(height, 100%);
                  @include rem(left, -19);
                  @include rem(top, 7);
                  content: "";
                  display: block;
                  position: absolute;
                  background-color: $bg-e0e0e0;
                }
                &:last-child {
                  padding: 0;
                  &::after {
                    display: none;
                  }
                }

                & + li {
                  .time {
                    padding: 0;
                  }
                  .item-info-bx {
                    border-top: none;
                  }
                }
                .time-label-bx {
                  @include rem(padding, 0 !important);
                  @include rem(width, 100%);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .time {
                    @include f14(500);
                  }
                }

                .item-info-bx {
                  @include rem(padding, 12 0 0 0 !important);
                  margin: 0;
                  align-items: flex-start;
                  flex-direction: column;
                  .info-txt-bx {
                    @include rem(width, 85%);
                  }
                  .tit-group {
                    .tit {
                      @include f16(400);
                      @include ellipsis(1);
                    }
                    .home-btn {
                      @include rem(margin-left, 8);
                      .ico-home {
                        @include rem(width, 20);
                        @include rem(height, 20);
                      }
                    }
                    .label-group {
                      @include rem(margin, 8 0 0 0);
                    }
                  }
                  .desc {
                    display: block;
                    & + .desc {
                      @include rem(margin, 4 0 0 0);
                      &::before {
                        display: none;
                      }
                    }
                  }
                  .link-btn-wrp {
                    @include rem(width, 32);
                    @include rem(height, 32);
                    @include rem(margin-top, 0);
                    @include rem(margin-left, 8);
                    @include rem(top, 34);
                    position: absolute;
                    right: 0;
                    background-size: 8px 12px;
                    // a {
                    //   @include rem(width, 32);
                    //   @include rem(height, 32);
                    //   &.home {
                    //     margin: 0;
                    //     background: url("pathImages/icon/ico_breadcrumb_home_white.png")
                    //       no-repeat center center $bg-335891;
                    //     background-size: 16px 16px;
                    //   }
                    // }
                  }
                  .bot-txt-bx {
                    @include rem(margin-top, 8);

                    .vido-btn {
                      .ico-vido {
                      }
                    }
                    .desc {
                    }
                  }
                }
              }
            }
          }
        }

        .bg-line-bar {
          display: none;
        }
      }
    }
  }
  // 라디오 상세 : mo
  .table-blit-wrap {
    .table-blit-item {
      @include rem(margin, 0 24 0 54);
      padding: 0;
      &:nth-child(1) {
        &::before {
          @include rem(top, 7);
        }
      }
      &::before {
        @include rem(top, 42);
        @include rem(left, -30);
      }
      & + .table-blit-item {
        @include rem(padding-top, 32);
        &::after {
          @include rem(top, -184);
          @include rem(left, -27);
        }
      }
    }
    .pastor-item {
      @include rem(padding-bottom, 88);
      flex-direction: row-reverse;
      justify-content: space-between;
      img {
        @include rem(width, 80);
        @include rem(height, 80);
      }
    }
    .pastor-list {
      .txt-info-bx {
        margin-left: 0;
        flex-direction: column;
        align-items: flex-start;
        .pastor-info {
          .day-week {
            @include f14(500);
            position: static;
            margin: 0;
          }
          p {
            @include f18(700);
            @include rem(margin, 8 0 4);
          }
        }
        .church-info {
          // display: flex;
          // align-items: center;
          span {
            @include f14(500);
            color: $font-777777;
            &.tell {
              // @include rem(margin-left, 16);
              display: block;
              @include rem(margin, 4 0 0 0);
              position: relative;
              // &::before {
              //   content: "";
              //   display: block;
              //   position: absolute;
              //   @include rem(width, 1);
              //   @include rem(height, 12);
              //   @include rem(top, 50%);
              //   @include rem(left, -8);
              //   transform: translateY(-50%);
              //   background-color: $bg-e0e0e0;
              // }
            }
          }
        }
      }
      .btn-list {
        @include rem(bottom, 32);
        @include rem(left, 0);
        position: absolute;
        .btn-item + .btn-item {
          @include rem(margin-left, 8);
        }
        .btn {
          @include rem(width, 32);
          @include rem(height, 32);
          &.home-btn {
            background-size: 16px 16px;
          }
          &.sharing-btn {
            background-size: 15px 15px;
          }
          &.play-btn {
            background-size: 8px 10px;
          }
        }
      }
    }
  }
  // 고충처리신청 : mo
  .personal-area {
    .round-bx {
      @include rem(padding, 16);
      ul {
        @include rem(gap, 12);
        li {
          @include f14(500);
          .tit {
            width: 50%;
          }
          .txt {
            width: 50%;
          }
        }
      }
    }
    .info-txt {
      @include f14(500);
      @include rem(padding-left, 10);
      @include rem(text-indent, -10);
    }
    .component-radio-sup {
    }
  }

  /*========================================================================
	메뉴 : 설교 : mo
========================================================================*/
  // 설교 상세 비쥬얼 영역 : mo
  .sermon-visual-wrap {
    @include rem(height, 100%);
    background: url("pathImages/contents/sermon_visual_mo_img.png") no-repeat
      center center;
    background-size: 100% 100%;
    .sermon-visual-bx {
      @include rem(padding, 80 24 72);
    }
    .sermon-visual-info {
      @include rem(margin-bottom, 56);
      padding: 0;
      .sermon-visual-tit-bx {
        @include rem(margin-bottom, 8);

        .sermon-visual-tit {
          @include f32(700);
        }
        .sermon-home-btn {
          @include rem(width, 32);
          @include rem(height, 32);

          .ico-sermon-home {
            @include rem(width, 32);
            @include rem(height, 32);
          }
        }
      }
      // .sermon-visual-tit {
      //   @include rem(margin-bottom, 8);
      //   @include f32(700);
      //   .sermon-home-btn {
      //     @include rem(width, 32);
      //     @include rem(height, 32);

      //     .ico-sermon-home {
      //       @include rem(width, 32);
      //       @include rem(height, 32);
      //     }
      //   }
      // }
      .sermon-visual-txt {
        @include f18(700);
      }
    }
    .sermon-visual-address {
      display: block;
      dl {
        & + dl {
          @include rem(margin, 16 0 0 0);
        }
        dt {
          @include rem(margin-bottom, 4);
          @include f14(500);
        }
        dd {
          @include f16(500);
        }
      }
    }
    .component-tooltip {
      @include rem(margin-top, 40);
      @include rem(padding, 0 28);
      position: static;
      z-index: 6;
      .tooltip-btn-trigger {
        &::before {
        }
      }
    }
    .character-list {
      @include rem(margin-top, 24);
      position: static;
      .character-item {
        width: 33.33%;
        & + .character-item {
          @include rem(margin-left, 12);
        }

        img {
          @include rem(width, 100%);
          @include rem(height, 100%);
        }
        .txt-bx {
          flex-direction: column;
          align-items: flex-start;
        }
        p {
          @include f16(500);
        }
        span {
          @include f14(500);
        }
      }
    }
  }
  // 설교 서브메인 : mo
  .sermon-sub-main-wrap {
    .sermon-contents-wrap {
      @include rem(margin-top, 72);
      .hgroup-component {
        @include rem(padding, 0 24);
      }
      .sermon-swiper {
        @include rem(max-width, 100%);
        padding: 0;
        margin: 0;
        // img {
        //   @include rem(max-width, 312);
        //   @include rem(max-height, 160);
        // }
        .swiper-container {
          @include rem(padding, 0 24);
        }
        .tit-bx {
          .tit {
            @include f18(700);
          }
        }
        .desc-bx {
          span {
            @include f14(500);
          }
        }
      }
    }
    .sermon-list-wrap {
      @include rem(margin-top, 72);
      .hgroup-component {
        @include rem(padding, 0 24);
      }
      .component-tab.tab-scroll-btn {
        margin: 0;
      }
      .table-custom {
        @include rem(padding, 0 24);
        margin: 0;
        &.type04 {
          .info-bx {
            .txt-bx {
              a {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
        .custom-item {
          @include rem(padding, 24 0);
          .info-bx {
            flex-direction: column;
            align-items: flex-start;
            .txt-bx {
              @include rem(margin-bottom, 24);
              flex-direction: column;
              align-items: flex-start;
              .tit {
                @include rem(margin-bottom, 8);
                @include f18(700);
              }
              .desc {
                @include f14(500);
                margin: 0;
                &::before {
                  display: none;
                }
              }
            }
            .church-bx {
              @include f14(500);
            }
          }
        }
      }
    }
    .last-radio-sermon-wrap {
      @include rem(max-width, 100%);
      .hgroup-component {
        @include rem(width, 100%);
        @include rem(padding, 0 24);
        margin: 0;
        justify-content: space-between;
        .hgroup-sub-tit {
          br {
            display: block !important;
          }
        }
        .hgroup-txt {
          margin: 0;
        }
        .btn.btn-txt-more {
          flex-shrink: 0;
        }
      }
      // @include rem(margin-top, 72);
      padding: 0;
      flex-direction: column;
      .sermon-list {
        @include rem(width, 100%);
        @include rem(margin-top, 56);
        @include rem(padding, 0 24);
        display: block;
        .sermon-item {
          @include rem(max-width, 100%);
          @include rem(padding, 24 0);
          display: flex;
          align-items: center;
          & + .sermon-item {
            @include rem(padding, 24 0);
            border-top: 1px solid $line-e0e0e0;
          }
          img {
            @include rem(width, 120);
            @include rem(height, 184);
            margin: 0;
          }
          .sermon-info {
            overflow: hidden;
            @include rem(margin-top, 0);
            @include rem(padding-left, 16);
            .day,
            .church,
            .pastor {
              @include f16(400);
            }
            .link-bx {
              .tit {
                @include f18(400);
              }
              .link-btn-group {
                a {
                  @include rem(width, 24);
                  @include rem(height, 24);
                  &.home {
                    background-size: 12px 12px;
                  }
                  &.play {
                    background-size: 8px 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // 설교 상세 : mo
  .sermon-details-wrap {
    @include rem(max-width, 100%);
    @include rem(margin, 72 0 0 0);
    // @include rem(padding, 0 24);
    .radio-details-program-wrap {
      .tbl-count.first {
        .component-form {
          @include rem(margin, 40 0 0 0);
          .select-bx {
            @include rem(width, 100%);
          }
        }
      }
      .tab-group {
        @include rem(margin, 48 0 32 0);
        .component-tab:nth-child(1) {
          margin: 0;
        }
      }
    }
    .sermon-play-info-wrap {
      @include rem(padding, 0 24);
      .hgroup-component {
        margin: 0 !important;
      }
      .sermon-play-info-bx {
        @include rem(margin-top, 24);
        display: block;

        .play-bx {
          // @include rem(height, 174);
          button {
            @include rem(width, 100%);
            @include rem(height, 100%);
            img {
              @include rem(width, 100%);
              @include rem(height, 100%);
              @include rem(border-radius, 8);
            }
          }
        }
        .txt-info-bx {
          @include rem(margin, 32 0 0 0);
          @include rem(padding, 24 0);
          .desc {
            @include rem(margin, 12 0 4);
            @include f16(500);
          }
          .tit {
            @include f24(500);
          }
          .name {
            @include rem(margin, 16 0 32);
            @include f16(500);
          }
          .txt {
            @include f16(500);
            br {
              display: block !important;
            }
          }
        }
      }
    }
    .sermon-table-list-wrap {
      @include rem(padding, 0 24);
      @include rem(margin-top, 72);
      .component-tab.tab-bar {
        .tab-head {
          padding: 0;
        }
      }
    }
    .sermon-schedule-list-wrap {
      .sermon-schedule-list {
        .sermon-schedule-item {
          @include rem(padding, 24 0);
          margin: 0;
          &::before,
          &::after {
            display: none;
          }
          .info-bx {
            flex-direction: column;
            align-items: flex-start;
            .txt-bx {
              .time {
                @include f18(700);
              }
              .tit {
                @include rem(margin, 12 0 4);
                @include f18(700);
              }
              .church {
                @include f14(500);
              }
              .pastor {
                @include rem(margin-left, 16);
                @include f14(500);
                &::before {
                  @include rem(left, -8);
                }
              }
            }
            .btn-bx {
              @include rem(margin-top, 24);
              a {
                &.home {
                  margin: 0;
                }
                &.play {
                  @include rem(margin-left, 8);
                }
              }
            }
          }
        }
      }
    }
    // 설교 - 복음을 담은 교회 : mo
    .sermon-map-wrap {
      .tbl-count {
        @include rem(margin-top, 40);
        @include rem(padding, 0 24);
        .component-form {
          .select-bx {
            @include rem(width, 100%);
            .component-select {
              @include rem(width, 100%);
              & + .component-select {
                @include rem(margin-left, 20);
              }
            }
          }
          .component-input {
            @include rem(width, 100%);
          }
        }
      }
      .sermon-map-info-bx {
        @include rem(margin-top, 40);
        flex-direction: column-reverse;
        .map-wrap {
          @include rem(width, 100%);
          margin: 0;
          .kakao-map-bx {
            @include rem(width, 100%);
            @include rem(min-height, 360);
            img {
              @include rem(width, 100%);
              @include rem(height, 100%);
            }
            .map-marker-point {
              @include rem(width, 40);
              @include rem(height, 40);
              &.disabled {
                @include rem(width, 24);
                @include rem(height, 24);
              }
            }
          }
          .btn-location {
            @include rem(right, 24);
            @include rem(bottom, 24);
          }
        }
        .sermon-map-list-bx {
          @include rem(margin-top, 40);
          @include rem(padding, 0 24);
          @include rem(width, 100%);
          a {
            @include rem(padding, 24 16);
          }
          .txt {
            @include f14(500);
          }
          .info {
            @include rem(padding, 8 0 16);
            .tit {
              @include f18(700);
            }
          }
          .pastor,
          .address,
          .tell {
            @include f14(500);
            display: block;
          }
          .address {
            @include rem(margin-top, 4);
            @include rem(margin-left, 0);
            position: relative;
            &::before {
              display: none;
            }
          }
          .tell {
            @include rem(padding-top, 4);
            pointer-events: all;
          }
        }
        .sermon-map-active {
          @include rem(margin-bottom, 40);
          @include rem(padding, 24);
          @include rem(height, 191);
          .marker {
            .ico-marker {
              @include rem(width, 32);
              @include rem(height, 32);
              transform: rotate(-90deg);
            }
          }
          &.on + .sermon-map-list {
            .inner {
              @include rem(height, 100%);
              @include rem(max-height, 100%);
            }
          }
        }
        .sermon-map-list {
          @include rem(padding, 0);
          @include rem(border-radius, 0);
          border: none;

          overflow: unset;
          .inner {
            @include rem(max-height, 100%);
            @include rem(height, 100%);
            @include rem(padding, 0);

            overflow: unset;
          }
          .txt-bx {
          }
          .count {
            @include f14(500);
          }
          .km-txt {
            @include f14(500);
          }
          ul {
            @include rem(margin-top, 16);
            border-bottom: 1px solid $line-e0e0e0;
            li {
              border-top: 1px solid $line-e0e0e0;
              &:last-child {
                @include rem(padding-bottom, 0);
              }
            }
          }
          .component-pagination {
            padding: 0;
            position: static;
          }
        }
      }
    }
  }
}
