@use "../abstracts" as *;

.footer {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  // Link
  .footer-link {
    @include rem(width, 393);
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include rem(padding-bottom, 18);
      border-bottom: 1px solid $line-e5e5e5;
      &:last-child {
        @include rem(padding-top, 20);
      }
    }
    .btn-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .btn-txt {
        @include f20(500);
      }
      .ico-link-arrow {
        @include rem(width, 24);
        @include rem(height, 24);
        background-color: $bg-000000;
        border-radius: 100%;
        background-size: 50%;
      }
    }
    p {
      @include f20(500);
    }
  }

  // 메인 푸터
  .footer-main {
    &-white {
      @include rem(padding, 120 120 88 120);
      background-color: $bg-ffffff;
      .footer-inner {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
      .footer-menu {
        display: flex;
        align-items: flex-start;
        &-list {
          @include rem(width, 160);
          & + li {
            @include rem(margin-left, 72);
          }
          span,
          a {
            @include rem(margin-top, 8);
            @include f16(400);
            display: inline-block;
            &.bold {
              @include f18(400);
              @include rem(margin, 0 0 8 0);
            }
          }
        }
      }
    }
    &-block {
      @include rem(padding, 48 60);
      background-color: $bg-333333;
      .footer-logo {
        position: absolute;
        @include rem(width, 122);
        @include rem(height, 41);
        img {
          @include rem(width, 100%);
        }
      }
      .footer-inner {
        @include rem(max-width, 1920);
        .footer-info {
          display: flex;
          justify-content: space-between;
          @include rem(padding-left, 347);
          .footer-list {
            flex: 1;
            .privacy-list {
              display: flex;
              align-items: center;
              li {
                &:first-child::before {
                  display: none;
                }
                &::before {
                  @include rem(width, 1);
                  @include rem(height, 12);
                  @include rem(top, 5);
                  @include rem(left, -9);
                  content: "";
                  position: absolute;
                  display: block;
                  background-color: #777777;
                }
                & + li {
                  @include rem(margin-left, 17);
                }
                position: relative;
                @include f16(400);
                .bold {
                  font-weight: 700;
                }
              }
              & + .address {
                @include rem(padding-top, 24);
                address,
                p {
                  @include f16(400);
                  opacity: 0.6;
                  span {
                    display: inline-block;
                    @include rem(margin, 0 10);
                  }
                }
                .copyright {
                  @include rem(margin-top, 57);
                  @include f14(400);
                }
              }
            }
            a,
            p,
            span,
            address {
              color: $font-ffffff;
            }
          }
          .marks {
            .component-select {
              @include rem(width, 181);

              .select-box {
                border: 1px solid $line-ffffff;
                color: $font-ffffff;
                background-color: $bg-transparent;
                opacity: 0.8;
                &::after {
                  content: "";
                  @include rem(width, 8);
                  @include rem(height, 8);
                  background: url("pathImages/icon/ico_footer_blank_arrow.png")
                    no-repeat 0 0 / 100% auto;
                }
              }
              .select-options {
                .option {
                  @include rem(padding, 0);
                }
                a {
                  display: block;
                  @include rem(padding, 10 15);
                }
              }
            }

            // &-img {
            //   display: none;
            //   // display: flex;
            //   align-items: center;
            //   @include rem(width, 400);
            //   @include rem(padding-top, 95);
            //   li + li {
            //     @include rem(margin-left, 22);
            //   }
            //   img {
            //     @include rem(width, 100%);
            //     opacity: 0.5;
            //   }
            // }
          }
        }
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .footer {
    br {
      display: block !important;
    }
    .footer-link {
      width: 100%;
      @include rem(margin, 72 0 12 0);
      @include rem(padding, 0 24);
      li {
        .btn-link {
          width: auto;
          justify-content: flex-start;
          .btn-txt {
            @include rem(margin-right, 8);
            @include f16(500);
          }
        }
        &:last-child {
          border: 0;
        }
      }
    }

    .footer-inner {
      @include rem(padding, 40 24 48 24);
      background-color: $bg-333333;

      .logo-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include rem(margin-bottom, 40);

        .logo {
          img {
            @include rem(width, 102);
            @include rem(height, 36);
          }
        }
      }

      .footer-etc-menu {
        opacity: 0.9;
        ul {
          display: flex;
        }
        li {
          a {
            @include f14(500);
            color: $font-ffffff;
            strong {
              @include f14(700);
            }
          }

          & + li {
            @include rem(margin-left, 8);
            &::before {
              content: "";
              display: inline-block;
              width: 1px;
              @include rem(height, 12);
              @include rem(margin-right, 8);
              background-color: $bg-777777;
            }
          }
        }
      }

      address {
        @include rem(margin-top, 24);
        @include f14(500);
        opacity: 0.9;
        color: $font-ffffff;
        span {
          display: inline-block;
          @include rem(margin, 0 10);
        }
      }
      .copyright {
        @include rem(margin-top, 16);
        @include f14(500);
        color: $font-ffffff;
        opacity: 0.9;
      }

      .febc-network {
        @include rem(margin-top, 48);
        .collapse-tit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          @include rem(padding, 12 24);
          border: 1px solid $line-ffffff;
          @include rem(border-radius, 4);
          @include f16(500);
          color: $font-ffffff;
          opacity: 0.9;
          &::after {
            content: "";
            display: block;
            @include rem(width, 8);
            @include rem(height, 8);
            background: url("pathImages/icon/ico_footer_blank_arrow.png")
              no-repeat 0 0 / 100% auto;
          }
        }
        .collapse-content {
          .network-list {
          }

          .network-item {
            @include rem(margin-top, 40);
            @include rem(padding-bottom, 16);
            dt {
              position: relative;
              @include rem(padding-bottom, 6);
              @include f18(700);
              color: $font-ffffff;
              &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: $bg-ffffff;
                opacity: 0.3;
              }
            }

            dd {
              @include rem(padding-top, 8);
              ul {
                display: flex;
                flex-wrap: wrap;
              }
              li {
                width: 50%;
                @include rem(margin-top, 16);
              }
              a {
                @include f14(500);
                color: $font-ffffff;
              }
            }
          }
        }
      }

      .family-site-link {
        @include rem(margin-bottom, 24);
        .component-collapse {
          position: relative;
        }
        .collapse-tit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          @include rem(height, 56);
          @include f16(400);
          color: $font-ffffff;
          border-bottom: 1px solid $line-ffffff;
          &::after {
            content: "";
            display: inline-block;
            @include rem(width, 24);
            @include rem(height, 24);
            background: url("pathImages/icon/ico_footer_select_arrow.png")
              no-repeat 0 0 / 100% auto;
          }
        }
        .collapse-content {
          width: 100%;
          position: absolute;
          // @include rem(bottom, 56);
          left: 0;
          z-index: 5;
          @include rem(padding, 32 24);
          background-color: $bg-000000;
          li {
            a {
              @include f16(700);
              color: $font-ffffff;
            }
            & + li {
              @include rem(margin-top, 24);
            }
          }
        }
      }
    }
  }
}
