@use "../abstracts" as *;

/* component accordion */
.component-collapse {
  //border: 1px solid black;

  .collapse-tit {
    //width: 100%;
    //text-align: left;
    //border: 1px solid black;
  }

  .collapse-content {
    display: none;
    //border: 1px solid black;
    height: 0;
    overflow: hidden;

    //padding: 10px;
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
}
