@use "../abstracts" as *;

/*========================================================================
	메뉴 : 로그인/회원가입
========================================================================*/

// member
.member {
  .layout-col:nth-child(2) {
    .hgroup-slogan {
      @include f16(500);
    }
  }
  .hgroup-tit {
    @include f32(500);
    br {
      display: block !important;
    }
  }
  .hgroup-slogan {
    @include rem(margin-top, 24);
    @include f20(500);
    &.gray-color {
      color: $font-777777;
    }
  }
  .component-form {
    &.login {
      @include rem(padding-top, 32);
      @include rem(margin-top, 48);
      border-top: 2px solid $line-000000;
    }
  }
  .member-tpa {
    @include rem(margin-top, 64);
    text-align: center;
    .tpa-txt {
      display: flex;
      align-items: center;
      &::before,
      &::after {
        content: "";
        @include rem(width, 100%);
        @include rem(height, 1);
        flex: 1;
        display: block;
        background-color: $bg-e0e0e0;
      }
      span {
        @include f16(500);
        @include rem(padding, 0 14);
        color: $font-777777;
      }
    }
    .tpa-list {
      display: flex;
      align-items: center;
      justify-content: center;
      li + li {
        @include rem(margin-left, 60);
      }
      i {
        @include rem(margin, 24 0 8);
        @include rem(width, 48);
        @include rem(height, 48);
      }
      .tpa-list-txt {
        @include f12(500);
      }
    }
  }
  .component-btns {
    @include rem(margin-top, 48);
  }
}
// user-wrap
.user-wrap {
  &.member-complet {
    .hgroup-sub-tit {
      @include f32(500);
    }
  }
  .step-wrap {
    position: relative;
    span.hgroup-desc {
      color: $font-777777;
    }
    .step-list {
      position: absolute;
      @include rem(top, 45);
      @include rem(right, 0);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .step-item {
      @include rem(width, 6);
      @include rem(height, 6);
      @include rem(border-radius, 50%);
      background-color: $bg-d9d9d9;
      position: relative;
      & + .step-item {
        @include rem(margin-left, 12);
      }
      span {
        @include rem(top, 50%);
        @include rem(left, 50%);
        position: absolute;
        transform: translate(-50%, -50%);
        color: $font-ffffff;
        @include f12(500);
        display: none;
      }
      &.on {
        @include rem(width, 24);
        @include rem(height, 24);
        @include rem(border-radius, 50%);
        background-color: $bg-fe508b;
        &::before {
          display: none;
        }
        span {
          display: block;
        }
      }
    }
  }
  .user-info {
    @include rem(width, 686);
    @include rem(padding, 0 119);
    @include rem(margin, 0 auto);
    @include rem(border-radius, 16);
    background-color: $bg-ffffff;
    display: none;
    &.on {
      display: block;
    }
    .user-info-tit {
      @include f32(500);
      @include rem(margin-bottom, 48);
    }
    // step1
    .agree-box {
      @include rem(padding, 16 24);
      @include rem(margin, 64 0 40);
      @include rem(border-radius, 4);
      border: 1px solid $line-000000;
      .checkbox-inner {
        @include rem(width, 100%);
      }
      .checkbox-item {
        flex: 1;
        cursor: pointer;
        // .checkbox-txt {
        //   @include rem(margin-left, 0);
        // }
      }
      .checkbox-info {
        @include rem(margin-top, 8);
        @include f12(500);
        color: $font-777777;
      }
    }
    .agree-group {
      @include rem(padding, 0 24);
    }
    .agree-item {
      .checkbox-inner {
        @include rem(width, 100%);
      }
      .checkbox-item {
        flex: 1;
        span {
          // @include rem(margin-left, 0);
          position: relative;
          &::after {
            content: "";
            display: block;
            position: absolute;
            @include rem(width, 24);
            @include rem(height, 24);
            @include rem(top, 50%);
            @include rem(right, -36);
            transform: translateY(-50%);
            background: url("pathImages/icon/ico_login_right.png") no-repeat
              center center;
            background-size: 100%;
          }
        }
      }
    }

    .user-inner {
      @include rem(padding-top, 64);
      .component-select {
        @include rem(width, 120);
      }
    }
    // 마이페이지
    &.mypage {
      @include rem(padding, 80 119);
      display: block;
      border: 1px solid $line-e0e0e0;
      .hgroup-sub-tit {
        @include f32(500);
        br {
          display: block !important;
        }
      }
      .hgroup-desc {
        br {
          display: block !important;
        }
      }
      .mypage-tap {
        @include rem(margin-top, 48);
        & + .component-btns {
          @include rem(margin-top, 32);
        }
        .certified {
          li {
            @include rem(padding, 24 0);
            border-bottom: 1px solid $line-e0e0e0;
            button {
              @include rem(width, 100%);
              @include rem(height, 48);
              text-align: left;
              position: relative;
              &::before {
                @include rem(width, 48);
                @include rem(height, 48);
                @include rem(top, 50%);
                @include rem(left, 0);
                content: "";
                display: block;
                position: absolute;
                transform: translateY(-50%);
              }
              &::after {
                @include rem(width, 24);
                @include rem(height, 24);
                @include rem(top, 50%);
                @include rem(right, 0);
                content: "";
                display: block;
                position: absolute;
                transform: translateY(-50%);
                background: url("pathImages/icon/ico_login_right.png") no-repeat
                  center center;
                background-size: 100%;
              }
              &.kakao::before {
                background: url("pathImages/icon/ico_kakao.png") no-repeat
                  center center;
                background-size: 100%;
              }
              &.facebook::before {
                background: url("pathImages/icon/ico_facebook.png") no-repeat
                  center center;
                background-size: 100%;
              }
              &.naver::before {
                background: url("pathImages/icon/ico_naver.png") no-repeat
                  center center;
                background-size: 100%;
              }
              span {
                @include rem(padding-left, 64);
                @include f18(400);
              }
            }
          }
        }
        .mypage-text-field {
          @include rem(padding-top, 40);
          @include rem(margin, 48 0 80);
          border-top: 1px solid $line-e0e0e0;
          p {
            @include f18(400);
            .txt-color-pink {
              color: $font-fe508b;
            }
          }
        }
      }
      .withdrawal {
        @include rem(width, 96);
        @include rem(height, 96);
        margin: 0 auto;
        @include rem(margin-bottom, 32);
        img {
          @include rem(width, 100%);
        }
      }
    }
  }
}

// member-complet
.member-complet {
  @include rem(padding, 0 119);
  display: flex;
  justify-content: space-between;
  .layout-col:nth-child(2) {
    @include rem(margin, 72 0 0 0);
  }
  .img-box {
    @include rem(padding-right, 157);
  }
  img {
    @include rem(width, 100%);
  }
  .txt-color-pink {
    @include rem(margin-top, 28);
    @include f32(500);
    color: $font-fe508b;
  }
}

// 아이디찾기, 비밀번호 찾기
.info-setting-wrap {
  .txt-desc {
    @include f20(500);
    @include rem(margin-bottom, 32);
    color: $font-000000;
  }
  .form-chips label {
    background-color: $bg-ffffff;
  }
  .setting-accordion {
    border-top: 1px solid $line-000000;

    .accordion-item {
      border-bottom: 1px solid $line-e0e0e0;

      .collapse-tit {
        display: flex;
        align-items: center;
        @include rem(padding, 22 0);
        background-color: $bg-ffffff;
        @include f18(400);
        border-bottom: 0;
        &::before {
          display: inline-block;
          align-items: center;
          position: static;
          @include rem(width, 20);
          @include rem(height, 20);
          @include rem(margin-right, 12);
          background-image: url("pathImages/icon/icon_check_off.png");
          background-size: 100%;
          transform: none;
        }
      }
      .setting-zone {
        @include rem(padding, 40 119);
        background-color: $bg-f7f8fd;
      }
      &[data-state="open"] {
        .collapse-tit {
          &::before {
            background-image: url("pathImages/icon/icon_check_on.png");
          }
        }
      }
    }
    .collapse-content {
      // overflow: visible;
      @include rem(padding, 0);
      border: 0;

      .component-select {
        .select-options {
          @include rem(max-height, 125);
        }
      }
    }
  }
  & + .component-btns {
    @include rem(margin-top, 48);
  }
}

// mypage 회원정보수정
.mypage-wrap {
  max-width: 1400px;
  margin: 0 auto;
  .component-tab {
    display: flex;
  }
  .tab-head {
    @include rem(width, 329);
    display: block;
    border-bottom: 0;
    .hgroup-component {
      .hgroup-sub-tit {
        @include rem(margin-bottom, 64);
      }
    }
    .tab-label {
      @include rem(width, 100%);
      @include f20(500);
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      color: $font-777777;
      padding: 0;
      &[aria-selected="true"] {
        @include f20(500);
        color: $font-000000;
      }
      &::after {
        display: none;
      }
      & + .tab-label {
        @include rem(margin-top, 24);
      }
    }
  }
  .tab-contents-item {
    @include rem(margin-top, 0);
    @include rem(margin-right, 113);
    @include rem(padding-left, 266);
    flex: 1;
    &.item02 {
      @include rem(padding-left, 147);
    }
    .hgroup-txt {
      @include f32(500);
      & + .hgroup-desc {
        @include rem(margin-top, 16);
      }
    }
    .address {
      .component-input:nth-child(1) {
        @include rem(width, 160);
        flex: none;
      }
    }
    .component-form {
      @include rem(margin-top, 24);
    }
    .mypage-sns-interlock {
      @include rem(margin-top, 80);
      .hgroup-component {
        margin: 0 !important;
      }
      ul {
        @include rem(margin-top, 32);
      }
      .component-checkbox-switch {
        @include rem(width, 100%);
        @include rem(padding, 24 0);
        border-bottom: 1px solid $line-e0e0e0;
      }
      .switch-inner {
        @include rem(width, 100%);
        @include rem(height, 48);
        @include rem(padding-left, 64);
        position: relative;
        justify-content: space-between;
        &::before {
          @include rem(width, 48);
          @include rem(height, 48);
          @include rem(top, 0);
          @include rem(left, 0);
          content: "";
          display: block;
          position: absolute;
        }
        &.kakao::before {
          background: url("pathImages/icon/ico_kakao.png") no-repeat center
            center;
          background-size: 100%;
        }
        &.facebook::before {
          background: url("pathImages/icon/ico_facebook.png") no-repeat center
            center;
          background-size: 100%;
        }
        &.naver::before {
          background: url("pathImages/icon/ico_naver.png") no-repeat center
            center;
          background-size: 100%;
        }
      }
      .switch-label {
        @include f18(400);
      }
    }
    .mypage-email-wrap {
      @include rem(margin-top, 80);
      .hgroup-component {
        margin: 0 !important;
      }
      .component-radio-email {
        @include rem(margin-top, 24);
        @include rem(padding, 32 40);
        @include rem(border-radius, 4);
        background-color: $bg-f7f8fd;
        .email-switch-txt {
          @include rem(margin-bottom, 16);
        }
        .component-radio {
          .radio-inner + .radio-inner {
            @include rem(margin-left, 64);
          }
        }
      }
      .switch-inner {
        @include rem(height, 80);
        justify-content: space-between;
      }
      .switch-label {
        @include f16(500);
      }
      .email-switch-txt {
        @include f16(500);
      }
      .switch-item {
        @include rem(margin-left, 8);
      }
    }
    .btn-line {
      @include rem(width, 100%);
      @include rem(margin-top, 24);
      text-align: right;
    }
    .mypage-sponsor-wrap {
      @include rem(margin-top, 56);
      @include rem(padding, 104 32 26);
      @include rem(border-radius, 8);
      background-color: $bg-fdf1f1;
      position: relative;
      .hgroup-txt {
        @include rem(margin-top, 0);
        @include rem(padding-left, 24);
      }
      .badge {
        @include rem(top, 48);
        @include rem(left, 56);
        @include rem(padding, 8 16);
        @include rem(border-radius, 4);
        @include f16(500);
        position: absolute;
        background-color: $bg-fe508b;
        color: $font-ffffff;
        i {
          @include rem(margin-right, 4);
        }
        &.purple {
          @include rem(width, auto);

          background-color: $bg-763ba5;
        }
      }
      .sponsor-txt {
        @include rem(padding-right, 16);
        @include f16(500);
        @include rem(top, 48);
        @include rem(right, 32);
        position: absolute;
        text-align: right;
        .pink-txt {
          color: $font-fe508b;
        }
        &.ing {
          &::after {
            @include rem(width, 24);
            @include rem(height, 24);
            @include rem(margin-left, 4);
            content: "";
            display: inline-block;
            width: 2.4rem;
            height: 2.4rem;
            margin-left: 0.4rem;
            background: url("pathImages/icon/ico_sponsor_nodata.png") no-repeat
              left top;
            background-size: 100%;
            vertical-align: bottom;
          }
        }
      }
      .sponsor-info {
        ul {
          @include rem(margin-top, 48);
          @include rem(padding, 48 40);
          @include rem(border-radius, 8);
          display: flex;
          background-color: $bg-ffffff;
          li {
            flex: 1;
            @include f24(500);
            text-align: center;
            & + li {
              // @include rem(margin-left, 45);
              // @include rem(padding-left, 45);
              border-left: 1px solid $line-e0e0e0;
            }
            .info-txt {
              @include f18(500);
              @include rem(margin-bottom, 10);
              color: $font-777777;
            }
            // .sponsor-pup-wrap {
            //   @include rem(margin-bottom, 10);
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            //   .info-txt {
            //     margin-bottom: 0;
            //   }
            //   .btn.btn-round {
            //     @include rem(margin-left, 8);
            //     background-color: $bg-transparent;
            //     &::after {
            //       @include rem(width, 20);
            //       @include rem(height, 20);
            //       background: url("pathImages/btn/btn_add.png") no-repeat center
            //         center;
            //       background-size: 20px 20px;
            //     }
            //   }
            // }
            strong {
              @include f32(700);
              display: flex;
              align-items: center;
              justify-content: center;
              em {
                @include rem(margin-left, 8);
                @include f24(500);
                & + span {
                  @include rem(margin-left, 8);
                }
              }
            }
          }
        }
      }
    }
    .sponsor-info-link {
      @include rem(margin-top, 24);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sponsor-gray-txt {
        @include f16(500);
        color: $font-777777;
        i {
          @include rem(width, 20);
          @include rem(height, 20);
          vertical-align: sub;
        }
      }
    }
    .sponsor-inquiry {
      @include rem(margin-top, 80);
      @include rem(padding, 48);
      @include rem(border-radius, 8);
      border: 1px solid $line-e0e0e0;
      .sponsor-inquiry-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          @include f18(400);
        }
      }
    }
  }
}

// 아이디 찾기 결과
.result-find-wrap {
  .txt-desc {
    @include f20(500);
  }
  .round-bx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .join-day {
      @include f20(500);
      span {
        // @include f20(500);
      }
    }
    & + .txt-desc {
      @include rem(margin-top, 80);
    }
  }

  .list-type-line {
    @include rem(margin-top, 24);
    & + .txt-desc {
      @include rem(margin-top, 80);
    }
  }
}
.sponsorship-details-wrap {
  border-top: 1px solid $line-000000;
  ul {
    li {
      @include rem(padding, 24 0);
      @include f18(400);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $line-e0e0e0;
    }
  }
  .money {
    @include rem(margin-top, 24);
    @include f20(500);
    text-align: right;
    strong {
      & + span {
        @include rem(margin-left, 24);
      }
    }
  }
}

// 개인정보 약관 : pc
.pop-table {
  @include rem(width, 100%);
  @include rem(margin-bottom, 16);

  table {
    th {
      background-color: $bg-eff3f7;
      border-right: 0;
    }
    th,
    td {
      @include rem(padding, 18 0);
      @include f14(500);
      border: 1px solid $line-e0e0e0;
      text-align: center;
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /*========================================================================
	메뉴 : 로그인/회원가입 : mo
========================================================================*/
  .pageClassMain.login {
    #content {
      .layout-col:nth-child(2) {
        @include rem(margin-left, 0);
      }
      &::before {
        display: none;
      }
    }
  }
  // 아이디찾기, 비밀번호 찾기 : mo
  .info-setting-wrap {
    .txt-desc {
      @include f20(500);
      @include rem(padding-right, 89);
      @include rem(margin-bottom, 48);
      br {
        display: none;
      }
    }
    .setting-accordion {
      .accordion-item {
        border: 0;
        .collapse-tit {
          @include f16(500);
          &::before {
          }
        }
        .setting-zone {
          @include rem(padding, 24);
        }
        &[data-state="open"] {
          .collapse-tit {
            &::before {
            }
          }
        }
        & + .accordion-item {
          border-top: 1px solid $line-e0e0e0;
        }
        &:last-child {
          .setting-zone {
            border-bottom: 1px solid $line-e0e0e0;
          }
        }
      }
      .collapse-content {
      }
    }
    & + .component-btns {
    }
  }
  .components-layout {
    &.user-wrap {
      @include rem(width, 100%);
      @include rem(padding, 0);
      .user-info {
        @include rem(width, 100%);
        @include rem(padding, 0);
        &.mypage {
          border: 0;
          .mypage-tap {
            & + .component-btns {
              @include rem(margin-top, 48);
            }
            .certified {
              li:nth-child(1) {
                @include rem(padding-top, 0);
              }
              button {
                &::before {
                  @include rem(width, 40);
                  @include rem(height, 40);
                }
                span {
                  @include rem(padding-left, 56);
                  @include f16(500);
                }
              }
            }
          }
        }
        .user-inner {
          @include rem(padding-top, 40);
        }
        .hgroup-sub-tit {
          @include f24(700);
          & + .hgroup-desc {
            @include rem(margin-top, 16);
          }
        }
        .hgroup-desc {
          @include f16(500);
        }
        .agree-box {
          border: 0;
          padding: 0;
          .checkbox-inner {
            @include rem(padding, 16 20);
            @include rem(border-radius, 4);
            border: 1px solid $line-000000;
            .checkbox-txt {
              @include f16(500);
            }
          }

          .checkbox-info {
            @include rem(padding, 0 16);
            @include rem(margin-top, 16);
            @include f16(500);
          }
        }
        .agree-group {
          @include rem(padding, 0 16);
          .checkbox-txt {
            @include f14(500);
          }
          .checkbox-item {
            span::after {
              @include rem(right, -28);
            }
          }
        }
        .component-select {
          @include rem(width, 97);
        }
      }
      .hgroup-component {
        text-align: left;
      }
      .step-wrap {
        .step-list {
          @include rem(top, 30);
        }
      }
    }
  }
  .member-complet {
    display: block;
    @include rem(padding, 0);
    .complet-item {
      &:nth-child(2) {
        @include rem(margin, 96 0 0 0);
      }
    }
    .img-box {
      @include rem(padding-right, 42);
      img {
        @include rem(width, 100%);
        @include rem(height, 100%);
      }
    }
    .hgroup-desc {
      @include f16(500);
    }
    .hgroup-sub-tit {
      @include f24(700);
      text-align: left;
    }
    .txt-color-pink {
      @include rem(margin-top, 16);
      @include f16(500);
    }
  }
  .member {
    &.components-layout {
      .layout-row {
        display: flex;
        flex-direction: column-reverse;
        .layout-col:nth-child(1) {
          @include rem(margin-top, 72);
        }
        .hgroup-slogan {
          @include rem(margin-top, 16);
          @include rem(padding-top, 16);
          @include rem(border-top, 1px solid $line-000000);
          @include f16(500);
          color: $font-000000;
          br {
            display: block !important;
          }
        }
      }
    }
    .hgroup-tit {
      @include f24(700);
    }
    .hgroup-slogan {
      @include f16(500);
    }
    .layout-col {
      margin: 0;
      &.pc-show {
        display: none;
      }
    }
    .component-form {
      &.login {
        @include rem(margin-top, 40);
        padding-top: 0;
        border-top: none;
      }
    }

    .member-tpa {
      .tpa-list {
        .tpa-list-txt {
          @include f14(500);
        }
      }
    }
    .member-complet {
      .layout-col:nth-child(2) {
        @include rem(margin-top, 80);
      }
      .img-box {
        @include rem(padding-right, 66);
      }
    }
  }
  .mypage-wrap {
    .mypage-user-info {
      .hgroup-component {
        margin: 0 !important;
      }
    }
    .component-tab {
      display: contents;
      &.tab-scroll {
        .tab-head {
          .tab-label {
            @include rem(min-width, auto);
          }
        }
      }
    }
    // .tab-contents-item {
    //   @include rem(padding, 0 24);
    //   // @include rem(padding-left, 24);
    //   //   @include rem(margin-right, 0);
    //   margin: 0;
    //   // &:nth-child(2) {
    //   //   padding: 0;
    //   // }
    //   // &:nth-child(3) {
    //   //   @include rem(padding-left, 24);
    //   // }
    // }
    .tab-head {
      @include rem(width, 100%);
      @include rem(padding, 0 24);
      display: flex;
      .hgroup-component {
        display: none;
      }
      .tab-label {
        @include rem(padding, 0);
        white-space: nowrap;
        border-bottom: 2px solid $line-ffffff;
        &[aria-selected="true"] {
          border-bottom: 2px solid $line-fe508b;
        }
        & + .tab-label {
          @include rem(margin-left, 40);
          @include rem(margin-top, 0);
          &::before {
            @include rem(width, 4);
            @include rem(height, 4);
            @include rem(top, 50%);
            @include rem(left, -20);
            @include rem(border-radius, 50%);
            content: "";
            display: block;
            position: absolute;
            transform: translateY(-50%);
            background-color: $bg-e0e0e0;
          }
        }
      }
      i {
        display: none;
      }
    }
    .tab-contents-item {
      @include rem(padding, 0 24);
      margin: 0;
      &.item02 {
        padding: 0;
      }
      .hgroup-txt {
        @include rem(margin-top, 40);
        @include f24(700);
        & + .hgroup-desc {
          @include rem(margin-top, 16);
          @include f16(500);
          display: block;
        }
      }
      .address {
        .component-input:nth-child(1) {
          @include rem(width, 100);
          flex: none;
        }
      }
      .mypage-sns-interlock {
        @include rem(margin-top, 72);
        ul {
          @include rem(margin-top, 0);
        }
      }
      .mypage-email-wrap {
        @include rem(margin-top, 72);
        .kakao-interlock {
          .component-checkbox-switch {
            @include rem(padding, 0 24);
          }
        }
        .switch-inner {
          @include rem(width, 100%);
          @include rem(height, 64);
        }
        .component-radio-email {
          @include rem(padding, 24);
        }
      }
      .btn-line {
        text-align: left;
      }
      .mypage-sponsor-wrap {
        @include rem(margin-top, 48);
        @include rem(padding, 48 24 24);
        border-radius: 0;
        .hgroup-component {
          @include rem(padding-top, 52);
          margin: 0 !important;
        }
        .badge {
          @include rem(width, 85);
          @include rem(padding, 8 12);
          @include rem(top, 48);
          @include rem(left, 24);
          @include f14(500);
          i {
            @include rem(width, 20);
            @include rem(height, 20);
          }
        }
        .hgroup-txt {
          @include rem(padding-left, 0);
          @include rem(margin-top, 0);
        }
        .btn-line-arrow {
          @include rem(padding-left, 0);
          @include rem(margin-top, 16);
        }
        .sponsor-txt {
          @include rem(top, 175);
          @include rem(left, 24);
          @include rem(padding-right, 0);
          text-align: left;
        }
        .sponsor-info {
          @include rem(margin-top, 80);
          ul {
            @include rem(padding, 24);
            display: block;
            li {
              @include rem(padding, 24 0);
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid $line-e0e0e0;
              &:nth-child(1) {
                @include rem(padding-top, 0);
              }
              &:nth-child(3) {
                @include rem(padding-bottom, 0);
                border-bottom: none;
              }
              & + li {
                @include rem(padding, 16 0);
                @include rem(margin-left, 0);

                border-left: none;
              }
              .info-txt {
                @include rem(margin-bottom, 0);
                @include f14(500);
                color: $font-999999;
              }
              strong {
                @include f18(700);
                flex: 1;
                justify-content: right;
                em {
                  @include f16(500);
                  flex-shrink: 0;
                }
              }
            }
          }
          .sponsor-gray-txt {
            @include rem(padding-left, 0);
            @include f14(500);
          }
        }
      }
      .sponsor-info-link {
        @include rem(padding, 0 24);
        flex-direction: column;
        align-items: flex-start;
        .sponsor-gray-txt {
          @include rem(margin-bottom, 24);
        }
      }
      .table-wrap {
        @include rem(padding, 0 24);
        .hgroup-component {
          margin: 0 0 24px 0 !important;
        }
      }
      .sponsor-inquiry {
        @include rem(margin-top, 48);
        @include rem(padding, 0 24);
        border: none;
        .sponsor-inquiry-inner {
          @include rem(padding, 26 24);
          @include rem(border-radius, 8);
          border: 1px solid $line-e0e0e0;
          display: block;
          text-align: left;
          p {
            @include rem(margin-bottom, 40);
            @include f16(500);
          }
        }
      }
    }
  }

  // 아이디 찾기 결과 : mo
  .result-find-wrap {
    .txt-desc {
    }
    .round-bx {
      display: block;
      text-align: center;
      .join-day {
        @include rem(margin-bottom, 24);
        @include f16(500);
      }
      & + .txt-desc {
        @include rem(margin-top, 48);
      }
    }

    .list-type-line {
      @include rem(margin-top, 40);
      & + .txt-desc {
        @include rem(margin-top, 80);
      }
    }
  }

  // 개인정보 약관 : mo
  .pop-table {
    @include rem(width, 100%);
    overflow-x: scroll;
    overflow-y: hidden;
    table {
      @include rem(width, 500);

      th {
      }
      th,
      td {
      }
    }
  }
}
