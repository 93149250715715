@use "../abstracts" as *;

// [공통] breadcrumb-zone
.breadcrumb-zone {
  max-width: 1400px;
  @include rem(margin, 40 auto 0 auto);

  @media (max-width: 1400px) {
    @include rem(padding, 0 24);
  }
  .breadcrumb-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      display: flex;
      align-items: center;
      & + li {
        .item {
          @include f12(500);
        }
        &:before {
          display: inline-block;
          content: "";
          @include rem(width, 24);
          @include rem(height, 24);
          @include rem(margin, 0 4 0 4);
          background: url("pathImages/icon/ico_breadcrumb_arrow_line.png")
            no-repeat center center;
          background-size: 100%;
          vertical-align: middle;
        }
      }
      &.home {
        .item {
          display: block;
          @include rem(width, 20);
          @include rem(height, 20);
          background: url("pathImages/icon/ico_breadcrumb_home_line.png")
            no-repeat center center;
          background-size: 100%;
        }
      }
    }

    &.align-center {
      justify-content: center;
    }
  }

  // white type
  &.white-type {
    position: relative;
    margin: 0 auto;
    z-index: 1;
    .breadcrumb-list {
      .item {
        color: $font-ffffff;
      }
      li {
        &.home {
          .item {
            background-image: url("pathImages/icon/ico_breadcrumb_home_white.png");
          }
        }
        & + li {
          .item {
          }
          &:before {
            background-image: url("pathImages/icon/ico_breadcrumb_arrow_white.png");
          }
        }
      }
    }
  }

  // floating
  &.floating {
    position: absolute;
    left: 50%;
    @include rem(top, 156);
    width: 100%;
    margin: 0;
    transform: translate(-50%, 0);
    z-index: 6;
  }
}

// ############################################# Mo #############################################

@media (max-width: 1023px) {
  // [공통] breadcrumb-zone
  .breadcrumb-zone {
    display: none;
  }
}
