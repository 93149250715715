@use "../abstracts" as *;

:root {
  --time: 0.3s;
}

.btn {
  display: inline-block;
  align-items: center;
  @include rem(height, 48);
  @include rem(padding, 0 24 0 24);
  border: 1px solid $line-000000;
  background: $font-ffffff;
  transition: border-color 0.25s;
  @include rem(border-radius, 64);
  vertical-align: middle;
  line-height: 1;
  text-align: center;
  // button 공통 - 텍스트
  .btn-txt {
    display: inline-flex;
    align-items: center;
    height: 100%;
    color: $font-000000;
    text-align: center;
    @include f16(500);
    vertical-align: middle;
    & + [class*="ico-"] {
      @include rem(margin-left, 19);
      margin-right: 0;
    }
  }

  // Icon Common
  [class*="ico-"] {
    @include rem(margin-right, 4);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    &:focus,
    &:hover {
      border-color: $font-4051b8;
      span {
        color: $font-000000;
      }
    }
    &.ico-search {
      @include rem(width, 17);
      @include rem(height, 17);
      background-image: url("pathImages/icon/ico_search.png");
    }
    &.ico-info {
      @include rem(width, 16);
      @include rem(height, 16);
      background-image: url("pathImages/icon/ico_info.png");
    }
    &.ico-more {
      @include rem(width, 16);
      @include rem(height, 16);
      background-image: url("pathImages/icon/ico_arrow_down.png");
    }
    &.ico-download {
      @include rem(width, 16);
      @include rem(height, 17);
      background-image: url("pathImages/icon/ico_download.png");
    }
    &.ico-detail-arrow {
      display: inline-block;
      position: relative;
      @include rem(width, 14);
      @include rem(height, 8);
      background-image: url("pathImages/icon/ico_detail_view.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: right center;
      // &::after,
      // &::before {
      //   position: absolute;
      //   left: 0;
      //   display: inline-block;
      //   content: "";
      //   @include rem(width, 27);
      //   @include rem(height, 16);
      //   background-image: url("pathImages/icon/ico_detail_view.png");
      //   background-repeat: no-repeat;
      //   background-size: 50% auto;
      //   background-position: 0 0;
      // }
      // &::before {
      //   content: "";
      //   position: absolute;
      //   transition: all 0.2s;
      //   @include rem(left, -16);
      //   opacity: 0;
      // }
    }
  }

  // button 공통 - Full
  &.btn-full {
    width: 100%;
    text-align: center;
  }

  // 형태 Type  ##########################
  // square type
  &.btn-square {
    @include rem(height, 60);
    // @include rem(width, 100%);
    @include rem(padding, 0 15);
    @include rem(border-radius, 4);
    border: 1px solid $line-000000;
  }

  &.btn-gray {
    // @include rem(height, 60);
    @include rem(padding, 0 64);
    border-radius: 0;
    border: 1px solid $line-e0e0e0;
    .btn-txt {
      @include f20(400);
    }
  }

  // Color Type ##########################

  // black-type
  &.btn-black {
    background-color: $bg-000000;
    .btn-txt {
      color: $font-ffffff;
    }
    .ico-detail-arrow {
      background-image: url("pathImages/icon/ico_detail_view_white.png");
    }
    &:focus,
    &:hover {
      // background: $font-ffffff;
      .btn-txt {
        // color: $font-4051b8;
      }
      .ico-detail-arrow {
        background-image: url("pathImages/icon/ico_detail_view_white.png");
      }
    }
  }

  // primary-type
  &.btn-primary {
    border: 0;
    background-color: $bg-fe508b;
    .btn-txt {
      color: $font-ffffff;
    }
    .ico-detail-arrow {
      &::after,
      &::before {
        background-image: url("pathImages/icon/ico_detail_view_white.png");
      }
    }
    &:focus,
    &:hover {
      // background: $font-ffffff;
      .btn-txt {
        // color: $font-4051b8;
      }
      .ico-detail-arrow {
        &::after,
        &::before {
          background-image: url("pathImages/icon/ico_detail_view_white.png");
        }
      }
    }
  }
  &.btn-primary-line {
    border-color: $bg-fe508b;
    background-color: $bg-ffffff;
    .btn-txt {
      color: $font-fe508b;
    }
  }

  // white-type
  &.btn-white {
    background-color: $bg-ffffff;
    border-color: $line-e0e0e0;
    .btn-txt {
      color: $font-000000;
    }
    .ico-detail-arrow {
      &::after,
      &::before {
        background-image: url("pathImages/icon/ico_detail_view.png");
      }
    }
    &:focus,
    &:hover {
      // background: $font-ffffff;
      .btn-txt {
        // color: $font-4051b8;
      }
      .ico-detail-arrow {
        &::after,
        &::before {
          background-image: url("pathImages/icon/ico_detail_view.png");
        }
      }
    }
  }

  &.btn-transparent {
    background-color: $bg-transparent;
    border-color: $line-000000;
  }

  // Size Type ##########################
  &.btn-large {
    @include rem(min-width, 220);
    @include rem(width, auto);
    @include rem(height, 60);
    @include rem(padding, 0 32);
    .btn-txt {
      @include f20(500);
    }
    &.btn-full {
      @include rem(width, 100%);
      @include rem(padding, 0 15);
    }
    // .ico-detail-arrow {
    //   &::after,
    //   &::before {
    //     @include rem(width, 21);
    //     @include rem(height, 8);
    //   }
    // }
  }
  &.btn-middle {
    @include rem(min-width, 154);
    @include rem(height, 54);
    @include rem(padding, 0 24);
  }
  &.btn-small {
    @include rem(min-width, 147);
    @include rem(height, 46);
    @include rem(padding, 0 24);
  }
  &.btn-mini {
    @include rem(min-width, 95);
    @include rem(height, 51);
    @include rem(padding, 0 24);
  }
  &.btn-nano {
    @include rem(min-width, 80);
    @include rem(height, 36);
    @include rem(padding, 0 12);
  }
  // More Type ##########################
  &.btn-more-type {
    padding: 0;
    border: 0;
    border-bottom: 1px solid $line-fe508b;
    border-radius: 0;
    .btn-txt {
      @include f20(500);
      em {
        @include rem(margin-left, 12);
        @include f14(700);
      }
    }
  }

  // Circle Type ##########################
  &.btn-circle {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      @include rem(width, 48);
      @include rem(height, 48);
      @include rem(right, 12);
      @include rem(top, 8);
      border-radius: 100%;
      background-color: $bg-fe508b;
      transition: all var(--time) ease;
    }
    .ico-detail-arrow {
      @include rem(width, 17);
      @include rem(height, 9);
      margin-left: auto;
      background-image: url("pathImages/icon/ico_detail_view_main.png");
      background-position: center center;
    }
    &:hover,
    &:focus {
      background-color: $bg-fe508b;
      border-color: $line-fe508b;
      .btn-txt {
        color: $font-ffffff;
      }
      &::before {
        scale: 10;
      }
    }
  }
  // Text Arrow
  &.btn-txt-more {
    @include rem(height, 24);
    padding: 0;
    border: 0;
    border-radius: 0;
    .btn-txt {
      @include f16(500);
    }
    .btn-txt-more {
      @include rem(width, 20);
      @include rem(height, 20);
      @include rem(margin-left, 4);
      display: block;
      background: url("pathImages/btn/btn_more.png") no-repeat center center;
      background-size: 20px 20px;
    }
  }
  // Line Type ##########################
  &.btn-line {
    @include rem(height, 24);
    padding: 0;
    border: 0;
    border-radius: 0;
    .btn-txt {
      @include f16(500);
      border-bottom: 1px solid $line-000000;
    }
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    border: 0;
    background-color: $bg-e5e5e5;
    .btn-txt {
      color: $font-999999;
    }
  }
  // line arrow
  &.btn-line-arrow {
    @include rem(height, 24);
    padding: 0;
    border: 0;
    border-radius: 0;
    background: none;
    .btn-txt {
      border-bottom: 1px solid $line-000000;
      @include f16(500);
      & + i {
        @include rem(width, 20);
        @include rem(height, 20);
        @include rem(margin-left, 4);
      }
    }
  }
  // button 공통 - Hover, Focus
  &:focus,
  &:hover {
    .btn-txt {
      // border-color: $font-4051b8;
      // color: $font-4051b8;
    }

    // animation
    .ico-detail-arrow {
      &::after,
      &::before {
        @include rem(width, 16);
        @include rem(height, 9);
      }
      &::before {
        @include rem(left, -16);
        // animation: RightArrowBefore 0.3s 0.3s forwards;
      }
      &::after {
        // animation: RightArrowAfter 0.3s forwards;
      }
    }
  }

  // btn round 타입 색추가시 class or color 추가
  &.btn-round {
    @include rem(width, 24);
    @include rem(height, 24);
    @include rem(padding, 0);
    @include rem(border, 0);
    position: relative;
    border-radius: 50%;
    background-color: $bg-000000;
    &::after {
      content: "";
      @include rem(width, 10);
      @include rem(height, 8);
      @include rem(top, 50%);
      @include rem(left, 50%);
      position: absolute;
      display: block;
      background: url("pathImages/icon/ico_round_arrow_white.png") center center
        no-repeat;
      background-size: 100%;
      transform: translate(-50%, -50%);
    }
  }
}

// Fixed, Sticky Type  ##########################
.btn-floating {
  position: fixed;
  @include rem(bottom, 20);
  @include rem(right, 20);
  @include rem(padding, 15);
  opacity: 0.8;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  @include f18(500);
  cursor: pointer;
  transition: all var(--timt) ease;
  background-color: $font-000000;
  &:hover {
    background-color: $bg-f9f9f9;
  }
}

// Event btn
// .event-zone {
//   overflow: hidden;
//   position: fixed;
//   right: 0;
//   bottom: 0;
//   z-index: 20;
//   max-width: 1920px;
//   margin: 0 auto;
//   .btn-event {
//     @include rem(width, 160);
//     @include rem(height, 160);
//     padding: 0;
//     border: 0;
//     border-radius: 0;
//     background-color: $bg-363853;
//     z-index: 5;
//     .btn-txt {
//       color: $font-ffffff;
//       @include f24(500);
//       & + [class*="ico-"] {
//         @include rem(margin-left, 8);
//         margin-right: 0;
//       }
//     }
//   }
// }

// 정렬  ##########################
.component-btns {
  display: flex;
  justify-content: center;
  @include rem(margin-top, 64);
  .btn + .btn {
    @include rem(margin-left, 16);
  }
  &.align-right {
    justify-content: flex-end;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.type01 {
    @include rem(margin-top, 68);
  }
}

// Only Icon  ##########################
// More btn
.btn-more-view {
  display: block;
  overflow: hidden;
  position: absolute;
  right: 0;
  @include rem(top, 12);
  @include rem(width, 28);
  @include rem(height, 28);
  background: url("pathImages/icon/ico_more_view.png") no-repeat left top;
  background-size: 100%;
}

.btn-pop-plus {
  display: inline-block;
  @include rem(width, 20);
  @include rem(height, 20);
  background: url("pathImages/btn/btn_add.png") no-repeat left top;
  background-size: 100%;
}

.btn-video-streaming {
  display: inline-block;
  @include rem(width, 40);
  @include rem(height, 40);
  background: url("pathImages/btn/btn_video_streaming.png") no-repeat left top;
  background-size: 100%;
}
.btn-audio-streaming {
  display: inline-block;
  @include rem(width, 40);
  @include rem(height, 40);
  background: url("pathImages/btn/btn_audio_streaming.png") no-repeat left top;
  background-size: 100%;
}

.btn-add {
  @include rem(width, 20);
  @include rem(height, 20);
  background: url("pathImages/btn/btn_add.png") no-repeat left top;
  background-size: 100%;
}

.btn-info-tooltip {
  display: inline-block;
  @include rem(width, 21);
  @include rem(height, 21);
  @include rem(margin-left, 4);
  background: url("pathImages/icon/ico_sub_tooltip_blue.png") no-repeat left top;
  background-size: 100%;
  &.on {
  }
}

// Go top
#btn-gotop {
  display: block;
  z-index: 99;
  position: fixed;
  text-align: center;
  top: auto;
  left: auto;
  @include rem(right, 30);
  @include rem(bottom, 170);

  opacity: 0;
  visibility: hidden;
  // transition: opacity var(--time) ease;
  &.gotop-show {
    opacity: 1;
    // visibility: visible;
  }
  // transition: all var(--time) ease;
  .missionary-btn {
    position: relative;
    display: block;
    @include rem(width, 96);
    @include rem(height, 96);
    @include rem(margin-bottom, 16);
    border: 1px solid $line-fe508b;
    border-radius: 100%;
    background: url("pathImages/icon/ico_hart.png") no-repeat $bg-fe508b center
      center;
    box-shadow: 2px 10px 20px 0px rgba(24, 32, 58, 0.2);
    // &::before,
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   @include rem(border, 20 solid $line-fe508b);
    //   border-radius: 50%;
    //   z-index: -1;
    // }
    // &:hover {
    //   &::before {
    //     animation: btn_ani_round 2s infinite;
    //   }
    //   &::after {
    //     animation: btn_ani_round 2s infinite;
    //     animation-delay: 0.4s;
    //   }
    // }
  }

  .btn-event {
    display: none;
    @include rem(width, 96);
    @include rem(height, 96);
    @include rem(margin-bottom, 16);
    border: 1px solid $line-335891;
    border-radius: 100%;
    background-color: $bg-ffffff;
    box-shadow: 2px 10px 20px 0px rgba(24, 32, 58, 0.2);
    .btn-txt {
      @include f20(500);
    }
  }

  .gotop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include rem(width, 96);
    @include rem(height, 96);
    border-radius: 100%;
    border: 1px solid $line-335891;
    box-shadow: 2px 10px 20px 0px rgba(24, 32, 58, 0.2);
    background: $bg-335891 url("pathImages/icon/ico_gotop.png") no-repeat center
      center;
    @include rem(background-size, 19 36);
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include rem(border, 20 solid $line-5976A4);
      border-radius: 50%;
      z-index: -1;
      // @include rem(border-width, 15);
    }

    &:hover {
      &::before {
        animation: btn_ani_round 1.6s infinite linear;
      }
      &::after {
        animation: btn_ani_round 1.6s .8s infinite linear;
        // animation-delay: 0.4s;
      }
    }
  }

  @keyframes btn_ani_round {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.45);
      opacity: 0;
    }
  }
  @-webkit-keyframes btn_ani_round {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.45);
      opacity: 0;
    }
  }
}

// Main page 예외처리
.main-page {
  #btn-gotop {
    .btn-event {
      display: block;
    }
    .missionary-btn {
      display: none;
    }
  }
}
// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .btn {
    min-width: auto;
    @include rem(height, 40);
    @include rem(padding, 0 16);
    &:hover,
    &:focus {
    }
    &.btn-circle {
      &:hover,
      &:focus {
        // background-color: $bg-fe508b;
        // border-color: $line-fe508b;
        .btn-txt {
          color: $font-000000;
        }
        &::before {
          scale: 1;
        }
      }
    }
    // button 공통 - 텍스트
    .btn-txt {
      @include f14(500);
      & + [class*="ico-"] {
        @include rem(margin-left, 16);
        margin-right: 0;
      }
    }
    // Size Type ##########################
    &.btn-full {
      @include rem(width, 100%);
      @include rem(height, 48);
      @include rem(padding, 0 15);
    }
    &.btn-large {
      width: 100%;
      min-width: auto;
      @include rem(height, 56);
      @include rem(padding, 0 32);
      .btn-txt {
        @include f18(700);
      }
    }
    &.btn-middle {
      min-width: auto;
    }
    &.btn-small {
      min-width: auto;
    }
    &.btn-square {
      @include rem(height, 56);
    }
    &.btn-nano {
      @include rem(height, 32);
      @include f14(500);
    }
    // More Type ##########################
    &.btn-more-type {
      .btn-txt {
        @include f14(500);
        em {
          @include rem(margin-left, 8);
          @include f14(500);
        }
      }
    }
  }

  // 정렬  ##########################
  .component-btns {
    @include rem(margin-top, 48);
    .btn + .btn {
      @include rem(margin-left, 4);
    }
    &.type01 {
    }
    &.block {
      display: block;
      .btn {
        margin: 0;
      }
      .btn + .btn {
        @include rem(margin, 8 0 0 0);
      }
    }
  }

  // Go top
  #btn-gotop {
    @include rem(right, 30);
    @include rem(bottom, 40);
    transition: opacity var(--time) ease;
    visibility: hidden;
    &.gotop-show {
      opacity: 1;
    }
    &.gotop-active {
      position: absolute;
      // @include rem(top, 5);
      // bottom: auto;
      bottom: calc(100% - 15rem);
    }
    .missionary-btn {
      @include rem(width, 64);
      @include rem(height, 64);
      background-size: 3rem 3rem;
    }
    .btn-event {
      display: none;
      @include rem(width, 64);
      @include rem(height, 64);
      @include rem(margin-bottom, 8);
      .btn-txt {
        @include f14(500);
        color: $font-335891;
      }
    }

    .gotop {
      @include rem(width, 64);
      @include rem(height, 64);
      @include rem(background-size, 10 18);

      &::before,
      &::after {
        display: none;
      }
    }
  }

  // Main page 예외처리
  .main-page {
    #btn-gotop {
      &.gotop-active {
        @include rem(top, 15);
      }
    }
  }
}

@-webkit-keyframes RightArrowBefore {
  0% {
    opacity: 0;
    left: -21px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}
@keyframes RightArrowBefore {
  0% {
    opacity: 0;
    left: -21px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@-webkit-keyframes RightArrowAfter {
  0% {
    opacity: 1;
    left: 0;
  }
  to {
    opacity: 0;
    left: 21px;
  }
}

@keyframes RightArrowAfter {
  0% {
    opacity: 1;
    left: 0;
  }
  to {
    opacity: 0;
    left: 21px;
  }
}
