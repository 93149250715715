@use "../abstracts" as *;

.btn-snackbar {
  @include rem(padding, 0 10);
  @include rem(height, 48);
  @include rem(line-height, 48);
  background-color: $bg-4051b8;
  color: $font-ffffff;
  @include rem(border-radius, 4);
}

.component-snackbar {
  position: fixed;
  left: 50%;
  @include rem(bottom, 30);
  transform: translate3d(-50%, 0, 0);
  z-index: 10;
}

.snackbar-container {
  &.type02 {
    opacity: 0;
  }
  transform: translate3d(0, 30px, 0);
  overflow: hidden;
  .snackbar-content {
    @include rem(max-width, 500);
    background-color: rgba(0, 0, 0, 0.8);
    @include rem(border-radius, 2);
    @include rem(padding, 8 16);
    @include rem(margin-bottom, 8);
  }
  .snackbar-txt {
    @include f16;
    color: $font-ffffff;
    text-align: center;
  }
}
