@use "../abstracts" as *;

:root {
  --time: 0.3s;
}

.intro-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: $zindex-loading;
  overflow: hidden;
  background-color: #fff;
  .logo-wrap {
    @include rem(width, 236);
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -53%) scale(0.8);
    z-index: 3;
    img {
      width: 100%;
    }
  }
  .circle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: $zindex-loading;
  }
  .circle-reached-end {
    @include rem(width, 11);
    @include rem(height, 11);
    position: absolute;
    top: 55%;
    left: calc(50% + 1px);
    z-index: 2;
    transform: translate(-50%, -55%);
    background-color: $line-fe508b;
    border-radius: 50%;
  }
  .line {
    width: 1px;
    display: inline-block;
    height: 45vh;
    background-color: $line-f8d7d3;
    position: absolute;
    left: calc(50%);
    z-index: 1;
    bottom: 0;
  }
  .line-inner {
    width: 1px;
    display: inline-block;
    height: 0;
    background-color: $line-fe508b;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.febc-page-container-main {
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  #content {
    // padding: 0;
  }
  section[class*="-wrap"] {
    max-width: 1920px;
    .section-inner {
      max-width: 1680px;
      margin: 0 auto;
    }
  }
  .hgroup-component {
    .hgroup-tit {
      @include f40(700);
      @include rem(margin-bottom, 40);
    }
  }
  .img-zone {
    @include rem(border-radius, 8);
  }
}

// main-visual-wrap
.main-visual-wrap {
  position: relative;
  max-width: 1792px !important;
  margin: 0 auto;

  .main-visual-inner {
    overflow: hidden;
    @include rem(border-radius, 24);
  }
  .hgroup-component {
    position: absolute;
    @include rem(top, 216);
    top: 25%;
    @include rem(left, 160);
    z-index: 5;
    .hgroup-tit {
      @include rem(margin-bottom, 0);
      @include f56(700);
      color: $font-ffffff;
    }
    .hgroup-txt {
      @include rem(margin-top, 24);
      @include f24(500);
      color: $font-ffffff;
    }
    .produce {
      display: flex;
      @include rem(margin-top, 64);
      @include f18(400);
      color: $font-ffffff;
      .item {
        display: flex;
        dd {
          &::before {
            content: "";
            display: inline-block;
            width: 1px;
            @include rem(height, 12);
            @include rem(margin, 0 8);
            background-color: $bg-ffffff;
          }
        }
        & + .item {
          @include rem(margin-left, 24);
        }
      }
    }
    @media (max-width: 1600px) {
      @include rem(left, 120);
    }
    @media (max-width: 1500px) {
      // @include rem(left, 120);
      .produce {
        @include rem(margin-top, 24);
      }
    }
    @media (max-width: 1300px) {
      .hgroup-tit {
        @include f40(700);
      }
    }
  }

  .main-visual-swiper {
    visibility: hidden;
    .img-zone {
      @include rem(border-radius, 0);
      @include rem(height, 640);
    }
  }
  .swiper-wrapper {
    .swiper-slide {
    }
    .swiper-item {
      @include rem(height, 640);
    }
  }
  .swiper-autoplay-wrap {
    position: absolute;
    @include rem(left, 160);
    // left: 10.41%;
    @include rem(bottom, 88);
    z-index: 5;
    .swiper-autoplay {
      display: block;
      background-image: url("pathImages/btn/btn_swiper_main_pause.png");
      &.stop {
        background-image: url("pathImages/btn/btn_swiper_main_play.png");
      }
    }

    @media (max-width: 1300px) {
      @include rem(bottom, 38);
    }
  }
  .swiper-pagination-wrap {
    width: auto;
    .swiper-pagination {
      @include rem(left, 118);
      @include rem(bottom, 88);
      width: auto;
      .swiper-pagination-bullet {
        position: relative;
        @include rem(width, 16);
        @include rem(height, 16);
        background-color: transparent;
        opacity: 1;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          @include rem(width, 4);
          @include rem(height, 4);
          border-radius: 100%;
          background-color: $bg-ffffff;
          transform: translate(-50%, -50%);
        }
      }
      .swiper-pagination-bullet-active {
        border: 1px solid $line-ffffff;
      }
    }
  }

  // num paging
  .navi-num {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    @include rem(left, 80);
    // left: 6.25%;
    @include rem(bottom, 88);
    @include rem(width, 72);
    @include rem(height, 32);
    border: 1px solid $line-ffffff;
    @include rem(border-radius, 16);
    color: $font-ffffff;
    @include rem(line-height, 32);
    @include f16(500);
    .swiper-pagination-current {
      @include rem(margin-right, 3);
    }
    .swiper-pagination-total {
      @include rem(margin-left, 3);
    }
    @media (max-width: 1300px) {
      @include rem(bottom, 38);
    }
  }

  // 좌, 우 버튼
  .swiper-navigation {
    position: absolute;
    @include rem(right, 120);
    top: 50%;
    display: flex;
    flex-direction: column-reverse;
    z-index: 5;
    transform: translate(0, -50%);
    [class^="swiper-button"] {
      position: static;
      @include rem(width, 48);
      @include rem(height, 48);
      margin: 0;
      transform: none;
    }
    .swiper-button-prev {
      background-image: url("pathImages/btn/btn_swiper_visual_arrow_l.png");
      @include rem(margin-top, 16px);
    }
    .swiper-button-next {
      background-image: url("pathImages/btn/btn_swiper_visual_arrow_r.png");
    }
  }

  .swiper-navigation {
    &.ani-custom {
      position: absolute;
      @include rem(right, 47);
      top: 50%;
      display: flex;
      flex-direction: column-reverse;
      z-index: 5;
      transform: translate(0, -50%);
      span,
      i {
        pointer-events: none;
      }
      [class^="swiper-button"] {
        position: static;
        @include rem(width, 48);
        @include rem(height, 48);
        margin: 0;
        transform: none;
      }
      .button-icon {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .swiper-button-prev {
        @include rem(margin-top, 16px);
        background-image: none;
        &::before {
          display: none;
        }
        .button-icon {
          background-image: url("pathImages/btn/btn_swiper_visual_arrow_r.png");
        }
      }
      .swiper-button-next {
        margin-top: 0;
        background-image: none;
        &::before {
          display: none;
        }
        .button-icon {
          background-image: url("pathImages/btn/btn_swiper_visual_arrow_l.png");
        }
      }

      .ani-i {
        display: block;
        position: absolute;
        opacity: 1;
        width: 100%;
        height: 100%;
        border: 1px solid $line-ffffff;
        border-radius: 50%;
        -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

// onair-wrap
.onair-wrap {
  position: relative;
  @include rem(margin, 80 auto 0 auto);

  .section-inner {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      @include rem(bottom, 53);
      width: 100%;
      height: 1px;
      background-color: $bg-e0e0e0;
    }
  }

  .onair-swiper {
    position: relative;
    overflow: hidden;
    .swiper-container {
      @include rem(margin, 0 44);
      @include rem(padding, 50 0);

      .swiper-wrapper {
        align-items: flex-start;
      }
      .swiper-slide {
        //On Live
        &.live {
          // width: 620px;
          .on-air-progress {
            display: block;
          }
          .onair-info {
            @include rem(margin-left, 32);
          }
          .onair-img {
            position: relative;
            // @include rem(width, 240);
            // @include rem(height, 240);
            img {
              // @include rem(width, 240);
              // @include rem(height, 240);
              background-color: $bg-fdf1f1;
            }
            .onair-live {
              display: flex;
            }
          }

          .in-line,
          .out-line {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include rem(border, 20 solid $line-ffb9d1);
            border-radius: 50%;
            z-index: -1;
          }

          .in-line {
            animation: ani_round 2.5s infinite linear;
          }

          .out-line {
            animation: ani_round 2.5s 1.25s infinite linear;
            // animation-delay: 0.4s;
          }

          // @keyframes ani_round {
          //   0% {
          //     transform: scale(1);
          //     opacity: 1;
          //   }
          //   50% {
          //     transform: scale(1.2);
          //     opacity: 0;
          //   }
          //   100% {
          //     transform: scale(1);
          //     opacity: 1;
          //   }
          // }

          // @-webkit-keyframes ani_round {
          //   0% {
          //     transform: scale(1);
          //     opacity: 1;
          //   }
          //   50% {
          //     transform: scale(1.2);
          //     opacity: 0;
          //   }
          //   100% {
          //     transform: scale(1);
          //     opacity: 1;
          //   }
          // }
          @keyframes ani_round {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            100% {
              transform: scale(1.33);
              opacity: 0;
            }
          }

          @-webkit-keyframes ani_round {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            100% {
              transform: scale(1.33);
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .hgroup-component {
    @include rem(margin, 0 0 6 0);
    .hgroup-tit {
      display: flex;
      align-items: center;
      position: relative;
      @include rem(margin-bottom, 0);
      @include f36(700);
      color: $font-fe508b;
      .component-lottie {
        display: block;
        @include rem(width, 30);
        @include rem(height, 20);
        font-size: small;
        line-height: 1;
        transform: scale(2.2);
        @include rem(margin-right, 16);
      }
      // &::before {
      //   content: "";
      //   display: inline-block;
      //   @include rem(width, 40);
      //   @include rem(height, 20);
      //   @include rem(margin-right, 16);
      //   background: url("pathImages/icon/ico_onair_bar.png") no-repeat left top;
      //   background-size: 100%;
      // }
    }

    .txt-info-tip {
      @include rem(margin-top, 8);
      @include f16(500);
      strong {
        @include f16(500);
        color: $font-fe508b;
      }
    }

    .btn-round-arrow {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      @include rem(top, 14);
      .btn-txt {
        @include f18(700);
      }
      .ico-round-arrow {
        @include rem(width, 32);
        @include rem(height, 32);
        @include rem(margin-left, 16);
        border-radius: 100%;
        background-color: $bg-000000;
      }
    }
  }

  .onair-zone {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: auto;

    .onair-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      flex-shrink: 0;
      @include rem(width, 172);
      @include rem(height, 172);
      img {
        @include rem(width, 172);
        @include rem(height, 172);
        border-radius: 100%;
        background-color: $bg-eff3f7;
      }
    }
    .onair-live {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      @include rem(right, 0);
      @include rem(top, 0);
      @include rem(width, 58);
      @include rem(height, 58);
      border-radius: 100%;
      background-color: $bg-ffffff;
      z-index: 5;
      .btn-live-onair {
        @include rem(width, 40);
        @include rem(height, 40);
        background: url("pathImages/btn/btn_listen_again.png") no-repeat left
          top;
        background-size: 100%;
      }
    }

    .onair-info {
      width: 56%;
      @include rem(margin-left, 40);
      .label-bx {
        @include rem(margin-bottom, 8);
      }
      .onair-tit {
        display: flex;
        align-items: center;
        @include rem(margin, 0 0 16 0);
        a {
          display: block;
          max-width: 100%;
          @include f24(700);
          @include ellipsis(1);
          // color: $font-999999;
        }
      }
      .onair-txt {
        @include f18;
        color: $font-777777;
        .time {
          &::before {
            content: "";
            display: inline-block;
            width: 1px;
            @include rem(height, 12);
            @include rem(margin, 0 5);
            background-color: $bg-e5e5e5;
          }
        }
      }

      .btn-visible-radio {
        display: flex;
        align-items: center;
        @include rem(margin-top, 32);

        &::before {
          display: inline-block;
          content: "";
          @include rem(width, 22);
          @include rem(height, 16);
          @include rem(margin-right, 8);
          background: url("pathImages/icon/ico_visible_radio.png") no-repeat
            left top;
          background-size: 100%;
        }
        .btn-txt {
          @include f16(500);
        }
      }
    }
  }

  .swiper-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .on-air-progress {
      display: none;
      width: 100%;
      @include rem(height, 2);
      @include rem(margin, 44 0 3 0);
      background-color: $bg-bfbfbf;
      .progress-bar {
        position: relative;
        @include rem(height, 2);
        background-color: $font-fe508b;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          @include rem(top, -3);
          @include rem(width, 8);
          @include rem(height, 8);
          border-radius: 100%;
          background-color: $font-fe508b;
        }
      }
    }

    // 프로그램 없을때
    .no-program {
      p {
        @include rem(padding-top, 32);
        @include f24(700);
      }
      ul {
        @include rem(margin-top, 15);
      }
      li {
        @include f18(400);
        color: $font-777777;
      }
    }
  }

  // 좌, 우 버튼
  .swiper-navigation {
    [class^="swiper-button"] {
      @include rem(width, 24);
      @include rem(width, 24);

      // @include rem(bottom, 90);
    }
    .swiper-button-prev {
      background-image: url("pathImages/btn/btn_swiper_onair_l.png");
    }
    .swiper-button-next {
      @include rem(margin-left, 24);
      background-image: url("pathImages/btn/btn_swiper_onair_r.png");
    }
  }
}

// meet-video-wrap
.meet-video-wrap {
  position: relative;
  @include rem(margin, 110 auto 0 auto);

  .section-inner {
    position: relative;
  }

  .tab-day-week {
    display: flex;
    position: absolute;
    @include rem(right, -8);
    @include rem(top, 17);
    li {
      a {
        @include rem(padding, 5 8 5 9);
        @include f18;
        &.on {
          color: $font-fe508b;
        }
      }
    }
  }

  .main-meet-swiper {
    position: relative;
    // overflow: hidden;
    overflow: visible;
    // width: 100vw;
    .swiper-container {
      overflow: visible;
    }
    .swiper-slide {
      .swiper-item {
        .img-zone {
          @include rem(border-radius, 0);
          // @include rem(height, 302);
          img {
            object-fit: cover;
          }
        }
        .txt-info {
          @include rem(padding, 24 40 32 40);
          border: 1px solid $line-e5e5e5;
          border-right-color: $line-ffffff;
          border-top: 0;
          .txt {
            @include f22(400);
            @include ellipsis(1);
            color: $font-000000;
          }
        }
        .btn-area {
          display: flex;
          justify-content: flex-end;
          @include rem(margin-top, 34);
          .ico-play-bx {
          }
        }
        &:focus {
          border: 1px solid red;
          position: relative;
          z-index: 100;
        }
      }
      &:last-child {
        .swiper-item {
          .txt-info {
            border-right: 1px solid $line-e5e5e5;
          }
        }
      }
      &:first-child {
        .img-zone {
          @include rem(border-radius, 8 0 0 0);
        }
      }
      &:last-child {
        .img-zone {
          @include rem(border-radius, 0 8 0 0);
        }
      }
    }
  }

  // swiper-progress
  .swiper-progress {
    max-width: 1680px;
    @include rem(height, 36);
    @include rem(margin-top, 43);
    @include rem(padding-left, 175);
    .progress-bar {
      background-color: $bg-e5e5e5;
      .bar {
        background-color: $bg-fe508b;
      }
    }
  }

  // 좌, 우 버튼
  .swiper-navigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    @include rem(width, 135);
    [class^="swiper-button"] {
      display: inline-block;
      width: auto;
      height: auto;
      transform: none;
      &::after {
        display: block;
        @include f24(600);
        color: $font-000000;
      }
    }
    .swiper-button-prev {
      position: static;
      background: none;
      &::after {
        content: "Prev";
      }
    }
    .swiper-button-next {
      position: static;
      background: none;
      &::after {
        content: "Next";
      }
    }
  }
}

// information-wrap
.information-wrap {
  position: relative;
  @include rem(margin, 0 auto);
  & + .family-site-wrap {
    @include rem(margin-top, 160);
  }
  .lottie-video {
    position: relative;

    .txt-animation {
      position: absolute;
      left: 50%;
      @include rem(top, 400);
      @include rem(width, 1394);
      transform: translate(-50%, 0);
      z-index: 5;
      [class*="ani-"] {
        @include f104(700);
        @include rem(line-height, 160);
      }
      .ani-txt01 {
        position: absolute;
        @include rem(top, 72);
        right: 0;
      }
      .ani-txt02 {
      }
      .ani-txt03 {
        text-align: right;
        @include rem(padding-right, 154);
      }
    }
    @media (min-width: 1024px) and (max-width: 1920px) {
      .txt-animation {
        top: 20.8333vw;
        width: 72.6042vw;
        [class*="ani-"] {
          font-size: 5.4167vw;
          font-weight: bold;
          line-height: 8.3333vw;
        }
        .ani-txt01 {
          top: 5vw;
          img {
            width: 38.5417vw;
          }
        }
        .ani-txt03 {
          padding-right: 8.0208vw;
        }
      }
    }

    .lottie-inner {
      img {
        width: 100%;
      }
    }
  }
  .info-zone {
    overflow: hidden;
    max-width: 1680px;
    // margin: 0 auto;
    @include rem(margin, -380 auto 0 auto);

    @media (max-width: 1700px) {
      @include rem(margin-top, -200);
    }
    .info-list {
    }
    .info-item {
      position: relative;
      overflow: hidden;
      @include rem(height, 160);
      .item-inner {
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
      }
      .tit-clone {
        display: none;
      }
      .txt-info {
        position: relative;
        width: 35%;
        @include rem(height, auto);
        @include rem(padding, 46 150 0 40);
        border-top: 1px solid $line-000000;
        a {
          // pointer-events: none;
        }
        .tit {
          @include f22(700);
          @include rem(margin-bottom, 8);
          transition: all var(--time) ease-out;
          transition-delay: 0.05s;
        }
        .txt {
          @include f18(700);
        }
        .btn-area {
          display: none;
          position: absolute;
          @include rem(bottom, 59);
          @include rem(left, 40);
        }
        .btn-bullet {
          position: absolute;
          @include rem(top, 30);
          @include rem(right, 14);
          .ico-bullet {
            @include rem(width, 48);
            @include rem(height, 48);
            background: url("pathImages/btn/btn_plus.png") no-repeat center
              center;
            background-size: 100%;
          }
        }
      }

      .img-zone {
        position: relative;
        width: 65%;
        @include rem(height, 160);
        @include rem(border-radius, 0);
        z-index: 5;
        //transition: all var(--time) ease;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: auto;
        }
      }

      &.on {
        @include rem(height, 560);
        .txt-info {
          @include rem(height, 560);
          @include rem(padding-top, 64);
          .tit {
            @include f40(700);
            @include rem(margin-bottom, 12);
          }
          .txt {
          }
          .btn-area {
            display: block;
          }
          .btn-bullet {
            @include rem(top, 64);
            .ico-bullet {
              background-image: url("pathImages/btn/btn_minus.png");
            }
          }
        }
        .img-zone {
          @include rem(height, 560);
        }
      }

      &:last-child {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $bg-000000;
        }
      }
    }
  }
}

// sermon-wrap
.sermon-wrap {
  position: relative;
  @include rem(margin, 200 auto 0 auto);
  .hgroup-component {
    @include rem(padding, 0 120);
    .hgroup-tit {
      @include rem(margin-bottom, 24);
    }
  }

  .sermon-cont {
    position: relative;
    @include rem(height, 656);
    @include rem(margin-top, 134);
    // @include rem(padding, 0 34);
    .component-swiper {
    }

    .swiper-wrapper {
      width: auto;
      align-items: flex-end;
      .swiper-slide {
        width: auto;
        .slide-inner {
          display: flex;
          width: 200%;
          align-items: flex-end;
          [class*="h-c"] {
            display: flex;
            flex-direction: column;
            @include rem(margin-right, 184);
          }

          // content width, height
          .h-cont01 {
            // @include rem(width, 240);
            @include rem(height, 488);
            @include rem(margin-left, 34);
            .img-zone {
              @include rem(width, 240);
              @include rem(height, 240);
            }
            .church-txt {
              @include rem(right, -101);
              @include rem(top, 171);
            }
          }
          .h-cont02 {
            // @include rem(width, 160);
            @include rem(height, 376);
            .img-zone {
              @include rem(width, 160);
              @include rem(height, 160);
            }
            .church-txt {
              @include rem(right, -121);
              @include rem(top, 110);
            }
          }
          .h-cont03 {
            // @include rem(width, 160);
            @include rem(height, 656);
            .img-zone {
              @include rem(width, 160);
              @include rem(height, 160);
            }
            .church-txt {
              @include rem(right, -121);
              @include rem(top, 110);
            }
          }
          .h-cont04 {
            // @include rem(width, 240);
            @include rem(height, 440);
            .img-zone {
              @include rem(width, 240);
              @include rem(height, 240);
            }
            .church-txt {
              @include rem(right, -101);
              @include rem(top, 171);
            }
          }
        }

        .church-info {
          position: relative;
          @include rem(padding-left, 24);
          @include rem(margin-bottom, -40);
        }

        .img-zone {
          position: relative;
          flex-shrink: 0;
          overflow: hidden;
          border-radius: 100%;
        }

        .church-txt {
          position: absolute;
          @include rem(width, 170);

          .btn-homepage {
            position: relative;
            z-index: 5;
            display: block;
            @include rem(width, 60);
            @include rem(height, 60);
            @include rem(margin-bottom, 16);
            @include rem(border, 10 solid $line-ffffff);
            border-radius: 100%;
            &::before {
              content: "";
              display: inline-block;
              @include rem(width, 40);
              @include rem(height, 40);
              background: $bg-ffffff url("pathImages/icon/icon_church.png")
                no-repeat left top;
              @include rem(background-size, 40 40);
            }
          }
          dl {
            dt {
              @include rem(margin-bottom, 4);
              @include f16(500);
            }
            dd {
              @include f18(700);
            }
          }
        }
      }
    }

    .line-bar {
      position: relative;
      flex: 1 1 100%;
      .line-pattern {
        &::before,
        &::after {
          content: "";
          position: absolute;
          @include rem(top, 0);
        }
        &::before {
          @include rem(left, 0);
          @include rem(width, 6);
          @include rem(height, 6);
          background-color: $bg-000000;
          border-radius: 100%;
        }
        &::after {
          @include rem(left, 2);
          width: 1px;
          height: 100%;
          background-color: $bg-000000;
        }
      }
    }

    .lottie-sermon {
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .component-btns {
    position: absolute;
    @include rem(right, 126);
    @include rem(top, 71);
    margin: 0;
    .btn {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      @include rem(width, 250);
      @include rem(height, 64);
      @include rem(padding, 0 29 0 32);
      border: 1px solid $line-e0e0e0;
      background-color: transparent;
      transition: all var(--time) cubic-bezier(0.65, 0.05, 0.36, 1);
      // transition: all var(--time) ease-in-out;
      &::before {
        transition: all var(--time) cubic-bezier(0.65, 0.05, 0.36, 1);
        // transition: all var(--time) ease-in-out;
      }
      // }
      .ico-detail-arrow {
        // background-image: url("pathImages/icon/ico_detail_view_main.png");
      }

      .btn-txt {
        @include f20(500);
        transition: all var(--time) ease;
      }
    }
  }
}

// family-site-wrap
.family-site-wrap {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    @include rem(width, 16);
    height: 100%;
    background-color: $bg-ffffff;
  }
  &::after {
    @include rem(right, 120);
  }
  &::before {
    @include rem(left, 120);
  }

  .family-site-cont {
    // @include rem(padding, 0 216);
    padding: 0 11.25%;
    @include rem(padding-top, 190);
    @include rem(height, 750);
    background-color: $bg-eff3f7;

    // .component-tab {
    //   @include rem(padding-top, 80);
    //   .tab-head {
    //     justify-content: center;
    //     @include rem(height, 48);
    //     border-bottom: 0;
    //   }
    //   .tab-body {
    //     @include rem(margin-top, 64);
    //   }
    //   .tab-label {
    //     position: relative;
    //     @include rem(padding, 0);
    //     @include f20(500);
    //     & + .tab-label {
    //       @include rem(margin-left, 48);
    //     }
    //     &[aria-selected="true"] {
    //       color: $font-fe508b;
    //       &::after {
    //         @include rem(bottom, -8);
    //         background-color: $line-fe508b;
    //       }
    //     }
    //   }
    // }
    .cont-body {
      position: relative;
    }

    .family-site-swiper {
      @include rem(max-width, 1108);
      margin: 0 auto;
    }
    .swiper-slide {
      // padding: 0 11.7%;
    }
    .swiper-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // @include rem(padding, 0 171);

      .txt-info {
        flex-shrink: 0;
        width: 33.81%;
        margin-right: 5%;
        .tit {
          @include rem(margin-bottom, 24);
          @include f40(700);
        }
        .txt {
          @include f18(400);
        }
      }
      .img-zone {
        width: 61.19%;
      }
      .component-btns {
        @include rem(margin-top, 121);
        justify-content: flex-start;
      }
    }

    // swiper-indicators
    .swiper-indicators {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      @include rem(top, -113);
      @include rem(height, 50);

      .swiper-pagination-bullet {
        position: relative;
        width: auto;
        height: auto;
        @include rem(margin, 0 24);
        opacity: 1;
        border-radius: 0;
        @include f20(500);
        color: $font-000000;
        background: none;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          @include rem(height, 2);
          transform: scaleX(0);
          transform-origin: 50% 0%;
          transition: transform 0.3s;
        }
        &.swiper-pagination-bullet-active {
          color: $font-fe508b;
          &::after {
            position: absolute;
            transform: scaleX(1);
            transition: transform 0.3s;
            @include rem(bottom, -8);
            background-color: $line-fe508b;
          }
        }
      }
    }
  }
}

// febc-amu-wrap
.febc-amu-wrap {
  position: relative;
  @include rem(margin, 160 auto 0 auto);
  .hgroup-component {
    margin-bottom: 0;
    .hgroup-tit {
      margin-bottom: 0;
    }
  }
  .list-comm-wrap {
    margin-bottom: 0;
  }
  .gallary-type {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // iOS Safari: grid-template-columns 10.3부터 지원
    @include rem(column-gap, 37);
    @include rem(row-gap, 37);
    // iOS Safari: gap iOS 12.2부터 지원
    li {
      .list-tit {
        @include rem(padding, 16 16 0 16);
        @include f18(500);
        color: $font-2c2c2c;
        @include ellipsis(2);
        min-height: auto;
      }
      & + li {
        margin: 0;
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .intro-container {
    .logo-wrap {
      @include rem(width, 196);
      top: 50%;
      left: 49%;
      transform: translate(-49%, -50%);
    }
    overflow: hidden;
    .circle-reached-end {
      top: 57%;
      transform: translate(-50%, -43%);
    }
    .line {
      height: 43%;
    }
  }
  .febc-page-container-main {
    overflow: hidden;
    width: 100%;
    #content {
      @include rem(padding-top, 80);
    }
    [class*="-wrap"] {
      max-width: 1920px;
      .section-inner {
        position: static;
        max-width: 1680px;
        margin: 0 auto;
        @include rem(padding, 0 24);
      }
    }
    .hgroup-component {
      .hgroup-tit {
        @include f26(700);
        @include rem(margin-bottom, 0);
      }
    }
  }
  // main-visual-wrap : mo
  .main-visual-wrap {
    background-color: white;
    margin: 0;

    .main-visual-inner {
      border-radius: 0;
    }
    .hgroup-component {
      @include rem(left, 24);
      @include rem(top, 40);
      .hgroup-tit {
        @include f24(700);
      }
      .hgroup-txt {
        @include f16(500);
      }
      .produce {
        @include rem(margin-top, 16);
        @include f14(500);
        br {
          display: block !important;
        }
      }
    }

    .main-visual-swiper {
      .img-zone {
        @include rem(height, 100%);
      }
    }

    .swiper-wrapper {
      .swiper-slide {
      }
      .swiper-item {
        @include rem(height, 100%);
      }
    }
    // swiper-autoplay-wrap : mo
    .swiper-autoplay-wrap {
      @include rem(left, 98);
      @include rem(bottom, 32);
      z-index: 10;
      .swiper-autoplay {
        &.stop {
        }
      }
    }
    // num paging : mo
    .navi-num {
      @include rem(left, 24);
      @include rem(bottom, 32);
      @include rem(width, 66);
      @include rem(height, 32);
      @include f14(500);
      .swiper-pagination-current {
        // @include rem(margin-right, 0);
      }
      .swiper-pagination-total {
        // @include rem(margin-left, 0);
      }
    }

    // 좌, 우 버튼
    .swiper-navigation {
      display: none;
    }
    .swiper-navigation {
      &.ani-custom {
        display: none;
      }
      &.ani-custom {
        display: none;
      }
    }
  }

  // onair-wrap: mo
  .onair-wrap {
    @include rem(margin, 72 auto 0 auto);
    .section-inner {
      &::after {
        display: none;
      }
    }
    .onair-swiper {
      .swiper-container {
        @include rem(margin, 0);
        @include rem(padding, 0);

        .swiper-wrapper {
          align-items: center;
        }

        .swiper-slide {
          &.live {
            .on-air-progress {
              @include rem(margin-top, 32);
            }
            .onair-info {
              @include rem(margin-left, 0);
              .onair-tit {
                a {
                  color: $font-000000;
                }
              }
              .onair-txt {
                color: $font-777777;
              }
              .btn-visible-radio {
              }
            }
            .onair-img {
              img {
              }

              .onair-live {
                display: flex;
              }
              // interaction
              .in-line,
              .out-line {
                // @include rem(width, 170);
                // @include rem(height, 170);
              }

              .in-line {
              }

              .out-line {
                // @include rem(width, 175);
                // @include rem(height, 175);
              }
            }
          }
        }
      }
    }
    .hgroup-component {
      @include rem(margin, 0 0 0 0);
      .hgroup-tit {
        position: relative;
        @include rem(margin-bottom, 0);
        @include f24(700);
        .component-lottie {
          @include rem(margin-right, 8);
        }
        // &::before {
        //   content: "";
        //   display: inline-block;
        //   @include rem(width, 32);
        //   @include rem(height, 14);
        //   @include rem(margin-right, 8);
        // }
      }

      .txt-info-tip {
        @include rem(margin-top, 16);
        @include f16(500);
        strong {
          @include f16(500);
          color: $font-fe508b;
        }
      }

      .btn-round-arrow {
        display: flex;
        align-items: center;
        position: static;
        @include rem(margin-top, 24);
        .btn-txt {
        }
        .ico-round-arrow {
          @include rem(margin-left, 8);
        }
      }
    }

    .onair-zone {
      display: block;
      text-align: center;
      .onair-img {
        @include rem(width, 160);
        @include rem(height, 160);
        @include rem(margin, 48 auto 32 auto);
        img {
          @include rem(width, 160);
          @include rem(height, 160);
        }
      }
      .onair-live {
        .btn-live-onair {
        }
      }

      .onair-info {
        display: block;
        width: 100%;
        @include rem(margin-left, 0);
        .label-bx {
          @include rem(margin-bottom, 16);
        }
        .onair-tit {
          justify-content: center;
          @include rem(margin, 0 0 8 0);
          a {
            display: block;
            @include f18(700);
            @include ellipsis(1);
          }
        }
        .onair-txt {
          @include f14(500);
          .time {
            &::before {
              content: "";
              display: inline-block;
              width: 1px;
              @include rem(height, 12);
              @include rem(margin, 0 5);
              background-color: $bg-e5e5e5;
            }
          }
        }

        .btn-visible-radio {
          justify-content: center;
          @include rem(margin, 24 auto 0 auto);

          .btn-txt {
            @include f16(500);
          }
        }
      }
    }

    .swiper-item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .on-air-progress {
        display: none;
        width: 100%;
        @include rem(height, 2);
        @include rem(margin, 44 0 3 0);
        background-color: $bg-bfbfbf;
        .progress-bar {
          position: relative;
          @include rem(height, 2);
          background-color: $font-fe508b;
          &::after {
            content: "";
            position: absolute;
            right: 0;
            @include rem(top, -3);
            @include rem(width, 8);
            @include rem(height, 8);
            border-radius: 100%;
            background-color: $font-fe508b;
          }
        }
      }

      // 프로그램 없을때
      .no-program {
        @include rem(padding, 0 50);
        text-align: center;
        p {
          @include f24(700);
        }
        ul {
          // @include rem(margin-top, 15);
        }
        li {
          @include f18(400);
          text-align: left;
          // color: $font-777777;
        }
      }
    }

    // 좌, 우 버튼
    .swiper-navigation {
      [class^="swiper-button"] {
        @include rem(width, 24);
        @include rem(width, 24);

        // @include rem(bottom, 90);
      }
      .swiper-button-prev {
        background-image: url("pathImages/btn/btn_swiper_onair_l.png");
      }
      .swiper-button-next {
        @include rem(margin-left, 24);
        background-image: url("pathImages/btn/btn_swiper_onair_r.png");
      }
    }
  }

  // meet-video-wrap : mo
  .meet-video-wrap {
    @include rem(margin-top, 72);
    position: relative;
    .section-inner {
      // position: relative;
    }

    .tab-day-week {
      position: static;
      justify-content: center;
      @include rem(margin, 24 0 16 0);
      justify-content: space-around;
      li {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          @include f16;
          @include rem(width, 38);
          @include rem(height, 40);
          color: $font-cbcbcb;
          &.on {
            @include f16;
            @include rem(border-radius, 8);
            background-color: $bg-fe508b;
            color: $font-ffffff;
          }
        }
      }
    }

    .main-meet-swiper {
      @include rem(margin-top, 16);
      .swiper-slide {
        .swiper-item {
          .img-zone {
            height: auto;
          }
          .txt-info {
            @include rem(padding, 24);

            .txt {
              @include ellipsis(1);
              // white-space: normal;
              @include f14(400);
            }
          }
          .btn-area {
            @include rem(margin-top, 30);
            .ico-play-bx.type02 {
              @include rem(width, 40);
              @include rem(height, 40);
              .ico-play {
                .ico {
                  @include rem(width, 12);
                  @include rem(height, 14);
                  background-image: url("pathImages/icon/ico_play_black_mo.png");
                }
              }
            }
          }
          &:focus {
          }
        }
        &:last-child {
          .swiper-item {
            .txt-info {
            }
          }
        }
      }
    }

    // swiper-progress
    .swiper-progress {
      display: none;
    }

    // 좌, 우 버튼
    .swiper-navigation {
      display: none;
      [class^="swiper-button"] {
        &::after {
        }
      }
      .swiper-button-prev {
        &::after {
          // content: "Prev";
        }
      }
      .swiper-button-next {
        &::after {
          // content: "Next";
        }
      }
    }
  }

  // information-wrap : mo
  .information-wrap {
    @include rem(margin-top, 0);
    .lottie-video {
      position: relative;

      .txt-animation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include rem(width, 278);
        @include rem(height, 417);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        [class*="ani-"] {
          position: static;
          @include f40(700);
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }

          & + [class*="ani-"] {
            @include rem(margin-top, 12);
          }
        }

        .ani-txt01 {
          @include rem(padding-left, 12);
        }

        .ani-txt02 {
        }

        .ani-txt03 {
          @include rem(padding-right, 20);
          text-align: right;
        }
        .ani-txt04 {
          @include rem(padding-left, 20);
          text-align: left;
        }
      }

      .lottie-inner {
        @include rem(height, 417);
        .video-bx {
          width: 100%;
          transform: scale(1.8);
        }
      }
    }

    .info-zone {
      @include rem(margin-top, 0);
      .info-list {
      }
      .info-item {
        position: relative;
        height: auto;
        .item-inner {
          display: flex;
          flex-direction: column-reverse;
          // height: auto;
          @include rem(height, 104);
        }
        .tit-clone {
          display: block;
          position: absolute;
          @include rem(left, 24);
          @include rem(top, 61);
          @include f18(700);
          color: $font-ffffff;
          z-index: 5;
        }
        .txt-info {
          overflow: hidden;
          position: absolute;
          z-index: 5;
          @include rem(left, 24);
          @include rem(top, 61);
          width: auto;
          @include rem(height, 27);
          padding: 0;
          border: 0;
          z-index: 10;

          .tit {
            display: inline-block;
            @include f18(700);
            color: $font-ffffff;
            // @include rem(margin-bottom, 8);
          }
          .txt {
            @include f16(400);
          }
          .btn-area {
            position: static;
            @include rem(margin-top, 72);
          }
          .btn-bullet {
            display: none;
            .ico-bullet {
            }
          }
        }

        .img-zone {
          position: relative;
          width: 100%;
          // height: auto;
          @include rem(height, 104);

          img {
            // position: static;
            // transform: none;
            // width: 100%;
            width: 100%;
          }
        }

        &.on {
          .item-inner {
            height: auto;
          }
          .txt-info {
            position: static;
            height: auto;
            @include rem(margin, 32 24 32 24);
            padding: 0;
            border-top: 0;
            .tit {
              @include f24(700);
              color: $font-000000;
              @include rem(margin-bottom, 8);
            }
            .txt {
              @include f16(400);
            }
          }
          .img-zone {
            // height: auto;
            @include rem(height, 264);
          }
        }

        &:last-child {
          .txt-info {
            border-bottom: 0;
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }

  // sermon-wrap : mo
  .sermon-wrap {
    @include rem(margin-top, 80);
    .hgroup-component {
      @include rem(padding, 0 24);
      .hgroup-tit {
        @include rem(margin-bottom, 8);
      }
      .hgroup-txt {
        @include f16(400);
        margin: 0;
      }
    }

    .sermon-cont {
      @include rem(height, auto);
      @include rem(margin-top, 40);
      padding: 0;
      .component-swiper {
      }
      .swiper-wrapper {
        .swiper-slide {
          .slide-inner {
            // @include rem(margin, 0 24);
            [class*="h-"] {
              // @include rem(width, 272);
              .img-zone {
                // @include rem(width, 272);
                // @include rem(height, 200);
              }
              & + [class*="h-"] {
                @include rem(margin-left, 49);
              }
            }
            .h-cont01 {
              @include rem(height, 438);
            }
            .h-cont02 {
              @include rem(height, 286);
            }
            .h-cont03 {
              @include rem(height, 566);
            }
            .h-cont04 {
              @include rem(height, 350);
            }
          }
        }
      }

      .line-bar {
        .line-pattern {
          &::after,
          &::before {
          }
        }
      }

      .lottie-sermon {
        bottom: 0;
        .video-bx {
          width: auto;
        }
      }
    }

    .component-btns {
      position: static;
      @include rem(margin-top, 36);
      .btn {
        width: 100%;
        @include rem(height, 56);
        @include rem(padding-right, 25);
        .ico-detail-arrow {
          @include rem(margin-right, 0);
        }
        .btn-txt {
          @include f16(500);
        }
        &::before {
          @include rem(width, 40);
          @include rem(height, 40);
        }
      }
    }
  }
  // family-site-wrap : mo
  .family-site-wrap {
    @include rem(margin-top, 72);
    @include rem(padding, 0 24);
    &::after,
    &::before {
      display: none;
    }

    .hgroup-component {
      .hide-txt {
        position: static;
        margin: 0;
        width: auto;
        height: auto;
        clip: auto;
      }
    }
    .family-site-cont {
      padding: 0;
      height: auto;
      background-color: $bg-ffffff;
      @include rem(margin-top, 27);
      border-top: 1px solid $line-000000;
      border-bottom: 1px solid $line-000000;
      li {
        @include rem(padding, 24 0);
        & + li {
          border-top: 1px solid $line-e0e0e0;
        }
        .item {
        }
      }

      .txt-info {
        position: relative;
        @include rem(padding-right, 50);
        .tit {
          @include rem(margin-bottom, 0);
          @include f16(500);
        }
        .txt {
          @include f16(400);
          @include ellipsis(2);
        }
        &::after {
          content: "";
          position: absolute;
          @include rem(right, 8);
          top: 50%;
          transform: translate(0, -50%);
          @include rem(width, 40);
          @include rem(height, 40);
          background: url("pathImages/btn/btn_family_arrow_mo.png") no-repeat 0
            0 / 100% auto;
        }
      }
    }
  }

  // febc-amu-wrap : mo
  .febc-amu-wrap {
    @include rem(margin-top, 72);
    .gallary-type {
      display: block;
      // @include rem(padding-top, 32);
      // border-top: 1px solid $line-000000;
      // border-bottom: 1px solid $line-000000;
      li {
        .img-zone {
        }
        .list-tit {
          min-height: auto;
          @include f16(400);
          color: $font-000000;
        }
        & + li {
          @include rem(padding-top, 32);
        }
      }
    }

    .list-comm-wrap {
      @include rem(margin, 24 0 0 0);
    }
  }
}
