@use "../abstracts" as *;

.ico-normal {
  @include icon-size;
}
.ico-close {
  @include ico-x;
}
.ico-go-back {
  @include ico-back;
}
.ico-arrow-up {
  @include ico-arrow-up;
}
// .ico-arrow-down {
//   @include ico-arrow-down;
// }
.ico-arrow-left {
  @include ico-arrow-left;
}
.ico-arrow-right {
  @include ico-arrow-right;
}
.ico-share {
  @include ico-share;
}
.ico-search {
  @include ico-search;
}
.ico-search-white {
  @include ico-search(white);
}
.ico-hamburger {
  @include ico-hamburger;
}
.ico-refresh {
  @include ico-refresh;
}
.ico-calendar {
  @include ico-calendar;
}
.ico-spinner {
  @include ico-spinner;
  animation: ico-spinner 0.6s linear infinite;
}
@keyframes ico-spinner {
  100% {
    transform: rotate(360deg);
  }
}

/*! 아이콘 공통 속성 */
// mobile, pc 공통
[class^="ico-"] {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  @include rem(width, 20);
  @include rem(height, 20);

  &.ico-sm {
    @include rem(width, 16);
    @include rem(height, 16);
  }

  &.ico-mid {
    @include rem(width, 24);
    @include rem(height, 24);
  }

  &.ico-big {
  }

  // Reset
  &.ico-clear {
    background-image: url("pathImages/icon/ico_reset.png");
  }
  // Paging Up
  &.ico-paging-arrow-up {
    background-image: url("pathImages/icon/ico_arrow_up.png");
    &-black {
      background-image: url("pathImages/icon/ico_arrow_up03.png");
    }
  }
  // Paging Down
  &.ico-paging-arrow-down {
    background-image: url("pathImages/icon/ico_arrow_down02.png");
    &-black {
      background-image: url("pathImages/icon/ico_arrow_down03.png");
    }
  }

  // header search
  &.ico-search-header {
    background-image: url("pathImages/icon/ico_search_header.png");
  }

  // main Event Button
  &.ico-event-arrow-down {
    background-image: url("pathImages/icon/ico_event_arrow_down.png");
  }
  &.ico-step-completion {
    background-image: url("pathImages/icon/ico_step_completion.png");
  }
  &.ico-step-02 {
    background-image: url("pathImages/icon/ico_step_02.png");
  }
  &.ico-step-03 {
    background-image: url("pathImages/icon/ico_step_03.png");
  }
  &.ico-step-disabled {
    background-image: url("pathImages/icon/ico_step_disabled.png");
  }
  // login
  &.ico-kakao {
    background-image: url("pathImages/icon/ico_kakao.png");
  }
  &.ico-facebook {
    background-image: url("pathImages/icon/ico_facebook.png");
  }
  &.ico-naver {
    background-image: url("pathImages/icon/ico_naver.png");
  }
  // Footer
  &.ico-link-arrow {
    background-image: url("pathImages/icon/ico_round_arrow_white.png");
  }
  &.ico-arrow-right {
    background-image: url("pathImages/icon/ico_login_right.png");
  }
  &.ico-more-arrow {
    background-image: url("pathImages/icon/ico_more_arrow.png");
  }
  &.ico-badge {
    background-image: url("pathImages/icon/ico_badge.png");
  }
  &.ico-file-upload {
    background-image: url("pathImages/icon/ico_file_upload.png");
  }
  &.ico-radio-search {
    background-image: url("pathImages/icon/ico_radio_search.png");
  }
  &.ico-table-file {
    background-image: url("pathImages/icon/ico_file.png");
  }
  &.ico-table-look {
    background-image: url("pathImages/icon/ico_look.png");
  }
  &.ico-listening {
    background-image: url("pathImages/icon/ico_listening.png");
  }
  &.ico-next-arrow-gray {
    background-image: url("pathImages/icon/ico_member_next_gray.png");
  }

  // common icon
  &.ico-spread {
    background-image: url("pathImages/icon/ico_spread.png");
  }
  &.ico-terms-arrow {
    background-image: url("pathImages/icon/ico_terms_arrow.png");
  }

  // input delete
  &.ico-delete-state {
    background-image: url("pathImages/icon/ico_delete_input.png");
  }

  &.ico-round-arrow {
    background-image: url("pathImages/btn/btn_round_arrow_black.png");
  }

  &.ico-edit {
    background-image: url("pathImages/btn/ico_edit.png");
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  [class^="ico-"] {
    @include rem(width, 24);
    @include rem(height, 24);

    // 전체메뉴
    &.ico-header-allmenu {
      background-image: url("pathImages/icon/btn_header_allmenu.png");
    }

    // 전체메뉴 로그인
    &.ico-allmenu-login {
      background-image: url("pathImages/icon/ico_allmenu_arrow_r.png");
    }

    // 전체메뉴 리스트
    &.ico-allmenu-arrow {
      background-image: url("pathImages/icon/ico_allmenu_arrow.png");
    }

    // 전체메뉴 APP 다운로드
    &.ico-app-download {
      background-image: url("pathImages/icon/ico_app_download.png");
    }

    // input delete
    &.ico-delete-state {
      @include rem(width, 20);
      @include rem(width, 20);
    }

    // 테이블 상단 검색
    &.ico-radio-search {
      background-image: url("pathImages/icon/ico_radio_search_mo.png");
    }
  }
}
