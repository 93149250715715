@use "../abstracts" as *;

/*========================================================================
	메뉴 : 전파선교사
========================================================================*/

// 서브메인
.radio-missionary-wrap {
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  &.type02 {
    .sub-comm-visual {
      @include rem(padding-bottom, 115);
      height: 100%;
    }
    br {
      display: block !important;
    }
  }
  .sub-comm-visual {
  }
  .missionary-swiper {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    .hgroup-component {
      text-align: center;
      @include rem(margin-bottom, 56);
    }
    .swiper-wrapper {
      align-items: flex-end;
    }
    .swiper-slide {
      @include rem(width, 536);
      // @include rem(height, 697);
      // @include rem(height, 537);

      .slide-item {
        .img-zone {
          position: relative;
          // @include rem(height, 400);
          // @include rem(height, 560);
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 200px;
            background: linear-gradient(
              rgba(255, 255, 255, 0),
              rgba(0, 0, 0, 0.7) 100%
            );
            transition: all 0.5s ease;
            opacity: 0;
          }
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: 0.5);
            z-index: 5;
            transition: all 0.5s ease;
          }
          .tit {
            position: absolute;
            width: 100%;
            text-align: center;
            // bottom: 44%;
            @include f32(400);
            color: $font-ffffff;
            z-index: 5;
          }
        }
        .txt {
          @include rem(height, 135);
          @include rem(margin-top, 56);
          @include f18(400);
          text-align: center;
          opacity: 0;
          transition: all 0.5s ease;
        }
      }
      &.swiper-slide-active {
        width: 100%;
        .slide-item {
          .img-zone {
            width: 100%;
            position: relative;
            &::after {
              opacity: 1;
            }
            &::before {
              opacity: 0;
            }
            .tit {
              // bottom: 0;
              // @include rem(bottom, 40);
              @include f32(500);
              // transform: none !important;
            }
          }
        }
        .txt {
          opacity: 1;
        }
      }
    }

    .swiper-navigation {
      .swiper-button-prev {
        left: 23%;
        top: 38%;
      }
      .swiper-button-next {
        right: 23%;
        top: 38%;
      }
    }
  }

  .radio-missionary {
    position: relative;
    .txt-animation {
      position: absolute;
      @include rem(top, 208);
      left: 50%;
      @include rem(width, 1352);
      transform: translateX(-50%);
      @media (max-width: 1352px) {
        width: 100%;
      }
      .txt-info {
        position: relative;
        @include rem(margin-top, 44);
        @include rem(padding-left, 530);
        .tit {
          @include f32(500);
          color: $font-fe508b;
          @include rem(margin-bottom, 16);
        }
        .txt {
          @include f24(400);
        }
        .btn-area {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
    .lottie-video {
      .lottie-inner {
      }
      img {
        max-width: 100%;
      }
    }
    .letter-swiper {
      @include rem(margin-top, -476);
      @media (max-width: 1800px) {
        @include rem(margin-top, -270);
      }
      @media (max-width: 1500px) {
        @include rem(margin-top, -100);
      }
      @media (max-width: 1200px) {
        @include rem(margin-top, -20);
      }
      .swiper-slide {
        width: auto;
      }
      .slide-inner {
        display: flex;
        width: 200%;
        @include rem(height, 500);
        align-items: flex-start;
      }
      .letter-item {
        @include rem(width, 320);
        @include rem(height, 400);
        @include rem(padding, 100 80);
        @include rem(border-radius, 8);
        background-color: $bg-f7f8fd;
        box-shadow: 32px 40px 64px 0px rgba(121, 89, 122, 0.2);
        .imgbx {
          display: block;
          @include rem(width, 160);
          @include rem(height, 200);
          @include rem(padding, 20);
          background-color: $bg-dcdfed;
        }

        & + .letter-item {
          @include rem(margin-left, 36);
        }
        &.txt-type {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          @include rem(padding, 0 57);
          @include f24(400);
          .copy {
            position: absolute;
            width: 100%;
            @include f20(500);
            color: $font-999999;
            text-align: center;
            @include rem(bottom, 48);
          }
        }
      }
    }
  }
  .missionary-video {
    position: relative;
    @include rem(margin-top, 136);
    @include rem(padding, 0 0 160 0);
    text-align: center;

    .hgroup-component {
      @include rem(margin-bottom, 40);
      .hgroup-tit {
        @include f40(700);
      }
    }
    .video-area {
      position: relative;
      z-index: 5;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      @include rem(height, 320);
      background-color: $bg-f7f8fd;
    }
  }
}

// 북방에서 온 편지 pop
.letter-swiper-wrap {
  display: flex;
  justify-content: space-between;
  @include rem(height, 512);
  @include rem(padding-right, 10);
  .swiper-slide {
    img {
      vertical-align: top;
      width: auto;
      height: auto;
      max-width: 100%;
    }
    .swiper-item {
      width: 100%;
      text-align: center;
    }
  }

  // left
  .swiper-l {
    @include rem(width, 451);
    @include rem(padding, 0 36);
    @include rem(border-radius, 8);
    background-color: $bg-f7f8fd;
    text-align: center;
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }
  }

  // right
  .swiper-r {
    position: relative;
    @include rem(width, 75);
    @include rem(padding, 28 0);
    height: 100%;
    .ui-swiper-thumbnail,
    .swiper-container {
      height: 100%;
    }
    // .component-swiper,
    // .swiper-container {
    //   height: 100%;
    // }
    .swiper-slide-thumb-active {
      border: 4px solid $font-fe508b;
    }

    .swiper-wrapper {
      // @include rem(margin, 28 0);
      flex-direction: column;
      .swiper-slide {
        // width: 100% !important;
        img {
          width: 100%;
          height: 100%;
        }
        & + .swiper-slide {
        }
      }
    }
  }

  .swiper-navigation {
    [class^="swiper-button"] {
      position: absolute;
      @include rem(width, 20);
      @include rem(height, 20);
      left: 50%;
      transform: translateX(-50%);
      box-sizing: initial;
      @include rem(background-size, 20 20);
    }
    .swiper-button-prev {
      top: 0;
      background-image: url("pathImages/btn/btn_swiper_sub_up.png");
    }
    .swiper-button-next {
      top: auto;
      bottom: 0;
      background-image: url("pathImages/btn/btn_swiper_sub_down.png");
    }
  }
}

// 전파선교사 결제
.join-support-wrap {
  position: relative;
  @include rem(margin-top, 80);

  .join-support-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1400px;
    margin: 0 auto;
    // @include rem(padding-right, 260);
    // padding-right: 15.6%;
    .visual-bg {
      position: absolute;
      left: 0;
      @include rem(left, -260);
      top: 0;
      @include rem(width, 656);
      @include rem(height, 928);
      background: url("pathImages/missionary/bg_join_timeline01.png") no-repeat
        left top;
      background-size: 100%;
    }
  }

  .support-visual {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    @include rem(width, 656);
    width: 35.68%;

    .visual-inner {
      position: sticky;
      top: 0;
      @include rem(width, 656);
      @include rem(height, 928);
    }
    .visual-tit {
      position: absolute;
      @include rem(top, 120);
      @include f32(500);
      color: $font-363853;
      z-index: 5;
      // transform: translateY(-50%);
    }
    [class^="ico-"] {
      position: absolute;
      @include rem(right, 144);
      @include rem(bottom, 0);
      @include rem(width, 412);
      @include rem(height, 358);
      &.ico-timeline {
        background-image: url("pathImages/missionary/img_timeline01.png");
      }
    }
  }

  .support-info {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-shrink: 0;
    @include rem(width, 905);
    width: 64.32%;
    @include rem(padding-left, 67);
    @include rem(padding-top, 100);
    // background-color: gray;

    .support-info-inner {
      @include rem(width, 833);
      @include rem(padding-bottom, 60);
    }
    .join-step-area {
      position: absolute;
      right: 0;
      top: 0;
      @include rem(width, 210);
      @include rem(margin-bottom, 76);
      .tit {
        @include f16(500);
      }
      .step-num {
        position: absolute;
        right: 0;
        @include rem(top, 3);
        @include f12(500);
      }
      .join-progress {
        position: relative;
        @include rem(height, 4);
        width: 100%;
        @include rem(margin-top, 8);
        background-color: $bg-e0e0e0;
        .bar {
          position: absolute;
          left: 0;
          top: 0;
          @include rem(height, 4);
          background-color: $bg-fe508b;
        }
      }
    }

    .user-select-area {
      position: relative;
      border: 1px solid $line-e0e0e0;
      .bg-select-bar {
        position: absolute;
        @include rem(left, -53);
        @include rem(top, 62);
        width: 1px;
        @include rem(height, calc(100% - 134px));
        background-color: $bg-e0e0e0;
      }
      .user-item {
        position: relative;
        @include rem(padding, 64);
        & + .user-item {
          border-top: 1px solid $line-e0e0e0;
        }

        .form-group-sup {
          .form-group {
            & + .form-group {
              @include rem(margin, 0 0 0 8);
            }
          }
        }

        .tit {
          display: flex;
          align-items: center;
          @include f24(500);
          .component-tooltip {
            @include rem(margin-left, 8);
          }
          .component-checkbox {
            .checkbox-txt {
              @include f24(500);
            }
          }
        }
        .tips {
          display: block;
          @include rem(margin-top, 16);
          @include f18(400);
          color: $font-777777;
          @include rem(padding-left, 10);
          @include rem(text-indent, -10);
          & + .tips {
            @include rem(margin-top, 8);
          }
        }

        .component-tooltip {
          text-align: center;
        }

        .component-btns {
          justify-content: flex-start;
          @include rem(margin-top, 40);
          .component-select {
          }
        }
        .component-agree,
        .component-form {
          @include rem(margin-top, 40);
        }

        .component-form {
          .component-radio {
            & + .component-radio {
              @include rem(margin-left, 90);
            }
          }
        }

        .input-add-area {
          width: 100%;
          li {
            display: flex;
            align-items: center;
            .component-input {
              flex: 1;
            }
            [class^="btn-"] {
              flex-shrink: 0;
              @include rem(margin-left, 25);
              @include rem(width, 20);
              @include rem(height, 20);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 100%;
            }
            .btn-input-add {
              background-image: url("pathImages/btn/btn_add.png");
              background-size: 100%;
            }
            .btn-input-del {
              background: url("pathImages/btn/icon_input_del.png");
              background-size: 100%;
            }
            & + li {
              @include rem(margin-top, 16);
            }
          }
        }

        &::after {
          content: "";
          display: block;
          z-index: 5;
          position: absolute;
          @include rem(left, -72);
          @include rem(top, 60);
          @include rem(width, 40);
          @include rem(height, 40);
          background-image: url("pathImages/missionary/ico_timeline01_sm.png");
          background-size: 100%;
        }
        // .ico-timeline-sm {
        //   display: block;
        //   z-index: 5;
        //   position: absolute;
        //   @include rem(left, -72);
        //   @include rem(top, 60);
        //   @include rem(width, 40);
        //   @include rem(height, 40);
        //   background-image: url("pathImages/missionary/ico_timeline01_sm.png");
        //   background-size: 100%;
        // }

        &.on {
          .ico-timeline-sm {
          }
        }
        // last
        &:last-of-type {
          &::after {
            top: auto;
            @include rem(bottom, 60);
          }
        }
        // first
        &:nth-child(1) {
          &::after {
            @include rem(top, 60);
            bottom: auto;
          }
          & + .bg-select-bar {
            display: none;
            & + .bg-select-bar {
              display: block;
            }
          }
        }
      }

      .txt-tips {
        @include rem(margin-top, 16);
        p {
          @include f18(400);
          color: $font-777777;
          & + p {
            @include rem(margin-top, 8);
          }
        }
      }

      .legal-info-txt {
        @include rem(margin-top, 40);

        .comm-bl-dot {
          @include rem(margin-bottom, 32);
          li {
            &::before {
              @include rem(width, 3);
              @include rem(height, 3);
            }
          }
        }
        p {
          @include f16(500);
          color: $font-777777;
          & + p {
            @include rem(margin-top, 32);
          }
        }
        strong {
          color: $font-fe508b;
          @include f16(500);
        }
      }

      .withdraw-info {
        @include rem(margin-top, 40);
        .withdraw-bx {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include rem(padding, 28 40);
          @include rem(border-radius, 8);
          background-color: $bg-f7f8fd;
          dt {
            @include f20(500);
          }
          dd {
            ul {
              li {
                strong {
                  color: $font-777777;
                }
                span {
                  @include rem(margin-left, 16);
                }
                .txt-point {
                  color: $font-fe508b;
                }
                & + li {
                  @include rem(margin-top, 8);
                }
              }
            }
            @include f16(500);
          }
        }
      }

      // dev mode
      &.dev-mode {
        .user-item {
          &:nth-last-of-type(2) {
            &::after {
              top: auto;
              @include rem(bottom, 60);
            }
          }
        }
      }
    }

    .total-amount-aera {
      display: flex;
      justify-content: space-between;
      @include rem(padding, 32 40);
      @include rem(border, 2 solid $line-000000);
      .tot-txt {
        @include f24(500);
      }
      .tot-amount {
        @include f24(500);
        span {
          @include rem(margin-left, 5);
        }
      }
    }
  }

  // 본인인증정보
  &.step02 {
    .join-support-inner {
      .visual-bg {
        background-image: url("pathImages/missionary/bg_join_timeline02.png");
      }
    }

    .support-visual {
      [class^="ico-"] {
        &.ico-timeline {
          background-image: url("pathImages/missionary/img_timeline02.png");
        }
      }
    }
    .support-info {
      .user-select-area {
        .user-item {
          .component-btns {
            .component-select {
            }
          }
          &::after {
            background-image: url("pathImages/missionary/ico_timeline02_sm.png");
          }
        }
      }
    }
  }

  // 납입정보
  &.step03 {
    .join-support-inner {
      .visual-bg {
        background-image: url("pathImages/missionary/bg_join_timeline03.png");
      }
    }

    .support-visual {
      .visual-tit {
      }
      [class^="ico-"] {
        &.ico-timeline {
          background-image: url("pathImages/missionary/img_timeline03.png");
        }
      }
    }

    .support-info {
      .support-info-inner {
        .form-chips {
          display: flex;
          flex: 1;
          .component-radio {
            flex: 1;
            width: auto;

            &.alone {
              flex: none;
              @include rem(width, 296);
            }
          }
        }
      }
      .user-select-area {
        .user-item {
          &::after {
            background-image: url("pathImages/missionary/ico_timeline03_sm.png");
          }
        }
      }
    }
  }

  // 후원완료
  &.completed {
    .join-support-inner {
      .visual-bg {
        background-image: url("pathImages/missionary/bg_join_timeline04.png");
      }
    }
    .support-visual {
      padding: 0;
      .visual-tit {
        text-align: left;
        span {
          display: block;
          & + span {
            @include rem(margin-top, 40);
          }
        }
      }
    }
    .support-info {
      padding-top: 0;
    }
    .support-completed {
      @include rem(padding, 64);
      border: 1px solid $line-e0e0e0;
      h3 {
        @include f24(500);
      }
      .completed-list {
        @include rem(margin-top, 16);
        li {
          display: flex;
          justify-content: flex-start;
          @include rem(padding, 24 0);
          .tit {
            flex-shrink: 0;
            @include rem(width, 160);
            @include f20(500);
            color: $font-777777;
          }
          .txt {
            @include rem(padding-left, 32);
            @include f20(500);
          }
          & + li {
            border-top: 1px solid $line-e0e0e0;
          }
        }
      }

      .btn-area {
        @include rem(margin-top, 16);
        display: flex;
        // .btn-receive {
        //   display: flex;
        //   align-items: center;
        //   @include f16(500);
        //   border-bottom: 1px solid $line-000000;
        //   &::after {
        //     content: "";
        //     display: inline-block;
        //     @include rem(width, 20);
        //     @include rem(height, 20);
        //     @include rem(margin-left, 8);
        //     background: url("pathImages/btn/btn_download.png") no-repeat left
        //       top;
        //     background-size: 100%;
        //   }
        // }
      }
    }
  }

  & + .consulting-inquiry {
    @include rem(margin-top, 160);
  }
}

// 전파선교사 나의 후원 내역
.missionary-sponsor-wrap {
  .hgroup-component {
    max-width: 1400px;
    margin: 0 auto;
    .hgroup-sub-tit {
    }
  }
  .missionary-sponsor-adjustment-simplified {
    @include rem(max-width, 1400);
    @include rem(padding, 66 80 66 66);
    @include rem(border-radius, 8);
    @include rem(margin, 48 auto 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $line-e0e0e0;
    .info-bx {
      display: flex;
      align-items: center;
      p {
        @include f24(700);
        @include rem(margin-right, 30);
      }
      .simplified-btn {
        @include f16(500);
        @include rem(padding, 6 12);
        @include rem(border-radius, 4);
        border: 1px solid $line-000000;
      }
    }
    .radio-wrap {
      margin: 0;
      .component-radio + .component-radio {
        @include rem(margin-left, 40);
      }
    }
  }
  .missionary-sponsor-details {
    @include rem(padding, 80 0);
    @include rem(border-radius, 8);
    background-color: $bg-fdf1f1;
    .missionary-sponsor-inner {
      position: relative;
      max-width: 1400px;
      margin: 0 auto;
    }

    .missionary-sponsor-head {
      @include rem(margin-bottom, 24);
      .badge {
        display: inline-block;
        @include rem(height, 40);
        @include rem(padding, 8 16);
        @include rem(border-radius, 4);
        @include f16(500);
        color: $font-ffffff;
        background-color: $bg-fe508b;
        .txt {
          vertical-align: middle;
        }
      }

      .tit {
        @include f32(500);
        @include rem(margin-top, 16);
      }
    }

    .missionary-sponsor-cont {
      .ing {
        position: absolute;
        right: 0;
        top: 0;
        @include f16(500);
        span {
          color: $font-fe508b;
        }
      }
      .money-info {
        display: flex;
        align-items: flex-start;
        @include rem(margin, 48 0 42 0);
        @include rem(padding, 48 0);
        @include rem(border-radius, 12);
        background-color: $bg-ffffff;
        li {
          position: relative;
          flex: 1;
          text-align: center;
          & + li {
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              // transform: translateY(-50%);
              width: 1px;
              @include rem(height, 96);
              background-color: $bg-e0e0e0;
            }
          }
        }
        dl {
          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            @include f24(400);
            color: $font-000000;
            @include rem(margin-bottom, 10);
          }
          dd {
            strong {
              display: inline-block;
              @include f40(700);
              vertical-align: middle;
              & + em {
                @include rem(margin, 0 0 0 5);
              }
            }
            em {
              display: inline-block;
              @include f24(400);
              vertical-align: middle;
              & + strong {
                @include rem(margin-left, 5);
              }
            }
            span {
              @include rem(margin-top, 32);
              @include f18(400);
              display: inline-block;
              color: $font-777777;
            }
          }
        }
      }
      .receipt-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .txt {
          @include f18(400);
        }
      }
      .btn-pop-plus {
        @include rem(margin-left, 8);
      }
    }
    .missionary-sponsor-privacy {
      @include rem(padding-top, 24);
      @include rem(margin-top, 31);
      border-top: 1px solid $line-e0e0e0;
      .component-checkbox {
        @include rem(margin-bottom, 16);
      }
      p {
        @include rem(padding-left, 30);
        color: $font-777777;
      }
    }
    // 후원없을경우
    &.nodata {
      .missionary-sponsor-head {
        .badge {
          background-color: $bg-763ba5;
          // .ico-spread {
          //   display: none;
          // }
        }
      }
      .missionary-sponsor-cont {
        .ing {
          &::after {
            content: "";
            display: inline-block;
            @include rem(width, 24);
            @include rem(height, 24);
            @include rem(margin-left, 4);
            background: url("pathImages/icon/ico_sponsor_nodata.png") no-repeat
              left top;
            background-size: 100%;
            vertical-align: middle;
          }
        }
      }
      .missionary-sponsor-privacy {
        p {
          padding: 0;
        }
      }
    }
  }

  .missionary-sponsor-agreement {
    max-width: 1400px;
    @include rem(margin, 160 auto 0 auto);
    .component-tab {
      @include rem(margin, -37 0 61 0);
      .tab-head {
        justify-content: flex-end;
      }
    }
  }

  .missionary-sponsor-list {
    max-width: 1400px;
    @include rem(margin, 160 auto 0 auto);

    .tbl-top-area {
      @include rem(margin-top, 40);
      justify-content: flex-end;
    }
    .total-list {
      border-top: 1px solid $line-e0e0e0;
      .list-items {
        display: flex;
        align-items: center;
        @include rem(padding, 16 56);
        border-bottom: 1px solid $line-e0e0e0;
        .s-date {
          display: flex;
          align-items: center;
          @include rem(width, 237);
          @include rem(margin-right, 184);
          margin-right: 13%;
          flex-shrink: 0;
          @include f24(400);
          .btn-sponsor_amount {
            display: inline-block;
            @include rem(width, 100);
            @include rem(height, 100);
            @include rem(margin-right, 40);
            background: url("pathImages/icon/ico_sponsor_amount.png") no-repeat
              left top;
            background-size: 100%;
          }
        }
        .amount-wrap {
          display: flex;
          align-items: center;
          flex: 1;
          overflow: hidden;
        }
        .amount-list {
          @include rem(width, 803);
          width: 57%;
          flex: 1;
          li {
            display: flex;
            justify-content: space-between;
            @include rem(padding, 16 0);
            dl {
              display: flex;
              justify-content: space-between;
              width: 100%;
              @include f18(400);
              span {
                color: $font-777777;
                @include f18(400);
              }
              strong {
                @include rem(margin-left, 16);
              }
              dt {
              }
              dd {
              }
            }
            & + li {
              border-top: 1px solid $line-e0e0e0;
            }
          }
        }
        .btn-area {
          @include rem(margin, 0 0 0 42);
          line-height: 0;
          .btn-accordion {
            @include rem(width, 20);
            @include rem(height, 20);
            background: url("pathImages/btn/btn_accordion_down.png") no-repeat
              left top;
            background-size: 100%;
          }
        }

        &.list-show {
          .btn-accordion {
            background-image: url("pathImages/btn/btn_accordion_up.png");
          }
        }
      }

      & + .total-list {
        border-top: 0;
      }
    }
  }

  .missionary-sponsor-view {
    @include rem(margin-top, 160);
    background-color: $bg-f7f8fd;
    .missionary-sponsor-view-inner {
      overflow: hidden;
      position: relative;
      max-width: 1400px;
      @include rem(height, 809);
      margin: 0 auto;
      @include rem(padding, 160 0 0 0);
    }
    .txt-info {
    }
    .img-zone {
      position: absolute;
      @include rem(bottom, -24);
      right: 0;
    }
    .component-btns {
      @include rem(margin-top, 80);
      justify-content: flex-start;
    }
  }
}

// 전파선교사 나의 후원 기록
.sponsorship-record-wrap {
  overflow: hidden;
  position: relative;

  .lottie-inner {
    position: relative;
    // @include rem(height, 1132);
    padding-top: 59%;
    .video-bx {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  .record-cont {
    position: absolute;
    left: 50%;
    @include rem(top, 80);
    transform: translate(-50%, 0);
    @include rem(width, 1160);
  }
  .year-record {
    display: flex;
    justify-content: flex-end;
    .component-select.type-custom {
      position: relative;
      z-index: 100;
      @include rem(width, 220);
      @include rem(margin-bottom, 40);

      .select-box {
        border: 1px solid $line-ffffff;
        color: $font-ffffff;
        background-color: $bg-transparent;
        opacity: 0.8;
        &::after {
          background-image: url("pathImages/icon/ico_select_arrow_transparent.png");
        }
      }
    }
  }
  .book-record {
    @include rem(width, 1160);
    // @include rem(height, 800);
  }

  .book-inner {
    display: flex;
    @include rem(max-width, 1107);
    margin: 0 auto;
    .book-col {
      width: 50%;
    }
  }

  .record-fixed {
    display: none;
    @include rem(padding, 148 64 142 88);
    strong {
      display: block;
      @include rem(margin-bottom, 16);
      @include f18(700);
    }
    p {
      @include f16(500);
      & + p {
        @include rem(margin-top, 24);
      }
    }
  }
  .record-writing {
    position: relative;
    @include rem(padding, 64);

    .month {
      @include rem(padding-bottom, 17);
      @include rem(margin-bottom, 16);
      border-bottom: 1px solid $line-999999;
      @include f12(500);
      color: $font-777777;
    }
    .writing-area {
      .revision {
        @include f12(500);
      }
      .txt-writing {
        overflow: auto;
        @include rem(max-height, 520);
        @include rem(margin-top, 56);
        @include f24(400);

        &::-webkit-scrollbar {
          @include rem(width, 4);
        }
        &::-webkit-scrollbar-thumb {
          background-color: $bg-999999;
        }
        &::-webkit-scrollbar-track {
          background-color: $bg-e0e0e0;
        }
      }
    }

    .record-animation {
      // display: none;
    }
  }
  .swiper-books {
    .swiper-controls {
      position: static;
    }

    // 좌, 우 버튼
    .swiper-navigation {
      position: absolute;
      @include rem(top, 67);
      @include rem(right, 63);

      [class^="swiper-button"] {
        position: static;
        @include rem(width, 23);
        @include rem(height, 15);
        padding: 0;
        background-size: 100%;
      }
      .swiper-button-prev {
        background-image: url("pathImages/btn/btn_swiper_book_l.png");
      }
      .swiper-button-next {
        @include rem(margin-left, 24);
        background-image: url("pathImages/btn/btn_swiper_book_r.png");
      }
    }
  }
  .pen-writing {
    position: absolute;
    z-index: 2;
    @include rem(right, -60);
    @include rem(top, 50);
    transform-origin: bottom left;
    z-index: 10;
    transform: translate(150px, 0) rotateY(24deg) rotateX(0deg);
    opacity: 0;
    visibility: hidden;
    img {
      width: 507px;
      height: 513px;
    }
    // filter: drop-shadow(35px 26px 15px rgba(0, 0, 0, 0.5));
    // &::after {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    // }
  }
}

// scroll-body
// .scroll-body {
//   @include rem(width, 1920);
//   margin: 0 auto;
// }

@media (min-width: 1024px) and (max-width: 1920px) {
  .sponsorship-record-wrap {
    .record-cont {
      width: 60.4167vw;
      top: 4.1667vw;
    }
    .year-record {
      .component-select.type-custom {
        width: 11.4583vw;
        margin-bottom: 2.0833vw;

        .select-box {
          height: 2.7083vw;
          padding: 0 0.7813vw;
          font-size: 0.8333vw;
        }

        .option {
          font-size: 0.8333vw;
        }
      }
    }
    .book-record {
      width: 60.4167vw;
    }

    .book-inner {
      max-width: 57.6563vw;
      .book-col {
        width: 50%;
      }
    }
    .record-writing {
      padding: 3.3333vw;
      .month {
        padding-bottom: 0.8854vw;
        margin-bottom: 0.8333vw;
        font-size: 0.625vw;
      }
      .writing-area {
        .revision {
          font-size: 0.625vw;
        }
        .txt-writing {
          max-height: 27vw;
          margin-top: 2.9167vw;
          font-size: 1.25vw;
        }
      }
    }
    .swiper-books {
      // 좌, 우 버튼
      .swiper-navigation {
        top: 3.4896vw;
        right: 3.2813vw;

        [class^="swiper-button"] {
          width: 1.1979vw;
          height: 0.7813vw;
        }
        .swiper-button-prev {
        }
        .swiper-button-next {
          margin-left: 1.25vw;
        }
      }
    }
    .pen-writing {
      @include rem(right, -210);
      right: -10.9375vw;
      top: 2.6042vw;
      opacity: 0;
      visibility: hidden;

      img {
        width: 26.4063vw;
        height: 26.7188vw;
      }
    }
  }
}

//  전파선교사 변경
.support-modify {
  .board-view {
    @include rem(margin-top, 96);
  }
  .board-write-line {
    @include rem(margin-top, 160);
  }
}

// 나의후원 로그인
.support-login-wrap {
  @include rem(margin-top, 96);
  .login-check {
    dl {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      dt {
        @include f40(700);
      }
      dd {
        @include f18(400);
        color: $font-777777;
      }
    }
    .round-bx {
      @include rem(padding, 24 40);
      @include rem(margin-top, 40);
    }
  }

  .login-info {
    @include rem(margin-top, 80);
    h3 {
      @include f24(400);
      @include rem(margin-bottom, 24);
    }
  }
}

// 전파선교 후원가이드
.suport-guide-wrap {
  overflow: hidden;
  @include rem(margin-top, 96);
  .guide-swiper-outer {
    position: relative;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   width: 100%;
    //   @include rem(height, 360);
    //   background-color: $bg-f7f8fd;
    // }
  }
  .guide-swiper {
    position: relative;
    @include rem(width, 1162);
    @include rem(height, 560);
    margin: 0 auto;

    @include rem(border-radius, 8);
    .swiper-container {
      position: relative;
      box-shadow: 32px 40px 64px 0px rgba(121, 89, 122, 0.2);
    }
  }

  .slide-item {
    display: flex;
    justify-content: space-between;
    @include rem(height, 560);
    @include rem(padding, 73 0 0 120);
    @include rem(border-radius, 8);
    .txt-info {
      .tit {
        @include f32(500);
        @include rem(margin-bottom, 24);
      }
      .txt {
        @include f16(500);
        color: $font-777777;
      }
      .info-area {
        position: absolute;
        @include rem(left, 120);
        @include rem(bottom, 87);
        img {
          @include rem(width, 80);
        }
        dl {
          @include f24(700);
          color: $font-335891;
          dt {
          }
          dd {
          }
        }
      }
      .btn {
        position: absolute;
        @include rem(left, 120);
        @include rem(bottom, 88);
        background-color: transparent;
      }
    }
    .img-zone {
      position: absolute;
      right: 0;
      bottom: 0;
      flex-shrink: 0;
      // @include rem(width, 486);
      // @include rem(height, 487);
    }
    &.color01 {
      background-color: $bg-fdf1f1;
    }
    &.color02 {
      background-color: $bg-ebf6ff;
      .info-area {
        p {
          @include f24(700);
          color: $font-335891;
        }
      }
    }
    &.color03 {
      background-color: $bg-fff1da;
    }
  }

  .swiper-autoplay-wrap,
  .swiper-pagination {
    position: absolute;
    @include rem(right, 40);
    @include rem(top, 40);
    left: auto;
    z-index: 5;
  }

  .swiper-pagination {
    @include rem(right, 80);
  }

  .swiper-navigation {
    .swiper-button-prev {
      @include rem(left, -118);
    }
    .swiper-button-next {
      @include rem(right, -118);
    }
  }

  .suport-guide-info {
    max-width: 1400px;
    margin: 0 auto 160px;

    .guide-section {
      @include rem(margin-top, 160);
      .board-write-line {
        @include rem(margin-top, 40);
      }
      .component-accordion {
        @include rem(margin-top, 48);
      }
    }
    .donation-wrap {
      position: relative;
      .btn {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

// 전자서명 pop
.electronic-sign {
  p {
    @include rem(margin-bottom, 24);
    @include f18(400);
  }
  .solution-area {
    @include rem(height, 240);
    border: 1px solid $line-e0e0e0;
    @include rem(border-radius, 8);
    // validation
    &.input-invalid {
      @include rem(border, 2 solid $line-ee0202);
    }
  }
  .txt-tip {
    @include rem(margin, 16 0 0 0);
    @include f18(400);
    color: $font-777777;
  }
}

// 이달의 말씀 pop
.month-words {
  .modal-container {
    background: url("pathImages/missionary/img_month_words.png") no-repeat left
      top;
    background-size: cover;
    .modal-content {
      @include rem(min-height, 797);
      @include rem(padding, 0 64);
      .words-zone {
        @include rem(padding-top, 68);
        .txt-info {
        }
        .words-tit {
          display: block;
          @include rem(margin-bottom, 48);
          @include f40(700);
        }
        .words-txt {
          @include f24(400);
        }
        .words-revision {
          @include rem(margin-top, 32);
          @include f24(400);
        }
      }
    }
  }
}
// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /*========================================================================
	메뉴 : 전파선교사 : mo
========================================================================*/

  // 서브메인 : mo
  .radio-missionary-wrap {
    &.type02 {
      .sub-comm-visual {
        @include rem(padding-bottom, 0);
      }
    }
    .sub-comm-visual {
    }
    .missionary-swiper {
      .hgroup-component {
        @include rem(margin-bottom, 24);
        .hgroup-sub-tit {
          br {
            display: block !important;
          }
        }
      }
      .swiper-wrapper {
        align-items: flex-end;
      }
      .swiper-slide {
        width: auto;
        height: auto;
        align-self: flex-start;

        .slide-item {
          .img-zone {
            height: auto;
            .tit {
              @include f16(400);
            }
          }
          .txt {
            height: auto;
            @include rem(margin-top, 24);
            @include f16(400);
          }
        }
        &.swiper-slide-active {
          width: 100%;
          height: auto;
          .slide-item {
            .img-zone {
              width: 100%;
              height: auto;
              &::before {
                display: none;
              }
              .tit {
                @include rem(bottom, 24);
                @include f16(500);
              }
            }
          }
        }
      }

      .swiper-navigation {
        display: none;
      }
    }

    .radio-missionary {
      position: relative;
      @include rem(margin-top, 56);
      .txt-animation {
        position: absolute;
        @include rem(width, 312);
        // @include rem(height, 336);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        .txt-info {
          @include rem(margin-top, 40);
          @include rem(padding-left, 0);
          .tit {
            @include f16(500);
            @include rem(margin-bottom, 8);
          }
          .txt {
            @include f16(500);
          }
          .btn-area {
            position: static;
            @include rem(margin-top, 32);
          }
        }
      }
      .lottie-video {
        position: relative;
        .video-bx {
          transform: scale(1.8);
        }
        img {
          width: 100%;
        }
        .ani-txt01 {
          position: relative;
          @include rem(height, 125);
          .ani-txt-sub01 {
            position: absolute;
            left: 0;
            @include rem(width, 167);
            @include rem(height, 49);
            @include rem(margin-bottom, 10);
          }
          .ani-txt-sub02 {
            position: absolute;
            right: 0;
            @include rem(bottom, 0);
            @include rem(width, 282);
            @include rem(height, 62);
          }
        }
      }

      .lottie-inner {
        @include rem(height, 417);
      }
      .letter-swiper {
        @include rem(margin-top, 0);
        .swiper-slide {
          width: auto;
        }
        .slide-inner {
          display: flex;
          width: 200%;
          @include rem(height, 297);
          align-items: flex-start;
        }
        .letter-item {
          @include rem(width, 180);
          @include rem(height, 225);
          @include rem(padding, 50 40);
          // box-shadow: 32px 40px 64px 0px rgba(121, 89, 122, 0.2);
          .imgbx {
            display: block;
            @include rem(width, 100);
            @include rem(height, 125);
            @include rem(padding, 20);
          }
          & + .letter-item {
            @include rem(margin-left, 16);
          }
          &.txt-type {
            @include rem(padding, 0 24);
            @include f14(400);
            .copy {
              @include f16(500);
              @include rem(bottom, 24);
            }
          }
        }
      }
    }
    .missionary-video {
      @include rem(margin-top, 0);
      @include rem(padding, 0 24 24 24);

      .hgroup-component {
        @include rem(margin-bottom, 24);
        .hgroup-tit {
          @include f24(500);
        }
      }
      .video-area {
        position: relative;
        padding-top: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
      &::after {
        @include rem(height, 96);
      }
    }
  }

  // 북방에서 온 편지 pop : mo
  .letter-modal {
    .modal-container {
      .modal-header {
        @include rem(padding-bottom, 24);
      }
      .modal-content {
        max-height: 100%;
      }
    }
  }
  .letter-swiper-wrap {
    display: block;
    height: auto;
    @include rem(padding-right, 0);
    .swiper-slide {
      img {
      }
      .swiper-item {
      }
    }

    // left
    .swiper-l {
      width: 100%;
      @include rem(padding, 0 24);
      @include rem(border-radius, 8);
      background-color: $bg-f7f8fd;
      text-align: center;
      .swiper-wrapper {
        display: flex;
        align-items: center;
      }
    }

    // right
    .swiper-r {
      width: auto;
      @include rem(margin, 40 24 0 24);
      @include rem(padding, 0 24);
      height: 100%;
      .ui-swiper-thumbnail,
      .swiper-container {
        height: 100%;
      }
      .swiper-slide-thumb-active {
      }

      .swiper-wrapper {
        flex-direction: row;
        .swiper-slide {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .swiper-navigation {
      [class^="swiper-button"] {
        top: 50%;
        left: auto;
        transform: translate(0, -50%);
      }
      .swiper-button-prev {
        left: 0;
        background-image: url("pathImages/btn/btn_swiper_sub_l.png");
      }
      .swiper-button-next {
        right: 0;
        background-image: url("pathImages/btn/btn_swiper_sub_r.png");
      }
    }
  }

  // 전파선교사 결제 : mo
  .join-support-wrap {
    @include rem(margin-top, 40);
    @include rem(padding, 0);

    .join-support-inner {
      display: block;
      width: 100%;
      .visual-bg {
        @include rem(left, 0);
        @include rem(top, 0);
        width: 100%;
        @include rem(height, 400);
        // background-image: url("pathImages/missionary/bg_join_timeline01_mo.png");
        background-size: cover;
      }
    }

    .support-visual {
      display: none;
      width: 100%;
      @include rem(height, 400);
      @include rem(padding, 48 40);
      .visual-inner {
        position: static;
        width: 100%;
        height: 100%;
      }
      .visual-tit {
        position: relative;
        @include f18(700);
        transform: none;
        top: 0;
        br {
          display: none;
        }
      }
      [class^="ico-"] {
        position: absolute;
        @include rem(right, 0);
        @include rem(bottom, 0);
        @include rem(width, 276);
        @include rem(height, 240);
        &.ico-timeline {
          background-image: url("pathImages/missionary/img_timeline01_mo.png");
        }
      }
    }

    .support-info {
      position: relative;
      width: 100%;
      @include rem(padding, 0 24);
      @include rem(margin-top, 48);

      .support-info-inner {
        width: 100%;
        @include rem(padding, 0 0 0 0);
      }
      .join-step-area {
        position: relative;
        width: 100%;
        @include rem(margin-bottom, 24);
        .tit {
          @include f16(500);
        }
        .step-num {
          position: absolute;
          right: 0;
          @include rem(top, 3);
          @include f14(500);
        }
        .join-progress {
          .bar {
          }
        }
      }

      .user-select-area {
        .bg-select-bar {
          display: none;
        }
        .user-item {
          @include rem(padding, 32 24);
          & + .user-item {
          }

          .form-group-sup {
            display: flex;
          }

          .tit {
            position: relative;
            // display: block;
            @include f18(700);
            .component-tooltip {
              // position: static;
              @include rem(margin-left, 4);
              vertical-align: middle;
              br {
                display: block;
              }
              .tooltip-container {
                top: calc(100% + 10px);
                &::after {
                  // display: none;
                }
              }
            }

            @media (max-width: 500px) {
              .component-tooltip {
                position: static;
              }
            }

            .component-checkbox {
              .checkbox-txt {
                @include f18(700);
              }
            }
          }
          .tips {
            @include rem(margin-top, 8);
            @include f14(500);
          }

          .component-btns {
            display: block;
            @include rem(margin-top, 24);
            .component-select {
            }
          }
          .component-agree,
          .component-form {
            @include rem(margin-top, 24);
          }

          .component-form {
            .component-radio {
              display: block;
              & + .component-radio {
                @include rem(margin, 24 0 0 0);
              }
            }
          }

          .input-add-area {
            width: 100%;
            li {
              display: flex;
              align-items: center;
              .component-input {
                flex: 1;
              }
              [class^="btn-"] {
                flex-shrink: 0;
                @include rem(margin-left, 16);
                @include rem(width, 20);
                @include rem(height, 20);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100%;
              }
              .btn-input-add {
                background-image: url("pathImages/btn/btn_add.png");
                background-size: 100%;
              }
              .btn-input-del {
                background: url("pathImages/btn/icon_input_del.png");
                background-size: 100%;
              }
              & + li {
                @include rem(margin-top, 8);
              }
            }
          }

          &::after {
            content: "";
            display: block;
            z-index: 5;
            position: absolute;
            @include rem(left, -72);
            @include rem(top, 60);
            @include rem(width, 40);
            @include rem(height, 40);
            background-image: url("pathImages/missionary/ico_timeline01_sm.png");
            background-size: 100%;
          }
          // .ico-timeline-sm {
          //   display: block;
          //   z-index: 5;
          //   position: absolute;
          //   @include rem(left, -72);
          //   @include rem(top, 60);
          //   @include rem(width, 40);
          //   @include rem(height, 40);
          //   background-image: url("pathImages/missionary/ico_timeline01_sm.png");
          //   background-size: 100%;
          // }

          &.on {
            .ico-timeline-sm {
            }
          }
          // last
          &:last-of-type {
            &::after {
              top: auto;
              @include rem(bottom, 60);
            }
          }
          // first
          &:nth-child(1) {
            &::after {
              @include rem(top, 60);
              bottom: auto;
            }
            & + .bg-select-bar {
              display: none;
              & + .bg-select-bar {
                display: block;
              }
            }
          }
        }

        .txt-tips {
          @include rem(margin-top, 16);
          p {
            @include f14(500);

            & + p {
            }
          }
        }

        .legal-info-txt {
          @include rem(margin-top, 24);
          .comm-bl-dot {
            @include rem(margin-bottom, 16);
          }
          p {
            @include f14(500);
            br {
              display: none;
            }
            & + p {
              @include rem(margin-top, 16);
            }
          }
          strong {
            @include f14(500);
          }
        }

        .withdraw-info {
          @include rem(margin-top, 24);
          .withdraw-bx {
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include rem(padding, 32 0);
            dt {
              @include f16(500);
            }
            dd {
              @include rem(margin-top, 24);
            }
          }
        }
      }

      .total-amount-aera {
        @include rem(padding, 24);
        .tot-txt {
          @include f18(400);
        }
        .tot-amount {
          @include f18(400);
          span {
            @include rem(margin-left, 5);
          }
        }
      }
    }

    .component-tooltip {
      &[data-props-position="bottom"] {
        .tooltip-container {
          left: auto;
          transform: none;
          right: 0;
        }
      }
    }

    // 본인인증정보
    &.step02 {
      .join-support-inner {
        .visual-bg {
          // background-image: url("pathImages/missionary/bg_join_timeline02.png");
        }
      }

      .support-visual {
        .visual-tit {
        }
        [class^="ico-"] {
          &.ico-timeline {
            background-image: url("pathImages/missionary/img_timeline02_mo.png");
          }
        }
      }
    }

    // 납입정보
    &.step03 {
      .join-support-inner {
        .visual-bg {
          // background-image: url("pathImages/missionary/bg_join_timeline03.png");
        }
      }

      .support-visual {
        .visual-tit {
        }
        [class^="ico-"] {
          &.ico-timeline {
            background-image: url("pathImages/missionary/img_timeline03_mo.png");
          }
        }
      }

      .support-info {
        .support-info-inner {
          .form-chips {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @include rem(gap, 4);
            .component-radio {
              width: 100%;
              &.alone {
                width: 100%;
              }

              & + .component-radio {
                margin-top: 0;
              }
            }
          }
        }
      }
      .user-select-area {
        .user-item {
          .radio-only {
            margin: 0;
            .form-frame {
              display: flex;
              flex-wrap: wrap;
              .component-radio {
                @include rem(margin, 24 0 0 0);
                @include rem(width, 50%);
              }
            }
          }
        }
      }
    }

    // 후원완료
    &.completed {
      .join-support-inner {
        .visual-bg {
          // background-image: url("pathImages/missionary/bg_join_timeline04.png");
        }
      }
      .support-visual {
        display: block;
        height: auto;
        @include rem(padding, 48 40);
        .visual-tit {
          @include f16(700);
          span {
            & + span {
              @include rem(margin-top, 16);
            }
          }
        }
        .visual-bg {
          height: 100%;
        }
        [class^="ico-"] {
          &.ico-timeline {
            display: none;
            background-image: url("pathImages/missionary/img_timeline04_mo.png");
          }
        }
      }
      .support-info {
        padding-top: 0;
      }
      .support-completed {
        @include rem(padding, 32 24);
        h3 {
          @include f18(700);
        }
        .completed-list {
          @include rem(margin-top, 0);
          li {
            display: block;
            .tit {
              display: block;
              width: 100%;
              @include f14(500);
            }
            .txt {
              display: block;
              @include rem(margin-top, 8);
              @include rem(padding-left, 0);
              @include f14(500);
            }
            & + li {
            }
          }
        }

        .btn-area {
          @include rem(margin-top, 0);
          .btn-txt {
            // @include f14(500);
          }
        }
      }
    }
    & + .consulting-inquiry {
      @include rem(margin-top, 72);
    }
  }

  // 전파선교사 나의 후원 : mo
  .missionary-sponsor-wrap {
    .missionary-sponsor-adjustment-simplified {
      @include rem(max-width, 100%);
      @include rem(padding, 0 24);
      @include rem(border-radius, 8);
      @include rem(margin, 78 auto 0);
      display: block;
      border: 0;
      .info-bx {
        display: block;
        p {
          @include rem(margin, 0 0 24 0);
        }
        .simplified-btn {
          @include rem(width, 100%);
          @include rem(margin-bottom, 16);
          @include f18(700);
          @include rem(padding, 14 0);
          @include rem(border-radius, 4);
          display: block;
          text-align: center;
        }
      }
      .radio-wrap {
        @include rem(margin-top, 24);
        padding: 0;
        .component-radio {
          input {
            background-image: url("pathImages/icon/ico_checkbox_off.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% auto;
            @include rem(background-size, 8 7);
            background-color: transparent;
            appearance: none;
            outline: none;
            @include rem(width, 20);
            @include rem(height, 20);
            border: 1px solid $line-999999;
            @include rem(border-radius, 50%);
            cursor: pointer;
            &:checked {
              background-image: url("pathImages/icon/ico_checkbox_on.png");
              background-color: $bg-fe508b;
              border: 1px solid $line-fe508b;
            }
          }
        }
        .component-radio + .component-radio {
          @include rem(margin-left, 100);
        }
      }
    }
    .missionary-sponsor-details {
      @include rem(padding, 48 24 54 24);
      .missionary-sponsor-inner {
        position: relative;
        width: 100%;
        margin: 0;
      }

      .missionary-sponsor-head {
        @include rem(margin-bottom, 40);
        .badge {
          display: inline-block;
          @include rem(height, 37);
          @include rem(padding, 8 12);
          @include rem(border-radius, 4);
          @include f14(500);
          color: $font-ffffff;
          background-color: $bg-fe508b;
          .txt {
            vertical-align: middle;
          }
        }

        .tit {
          @include f24(500);
          @include rem(margin-top, 16);
        }
      }

      .missionary-sponsor-cont {
        .ing {
          position: static;
          span {
          }
        }
        .money-info {
          display: block;
          @include rem(margin, 16 0 32 0);
          @include rem(padding, 8 24);
          @include rem(border-radius, 4);
          li {
            position: relative;
            @include rem(padding, 16 0 16 0);
            text-align: left;
            & + li {
              border-top: 1px solid $line-e0e0e0;
              &::before {
                display: none;
              }
            }
          }
          dl {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            dt {
              @include f14(500);
              color: $font-000000;
              @include rem(margin-bottom, 0);
            }
            dd {
              &.desc {
                @include rem(width, 100%);
                // text-align: right;
                span {
                  @include rem(margin-top, 24);
                  @include f14(500);
                }
              }
              strong {
                @include f18(700);
                & + em {
                  @include rem(margin, 0 0 0 4);
                }
              }
              em {
                @include f16(500);
                & + strong {
                  @include rem(margin-left, 4);
                }
              }
            }
          }
        }
        .receipt-info {
          display: block;
          .txt {
            display: block;
            @include rem(margin-bottom, 24);
            @include f16(500);
          }
        }
        .btn-pop-plus {
          @include rem(margin-left, 8);
        }
      }
      .missionary-sponsor-privacy {
        @include rem(padding-top, 32);
        @include rem(margin-top, 32);
        .component-checkbox {
          .round-type {
            flex-shrink: 0;
            @include rem(margin-right, 12);
          }
          .checkbox-txt {
            margin: 0;
          }
        }
        p {
          @include f14(500);
          @include rem(padding-left, 0);
          & + p {
            @include rem(margin-top, 8);
          }
        }
      }
    }

    .missionary-sponsor-agreement {
      @include rem(margin-top, 72);
      .component-tab {
        @include rem(margin, 32 0);
        .tab-head {
          justify-content: flex-start;
        }
      }
    }

    .missionary-sponsor-list {
      @include rem(margin-top, 72);

      .tbl-top-area {
        @include rem(margin-top, 24);
      }
      .total-list {
        border-top: 0;
        .list-items {
          display: block;
          align-items: center;
          border-bottom: 0;
          @include rem(padding, 0 24);
          .s-date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-right: 0;
            @include rem(margin-bottom, 16);
            @include f18(700);
            .btn-sponsor_amount {
              @include rem(width, 32);
              @include rem(height, 32);
              @include rem(margin-right, 8);
              background: url("pathImages/icon/ico_sponsor_amount.png")
                no-repeat left top;
              background-size: 100%;
            }
          }
          .amount-wrap {
            display: block;
            flex: 1;
          }
          .amount-list {
            width: 100%;
            overflow: hidden;
            li {
              display: block;
              @include rem(padding, 16 0);
              dl {
                display: block;
                width: 100%;
                @include f14(500);
                span {
                  color: $font-777777;
                  @include f14(500);
                }
                strong {
                  @include rem(margin-left, 8);
                  color: $font-000000;
                  @include f14(500);
                }
                dt {
                  display: flex;
                  justify-content: space-between;
                }
                dd {
                  @include rem(margin-top, 12);
                  display: flex;
                  justify-content: space-between;
                }
              }
              & + li {
                border-top: 1px solid $line-e0e0e0;
              }
            }
          }
          .btn-area {
            @include rem(margin, 8 0 0 0);
            text-align: center;
          }
          & + .list-items {
            @include rem(margin-top, 40);
          }
        }
      }
      .nodata-wrap {
        @include rem(margin, 32 24 0 24);
      }
    }

    .missionary-sponsor-view {
      @include rem(margin, 65 0 0 0);
      .missionary-sponsor-view-inner {
        width: 100%;
        height: auto;
        @include rem(padding, 72 24 0 24);
      }
      .txt-info {
        .tit {
          @include f24(500);
          @include rem(margin-bottom, 0);
        }
        .txt {
          @include rem(margin-top, 24);
          @include f16(500);
        }
      }
      .img-zone {
        position: static;
        @include rem(margin-top, 64);
      }
      .component-btns {
        @include rem(margin-top, 48);
        justify-content: flex-start;
      }
    }
  }

  // 전파선교사 나의 후원 기록 : mo
  .sponsorship-record-wrap {
    @include rem(min-height, 564);
    @include rem(padding, 40 24);
    background: url("pathImages/missionary/bg_book_mo.png") no-repeat left top;
    background-size: cover;
    .component-select.type-custom {
      @include rem(margin-bottom, 40);
      .select-box {
        border: 1px solid $line-ffffff;
        color: $font-ffffff;
        background-color: $bg-transparent;
        opacity: 0.8;
        &::after {
          background-image: url("pathImages/icon/ico_select_arrow_transparent.png");
        }
      }
    }
    .sponsorship-record-inner {
      @include rem(width, 304);
      @include rem(min-height, 420);
      @include rem(padding, 47 32);
      margin: 0 auto;
      background: url("pathImages/missionary/img_book_bg_mo.png") no-repeat left
        top;
      background-size: 100%;
    }

    .record-writing {
      position: relative;
      @include rem(padding, 0);

      .month {
        @include rem(padding-bottom, 17);
        @include rem(margin-bottom, 12);
        @include f10(400);
        color: $font-000000;
      }
      .writing-area {
        .revision {
          @include f10(400);

          &:empty {
            @include rem(height, 12);
          }
        }
        .txt-writing {
          overflow: auto;
          @include rem(max-height, 230);
          @include rem(margin-top, 16);
          @include f16(400);
        }
      }

      .record-animation {
        // display: none;
      }
    }
    .swiper-books {
      position: relative;
      .swiper-controls {
        position: static;
      }

      // 좌, 우 버튼
      .swiper-navigation {
        position: absolute;
        @include rem(top, 0);
        @include rem(right, 0);
        z-index: 4;

        [class^="swiper-button"] {
          position: static;
          @include rem(width, 20);
          @include rem(height, 20);
        }
        .swiper-button-prev {
          background-image: url("pathImages/btn/btn_swiper_sub_l.png");
        }
        .swiper-button-next {
          @include rem(margin-left, 16);
          background-image: url("pathImages/btn/btn_swiper_sub_r.png");
        }
      }
    }

    br {
      display: block !important;
    }
  }

  //  전파선교사 변경 : mo
  .support-modify {
    .board-view {
      @include rem(margin-top, 40);
    }
    .board-write-line {
      @include rem(margin-top, 72);
    }
  }

  // 나의후원 로그인 : mo
  .support-login-wrap {
    @include rem(margin-top, 40);
    .login-check {
      dl {
        display: block;
        dt {
          @include f24(500);
          @include rem(margin-bottom, 24);
        }
        dd {
          @include f14(500);
          @include rem(padding-left, 10);
          @include rem(text-indent, -10);
        }
      }
      .round-bx {
        @include rem(margin-top, 8);
        @include rem(padding, 24 16);
        .component-checkbox {
          .round-type {
            @include rem(width, 20);
            @include rem(margin-right, 12);
            flex-shrink: 0;
          }
          .checkbox-item {
            position: relative;
            .checkbox-txt {
              margin: 0;
            }
            .ico-terms-arrow {
              @include rem(margin-left, 0);
            }
          }
        }
      }
    }

    .login-info {
      @include rem(margin-top, 72);
      h3 {
        @include f24(400);
        @include rem(margin-bottom, 24);
      }
    }
  }

  // 전파선교 후원가이드 : mo
  .suport-guide-wrap {
    @include rem(margin-top, 72);
    .guide-swiper-outer {
      overflow: hidden;
      position: relative;
      &::before {
        display: none;
      }
    }
    .guide-swiper {
      position: relative;
      width: auto;
      @include rem(height, 382);
      background-color: transparent;
      @include rem(margin, 0 24);
      // @include rem(margin, 0 24);
      // @include rem(border-radius, 8);
      .swiper-container {
        position: relative;
        @include rem(height, 382);
        overflow: visible;
        box-shadow: none;
      }
    }
    .swiper-slide {
      overflow: hidden;
      @include rem(border-radius, 8);
    }
    .slide-item {
      display: block;
      height: 100%;
      @include rem(padding, 64 24 0 24);
      .txt-info {
        .tit {
          @include f20(500);
          @include rem(margin-bottom, 16);
        }
        .txt {
          @include f14(500);
        }
        .info-area {
          position: static;
          @include rem(margin-top, 24);
          img {
            @include rem(width, 56);
          }
          dl {
            @include f16(500);
          }
          p {
            @include f16(700);
            br {
              display: block !important;
            }
          }
        }
        .btn {
          position: static;
          @include rem(margin-top, 24);
        }
      }
      .img-zone {
        @include rem(margin-top, 18);
        position: absolute;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        img {
          width: 100%;
        }
      }
    }

    .swiper-autoplay-wrap,
    .swiper-pagination {
      position: absolute;
      @include rem(right, 16);
      @include rem(top, 16);
      left: auto;
      z-index: 5;
    }

    .swiper-pagination {
      @include rem(right, 56);
    }

    .swiper-navigation {
      display: none;
      .swiper-button-prev {
      }
      .swiper-button-next {
      }
    }

    .suport-guide-info {
      max-width: 1400px;
      margin: 0 auto 80px;

      .guide-section {
        @include rem(margin-top, 72);
        @include rem(padding, 0 24);
        .hgroup-component {
          margin: 0;
        }
        .board-write-line {
          @include rem(margin-top, 24);
        }
        .component-accordion {
          @include rem(margin, 40 0 0 0);
        }
      }
      .donation-wrap {
        @include rem(margin-top, 56);
        position: relative;
        .btn {
          position: static;
          @include rem(margin-top, 48);
        }
      }
    }
  }

  // 이달의 말씀 pop :mo
  .month-words {
    .words-revision {
      br {
        display: block !important;
      }
    }
    .modal-container {
      height: auto;
      padding: 0;
      @include rem(margin, 0 24);
      background: transparent;
      .modal-content {
        @include rem(max-height, max-content);
        @include rem(min-height, auto);
        @include rem(padding, 0);
        overflow: hidden;
        .words-zone {
          position: relative;
          @include rem(padding-top, 0);
          .imgbx {
            img {
              width: 100%;
            }
          }
          .txt-info {
            position: absolute;
            @include rem(height, 330);
            @include rem(top, 56);
            @include rem(padding, 0 32);
            overflow-y: scroll;
            overflow-x: hidden;
          }
          .words-tit {
            display: block;
            @include rem(margin-bottom, 24);
            @include f18(700);
          }
          .words-txt {
            @include f16(400);
          }
          .words-revision {
            @include rem(margin-top, 24);
            @include f16(400);
          }
        }
        .modal-close {
          @include rem(right, 24);
          @include rem(top, 24);
        }
      }
    }
  }

  // scroll-body : mo
  .scroll-body {
    overflow-x: inherit;
    width: 100%;
  }

  // 외부 솔루션
  #zipcodeWrap {
    position: absolute !important;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;
  }
}
