@use "../abstracts" as *;

.m-0 {
  margin: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.ms-0 {
  margin-inline-start: 0;
}
.me-0 {
  margin-inline-end: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
