@use "../abstracts" as *;

// Alert에만 사용
.no-scroll {
  overflow: hidden;
}

.component-dialog {
  display: none;

  .dialog-dimm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 100;
    z-index: 101;
    opacity: 0.7;
    display: none;
    //animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: rgba(0, 0, 0, 0.6);
  }
  .dialog-frame {
    position: fixed;
    inset: 0 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
  }

  .dialog-container {
    margin: auto;
    @include rem(padding, 96 30 24 30);
    display: flex !important;
    justify-content: space-between !important;
    @include rem(width, 495);
    @include rem(min-height, 280);
    @include rem(border-radius, 8);
    background-color: $bg-ffffff;
    @include f18;
    flex-direction: column;
    align-items: center;
    .dialog-header {
      text-align: center;
      .dialog-tit {
        @include rem(border-radius, 10);
        color: $font-333333;
        @include f16;
        &:empty {
          display: none;
        }
      }
    }
    .dialog-content {
      // @include rem(margin-top, 60);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      .dialog-info {
        @include f24(500);
        color: $font-000000;
      }
      .alert {
        display: none;
      }
    }

    .btn-group {
      margin-top: auto;
      display: flex;
      width: 100%;
      justify-content: center;
      @include rem(margin-top, 64);
      .btn {
        display: block;
        width: 100%;
        @include rem(max-width, 220);
        @include rem(height, 60);
        @include f20(500);
        @include rem(border-radius, 4);
        color: $font-ffffff;
        &.btn-white {
          color: $font-000000;
        }
        &.btn-close {
          color: $font-333333;
          background-color: $bg-eeeeee;
        }
        & + .btn {
          @include rem(margin-left, 8);
        }
      }
    }

    .dialog-close {
      position: absolute;
      @include rem(top, 24);
      @include rem(right, 24);
      @include rem(width, 48);
      @include rem(height, 48);
      background: url("pathImages/btn/btn_modal_close.png") no-repeat 0 0 / 100%
        auto;
      pointer-events: all;
      z-index: 5;
    }
  }

  &.youtube-dialog {
    .dialog-container {
      @include rem(width, 1280);
      max-height: calc(100% - 10px);
      max-width: calc(100% - 120px);
      @include rem(padding, 0);
      border-radius: 0;

      .dialog-content {
        overflow: hidden;
        max-height: 720px;
        @include rem(padding, 0);
      }
    }

    .youtube-zone {
      // @include rem(height, 760);
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .dialog-close {
      position: absolute;
      @include rem(top, 0);
      @include rem(right, -64);
      background-image: url("pathImages/btn/btn_youtube_modal_close.png");
    }
  }

  &.jplayer-dialog {
    .dialog-container {
      @include rem(width, 640);
      max-height: calc(100% - 10px);
      max-width: calc(100% - 120px);
      @include rem(padding, 0);
      border-radius: 0;

      .dialog-header {
        width: 100%;
        @include rem(padding, 32 32 32 32);
        text-align: left;
        background-color: $bg-000000;
        .audio-tit {
          @include f24(700);
          color: $font-ffffff;
        }
        .video-tit {
          @include f24(700);
          color: $font-ffffff;
        }
        .video-txt {
          @include rem(margin-top, 8);
          span {
            @include f18(400);
            color: $font-777777;

            & + span {
              &::before {
                content: "";
                display: inline-block;
                width: 1px;
                @include rem(height, 12);
                @include rem(margin, 0 8);
                background-color: $bg-777777;
              }
            }
          }
        }
        .video-btn {
          position: absolute;
          @include rem(right, 32);
          @include rem(top, 32);
          // overflow: hidden;
          @include rem(width, 32);
          @include rem(height, 32);
          // width: 32px;
          // height: 32px;
          &::before {
            content: "";
            display: inline-block;
            @include rem(width, 32);
            @include rem(height, 32);
            // @include rem(margin-right, 4);
            background: url("pathImages/jplayer/icon/ico_church.png") no-repeat
              center center;
            background-size: 100%;
            @include rem(vertical-align, -3);
          }
        }
      }
      .dialog-content {
        overflow: hidden;
        // max-height: 768px;
        @include rem(padding, 0);
      }
    }

    .jplayer-zone {
      position: relative;
      .component-jplayer {
        .jp-jplayer {
          @include rem(min-height, 360);
          height: 1px;
          img {
            height: 100%;
            vertical-align: top;
          }
        }
        &.audio-player {
          .jp-jplayer {
            @include rem(min-height, 320);
          }
        }
      }
    }

    .dialog-close {
      position: absolute;
      @include rem(top, 0);
      @include rem(right, -64);
      background-image: url("pathImages/btn/btn_youtube_modal_close.png");
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .component-dialog {
    .dialog-container {
      @include rem(width, 312);
      @include rem(height, 208);
      @include rem(padding, 24);
      .dialog-content {
        align-items: end;
        .dialog-info {
          @include f16(500);
          br {
            display: block;
          }
        }
      }
      .btn-group {
        .btn {
          @include rem(height, 48);
          @include f18(700);
          & + .btn {
            @include rem(margin-left, 8);
          }
          &:focus-visible {
            outline: none;
          }
        }
      }
      .dialog-close {
        @include rem(width, 20);
        @include rem(height, 20);
        background-image: url("pathImages/btn/btn_modal_close_mo.png");
      }
    }

    // Youtube : mo
    // &.youtube-dialog {
    //   .dialog-container {
    //     justify-content: center;
    //     width: 100%;
    //     height: auto;
    //     max-height: 100%;
    //     max-width: 100%;
    //     .dialog-content {
    //       height: auto;
    //       .youtube-zone {
    //         position: relative;
    //         padding-top: 56.25%;
    //         height: auto;
    //         iframe {
    //         }
    //       }
    //     }
    //   }

    //   .dialog-close {
    //     position: absolute;
    //     @include rem(width, 20);
    //     @include rem(height, 20);
    //     @include rem(top, -36);
    //     @include rem(right, 24);
    //     background-image: url("pathImages/btn/btn_youtube_modal_close_mo.png");
    //   }
    // }

    &.youtube-dialog {
      .dialog-container {
        justify-content: center;
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        min-height: auto;
        .dialog-content {
          display: block;
          height: auto;
        }
      }
      .youtube-zone {
        position: relative;
        // padding-top: 56.25%;
        height: auto;
        iframe {
        }
      }
      .dialog-close {
        position: absolute;
        @include rem(width, 20);
        @include rem(height, 20);
        @include rem(top, -36);
        @include rem(right, 24);
        background-image: url("pathImages/btn/btn_youtube_modal_close_mo.png");
      }
    }

    &.jplayer-dialog {
      .dialog-container {
        justify-content: center;
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        min-height: auto;

        .dialog-header {
          @include rem(padding, 16);
          .audio-tit {
            @include f18(700);
          }
          .video-tit {
            @include f18(700);
          }
          .video-txt {
            @include rem(margin-top, 0);
            span {
              @include f14(400);
            }
          }
          .video-btn {
            @include rem(top, 16);
          }
        }
        .dialog-content {
          display: block;
          height: auto;

          // 가로모드 스크롤 test
          overflow: auto;
        }
      }
      .jplayer-zone {
        position: relative;
        height: auto;

        .component-jplayer {
          .jp-jplayer {
            min-height: auto;
            height: 100%;
            font-size: 0;
            img {
              min-height: auto;
            }
          }
          &.audio-player {
            .jp-jplayer {
              min-height: auto;
              height: calc(100% - 46px);
            }
          }
        }
      }

      .dialog-close {
        position: absolute;
        @include rem(width, 20);
        @include rem(height, 20);
        @include rem(top, -36);
        @include rem(right, 24);
        background-image: url("pathImages/btn/btn_youtube_modal_close_mo.png");
      }
    }
  }
}
