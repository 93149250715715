@use "../abstracts" as *;

/* component checkbox */
.component-checkbox {
  @include rem(width, 100%);
  display: inline-flex;

  // checkbox 공통 - inner 영역
  .checkbox-inner {
    display: flex;
    align-items: center;
  }

  // checkbox 공통 - 텍스트 영역
  .checkbox-txt {
    @include f18(400);
    @include rem(margin-left, 8);
    cursor: pointer;
    color: $font-000000;

    & + [class^="ico-"] {
      @include rem(margin-left, 8);
      vertical-align: top;
    }
  }

  // checkbox input 속성 : checked, disabled, checked+disabled
  input[type="checkbox"] {
    background-image: url("pathImages/icon/ico_checkbox_off.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% auto;
    @include rem(background-size, 8 7);
    background-color: transparent;
    appearance: none;
    outline: none;
    @include rem(width, 20);
    @include rem(height, 20);
    border: 1px solid $line-999999;
    @include rem(border-radius, 2);
    cursor: pointer;
    &.round-type {
      border-radius: 100%;
    }
    &:checked {
      background-image: url("pathImages/icon/ico_checkbox_on.png");
      background-color: $bg-fe508b;
      border: 1px solid $line-fe508b;
    }

    &:disabled {
      background-color: $bg-e0e0e0;
      border: 1px solid $line-e0e0e0;
      cursor: not-allowed;
      & + .checkbox-item {
        .checkbox-txt {
          color: gray;
          cursor: not-allowed;
        }
      }
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  // login
  &.type-more {
    @include rem(margin-top, 16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .checkbox-txt {
      @include f16(500);
      color: $font-777777;
    }
    ul {
      display: flex;
      align-items: center;
      li {
        a {
          @include f16(500);
          color: $font-777777;
        }
      }
    }
  }
  // chip
  &.type-chips {
    position: relative;
    display: inline-block;
    font-size: 0;
    height: auto;
    @include rem(margin, 0 10);
    @include rem(border-radius, 15);
    label {
      position: relative;
      display: inline-flex;
      background-color: $bg-eeeeee;
      color: $font-333333;
      @include rem(padding, 8 16 8 30);
      @include rem(border-radius, 32);
      @include f14;
      &::before {
        content: "";
        position: absolute;
        @include rem(left, 8);
        background-image: url("pathImages/icon/ico_check_default.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 10px auto;
        border: none;
        @include rem(width, 16);
        @include rem(height, 11);
      }
      span {
        line-height: normal;
        font-weight: 700;
      }
    }
    input {
      position: absolute;
      width: 100%;
      @include rem(height, 32);
      top: 0;
      transform: translate(0);
      border: 0;
      &:checked {
        border-color: transparent;
        background-color: transparent;
        & + label {
          color: $font-ffffff;
          background-color: $font-4051b8;
        }
      }
    }
  }
}

/* component-agree */
.component-agree {
  .agree-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .component-checkbox {
      width: 100%;
    }
    .btn-link {
      position: relative;
      display: inline-block;
      @include rem(width, 22);
      @include rem(height, 22);
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        @include rem(width, 6);
        @include rem(height, 10);
        background: url("pathImages/icon/ico_arrow_r.png") no-repeat center /
          100%;
      }
    }
    & + .agree-item {
      margin-top: 10px;
    }
  }
  .agree-box {
    margin-top: 16px;
  }

  &.terms-type {
    .agree-item {
      & + .agree-item {
        @include rem(margin-top, 16);
      }
      .component-checkbox {
        input {
          flex-shrink: 0;
        }
      }
    }
    .checkbox-inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
    .checkbox-item {
      display: flex;
      align-items: center;
      @include rem(margin-left, 11);
      .checkbox-txt {
        margin: 0;
        &::after {
          display: inline-block;
          content: "";
          @include rem(width, 24);
          @include rem(height, 24);
          @include rem(margin-left, 4);
          background: url("pathImages/icon/ico_agree_arrow.png") no-repeat
            center / 100%;
          vertical-align: middle;
        }
      }
    }
  }
}

/* component checkbox switch */
.component-checkbox-switch {
  display: inline-flex;

  // switch 공통 - inner 영역
  .switch-inner {
    display: flex;
    align-items: center;
  }

  // switch 타이틀
  .switch-label {
    @include f14;
    @include rem(margin-left, 5);
  }

  // switch 스타일
  .switch-item {
    position: relative;
    @include rem(min-width, 44);
    @include rem(height, 28);
    @include rem(padding, 4 0);
    @include rem(border-radius, 20);
    background-color: $bg-e0e0e0;
    cursor: pointer;
  }

  // switch 버튼
  .switch-handle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include rem(left, 5);
    right: calc(100% - 2.5rem);
    @include rem(width, 20);
    @include rem(height, 20);
    background-color: $bg-ffffff;
    border-radius: 50%;
    transition: 0.2s ease;
  }

  // switch 내 텍스트
  .switch-txts {
    @include rem(padding, 0 5 0 30);
    .txt {
      display: block;
      width: 100%;
      height: 0;
      text-align: center;
      &:first-child {
        opacity: 0;
      }
    }
  }

  // switch input 속성 : checked, disabled, checked+disabled
  input[type="checkbox"] {
    appearance: none;
    outline: none;
    &:checked {
      & + .switch-item {
        background-color: $bg-fe508b;
        .switch-handle {
          background-color: $bg-ffffff;
          left: calc(100% - 2.5rem);
          @include rem(right, 5);
          transition: 0.2s ease;
        }
        .switch-txts {
          @include rem(padding, 0 30 0 5);
          :first-child {
            opacity: 1;
          }
          :nth-child(2) {
            opacity: 0;
          }
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      & + .switch-item {
        cursor: not-allowed;
        .switch-handle {
          background-color: gray;
        }
      }
    }
  }

  // switch 포커스
  &:focus-within {
    .switch-item {
      // outline: -webkit-focus-ring-color auto 1px;
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .component-checkbox {
    // checkbox 공통 - 텍스트 영역
    .checkbox-txt {
      @include f16(500);

      & + [class^="ico-"] {
      }
    }
  }

  // component-agree : mo
  .component-agree {
    .agree-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .component-checkbox {
        width: 100%;
      }
      .btn-link {
        position: relative;
        display: inline-block;
        @include rem(width, 22);
        @include rem(height, 22);
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          @include rem(width, 6);
          @include rem(height, 10);
          background: url("pathImages/icon/ico_arrow_r.png") no-repeat center /
            100%;
        }
      }
      & + .agree-item {
        margin-top: 10px;
      }
    }
    .agree-box {
      margin-top: 16px;
    }

    &.terms-type {
      .agree-item {
        & + .agree-item {
          @include rem(margin-top, 24);
        }
        .component-checkbox {
          input {
            flex-shrink: 0;
          }
        }
      }
      .checkbox-inner {
      }
      .checkbox-item {
        display: block;
        width: 80%;
        text-align: left;
        word-break: normal;
        white-space: normal;
        .checkbox-txt {
          @include f14(500);
          margin: 0;
          &::after {
            // display: inline-block;
            // content: "";
            // @include rem(width, 24);
            // @include rem(height, 24);
            // @include rem(margin-left, 2);
            // background: url("pathImages/icon/ico_agree_arrow.png") no-repeat
            //   center / 100%;
          }
        }
      }
    }
  }
}
