@use "../abstracts" as *;
// forum-type
.forum-type {
  // Header
  .header {
    border: 0;
    .gnb-wrap {
      justify-content: flex-end;
      @include rem(padding-right, 48);
      // logo
      .gnb-logo {
        .logo {
          @include rem(width, 194);
          @include rem(height, 48);
          background-image: url("pathImages/logo_forum.png");
        }
      }
      .gnb-area {
        .gnb-list {
          .one-depth {
            .two-depth-list {
            }
            a {
              @include rem(padding, 32);
              span {
                @include f18(700);
              }
            }
          }
        }
      }
    }
  }

  // 전체메뉴
  .allmenu {
    // position: fixed;
    // overflow: auto;
    // top: 0;
    // z-index: 100;
    // width: 100%;
    // height: 100%;
    height: auto;
    @include rem(padding, 0 24);
    .allmenu-logo {
      display: flex;
      align-items: center;
      @include rem(height, 56);
      @include rem(margin-bottom, 16);
      .logo {
        display: inline-block;
        @include rem(width, 97);
        @include rem(height, 24);
        background-image: url("pathImages/logo_forum_mo.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        margin: 0;
      }
    }

    .allmenu-area {
      @include rem(padding-bottom, 40);
      border-top: 0;
      .one-depth {
        border-bottom: 0;
        & > a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include rem(padding, 24 0);
          @include f18(700);
          .ico-allmenu-arrow {
            transform: rotate(0);
          }
        }
        &.open {
          @include rem(padding-bottom, 0);
          .ico-allmenu-arrow {
            display: none;
          }
          .two-depth-bx {
            display: block;
          }
        }
        & + .one-depth {
          border-top: 1px solid $line-e0e0e0;
        }
      }
      .btn-allmenu-close {
        position: absolute;
        @include rem(right, 26);
        @include rem(top, 18);
        @include rem(width, 20);
        @include rem(height, 20);
        background: url("pathImages/btn/btn_allmenu_close_forum.png") no-repeat
          0 0 / 100% auto;
      }
    }
  }
  // contents
  #content {
    @include rem(padding-bottom, 160);

    .inner-comm-bx {
      .inner-comm {
        padding-top: 0;

        .hgroup-component {
          .hgroup-tit {
            @include rem(margin-bottom, 80);
          }
          .hgroup-sub-tit {
            @include rem(margin-bottom, 40);
          }

          &.more-view-type {
            @include rem(margin-bottom, 40);

            .hgroup-sub-tit {
              margin-bottom: 0;
            }
          }
        }

        &.full {
          .hgroup-component {
            max-width: 1400px;
            margin: 0 auto;

            &.more-view-type {
              @include rem(margin-bottom, 40);
              .hgroup-sub-tit {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .details-view-wrap {
      @include rem(margin-top, 32);
    }
  }
  // breadcrumb
  .breadcrumb-zone {
    @include rem(margin, 80 auto 32 auto);
    .breadcrumb-list {
      justify-content: flex-start;
    }
  }

  .component-tab {
    @include rem(margin-top, 62);
    .tab-head {
      justify-content: flex-start;
      .tab-label {
        @include rem(height, 42);
        @include f24(400);
        &::before {
          display: none;
        }
        &::after {
          background-color: $bg-000000;
        }
        & + .tab-label {
          @include rem(margin-left, 24);
        }
      }
    }
  }

  // Forum footer
  .footer {
    position: relative;
    background-color: $bg-000000;
    @include rem(padding, 40 80);
    .footer-inner {
      .footer-logo {
        display: inline-block;
        @include rem(margin-bottom, 56);
        .logo {
          display: block;
          @include rem(width, 194);
          @include rem(height, 48);

          background: url("pathImages/contents/footer_logo_forum.png") no-repeat
            left top;
          background-size: 100%;
        }
      }
      .footer-info {
        display: flex;
        justify-content: space-between;
        @include f16(400);
        color: $font-ffffff;
      }
      .footer-link {
        position: absolute;
        @include rem(right, 80);
        @include rem(top, 40);
        width: auto;
        .btn-blank {
          display: inline-flex;
          @include rem(padding, 12 24);
          @include rem(border-radius, 4);
          border: 1px solid $line-ffffff;
          background-color: transparent;
          .btn-txt {
            @include f16(500);
            color: $font-ffffff;
          }
          .ico-blank {
            @include rem(width, 8);
            @include rem(height, 8);
            @include rem(margin-left, 24);
            background: url("pathImages/icon/ico_blank.png") no-repeat center
              center;
            background-size: 100%;
          }
          & + .btn-blank {
            @include rem(margin-left, 8);
          }
        }
      }
    }
  }

  .table-custom {
    form {
      & + ul {
        @include rem(margin-top, 32);
      }
    }
  }
}

/*========================================================================
	메뉴 : 포럼
========================================================================*/

// 서브메인 PC
.far-east-forum-wrap {
  .forum-visual-wrap {
    @include rem(padding-top, 120);
    .hgroup-component {
      @include rem(margin-bottom, 40);
      h2 {
        @include f120(600);
        @include rem(margin-bottom, 48);
      }
      .hgroup-txt {
        display: inline-block;
        @include f24(400);
        margin: 0;
      }
    }

    .forum-visual {
      position: relative;
      overflow: hidden;
      @include rem(margin, -72 0 0 0);
      max-width: 100%;
      .layout-row {
        align-items: flex-end;
        max-width: 1400px;
        margin: 0 auto;
      }
      .layout-col {
        width: 34%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: none;
        @include rem(padding-bottom, 80);
        .component-swiper {
          display: flex;
          flex-direction: column-reverse;
        }
        // sub swiper
        &.clone {
          .swiper-container {
            transform: translateX(-477px);
            // @include rem(width, 827);
            @include rem(width, 1043);
            z-index: 5;
            .swiper-wrapper {
              justify-content: space-between;
              .swiper-slide {
                @include rem(width, 329);
                @include rem(margin-right, 28);
                height: auto;
              }
            }
          }
          .control-exception {
            @include rem(margin-bottom, 80);
            @include rem(padding-right, 60);
          }
        }

        // main swiper
        &.really {
          display: block;
          position: relative;
          width: 66%;
          @include rem(height, 720);
          margin: 0;
          .clone-inner {
            position: absolute;
            right: 0;
            @include rem(right, -260);
            top: 0;
            @include rem(width, 1184);
            @include rem(height, 720);
          }
          .component-swiper {
            display: block;
            right: 0;
            top: 0;
            .swiper-container {
              // overflow: hidden;
              position: relative;
              z-index: 4;
              .swiper-slide {
                // @include rem(width, auto !important);
                height: auto;
              }
            }
          }
        }
      }
    }

    .swiper-custom-wrap {
      display: none;
    }
  }
  .forum-gallay-wrap {
    position: relative;
    max-width: 1400px;
    @include rem(margin, 206 auto 0 auto);
    .hgroup-component {
      .hgroup-tit {
        @include f40(700);
      }
    }
    .gallay-area {
      display: flex;
      .main-gallay {
        flex: 1;
        img {
          width: 100%;
          @include rem(height, 648);
          object-fit: cover;
        }
      }
      .sub-gallay {
        // flex-shrink: 0;
        @include rem(width, 329);
        @include rem(margin-left, 28);
        li {
          img {
            vertical-align: top;
            @include rem(height, 200);
            object-fit: cover;
          }
          & + li {
            @include rem(margin-top, 24);
          }
        }
      }
    }
  }
  .components-layout {
    max-width: 1400px;
    @include rem(margin, 160 auto 0 auto);
  }
}

// 회사개요
.promotion-wrap {
  display: flex;
  align-items: center;
  @include rem(margin-top, 120);
  .img-zone {
    flex-shrink: 0;
    @include rem(width, 679);
    @include rem(height, 400);
    a {
      display: block;
      &:focus,
      &:hover {
        .ico-play {
          transform: scale(1.2);
        }
      }
    }
  }
  .txt-info {
    @include rem(margin-left, 64);
    margin-left: 4%;
  }
}

// 회사인사말
.greeting-wrap {
  display: flex;
  .img-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rem(width, 760);
    @include rem(height, 760);
    background: $bg-eeeeee;
    flex-shrink: 0;
  }
  .txt-info {
    // @include rem(margin-left, 40);
    margin-left: 4%;
  }
}

// 갤러리 grid-wrap
.gallay-grid-wrap {
  .grid-wrap {
    @include rem(margin, 32 0 0 0);
    display: grid;
    grid-template-columns: repeat(3, 1fr); //갯수에 따라 수정해서 사용
    grid-template-rows: minmax(max-content, auto);
    @include rem(column-gap, 28);
    @include rem(row-gap, 80);
    .grid-list {
      a {
        display: block;
      }
      .tit {
        display: block;
        @include f20(500);
        @include rem(margin-bottom, 4);
      }
      .txt {
        display: block;
        @include f20(500);
      }
      .img-zone {
        @include rem(margin-bottom, 16);
      }
    }
  }
}

// 역대강사
.past-lecturers-wrap {
  max-width: 1400px;
  @include rem(margin, 0 auto);

  .component-swiper {
    position: relative;
    @include rem(margin-top, 62);
    .swiper-container {
      @include rem(margin, 0 47);
    }
  }

  .swiper-item {
    .img-zone {
      position: relative;
      @include rem(margin-bottom, 24);
      .label-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        @include rem(left, 24);
        @include rem(top, 24);
        z-index: 5;
        @include rem(height, 40);
        @include rem(padding, 8 16);
        @include f16(500);
        background: rgba($color: #000000, $alpha: 0.6);
        @include rem(border-radius, 80);
        color: $font-ffffff;
      }
    }
    .txt-info {
      .tit {
        @include f24(400);
        @include rem(margin-bottom, 8);
      }
      .date {
        @include f18(400);
      }
      .txt {
        @include rem(margin-top, 24);
        @include rem(padding-top, 16);
        border-top: 1px solid $line-e0e0e0;
        span {
          display: block;
          color: $font-777777;
          @include f16(500);
          & + span {
            @include rem(margin-top, 4);
          }
        }
      }
    }
  }

  .swiper-navigation {
    display: flex;
    [class^="swiper-button"] {
      @include rem(width, 24);
      @include rem(height, 24);
      @include rem(padding, 0);
      box-sizing: initial;
      @include rem(background-size, 24 24);
    }
    .swiper-button-prev {
      background-image: url("pathImages/btn/btn_swiper_forum_l.png");
    }
    .swiper-button-next {
      background-image: url("pathImages/btn/btn_swiper_forum_r.png");
    }
  }
}

// 연혁
.forum-history-wrap {
  max-width: 1400px;
  margin: 0 auto;
  @include rem(margin-top, 80);
  .history-list {
    .history-item {
      &:first-child {
        .list-zone {
          .list-item {
            li {
              .list-day {
                color: $font-fe508b;
              }
              .list-month {
                color: $font-000000;
              }
              .list-detail {
                color: $font-000000;
              }
            }
          }
        }
      }
    }
  }

  .history-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .tit-zone {
      width: 32.5%;
      position: sticky;
      top: 0;
      .year-bx {
        @include rem(margin-bottom, 48);
        strong {
          display: block;
          @include f104(600);
          & + strong {
            text-align: right;
          }
        }
      }
    }
    .list-zone {
      width: 57.5%;
      @include rem(padding-bottom, 120);
      .list-item {
        li {
          display: flex;
          @include rem(margin-top, 40);
          @include f24(400);
          color: $font-777777;
          .list-day {
          }
          .list-month {
            @include rem(margin-left, 16);
            flex-shrink: 0;
            @include rem(width, 186);
          }
          .list-detail {
          }
          & + li {
          }
        }
        &:first-child {
          li {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    & + .history-item {
      .tit-zone {
        @include rem(padding-top, 120);
      }
      .list-zone {
        @include rem(padding-top, 120);
        border-top: 1px solid $line-000000;
      }
    }
    &.on {
      .list-zone {
        .list-item {
          li {
            .list-day {
              color: $font-fe508b;
            }
            .list-month {
              color: $font-000000;
            }
            .list-detail {
              color: $font-000000;
            }
          }
        }
      }
    }
  }
}

// 소개
.forum-intro-wrap {
  @include rem(margin-top, 64);

  .intro-animated {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .txt-zone {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      @include rem(width, 567);
      // width: 29%;
      // @include rem(margin, 0 260 0 148);
      margin: 0 13% 0 7%;
      @include rem(padding, 0 0 80 0);
      @include f24(400);
      flex-shrink: 0;
      .intro-tit {
        @include f80(700);
        @include rem(margin-bottom, 48);
      }
      .intro-txt {
        @include f24(400);
      }
    }

    .img-zone {
      // @include rem(width, 945);
      width: 49.21%;
      flex-shrink: 0;
    }
  }

  .introduction {
    .copy {
      max-width: 1100px;
      @include rem(margin, 240 auto);
      @include f32(500);
      color: $font-c0c0c0;
    }
    .picture-view {
      display: flex;
      justify-content: space-between;
      .picture-l {
      }
      .picture-r {
        @include rem(margin-left, 28);
      }
      img {
        width: 100%;
      }
    }
    .greetings-info {
      max-width: 1162px;
      margin: 0 auto;
      @include rem(padding, 80 0 0 0);
      .tit {
        @include f40(700);
        @include rem(margin-bottom, 32);
      }
      .txt {
        p {
          @include f18(400);
          & + p {
            @include rem(margin-top, 16);
          }
        }
      }
      dl {
        display: flex;
        align-items: baseline;
        @include rem(margin-top, 80);
        dt {
          @include f20(500);
          @include rem(margin-right, 8);
          color: $font-777777;
        }
        dd {
          @include f24(400);
          + dt {
            @include rem(margin-left, 8);
          }
        }
      }
    }
  }
}
// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // forum-type
  .forum-type {
    // Header
    .header {
      width: 100%;
      padding: 0;
      .gnb-wrap {
        padding: 0;
        // logo
        .gnb-logo {
          .logo {
            @include rem(width, 97);
            @include rem(height, 24);
            background-image: url("pathImages/logo_forum_mo.png");
          }
        }
        .gnb-area {
          display: none;
        }
      }
    }

    // contents
    #content {
      @include rem(padding-bottom, 72);
      .inner-comm-bx {
        .inner-comm {
          @include rem(padding-top, 40);

          .hgroup-component {
            .hgroup-tit {
              @include rem(margin-bottom, 40);
            }
            .hgroup-sub-tit {
              @include rem(margin-bottom, 24);
            }

            &.more-view-type {
              @include rem(margin-bottom, 24);

              .hgroup-sub-tit {
                margin-bottom: 0;
              }
            }
          }
          &.full {
            .hgroup-component {
              max-width: 100%;
              @include rem(margin, 0 24);

              &.more-view-type {
                @include rem(margin-bottom, 24);

                .hgroup-sub-tit {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    // breadcrumb
    .breadcrumb-zone {
      @include rem(margin, 120 auto 32 auto);
      .breadcrumb-list {
        justify-content: flex-start;
      }
    }

    .component-tab {
      @include rem(margin-top, 40);
      .tab-head {
        justify-content: flex-start;
        .tab-label {
          @include rem(height, 30);
          @include f20(500);
          &::before {
          }
          &::after {
          }
          & + .tab-label {
            // @include rem(margin-left, 24);
          }
        }
      }
    }

    // Forum footer
    .footer {
      @include rem(padding, 40 24);
      .footer-inner {
        padding: 0;
        background-color: $bg-000000;
        .footer-logo {
          @include rem(margin-bottom, 40);
          .logo {
            @include rem(width, 146);
            @include rem(height, 36);
            background-image: url("pathImages/contents/footer_logo_forum_m.png");
          }
        }
        .footer-info {
          display: block;
          @include f14(700);
          address {
            @include rem(margin, 0 0 8 0);
            color: $font-777777;
            font-weight: 700;
          }
          .copyright {
            opacity: 1;
          }
        }
        .footer-link {
          position: static;
          width: 100%;
          display: flex;
          margin: 0;
          padding: 0;

          .btn-blank {
            flex: 1;
            justify-content: center;
            @include rem(height, 42);
            @include rem(padding, 12 15);
            @include rem(margin, 40 0 0 0);
            @include rem(border-radius, 4);
            .btn-txt {
              @include f12(700);
              color: $font-ffffff;
              word-break: keep-all;
            }
            .ico-blank {
              @include rem(margin-left, 16);
              // background: url("pathImages/icon/ico_blank.png") no-repeat center
              //   center;
              // background-size: 100%;
            }
          }
        }
      }
    }

    .table-custom {
      form {
        & + ul {
          @include rem(margin-top, 16);
        }
      }
    }
  }

  /*========================================================================
	메뉴 : 포럼 : mo
========================================================================*/

  // 서브메인 : mo
  .far-east-forum-wrap {
    .forum-visual-wrap {
      padding: 0;
      .hgroup-component {
        @include rem(margin-bottom, 0);
        h2 {
          @include f40(700);
          @include rem(margin-bottom, 16);
        }
        .hgroup-txt {
          display: inline-block;
          @include f16(500);
        }
      }

      .forum-visual {
        @include rem(margin, 56 0 0 0);
        max-width: 100%;
        .layout-row {
          overflow: hidden;
          flex-direction: row-reverse;
          align-items: flex-end;
        }
        .layout-col {
          flex: 1;
          width: 100%;
          padding: 0;

          .swiper-controls {
            @include rem(padding, 0 24);
            @include rem(margin-bottom, 24);
          }
          // sub swiper
          &.clone {
            .ui-sub-swiper {
              display: none;
            }
            .control-exception {
              @include rem(padding, 0);
              @include rem(margin, 0);
            }
          }

          // main swiper
          &.really {
            width: 100%;
            height: auto;
            .clone-inner {
              position: static;
              width: 100%;
              height: auto;
            }
            .component-swiper {
              display: flex;
              flex-direction: column-reverse;
            }
            .swiper-controls {
              display: none;
            }
          }
        }
      }

      .swiper-custom-wrap {
        display: none;
      }
    }
    .forum-gallay-wrap {
      position: relative;
      max-width: 100%;
      @include rem(margin, 72 0 0 0);
      .hgroup-component {
        .hgroup-tit {
          @include f24(500);
        }
      }
      .gallay-area {
        display: block;
        @include rem(padding, 0 24);
        .main-gallay {
          @include rem(margin-bottom, 8);
          img {
            width: 100%;
            height: auto;
          }
        }
        .sub-gallay {
          width: 100%;
          @include rem(margin-left, 0);
          li {
            img {
              height: auto;
            }
            & + li {
              @include rem(margin, 8 0 0 0);
            }
          }
        }
      }
    }
    .components-layout {
      width: 100%;
      @include rem(margin-top, 72);
      .layout-col {
        & + .layout-col {
          @include rem(margin-top, 72);
        }
      }
      .list-comm-wrap {
        @include rem(padding, 0 24);
      }
    }
  }

  // 갤러리 grid-wrap : mo
  .gallay-grid-wrap {
    .grid-wrap {
      @include rem(margin, 24 0 0 0);
      display: block;

      .grid-list {
        a {
          display: block;
        }
        .tit {
          display: block;
          @include f16(500);
          @include rem(margin-bottom, 4);
        }
        .txt {
          display: block;
          @include f16(500);
        }
        .img-zone {
          @include rem(margin-bottom, 16);
        }

        & + .grid-list {
          @include rem(margin-top, 32);
        }
      }
    }
  }

  // 역대강사 : mo
  .past-lecturers-wrap {
    max-width: 100%;
    @include rem(margin, 0 auto);

    .component-swiper {
      position: relative;
      @include rem(padding, 0 24);
      @include rem(margin-top, 32);
      .swiper-container {
        @include rem(margin, 0);
        .swiper-wrapper {
          display: block;
          .swiper-slide {
            flex: 1;
            width: 100% !important;
            margin: 0;
            & + .swiper-slide {
              @include rem(margin-top, 56);
            }
          }
        }
      }
    }

    .swiper-item {
      .img-zone {
        position: relative;
        .label-tag {
          @include rem(left, 16);
          @include rem(top, 16);
          @include rem(height, 33);
          @include rem(padding, 6 12);
          @include f14(500);
          @include rem(border-radius, 33);
        }
      }
      .txt-info {
        .tit {
          @include f18(700);
          // @include rem(margin-bottom, 8);
        }
        .date {
          @include f16(500);
        }
        .txt {
          @include rem(margin-top, 16);
          @include rem(padding-top, 16);
          span {
            @include f14(500);
            & + span {
              @include rem(margin-top, 4);
            }
          }
        }
      }
    }

    .swiper-navigation {
      display: none;
    }
  }

  // 연혁 : mo
  .forum-history-wrap {
    @include rem(margin-top, 32);
    @include rem(padding, 0 24);
    .history-list {
    }

    .history-item {
      display: block;
      .tit-zone {
        width: 100%;
        position: static;
        @include rem(margin-bottom, 56);
        .year-bx {
          @include rem(margin-bottom, 16);
          strong {
            display: inline-block;
            @include f40(700);
            & + strong {
              text-align: left;
            }
          }
        }
      }
      .list-zone {
        width: 100%;
        @include rem(padding-bottom, 56);
        .list-item {
          li {
            display: block;
            @include rem(margin-top, 16);
            @include f18(700);
            color: $font-777777;
            .list-day {
              display: inline-block;
              color: $font-fe508b;
            }
            .list-month {
              display: inline-block;
              @include rem(margin-left, 8);
              flex-shrink: 0;
              width: auto;
              color: $font-000000;
            }
            .list-detail {
              width: 100%;
              @include rem(margin-top, 8);
              color: $font-000000;
              @include f16(500);
            }
            & + li {
            }
          }
          &:first-child {
            li {
              &:first-child {
                margin-top: 0;
              }
            }
          }
          & + .list-item {
            @include rem(margin-top, 32);
            border-top: 1px solid $line-e0e0e0;
          }
        }
      }

      & + .history-item {
        border-top: 1px solid $line-000000;
        .tit-zone {
          @include rem(padding-top, 56);
        }
        .list-zone {
          @include rem(padding-top, 0);
          border-top: 0;
        }
      }
    }
  }

  // 소개 : mo
  .forum-intro-wrap {
    @include rem(margin-top, 32);

    .intro-animated {
      display: block;
      .txt-zone {
        display: block;
        width: 100%;
        @include rem(margin, 0 0 40 0);
        @include rem(padding, 0 24);
        .intro-tit {
          @include f40(600);
          @include rem(margin-bottom, 16);
        }
        .intro-txt {
          @include f16(500);
        }
      }

      .img-zone {
        width: 100%;
        flex-shrink: 0;
      }
    }

    .introduction {
      .copy {
        max-width: 100%;
        @include rem(margin, 56 24);
        @include f20(500);
      }
      .picture-view {
        display: flex;
        justify-content: space-between;
        .picture-l {
          flex: 1;
          width: 60%;
          flex-shrink: 0;
        }
        .picture-r {
          @include rem(margin-left, 8);
          width: 20%;
          flex-shrink: 0;
        }
        img {
          width: 100%;
          @include rem(height, 214);
          object-fit: cover;
        }
      }
      .greetings-info {
        max-width: 100%;
        margin: 0;
        @include rem(padding, 40 24 0 24);
        .tit {
          @include f24(500);
          @include rem(margin-bottom, 16);
        }
        .txt {
          p {
            @include f16(500);
            & + p {
              @include rem(margin-top, 32);
            }
          }
        }
        dl {
          display: block;
          @include rem(margin-top, 40);
          dt {
            @include f14(500);
            @include rem(margin, 0 0 5 0);
          }
          dd {
            @include f20(500);
          }
        }
      }
    }
  }
}
