@use "../abstracts" as *;

/* component accordion */
.component-accordion {
  // accordion 공통 - item 영역
  .accordion-item {
    .collapse-content {
      height: auto;
      @include rem(padding, 30);
      border-bottom: 1px solid $line-e0e0e0;
    }

    &[data-state="open"] {
      .collapse-tit {
        &::before {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  // accordion 공통 - title 영역
  .collapse-tit {
    position: relative;
    width: 100%;
    @include rem(padding, 20);
    background-color: $bg-ffffff;
    @include f16(500);
    text-align: left;
    border-bottom: 1px solid $line-e0e0e0;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      @include rem(right, 10);
      transform: translateY(-50%);
      @include rem(width, 20);
      @include rem(height, 20);
      background: url("pathImages/icon/ico_accordion_arrow.png") no-repeat 0 0 /
        100% auto;
      transition: transform 0.35s;
    }
  }

  // accordion 공통 - content 영역
  .collapse-content {
    height: 0;
    @include rem(padding, 0 30);
    @include f12;
    color: $font-000000;
    overflow: hidden;
  }

  // FAQ
  &.faq-type {
    @include rem(margin-top, 32);
    // border-top: 1px solid $line-e0e0e0;
    .collapse-tit {
      display: flex;
      align-items: center;
      @include rem(padding, 32 16);
      @include f18(400);
    }
    .collapse-content {
      @include rem(padding, 32 16);
      @include f18(400);
      background-color: $bg-f7f8fd;

      .collapse-inner {
        display: flex;
      }
    }

    .answer-txt {
      p {
        & + p {
          @include rem(margin-top, 16);
        }
        .a-tit {
          display: block;
          @include rem(margin-bottom, 4);
          @include f16(700);
        }
      }
      .comm-bl-dot {
        @include rem(margin-top, 32);
        li {
          color: $font-000000;
          &::before {
            background-color: $bg-000000;
          }
          & + li {
            @include rem(margin-top, 4);
          }
        }
      }

      // 자주 묻는 질문
      .radio-frequency-list {
        @include rem(margin-top, 24);
        dt {
          @include f18(700);
        }
        dd {
          .comm-bl-dot {
            @include rem(margin, 16 0 0 0);
            li {
              @include f18(400);
            }
          }
        }
      }
    }
    [class^="ico-"] {
      display: flex;
      align-items: center;
      justify-content: center;
      @include rem(width, 32);
      @include rem(height, 32);
      @include rem(margin-right, 24);
      border-radius: 100%;
      flex-shrink: 0;
      &.ico-question {
        color: $font-ffffff;
        background-color: $bg-fe508b;
      }

      &.ico-answer {
        color: $font-ffffff;
        background-color: $bg-335891;
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /* component accordion */
  .component-accordion {
    // FAQ : mo
    &.faq-type {
      .collapse-tit {
        @include rem(padding, 24 40 24 0);
        @include f16(400);
      }
      .collapse-content {
        @include rem(padding, 24 16);
        @include f16(400);
        .collapse-inner {
          flex-wrap: wrap;
        }
      }
      .answer-txt {
        .comm-bl-dot {
          @include rem(margin-top, 16);
          li {
            &::before {
            }
            & + li {
              // @include rem(margin-top, 4);
            }
          }
        }

        // 자주 묻는 질문
        .radio-frequency-list {
          @include rem(margin-top, 32);
          dt {
            @include f16(700);
          }
          dd {
            .comm-bl-dot {
              li {
                @include f16(400);
              }
            }
          }
        }
      }
      [class^="ico-"] {
        display: none;
        width: auto;
        height: auto;
        @include rem(margin-right, 16);

        &.ico-question {
          background-color: transparent;
        }
        &.ico-answer {
          @include rem(margin, 0 0 16 0);
          @include f18(700);
          background-color: transparent;
        }
      }
      & + .component-btns {
        @include rem(margin-top, 48);
      }
    }
  }
}
