@use "../abstracts" as *;

// 개인정보처리방침
.febc-terms-wrap {
  @include rem(margin, 80 0 0 0);

  h3.term-tit {
    @include rem(margin, 80 0 8 0);
    @include f24(400);
  }
  h4 {
    @include f18(400);
    @include rem(margin, 32 0 0 0);
  }
  h5 {
    @include f18(400);
    @include rem(margin, 0 0 0 0);
  }
  .term-txt {
    @include f18(400);

    & + .term-tit {
      @include rem(margin-top, 40);
    }
  }
  .term-order-list {
    li {
      @include f18(400);
      ol {
        @include rem(margin-top, 8);
        // @include rem(padding-left, 24);
      }

      ul.comm-bl-dot {
        @include rem(margin, 0 0 0 10);
        li {
          @include f18(400);
        }
      }
    }
  }
  .trem-rule {
    @include rem(margin-top, 80);
    dt {
      @include f24(400);
    }
    dd {
      @include f18(400);
    }
  }

  ul.comm-bl-dot {
    @include rem(margin-top, 8);
    li {
      @include f18(400);
      color: $font-000000;
      & + li {
        @include rem(margin-top, 8);
      }
      &::before {
        background-color: $bg-000000;
      }
      ul {
        li + li {
          @include rem(margin-top, 0);
        }
      }
    }
  }
  .table-group {
    @include rem(width, 100%);
    table {
      @include rem(margin-top, 32);
      @include rem(width, 100%);
      thead {
        background-color: $bg-f7f8fd;
      }
      tr {
        border-top: 1px solid $line-e0e0e0;
        border-right: 1px solid $line-e0e0e0;
        border-left: 1px solid $line-e0e0e0;
        &:last-child {
          border-bottom: 1px solid $line-e0e0e0;
        }
        th,
        td {
          @include rem(padding, 24 0);
          text-align: center;
          & + td {
            border-left: 1px solid $line-e0e0e0;
          }
        }
        th {
          @include f18(700);
        }
        td {
          @include f16(500);
        }
      }
    }
  }
}

// 어린이합창단
.children-choir-wrap {
  .hgroup-component {
    @include rem(margin-bottom, 40);
  }
  [class*="chil-"] {
    @include rem(margin-top, 160);
  }
  .chil-inner {
    max-width: 1400px;
    margin: 0 auto;
  }

  .chil-visual {
    max-width: 1400px;
    @include rem(margin, 96 auto 0 auto);

    .img-zone {
    }

    .txt-info {
      display: flex;
      justify-content: space-between;
      @include rem(margin-top, 80);
      .hgroup-component {
        @include rem(width, 355);
        @include rem(margin-bottom, 0);
        flex-shrink: 0;
      }
      .desc {
        @include f18(400);
        @include rem(padding-left, 240);
        p + p {
          @include rem(margin-top, 32);
        }
      }
    }
  }

  .chil-video {
    @include rem(padding, 160 0);
    background-color: $bg-f7f8fd;

    .list-comm-wrap {
      @include rem(margin, 0);
      .gallary-type {
        grid-template-columns: repeat(3, 1fr);
        li {
          .list-tit {
            @include f24(400);
            @include rem(padding, 16 0 0 0);
            @include ellipsis(1);
            min-height: auto;
          }
        }
      }
    }
  }

  .chil-audition {
    position: relative;
    .audition-cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1400px;
      margin: 0 auto;

      .audition-txt {
        width: 49%;
        border-top: 1px solid $line-000000;
        dl {
          @include rem(padding, 32 24);
          border-bottom: 1px solid $line-e0e0e0;
          dt {
            @include rem(margin-bottom, 16);
            @include f18(700);
          }
          dd {
            @include f18(400);
          }
        }
      }

      .audition-img {
        position: absolute;
        right: 0;
        @include rem(top, 114);
        width: 48.3%;
        img {
          width: 100%;
        }
      }
    }
  }

  .chil-operate {
    .operate-list {
      display: flex;
      li {
        display: flex;
        align-items: flex-end;
        position: relative;
        width: 33.3%;
        @include rem(height, 448);
        @include rem(padding, 40);
        border: 1px solid $line-e0e0e0;
        @include rem(border-radius, 8);
        [class*="ico-"] {
          position: absolute;
          @include rem(right, 40);
          @include rem(top, 40);
          @include rem(width, 96);
          @include rem(height, 96);

          &.ico-operate01 {
            background-image: url("pathImages/icon/ico_operate01.png");
          }
          &.ico-operate02 {
            background-image: url("pathImages/icon/ico_operate02.png");
          }
          &.ico-operate03 {
            background-image: url("pathImages/icon/ico_operate03.png");
          }
        }
        dt {
          @include rem(margin-bottom, 24);
          @include f24(700);
        }
        dd {
          @include f18(400);
        }
        & + li {
          @include rem(margin-left, 28);
        }
      }
    }
  }

  .chil-contact-us {
  }

  .chil-praise {
  }
}

// 이미지보기 POP
.photo-pop {
  .modal-content {
    position: relative;
  }
  .component-swiper {
  }
  .swiper-controls {
    @include rem(margin-top, 24);
  }
  .photo-tit {
    position: absolute;
    @include rem(bottom, 70);
    @include f18(400);
  }
}

// 기부금 영수증 조회(본인인증)
.receipt-inquiry {
  @include rem(margin-top, 96);

  .inquiry-cont {
    h3 {
      @include f24(400);
      @include rem(margin-bottom, 24);
    }
  }
}

// 기부금 영수증 조회
.receipt-inquiry-detail {
  [class*="receipt-"] {
    @include rem(margin-top, 160);
  }
  .hgroup-component {
    @include rem(margin-bottom, 40);
  }
  .receipt-visual {
    display: flex;
    position: relative;
    @include rem(margin-top, 80);
    @include rem(padding, 88 43 0 120);
    background-color: $bg-fdf1f1;

    .hgroup-component {
      @include rem(width, 577);
      flex-shrink: 0;

      .hgroup-sub-tit {
        @include rem(margin-bottom, 24);
        @include f32(500);
      }
      .hgroup-sub-txt {
        @include f16(500);
        color: $font-777777;
      }
      .comm-bl-dot {
        @include rem(margin-top, 200);
        li + li {
          @include rem(margin-top, 0);
        }
      }
    }

    .img-zone {
    }
  }
  .receipt-document {
    position: relative;
    .hgroup-component {
      margin-bottom: 0;
    }
    .btn {
      position: absolute;
      right: 0;
      @include rem(bottom, 0);
    }
  }

  .receipt-call {
    .contact-list {
      li {
        dd {
          @include f18(400);
          span {
            min-width: auto;
          }
        }
      }
    }
  }
}
// 중보기도
.intermediate-view-wrap {
  // @include rem(max-width, 360);
  br {
    display: block;
  }
  .header {
    // @include rem(max-width, 360);
    .header-inner {
      @include rem(height, 80);
      h1 {
        @include rem(height, 100%);
        .logo {
          @include rem(width, 88);
          @include rem(height, 100%);
          display: inline-block;
          background: url("pathImages/logo_febc_mo.png") no-repeat left center;
          background-size: 100%;
        }
      }
    }
  }
  .contents {
    @include rem(padding, 128 0 72 0);
    .hgroup-bx {
      @include rem(padding, 0 24 314);
      .tit {
        @include f24(500);
      }
      .desc {
        @include f16(400);
        color: $font-335891;
        & + .tit {
          @include rem(margin-top, 16);
        }
      }
      background: url("pathImages/contents/febc_intermediate_img.png") no-repeat
        bottom center;
      background-size: 36rem 31.4rem;
    }
    .component-btns {
      .btn.btn-large {
        @include rem(min-width, auto);
        @include rem(width, 100%);
      }
    }
    // 중보기도 소개
    .prayer-bx {
      @include rem(padding, 0 24);
      text-align: center;
      .inner {
        @include rem(padding, 40 24);
        @include rem(border-radius, 8);
        background-color: $bg-f7f8fd;
      }
      .tit {
        @include f20(500);
        @include rem(margin-bottom, 16);
      }
      .txt {
        @include f16(400);
        color: $font-777777;
      }
      .btn-wrap {
        @include rem(margin-top, 40);
        .btn-txt {
          @include f18(700);
        }
      }
    }
    .real-time-bx {
      @include rem(margin-top, 72);
      @include rem(padding, 0 24);
      .hgroup-component {
        .hgroup-sub-tit {
          @include f24(500);
        }
      }
      .real-time-list {
        @include rem(margin-top, 16);
        .real-time-item {
          @include rem(padding, 16 0);
          display: flex;
          align-items: center;
          justify-content: space-between;
          & + .real-time-item {
            border-top: 1px solid $line-e0e0e0;
          }
          p {
            @include rem(padding-left, 28);
            @include f16(400);
            &.register {
              background: url("pathImages/icon/intermediate_register_img.png")
                no-repeat left center;
              background-size: 20px 20px;
            }
            &.reply {
              background: url("pathImages/icon/intermediate_reply_img.png")
                no-repeat left center;
              background-size: 20px 20px;
            }
          }
          span {
            @include f24(700);
            em {
              @include rem(padding-left, 4);
              @include f20(500);
            }
          }
        }
      }
    }
    .banner-bx {
      @include rem(margin-top, 72);
      @include rem(padding, 0 24);
      text-align: center;
      .inner {
        @include rem(width, 312);
        @include rem(padding, 64 34 220);
        @include rem(border-radius, 16);
        margin: 0 auto;
        background: url("pathImages/contents/intermediate_banner.png") no-repeat
          center center;
        background-size: 100%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
      p {
        @include rem(margin-bottom, 16);
        @include f20(500);
      }
      span {
        @include f16(400);
        color: $font-777777;
      }
    }
    // 중보기도 소개

    // 중보기도 신청
    .component-form {
      @include rem(padding, 0 24);
      .checkbox-txt {
        color: $font-777777;
      }
      .component-select.type-custom {
        .select-box {
          color: $font-777777;
        }
      }
      .input-label.required:after {
        display: none;
      }
    }
    .conditions {
      @include rem(margin-top, 24);
      p {
        @include f14(500);
      }
      a {
        @include rem(margin-top, 8);
        @include rem(padding, 16 24);
        @include rem(border-radius, 8);
        background-color: $bg-f7f8fd;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ico-allmenu-login {
          background-image: url("pathImages/contents/ico_allmenu_arrow_r.png");
        }
        .component-checkbox {
          align-items: center;
          input[type="checkbox"] {
            background-color: $bg-ffffff;
          }
          .checkbox-inner {
            @include rem(padding-left, 12);
            flex: 1;
            .checkbox-txt {
              @include f16(500);
              color: $font-000000;
              margin: 0;
            }
          }
        }
      }
    }
    // 중보기도 신청

    // 중보기도 완료
    .clear-info {
      @include rem(padding, 120 0 80);
      text-align: center;
      background: url("pathImages/icon/intermediate_clear.png") no-repeat top
        center;
      background-size: 88px 96px;
      .tit {
        @include rem(margin-bottom, 16);
        @include f24(500);
      }
      .txt {
        @include f16(400);
        color: $font-777777;
      }
    }
    .clear-link {
      @include rem(padding, 0 24);
      ul {
        li {
          & + li {
            @include rem(margin-top, 8);
          }
          &.youtube {
            a {
              i {
                background: url("pathImages/icon/intermediate_youtube.png")
                  no-repeat center center;
                background-size: 100%;
              }
            }
          }
          a {
            @include rem(width, 100%);
            @include rem(padding, 16 24);
            @include rem(border-radius, 8);
            background-color: $bg-f7f8fd;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .txt {
              @include f16(700);
            }
            i {
              @include rem(width, 56);
              @include rem(height, 56);
              background: url("pathImages/icon/intermediate_illust.png")
                no-repeat center center;
              background-size: 100%;
            }
          }
        }
      }
    }
    // 중보기도 완료
  }
  .footer {
    @include rem(padding, 40 24);
    position: static;
    width: 100%;
    margin-top: auto;
    background-color: $bg-333333;
    .logo-area {
      .logo {
        @include rem(height, 40);
        display: inline-block;
        img {
          @include rem(width, 100%);
          @include rem(height, 100%);
        }
      }
    }
    .address {
      @include rem(margin-top, 12);
      p {
        @include f14(500);
        color: $font-ffffff;
      }
    }
  }
}
// 중보기도 팝업
.component-modal.intermediate-pop {
  .modal-container {
    .modal-header {
      .modal-tit {
        @include f18(700);
      }
    }
    .modal-content {
      .txt {
        @include f16(400);
        color: $font-000000;
      }
      .comm-bl-dot {
        @include rem(margin-top, 16);
        & + .txt {
          @include rem(margin-top, 24);
        }
      }
      ul[class*="comm-bl-"].comm-bl-dot > li {
        color: $font-000000;
      }
    }
  }
}

// 에러페이지 404, 400, 500
.etc-error-wrap {
  @include rem(height, 100%);
  > div {
    @include rem(height, 100%);
    #content {
      padding: 0;
      margin: 0;
      max-width: 100%;
      > div {
        @include rem(height, 100%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .components-layout {
    [class^="layout-col"] {
      & + .layout-col {
        @include rem(margin-left, 0);
      }
    }
  }
  .hgroup-component {
    .hgroup-desc {
      @include rem(margin-top, 24);
    }
  }
  .layout-row {
    .img-box {
      img {
        margin: 0 80px 0 auto;
      }
    }
  }
}
// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // 개인정보처리방침 : mo
  .febc-terms-wrap {
    @include rem(margin, 40 0 0 0);

    h3.term-tit {
      @include rem(margin, 40 0 8 0);
      @include f18(400);
    }
    h4 {
      @include f16(400);
      @include rem(margin, 8 0 4 0);
    }
    h5 {
      @include f16(400);
      @include rem(margin, 8 0 4 0);
    }
    .term-txt {
      @include f16(400);
      & + .term-tit {
        @include rem(margin-top, 32);
      }
    }
    .term-order-list {
      li {
        @include f16(400);
        ol {
          // @include rem(margin-top, 8);
          // @include rem(padding-left, 24);
        }

        ul.comm-bl-dot {
          // @include rem(margin, 0 0 0 10);
          li {
            @include f16(400);
          }
        }
      }
    }
    .trem-rule {
      @include rem(margin-top, 40);
      dt {
        @include f18(500);
      }
      dd {
        @include f16(400);
      }
    }

    ul.comm-bl-dot {
      // @include rem(margin-top, 16);
      li {
        @include f16(400);
        & + li {
          // @include rem(margin-top, 8);
        }
        &::before {
          // background-color: $bg-000000;
        }
        ul {
          li + li {
            // @include rem(margin-top, 0);
          }
        }
      }
    }
    .table-group {
      position: relative;
      overflow-x: scroll;
      overflow-y: hidden;
      table {
        @include rem(width, 600);
        tr {
          th,
          td {
            @include rem(padding, 16 0);
          }
          th {
            @include f16(700);
          }
          td {
            @include f14(500);
          }
        }
      }
      .ico-network {
        @include rem(width, 128);
        @include rem(height, 32);
        @include rem(top, 50%);
        @include rem(left, 50%);
        transform: translate(-50%, -50%);
        position: absolute;
        background: url("pathImages/icon/ico_network.png") no-repeat;
        background-size: 100%;
        transition: opacity 0.5s;
      }
    }
  }
  // 어린이합창단 : mo
  .children-choir-wrap {
    .hgroup-component {
      @include rem(margin-bottom, 40);
    }
    [class*="chil-"] {
      @include rem(margin-top, 72);
    }
    .chil-inner {
      max-width: 100%;
    }

    .chil-visual {
      max-width: 100%;
      @include rem(margin, 40 auto 0 auto);
      @include rem(padding, 0 24);
      .img-zone {
      }

      .txt-info {
        display: block;
        @include rem(margin-top, 40);
        .hgroup-component {
          width: 100%;
          @include rem(margin, 0 0 24 0);
        }
        .desc {
          @include f16(400);
          @include rem(padding-left, 0);
          p + p {
            // @include rem(margin-top, 32);
          }
        }
      }
    }

    .chil-video {
      @include rem(padding, 72 0);
      .chil-inner {
        margin-top: 0;
      }
      .list-comm-wrap {
        @include rem(margin, 24 0 0 0);
        @include rem(padding, 0 24);
        .gallary-type {
          display: block;
          li {
            .list-tit {
              @include f16(400);
            }
          }
        }
      }
    }

    .chil-audition {
      position: relative;
      .audition-cont {
        display: block;
        max-width: 100%;
        @include rem(margin-top, 24);
        .audition-txt {
          width: 100%;
          @include rem(padding, 0 24);
          dl {
            @include rem(padding, 24 16);
            dt {
              @include f18(700);
            }
            dd {
              @include f16(400);
            }
          }
        }

        .audition-img {
          position: static;
          width: 100%;
          @include rem(margin-top, 32);
          img {
            width: 100%;
          }
        }
      }
    }

    .chil-operate {
      .operate-list {
        display: block;
        @include rem(padding, 24 24 0 24);
        li {
          display: flex;
          align-items: flex-end;
          position: relative;
          width: 100%;
          @include rem(height, 240);
          @include rem(padding, 24);
          [class*="ico-"] {
            position: absolute;
            @include rem(right, 24);
            @include rem(top, 24);
            @include rem(width, 64);
            @include rem(height, 64);

            // &.ico-operate01 {
            //   background-image: url("pathImages/icon/ico_operate01.png");
            // }
            // &.ico-operate02 {
            //   background-image: url("pathImages/icon/ico_operate02.png");
            // }
            // &.ico-operate03 {
            //   background-image: url("pathImages/icon/ico_operate03.png");
            // }
          }
          dt {
            @include rem(margin-bottom, 16);
            @include f18(700);
          }
          dd {
            @include f16(400);
          }
          & + li {
            @include rem(margin, 16 0 0 0);
          }
        }
      }
    }

    .chil-contact-us {
    }

    .chil-praise {
      overflow: hidden;
      .praise-swiper {
        @include rem(margin-top, 24);
        @include rem(padding, 0 24);
      }
      .swiper-container {
        overflow: visible;
        button {
          width: 100%;
        }
      }
    }
  }

  // 이미지보기 POP : mo
  .photo-pop {
    .modal-content {
    }
    .component-swiper {
    }
    .swiper-controls {
      @include rem(margin-top, 24);
      @include rem(padding, 0 24);
      .swiper-navigation {
        @include rem(right, 24);
      }
    }
  }

  // 기부금 영수증 조회 : mo
  .receipt-inquiry {
    @include rem(margin-top, 40);

    .inquiry-cont {
      h3 {
        @include f24(400);
        @include rem(margin-bottom, 24);
      }
    }
  }

  // 기부금 영수증 조회 : mo
  .receipt-inquiry-detail {
    [class*="receipt-"] {
      @include rem(margin-top, 72);
    }
    .hgroup-component {
      @include rem(margin-bottom, 24);
    }
    .receipt-visual {
      display: block;
      @include rem(margin-top, 40);
      @include rem(padding, 40 24);
      @include rem(border-radius, 8);
      .hgroup-component {
        width: 100%;

        .hgroup-sub-tit {
          @include rem(margin-bottom, 16);
          @include f20(500);
        }
        .hgroup-sub-txt {
          @include f16(400);
        }
        .comm-bl-dot {
          @include rem(margin-top, 32);
          li + li {
            @include rem(margin-top, 0);
          }
        }
      }

      .img-zone {
        @include rem(margin-top, 32);
      }
    }
    .receipt-document {
      position: relative;
      .hgroup-component {
        margin-bottom: 0;
      }
      .btn {
        position: static;
        @include rem(margin-top, 48);
      }
    }

    .receipt-call {
      .hgroup-component {
        @include rem(margin-bottom, 40);
      }
      .contact-list {
        @include rem(margin-top, 40);
        @include rem(padding, 0);
      }
    }
  }
  // 에러페이지 404, 400, 500

  .etc-error-wrap {
    > div {
      // @include rem(height, 100%);
      #content {
        // padding: 0;
        // margin: 0;
        // max-width: 100%;
        > div {
          // @include rem(height, 100%);
          // display: flex;
          // align-items: center;
          // justify-content: center;
        }
      }
    }
    .components-layout {
      [class^="layout-col"] {
        text-align: center;
        & + .layout-col {
          @include rem(padding, 0 24);
        }
      }
    }
    .hgroup-component {
      .hgroup-tit {
        @include rem(margin-top, 24);
      }
      .hgroup-desc {
        @include f16(400);
      }
    }
    .layout-row {
      .img-box {
        img {
          margin: 0 auto;
        }
      }
    }
    .component-btns {
      @include rem(width, 100%);
      @include rem(padding, 0 24);
      @include rem(bottom, 48);
      right: 0;
      left: 0;
      position: fixed;
    }
  }
}
