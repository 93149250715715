// ******************** code comments ********************
// 기본 변수 세팅
// $global-background-color: #ffffff; - 기본 배경 컬러 설정
// $global-font-color: #000000; - 기본 폰트 컬러 설정
// $global-font-size: 10px !default; - 기본 폰트 크기 설정
// ******************** code comments ********************
$global-background-color: #ffffff;
$global-font-color: #000000;
$global-font-size: 10px !default;

// ******************** code comments ********************
// 언어별 설정 <html lang="">
// font-familu, line-height, word-break, word-wrap, letter-spacing
// ******************** code comments ********************
$global-font-family: (
  // 'ko': ('Malgun Gothic', '맑은 고딕', Dotum, '돋움', arial, verdana, sans-serif),
  // 'en': (Helvetica, Arial, sans-serif),
  "ko":
    (
      "Pretendard Variable",
      Pretendard,
      -apple-system,
      BlinkMacSystemFont,
      system-ui,
      Roboto,
      "Helvetica Neue",
      "Segoe UI",
      "Apple SD Gothic Neo",
      "Noto Sans KR",
      "Malgun Gothic",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      sans-serif
    ),
  "en": (
    "Pretendard Variable",
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    "Helvetica Neue",
    "Segoe UI",
    "Apple SD Gothic Neo",
    "Noto Sans KR",
    "Malgun Gothic",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    sans-serif
  ),
  "ja": (
    Meiryo,
    "メイリオ",
    "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro",
    sans-serif
  ),
  "zh": (
    Helvetica,
    Arial,
    "Microsoft Yahei",
    "微软雅黑",
    STXihei,
    "华文细黑",
    sans-serif
  )
);
$global-line-height: (
  "ko": (
    1.15,
  ),
  "en": (
    1.15,
  ),
  "ja": (
    1.15,
  ),
  "zh": (
    1.15,
  ),
);
$global-word-break: (
  "ko": (
    keep-all,
  ),
  "en": (
    break-all,
  ),
  "ja": (
    break-all,
  ),
  "zh": (
    break-all,
  ),
);
$global-word-wrap: (
  "ko": (
    break-word,
  ),
  "en": (
    break-word,
  ),
  "ja": (
    break-word,
  ),
  "zh": (
    break-word,
  ),
);
$global-letter-spacing: (
  "ko": (
    -0.02em,
  ),
  "en": (
    -0.02em,
  ),
  "ja": (
    -0.02em,
  ),
  "zh": (
    -0.02em,
  ),
);

// ******************** code comments ********************
// z-index 세팅 및 관리
// 기본 z-index를 기준으로 +, - 하여 관리 한다
// 변수명은 되도록 직관적으로 네이밍 한다
// 프로젝트에서 사용되는 모든 z-index 값은 아래 정리 되어야 한다
// 단, z-index:1, z-index:2 등의 10이하 단위들은 컨텐츠내 css 에서 관리한다
// ******************** code comments ********************
$zindex-base: 0; // 기본 z-index
$zindex-loading: $zindex-base + 3200; // progress indicator, loading spinner
$zindex-notification: $zindex-base + 3100; // toast, snackbar
$zindex-modal: $zindex-base + 3000; // dialog, modal, lightbox
$zindex-floating: $zindex-base + 2500; // FAB, floating banner
$zindex-header: $zindex-base + 2000; // GNB, header
$zindex-side-area: $zindex-base + 1000; // drawer, panel, side navigation
$zindex-sticky: $zindex-base + 500; // anchor, tab, (sticky) banner
$zindex-picker: $zindex-base + 200; // menu, select, date/time picker, color picker content area
$zindex-guide: $zindex-base + 100; // tooltip, popover, coach mark
// ******************** code comments ********************
// spacing
// ******************** code comments ********************
// basic spacing - 4, 8배수 사용
$space04: 4;
$space08: 8;
$space12: 12;
$space16: 16;
$space24: 24;
$space32: 32;
$space40: 40;
$space64: 64;

// ******************** code comments ********************
// 컬러값 변수 설정
// 폰트컬러(color), 배경컬러(background-color), 라인컬러(border)으로 구분한다
// 변수 네이밍에 컬러 코드 값을 사용하여 작성한다
// 프로젝트 초반에 컬러값이 정리가 되지 않을 경우, 변수를 먼저 세팅하고 나중에 디자인팀에 현재 설정된 변수 컬러값을 전달하여 불필요한 컬러값들을 정리 하도록 한다
// ******************** code comments ********************
$font-000000: #000000;
$font-111111: #111111;
$font-ffffff: #ffffff;
$font-4051b8: #4051b8;
$font-333333: #333333;
$font-555555: #555555;
$font-777777: #777777;
$font-999999: #999999;
$font-fe3975: #fe3975;
$font-2c2c2c: #2c2c2c;
$font-f95a92: #f95a92;
$font-cbcbcb: #cbcbcb;
$font-ababab: #ababab;
$font-363853: #363853;
$font-1b5f84: #1b5f84;
$font-fe508b: #fe508b;
$font-335891: #335891;
$font-c0c0c0: #c0c0c0;
$font-763ba5: #763ba5;
$font-fea339: #fea339;

$bg-ffffff: #ffffff;
$bg-000000: #000000;
$bg-eeeeee: #eeeeee;
$bg-cccccc: #cccccc;
$bg-f7f8fd: #f7f8fd;
$bg-f9f9f9: #f9f9f9;
$bg-ffe53f: #ffe53f;
$bg-e1002a: #e1002a;
$bg-333333: #333333;
$bg-2b2b2b: #2b2b2b;
$bg-4051b8: #4051b8;
$bg-e5e5e5: #e5e5e5;
$bg-999999: #999999;
$bg-fe3975: #fe3975;
$bg-bfbfbf: #bfbfbf;
$bg-f0edeb: #f0edeb;
$bg-363853: #363853;
$bg-e0e0e0: #e0e0e0;
$bg-d9d9d9: #d9d9d9;
$bg-777777: #777777;
$bg-transparent: transparent;
$bg-1b5f84: #1b5f84;
$bg-fdf1f1: #fdf1f1;
$bg-fe508b: #fe508b;
$bg-335891: #335891;
$bg-5976A4: #5976a4;
$bg-763ba5: #763ba5;
$bg-fea339: #fea339;
$bg-dcdfed: #dcdfed;
$bg-ebf6ff: #ebf6ff;
$bg-fff1da: #fff1da;
$bg-c0c0c0: #c0c0c0;
$bg-eff3f7: #eff3f7;
$bg-ffdbe7: #ffdbe7;
$bg-444444: #444444;

$line-000000: #000000;
$line-555555: #555555;
$line-ffffff: #ffffff;
$line-1c1c1c: #1c1c1c;
$line-4051b8: #4051b8;
$line-ffe53f: #ffe53f;
$line-e1002a: #e1002a;
$line-e5e5e5: #e5e5e5;
$line-999999: #999999;
$line-fcdfe8: #fcdfe8;
$line-fff0f4: #fff0f4;
$line-fe3975: #fe3975;
$line-f8d7d3: #f8d7d3;
$line-e0e0e0: #e0e0e0;
$line-363853: #363853;
$line-1b5f84: #1b5f84;
$line-fe508b: #fe508b;
$line-fdf1f1: #fdf1f1;
$line-335891: #335891;
$line-5976A4: #5976a4;
$line-763ba5: #763ba5;
$line-fea339: #fea339;
// $line-ffb9d1: #ffb9d1;
$line-ffb9d1: #ffb1b1;
$line-ee0202: #ee0202;

// ******************** code comments ********************
// 프로젝트 별로 세팅 하기
// 폰트 굵기 참고용
// Thin 100
// Light 300
// Regular 400
// Medium 500
// Bold 700
// Black 900
// 프로젝트내에서 사용되는 폰트 굵기를 모아놓고 관리한다
// ******************** code comments ********************

// ******************** code comments ********************
// 프로젝트 별로 세팅 하기
// font size / line-height 참고
// 24 / 36 - 1.5
// 26 / 38 - 1.46
// 28 / 40 - 1.42
// 30 / 42 - 1.4
// 32 / 44 - 1.375
// 34 / 46 - 1.35
// 42 / 54 - 1.28
// 48 / 64 - 1.33
// ******************** code comments ********************

// ******************** code comments ********************
// flex 사용 참고용
// flex: 1 1 1; - 자동으로 더 커지게 하고 자동으로 더 줄어들게 하고 기본 값은 없음
// /* flex-grow: 1; flex-shrink: 1; flex-basis: 0%; */
// flex: 1 1 auto; - 자동으로 더 커지게 하고 자동으로 더 줄어들게 하고 기본값은 자동
// /* flex-grow: 1; flex-shrink: 1; flex-basis: auto; */
// flex: 1 1 500px; - 500보다 늘어날 수 있고 500이하로 줄어 들 수 있음, 기본값은 500
// /* flex-grow: 1; flex-shrink: 1; flex-basis: 500px; */

// /* flex-grow: 0; */ /* 기본값 - flex-basis 이상으로 안 늘어남 */
// /* flex-grow: 1; */ /* flex-basis 보다 늘어남 */
// flex-shrink: 1; /* 기본값 - flex-basis 크기 보다 줄어듬 */
// flex-shrink: 0; /* flex-basis 크기를 최소값으로 고정함 */
// ******************** code comments ********************

// ******************** code comments ********************
// box shadow 샘플
// box-shadow: 8px 8px 8px 0 rgba(0,0,0,0.05);
// PSD - box-shadow: 거리 거리 크기 스프레드 rgba(컬러,컬러,컬러,불투명도)
// 박스 inset - 보더대신 서야 할 때
// box-shadow: inset -1px 1px 0px 0px rgba(221,221,221,1);
// 프로젝트내에서 사용하는 box shadow 를 모아놓고 관리한다
// ******************** code comments ********************
