@use "../abstracts" as *;

/*========================================================================
	메뉴 : 이벤트
========================================================================*/

.event-swiper-wrap {
  position: relative;
  @include rem(height, 880);
  @include rem(padding-top, 56);
  @include rem(margin-bottom, 96);
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    @include rem(height, 766);
    // background: $bg-f7f8fd url("pathImages/contents/img_event_visual_bg.png")
    //   no-repeat center top;
    background-color: $bg-f7f8fd;
    background-size: 100%;
  }
  .event-swiper {
    position: relative;
    max-width: 1400px;
    @include rem(margin, 80 auto 0 auto);
    .swiper-slide {
      .swiper-item {
        display: flex;
        justify-content: space-between;
        outline-offset: -1px;
      }
    }
    .txt-info {
      @include rem(max-width, 500);
      .label-group {
        @include rem(margin-bottom, 16);
      }
      .tit {
        @include f40(700);
        @include rem(margin-bottom, 32);
        @include ellipsis(2);
      }
      .date-area {
        @include rem(margin-top, 112);
        dl {
          display: flex;
          dt {
            @include rem(margin-right, 16);
            @include f20(500);
            color: $font-777777;
          }
          dd {
            // &::before {
            //   content: "";
            //   display: inline-block;
            //   width: 1px;
            //   @include rem(height, 12);
            //   background-color: $bg-e0e0e0;
            //   @include rem(margin-right, 8);
            // }
            @include f20(500);
          }
          & + dl {
            @include rem(margin-top, 8);
          }
        }
      }
    }
    .img-zone {
      position: relative;
      @include rem(width, 567);
      @include rem(height, 720);
      .crrunt-ing {
        position: absolute;
        @include rem(right, 16);
        @include rem(top, 16);
        @include rem(padding, 8 16);
        @include f18(400);
        color: $font-fe508b;
        border: 1px solid $line-fe508b;
        @include rem(border-radius, 4);
        background-color: $bg-ffffff;
        z-index: 5;
      }
    }
  }

  .component-swiper {
    .swiper-controls {
      position: absolute;
      @include rem(bottom, 242);
      z-index: 5;
    }

    .swiper-navigation {
      position: absolute;
      @include rem(left, 592);
      @include rem(bottom, 236);
      @include rem(width, 88);
    }
  }
  & + .inner-comm {
    padding: 0;
  }
}
// event-wrap : pc
.event-wrap {
  .details-view-wrap {
    @include rem(margin-top, 0);
    .view-tit {
      justify-content: space-between;
    }

    .label-group {
      @include rem(margin-bottom, 16);
    }
    .view-date {
      @include rem(margin-top, 24);
      color: $font-777777;
    }
    .view-cont {
      @include rem(margin-top, 24);
    }
  }
  .component-tab {
    &.tab-round {
      // @include rem(margin-top, 96);
    }
    .txt-bx {
      @include f20(400);
    }
    .label-group {
      @include rem(margin-bottom, 24);
    }
    .day-bx {
      @include f18(400);
    }
  }
  .component-tooltip.dim-type {
    .tooltip-btn-trigger {
      @include rem(width, 100%);
      @include rem(height, 24);
      @include f16(700);
      position: relative;
      color: $font-000000;
      padding: 0;
      border: none;
      background: none;
      white-space: nowrap;
      &::before {
        @include rem(top, 50%);
        @include rem(left, -30);
        @include rem(width, 20);
        @include rem(height, 20);
        content: "";
        display: block;
        position: absolute;
        background: url("pathImages/icon/ico_tooltip_sm.png") no-repeat center
          center;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
  }
  // .component-tooltip {
  //   @include rem(margin-left, auto);
  //   .btn {
  //     @include rem(width, 32);
  //     @include rem(height, 32);
  //     padding: 0;
  //     border: 0;
  //     background: url("pathImages/icon/ico_tooltip.png") no-repeat center center;
  //     background-size: 100% 100%;
  //   }
  // }
  .event-info {
    @include rem(margin-top, 48);
    dl {
      display: flex;
      dt {
        @include rem(margin-right, 64);
        @include f20(500);
        color: $font-777777;
      }
      dd {
        @include f20(500);
      }
      & + dl {
        @include rem(margin-top, 16);
      }
    }
    .event-list {
      ul {
        display: flex;
      }

      li {
        .btn-branch {
          position: relative;
          @include rem(padding, 5 16);
          @include rem(border-radius, 4);
          background-color: $bg-f7f8fd;
          @include f20(500);
        }
        & + li {
          @include rem(margin-left, 8);
        }
      }
    }
  }
  .tel-tooltip {
    width: auto;
    @include rem(margin, 0);
    .tooltip-btn-trigger {
      width: auto;
      height: auto;
      background: none;
    }
    .tooltip-container {
      // @include rem(padding, 12);
      // @include rem(border-radius, 8);
      @include f14(500);
      color: $font-335891;
      width: auto;
      height: auto;
      white-space: nowrap;
      overflow: visible;
      left: 150%;
      &::after {
        content: "";
        @include rem(left, 26);
      }
    }
  }
}
// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /*========================================================================
	메뉴 : 이벤트 : mo
========================================================================*/

  .event-swiper-wrap {
    height: auto;
    @include rem(padding, 48 24 30 24);
    @include rem(margin-bottom, 80);
    // background: $bg-f7f8fd url("pathImages/contents/img_event_visual_bg_mo.png")
    //   no-repeat center top;
    background-color: $bg-f7f8fd;
    background-size: 100%;
    &::before {
      display: none;
    }
    .event-swiper {
      margin: 0;
      .swiper-slide {
        .swiper-item {
          display: block;
        }
      }
      .txt-info {
        .tit {
          @include f24(500);
          @include rem(margin-bottom, 48);
          @include rem(min-height, 70);
        }
        .date-area {
          @include rem(margin-top, 48);
          dl {
            dt {
              @include f16(500);
            }
            dd {
              @include f16(500);
            }
            & + dl {
            }
          }
        }
      }
      .img-zone {
        width: 100%;
        @include rem(height, auto);
        @include rem(margin-top, 112);
        @include rem(border-radius, 8);
        img {
        }
        .crrunt-ing {
          @include f14(500);
        }
      }
    }
    .component-swiper {
      .swiper-controls {
        @include rem(top, 330);
        bottom: auto;
      }

      .swiper-navigation {
        position: absolute;
        left: auto;
        right: 0;
      }
    }
    & + .inner-comm {
      @include rem(padding, 0 24);
      .table-custom {
        padding: 0;
        .tbl-count {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .component-form {
            width: auto;
            margin: 0;
          }
          .component-select {
            height: auto;
          }
          & + ul {
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
  // event-wrap : mo
  .event-wrap {
    .tel-tooltip-wrap {
      .tel-tooltip {
        display: none;
      }
    }
    .component-tab {
      &.tab-round {
        @include rem(margin-top, 8);
      }
    }
    .table-custom {
      .tbl-count {
        .component-select {
          @include rem(width, 110);
          @include rem(height, 40);
        }
      }
      .txt-bx {
        flex-direction: column-reverse;
        align-items: flex-start;
        @include f18(700);
      }
      .label-group {
        @include rem(margin-bottom, 16);
      }
    }

    .component-tooltip {
      .btn {
        position: absolute;
        right: 0;
        @include rem(top, 90);
      }

      .tooltip-container {
        // left: 178%;
        left: 0;
        transform: none;
        &.overflow-right {
          right: 0;
          left: auto;
          transform: none;
          &::after {
            @include rem(right, 15);
            left: auto;
            transform: none;
          }
        }
        &.overflow-left {
          right: auto;
          left: 0;
          transform: none;
        }
      }
    }
    .details-view-wrap {
      .component-tooltip.dim-type {
        @include rem(top, 140);
        @include rem(right, 24);
        position: absolute;
      }
      .view-date {
        span {
          color: $font-777777;
        }
      }
      .view-tit {
        @include f24(700);
        @include rem(padding-right, 0);
      }
      .view-cont {
        @include rem(margin-top, 32);
      }
    }
    .event-info {
      @include rem(margin-top, 72);
      dl {
        display: block;
        dt {
          @include rem(margin-right, 32);
          @include f16(500);
          color: $font-777777;
        }
        dd {
          @include f16(500);
          @include rem(margin-top, 8);
        }
        & + dl {
          @include rem(margin-top, 16);
        }
      }
      .event-list {
        ul {
          flex-wrap: wrap;
        }
        li {
          @include rem(margin, 8 8 0 0);
          .btn-branch {
            display: block;
            @include rem(height, 32);
            @include f16(500);
            @include rem(padding, 4 12);
            flex-shrink: 0;
          }
          & + li {
            @include rem(margin-left, 0);
          }
        }
      }
    }
  }
}
