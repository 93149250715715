@use "../abstracts" as *;

/* component radio */
.component-radio {
  display: inline-flex;

  // radio  공통 - inner 영역
  .radio-inner {
    display: flex;
    align-items: center;
    & + .radio-inner {
      @include rem(margin-left, 32);
    }
  }

  // radio 텍스트
  .radio-txt {
    @include f18;
    @include rem(margin-left, 12);
    cursor: pointer;
  }

  // radio input 속성 : checked, disabled, checked+disabled
  input[type="radio"] {
    @include ico-circle("%23999999");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    appearance: none;
    outline: none;
    @include rem(width, 20);
    @include rem(height, 20);
    border: 1px solid $bg-999999;
    border-radius: 50%;
    cursor: pointer;

    &:checked {
      @include ico-circle("%23ffffff");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      background-color: $bg-fe508b;
      border: 1px solid $bg-fe508b;
      & + .radio-item {
        .radio-txt {
          color: $font-000000;
        }
      }
    }

    &:disabled {
      background-color: $bg-e0e0e0;
      border: 1px solid $line-e0e0e0;
      cursor: not-allowed;
      & + .radio-item {
        .radio-txt {
          color: $font-000000;
          cursor: not-allowed;
        }
      }
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
      outline-offset: 1px;
    }
  }
}

// form-chips
.form-chips {
  display: flex;
  width: 100%;
  border: 1px solid $line-e0e0e0;
  @include rem(border-radius, 4);
  .component-radio {
    position: relative;
    flex: 1;
    border-right: 1px solid $line-e0e0e0;
    & + .component-radio {
      border-right: 0;
      input[type="radio"] {
        &:checked {
          & + .label-txt,
          & + label {
            @include rem(border-radius, 0 4 4 0);
          }
        }
      }
    }
  }
  input[type="radio"] {
    position: absolute;
    width: 100%;
    @include rem(height, 52);
    top: 0;
    transform: translate(0);
    border: 0;
    border-radius: 0;
    background: none;

    &:checked {
      & + .label-txt,
      & + label {
        border: 1px solid $line-000000;
        @include rem(border-radius, 4 0 0 4);
      }
      background: none;
      border: 0;
    }
  }
  .label-txt,
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include f16(500);
    @include rem(height, 52);
    text-align: center;
  }

  &.support-type {
    border: 0;
    .component-radio {
      flex-shrink: 0;
      flex: none;
      @include rem(width, 296);
      @include rem(height, 75);
      border-right: 0;
      & + .component-radio {
        @include rem(margin-left, 8);
      }
      & + .component-radio {
        input[type="radio"] {
          &:checked {
            & + .label-txt,
            & + label {
              @include rem(border-radius, 4);
              // .component-tooltip {
              //   .tooltip-btn-trigger {
              //     background-image: url("pathImages/icon/ico_sub_tooltip_white.png");
              //   }
              // }
            }
          }
        }
      }
    }

    input[type="radio"] {
      height: 100%;
      &:checked {
        & + .label-txt,
        & + label {
          border: 0;
          color: $font-ffffff;
          @include rem(border-radius, 4);
          background-color: $bg-fe508b;
        }
      }
      &:disabled {
        background-color: transparent;
        border: 1px solid $line-e0e0e0;
        @include rem(border-radius, 4);
        & + .label-txt,
        & + label {
          color: $font-999999;
          @include rem(border-radius, 4);
          background-color: $bg-e0e0e0;
        }
      }
    }
    .label-txt,
    label {
      height: 100%;
      @include f18(400);
      border: 1px solid $line-e0e0e0;
      @include rem(border-radius, 4);
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  /* component radio : mo*/
  .component-radio {
    // radio  공통 - inner 영역  : mo
    .radio-inner {
      & + .radio-inner {
        // @include rem(margin-left, 32);
      }
    }

    // radio 텍스트
    .radio-txt {
      @include f16(500);
    }
  }

  // form-chips : mo
  .form-chips {
    .component-radio {
      & + .component-radio {
        input[type="radio"] {
          &:checked {
            & + label {
            }
          }
        }
      }
    }
    input[type="radio"] {
      @include rem(height, 48);

      &:checked {
        & + label {
        }
      }
    }
    label {
      @include rem(height, 48);
      @include f14(500);
    }
    &.support-type {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include rem(gap, 4);
      border: 0;
      .component-radio {
        width: 100%;
        @include rem(height, 56);
        & + .component-radio {
          @include rem(margin-left, 0);
          @include rem(margin-top, 0);
        }
        & + .component-radio {
          input[type="radio"] {
            &:checked {
              & + .label-txt,
              & + label {
                @include rem(border-radius, 4);
              }
            }
          }
        }
      }

      input[type="radio"] {
        height: 100%;
        &:checked {
          & + .label-txt,
          & + label {
            border: 0;
            color: $font-ffffff;
            @include rem(border-radius, 4);
            background-color: $bg-fe508b;
          }
        }
      }
      .label-txt,
      label {
        height: 100%;
        @include f16(500);
      }

      &.br-show {
        .component-radio {
          @include rem(height, 80);
          br {
            display: block !important;
          }
        }
      }
    }
  }
}
