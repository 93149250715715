@use "../abstracts" as *;

// Basic
.hgroup-component {
  .hgroup-slogan {
  }
  .hgroup-tit {
    @include f56(700);
    color: $font-000000;
  }
  .hgroup-txt {
    color: $font-000000;
    @include f24(500);
    @include rem(margin-top, 16);
  }

  .hgroup-sub-tit {
    @include f40(700);
    & + .hgroup-desc {
      @include rem(margin-top, 24);
    }
    & + .hgroup-sub-txt {
      @include rem(margin-top, 16);
      @include f20(500);
    }
  }
  .hgroup-desc {
    @include f18(400);
  }
  .hgroup-sub-txt {
    color: $font-000000;
  }

  &.align-center {
    text-align: center !important;
  }

  // 더보기 Type
  &.more-view-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include rem(margin-bottom, 24);
    &.type02 {
      display: block;
    }
    .hgroup-tit {
      @include f32(500);
      em {
        @include rem(margin-left, 4);
      }
    }
    .hgroup-txt {
      @include f24(400);
    }
    .btn-tbl-more {
      border: 0;
      border-radius: 0;
      @include rem(padding, 5);
      height: auto;
      .ico-more-arrow {
        @include rem(margin-left, 4);
        background-image: url("pathImages/icon/ico_more_arrow.png");
      }
      .ico-more-arrow02 {
        @include rem(width, 32);
        @include rem(height, 32);
        background-image: url("pathImages/icon/ico_more_arrow_02.png");
        background-size: 100%;
      }
    }
  }
  &.center {
    max-width: 1400px;
    margin: 0 auto;
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // basic : mo
  .hgroup-component {
    .hgroup-tit {
      @include f32(700);
      color: $font-000000;
      &.forum {
        // @include f56(700);
      }
      &.type02 {
        // @include f48(600);
      }
    }
    .hgroup-txt {
      color: $font-000000;
      @include f16(500);
      @include rem(margin-top, 16);
    }

    .hgroup-sub-tit {
      @include f24(500);
      & + .hgroup-txt {
        @include f16(500);
      }
      & + .hgroup-sub-txt {
        @include f16(500);
      }
    }

    .hgroup-desc {
      // @include f18(400);
    }
    .hgroup-sub-txt {
      // color: $font-1c1c1c;
    }

    &.align-center {
      // text-align: center;
    }

    // 더보기 Type
    &.more-view-type {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // @include rem(margin-bottom, 24);
      &.type02 {
        display: flex;
      }
      .hgroup-tit {
        @include f16(500);
      }
      .btn-tbl-more {
        // border: 0;
        // border-radius: 0;
        // @include rem(padding, 5);
        // height: auto;
        .ico-more-arrow {
          // @include rem(margin-left, 4);
          @include rem(width, 24);
          @include rem(height, 24);
          background-image: url("pathImages/icon/ico_login_right.png");
        }
      }
    }
  }
}
