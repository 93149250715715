@use "../abstracts" as *;

.table-wrap {
  & + .table-wrap {
    @include rem(margin-top, 80);
  }
}
.txt-ellipsis {
  display: flex;
  justify-content: center;
  .txt {
    display: block;
    @include ellipsis(1);
    & + .ico-area {
      @include rem(margin-left, 8);
      width: auto;
      height: auto;
      & + .ico-area {
        @include rem(margin-left, 8);
      }
    }
    .txt-point {
      color: $font-fe508b;
    }
  }
}
/* component table */
.component-table {
  position: relative;
  @include rem(margin-top, 24);
  // table 공통
  table {
    border-collapse: collapse;
    width: 100%;
    @include f20;
    table-layout: fixed;
  }

  // table 공통 - caption 영역
  caption {
    position: absolute;
    @include rem(left, -9999);
    @include rem(width, 1);
    @include rem(height, 1);
    overflow: hidden;
  }

  // table 공통 - th,td 영역
  th,
  td {
    @include f18(400);
    text-align: center;
    vertical-align: middle;
    &.align-left {
      text-align: left;
    }
    &.align-right {
      text-align: right;
    }
  }
  th {
    @include f22(700);
    @include rem(padding, 20 10 14 10);
    background-color: $bg-f7f8fd;
  }
  td {
    &.num {
      color: $font-999999;
      @include f22(700);
    }
    &.nodata {
      color: $font-777777;
    }
    &.align-left {
      @include f20(600);
      strong {
        color: $font-f95a92;
      }
      .title {
        display: block;
        @include ellipsis(1);
      }
    }
    &.views,
    &.date {
      @include f22(700);
    }
    @include rem(padding, 0 10 0);
    @include rem(height, 97);
    border-bottom: 1px solid #e5e5e5;

    .btn {
      @include rem(height, 26);
      @include rem(padding, 0 8);
      .btn-txt {
        @include f12(500);
        color: $font-fe508b;
        flex-shrink: 0;
      }
      &.btn-square {
      }
      &.btn-primary-line {
        border-color: $line-fe508b;
      }
    }

    .tbl-nodata {
      @include rem(padding, 12 0);
      &::before {
        content: "";
        display: block;
        @include rem(width, 48);
        @include rem(height, 48);
        @include rem(margin, 0 auto 16 auto);
        background: url("pathImages/icon/ico_nodata.png") no-repeat center
          center;
        background-size: 100%;
      }
    }
    p {
      @include f16(500);
      text-align: center;
    }
    i {
      @include rem(margin-left, 8);
      vertical-align: text-top;
    }
  }

  // board-list
  &.board-list {
    margin: 0;

    .tbl-count {
      @include rem(margin-bottom, 16);
      span {
        @include f18(400);
      }
    }
    table {
      border-top: 1px solid $line-e0e0e0;
    }
    th,
    td {
      height: auto;
      @include f18(400);
      border-bottom: 1px solid $line-e0e0e0;
    }
    th {
      @include f16(500);
      @include rem(padding, 16 10);
      background-color: $bg-ffffff;
    }
    td {
      @include rem(padding, 20 10);
      &.tbl-num {
        color: $font-777777;
        &.block {
          color: $font-000000;
        }
      }
      &.tbl-day {
        color: $font-777777;
      }
      &.tbl-answer {
      }

      .component-tooltip {
        @include rem(margin-left, 3);
        vertical-align: middle;
      }
    }
    .tbl-desc {
      position: relative;
      @include rem(margin-top, 16);
      @include rem(padding-left, 12);
      @include f12(500);
      color: $font-777777;
      &::before {
        content: "";
        position: absolute;
        @include rem(left, 5);
        top: 50%;
        transform: translateY(-50%);
        @include rem(width, 3);
        @include rem(height, 3);
        border-radius: 100%;
        background-color: $bg-777777;
      }
    }
  }

  // table custom - fixed 영역
  &.table-fixed {
    overflow: hidden;
    overflow-y: auto;
    @include rem(max-height, 200);
    border-bottom: 1px solid gray;
    table {
      border-collapse: separate;
      border-bottom: 0 none;
    }
    .thead-fixed {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1;
      th {
        box-shadow: 0 1px 0 0 gray;
      }
    }
  }

  // table custom - scroll 영역
  &.table-scroll {
    overflow: hidden;
    overflow-x: auto;
    background-image:
      /* Shadows */
      linear-gradient(to right, white, white),
      linear-gradient(to right, white, white),
      /* Shadow covers */
        linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
      linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
    background-position:
      left center,
      right center,
      left center,
      right center;
    background-repeat: no-repeat;
    background-color: white;
    @include rem(background-size, 20 100%, 20 100%, 20 100%, 20 100%);
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    table {
      table-layout: fixed;
      mix-blend-mode: multiply;
    }
  }

  // table 공통 - scrollbar
  &::-webkit-scrollbar {
    @include rem(height, 10);
    @include rem(width, 10);
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: lightgray;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: gray;
  }
  &::-webkit-scrollbar-track:active {
    background-color: gray;
  }
  &::-webkit-scrollbar-thumb {
    @include rem(border-radius, 5);
    background-color: darkgray;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: black;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: black;
  }
}
// table-custom : pc
.table-custom {
  position: relative;

  .custom-item {
    @include rem(padding, 32 16);
    @include rem(border-top, 1px solid $line-e0e0e0);
    @include f18(400);
    display: flex;
    align-items: center;
    &:last-child {
      @include rem(border-bottom, 1px solid $line-e0e0e0);
    }
    .label-group {
      .label-tag {
        margin-bottom: 0;
      }
    }
    .num-bx {
      @include rem(min-width, 60);
      text-align: center;
      color: $font-777777;
    }
    .txt-bx {
      @include rem(width, 75%);
      // @include rem(padding-right, 20);
      @include rem(margin-left, 40);
      @include f18(400);
      display: flex;
      align-items: center;
      text-align: left;
      a {
        @include rem(width, 100%);
      }
      .txt-ellipsis {
        justify-content: flex-start;
      }
      p + i {
        @include rem(margin-left, 8);
      }
      i + i {
        @include rem(margin-left, 8);
      }
    }
    .day-bx {
      @include f16(500);
      margin-left: auto;
      color: $font-777777;
      span {
        position: relative;
        & + span {
          @include rem(margin-left, 40);
        }
      }
    }
    .event-bx {
      @include rem(margin-top, 24);
      dl {
        display: flex;
        dt {
          @include rem(margin-right, 8);
          @include f18(400);
          color: $font-777777;
        }
        dd {
          &::before {
            content: "";
            display: inline-block;
            width: 1px;
            @include rem(height, 12);
            background-color: $bg-e0e0e0;
            @include rem(margin-right, 8);
          }
          @include f18(400);
        }
        & + dl {
          @include rem(margin-top, 8);
        }
      }
    }

    .nodata {
      @include rem(width, 100%);
      @include rem(min-height, 364);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .nodata-txt {
        @include f20(500);
        color: $font-777777;
        & + .btn {
          @include rem(margin-top, 48);
        }
      }
    }
  }
  &.type01 {
    .custom-item {
      display: flex;
      justify-content: space-between;
      @include rem(padding, 48 56);

      .item-left,
      .item-right {
        display: flex;
        dl {
          & + dl {
            @include rem(margin-left, 80);
          }
        }
        .btn-area {
          @include rem(margin-left, 106);
          .btn.btn-square {
            display: block;
            @include rem(height, 36);
            @include rem(padding, 0 12);
            & + .btn {
              @include rem(margin-top, 16);
            }
          }
        }
      }
      dt {
        @include rem(margin-bottom, 24);
        @include f20(500);
        color: $font-777777;
      }
      dd {
        @include f24(400);
      }
    }
  }
  &.type02 {
    .txt-bx {
      overflow: hidden;
      @include rem(width, 85%);
      a {
        overflow: hidden;
        @include rem(width, auto);
      }
      .txt {
        @include f20(500);
      }
    }
    .day-bx {
      @include rem(margin-left, 32);
      @include f20(500);
      color: $font-000000;
      position: relative;
      flex-shrink: 0;
      &::before {
        content: "";
        display: block;
        position: absolute;
        @include rem(width, 1);
        @include rem(height, 12);
        @include rem(top, 50%);
        @include rem(left, -16);
        transform: translateY(-50%);
        background-color: $bg-e0e0e0;
      }
    }
    .play-btn {
      flex-shrink: 0;
    }
  }
  &.type03 {
    .custom-tit-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include rem(padding, 16 0);
      @include f16(500);
      @include rem(border-top, 1px solid $line-e0e0e0);
      p {
        @include rem(width, 210);
        text-align: center;
        &.artist {
          @include rem(width, 330);
          text-align: center;
        }
      }
    }
    .custom-item {
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .time-bx {
        @include rem(width, 210);
        text-align: center;
      }
      .info-box {
        @include rem(max-width, 60%);
        @include rem(width, 100%);
        display: flex;
        align-items: center;
        img {
          @include rem(width, 72);
          @include rem(height, 72);
        }
        .info-txt {
          @include rem(margin-left, 32);
          flex: 1;
        }
        .txt-bx {
          @include rem(width, 90%);
          @include rem(margin-bottom, 8);
          @include f20(500);
          margin-left: 0;
          .txt-ellipsis {
            @include rem(width, 90%);
          }
        }
        .desc-bx {
          color: $font-777777;
        }
      }
      .artist {
        @include rem(width, 300);
        text-align: center;
        margin-left: auto;
      }
    }
  }
  &.type04 {
    .custom-item {
      @include rem(padding, 32 0);
    }
    .info-bx {
      @include rem(width, 100%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      .txt-bx {
        // @include rem(width, 80%);
        @include f20(500);
        .desc {
          @include rem(margin-left, 32);
          position: relative;
          &::before {
            @include rem(width, 1);
            @include rem(height, 12);
            @include rem(top, 50%);
            @include rem(left, -16);
            content: "";
            display: block;
            position: absolute;
            transform: translateY(-50%);
            background-color: $bg-e0e0e0;
          }
        }
      }
      .church-bx {
        @include f18(400);
        display: flex;
        align-items: center;
        .church-name {
          @include rem(margin-left, 32);
          position: relative;
          &::before {
            @include rem(width, 1);
            @include rem(height, 12);
            @include rem(top, 50%);
            @include rem(left, -16);
            content: "";
            display: block;
            position: absolute;
            transform: translateY(-50%);
            background-color: $bg-e0e0e0;
          }
        }
      }
    }
  }
  &.type05 {
    .custom-item {
      .txt-bx {
        // @include rem(width, 80%);
        flex: 1;
        a {
          @include rem(max-width, 75%);
          @include rem(width, auto);
          .txt-ellipsis {
            .txt {
              @include f20(500);
            }
          }
        }
        .area-bx,
        .day-bx {
          @include rem(margin, 0 0 0 32);
          position: relative;
          span {
            @include f20(500);
            color: $font-000000;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            @include rem(width, 1);
            @include rem(height, 12);
            @include rem(top, 50%);
            @include rem(left, -16);
            transform: translateY(-50%);
            background-color: $bg-e0e0e0;
          }
        }
        .area-day-bx {
          display: flex;
        }
      }
      .name-bx {
        @include f18(400);
        color: $font-777777;
      }
    }
  }
  &.type-card {
    @include rem(margin-top, 32);
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include rem(margin-top, 8);
    }
    .custom-item {
      display: block;
      width: 49%;
      @include rem(margin-top, 24);
      @include rem(padding, 40 32);
      border: 1px solid $line-e0e0e0;
      @include rem(border-radius, 8);
      .badge-bx {
        @include rem(margin-bottom, 28);
        text-align: left;
      }
      .txt-ellipsis {
        justify-content: flex-start;
        .txt {
          @include ellipsis(2);
          @include f24(500);
          text-align: left;
          white-space: normal;
        }
      }

      &:nth-child(2n) {
        margin-left: 2%;
      }

      &.nodata {
        flex: 1;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
    }
  }
  &.list-type {
    .list-vowel {
      @include rem(margin-top, 32);
      @include rem(column-gap, 28);
      @include rem(row-gap, 68);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .list-vowel-item {
        @include rem(height, 100%);
        // @include rem(margin-bottom, 60);
        a {
          @include rem(max-width, 448);
          @include rem(max-height, 250);
          @include rem(width, 100%);
          @include rem(height, 100%);
          @include ellipsis(1);
          display: block;
          img {
            @include rem(width, 100%);
            @include rem(height, 250);
            object-fit: cover;
          }
        }
        .txt-bx {
          @include rem(padding, 24 0 32);
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $line-e0e0e0;
          .tit {
            @include f24(700);
            display: inline-grid;
            @include rem(margin-right, 10);
          }

          .label-group {
            flex-shrink: 0;
          }
        }
        .desc-bx {
          @include rem(padding-top, 16);
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          span {
            @include f18(400);
            color: $font-777777;
          }
          .day,
          .church {
            @include rem(margin-left, 16);
            position: relative;
            &::before {
              content: "";
              display: block;
              position: absolute;
              @include rem(width, 1);
              @include rem(height, 12);
              @include rem(top, 50%);
              @include rem(left, -8);
              transform: translateY(-50%);
              background-color: $bg-e0e0e0;
            }
          }
        }
      }
    }
  }
  .play-btn {
    @include rem(margin-left, auto);
    @include rem(width, 32);
    @include rem(height, 32);
    @include rem(border-radius, 50%);
    border: 1px solid $line-000000;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      @include rem(width, 8);
      @include rem(height, 9.6);
      @include rem(top, 50%);
      @include rem(left, 50%);
      transform: translate(-50%, -50%);
      background: url("pathImages/icon/ico_table_play.png") no-repeat;
      background-size: 100%;
    }
  }
  .writing-btn-wrap {
    @include rem(bottom, 4);
    right: 0;
    position: absolute;
  }
}

// tbl-count
.tbl-count {
  @include f18(400);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  & + ul {
    @include rem(margin-top, 32);
  }
  // component-form
  .component-form {
    @include rem(margin-left, auto);
    display: flex;
    align-items: center;
    .form-group-sup {
      .form-group {
        & + .form-group {
          margin: 0;
        }
      }
    }
    .component-select {
      @include rem(width, 200);
      .select-box {
        @include rem(height, 48);
      }
    }
    .component-input {
      @include rem(margin-left, 16);
      @include rem(width, 360);
      .input-field {
        @include rem(height, 48);
        @include rem(border-bottom, 2px solid $line-000000);
        box-sizing: border-box;
        input {
          @include rem(padding, 8);
          border: 0;
        }
      }
    }
    .btn {
      @include rem(margin-left, 32);
    }
  }
  // .form-group-sup {
  //   .form-group + .form-group {
  //     @include rem(margin, 0 0 0 16);
  //     .btn {
  //       @include rem(margin-left, 16);
  //     }
  //   }
  // }
  // .component-select {
  //   @include rem(width, 200);
  //   @include rem(height, 48);
  // }
  // .form-text-field {
  //   @include rem(width, 360);
  //   @include rem(height, 48);
  //   .input-field {
  //     @include rem(border-bottom, 2px solid $line-000000);
  //     input {
  //       @include rem(padding, 6.5 8);
  //       border: 0;
  //     }
  //   }
  // }
  .radio-search-btn {
    @include rem(width, 40);
    @include rem(height, 40);
    .ico-radio-search {
      @include rem(width, 40);
      @include rem(height, 40);
    }
  }
}
// table-custom : pc
// board-write
.board-write {
  .component-form {
    & + .component-form {
      border-top: 1px solid $line-e0e0e0;
      @include rem(margin-top, 48);
      @include rem(padding-top, 48);
    }
    & + .notice-wrap {
      @include rem(margin, 32 0 0 0);
    }
    .form-group-area {
      @include rem(padding, 56 0);
      @include rem(border-top, 1px solid $line-e0e0e0);
      &:first-child {
        @include rem(padding-top, 0);
        @include rem(border-top, 0);
      }
      &:last-child {
        @include rem(padding-bottom, 0);
      }
      &-info {
        position: relative;
        @include f12(500);
        color: $font-999999;
        @include rem(margin-bottom, 24);
        @include rem(padding-left, 8);
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          @include rem(left, 0);
          transform: translateY(-50%);
          @include rem(width, 4);
          @include rem(height, 4);
          border-radius: 50%;
          background-color: $bg-fe508b;
        }
      }
      > h3 {
        position: relative;
        width: fit-content;
        @include f24(700);
        @include rem(margin-bottom, 24);
        &.required {
          @include rem(margin-bottom, 8);
          &::after {
            content: "";
            position: absolute;
            @include rem(right, -8);
            @include rem(top, 0);
            @include rem(width, 4);
            @include rem(height, 4);
            background-color: $bg-fe508b;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .notice-wrap {
    @include rem(margin, 0 0 32 0);
    & + .component-form {
      @include rem(margin-top, 48);
    }
  }
}

// board-write-line
.board-write-line {
  .hgroup-component {
    @include rem(margin-bottom, 40);
    .hgroup-sub-tit {
    }
  }
  .component-form {
    border-top: 1px solid $line-000000;
    .component-checkbox {
      @include rem(margin-left, 16);
      width: 20%;
      flex-shrink: 0;
    }
  }
  .form-group {
    display: flex;
    @include rem(padding, 24 0);
    border-bottom: 1px solid $line-e0e0e0;
    .input-label {
      @include rem(width, 238);
      margin: 0;
      .label-txt {
        @include f18(700);
      }
      flex-shrink: 0;
      &.required {
        &::after {
          display: none;
        }
        .label-txt {
          position: relative;
          flex: none;
          &:after {
            content: "";
            position: absolute;
            @include rem(right, -8);
            @include rem(top, 0);
            @include rem(width, 4);
            @include rem(height, 4);
            background-color: $bg-fe508b;
            border-radius: 50%;
          }
        }
      }
    }

    .form-element {
      flex: 1;
    }
    & + .form-group {
      margin: 0;
    }
  }

  .add-delete-area {
    display: flex;
    align-items: center;
    .component-input {
      @include rem(width, 222);
      & + .component-input {
        @include rem(margin-left, 8);
      }
    }
    .btn-add {
      @include rem(margin-left, 8);
    }
  }

  .component-radio-sup {
    .component-radio {
      & + .component-radio {
        @include rem(margin-left, 90);
      }
    }
  }

  .txt-info {
    display: flex;
    justify-content: flex-end;
    @include rem(margin-top, 24);
    .txt {
      @include f20(500);
    }
    .amount {
      @include f20(500);
      @include rem(margin-left, 24);
    }
  }
}

// board-view
.board-view {
  .hgroup-component {
    @include rem(margin-bottom, 40);
    .hgroup-sub-tit {
    }
  }
  .view-detail {
    border-top: 1px solid $line-000000;
    width: 100%;
    li {
      display: flex;
      @include rem(padding, 24 0);
      border-bottom: 1px solid $line-e0e0e0;
      .view-item {
        display: flex;
        width: 50%;
        dt {
          @include rem(width, 160);
          flex-shrink: 0;
          @include f20(500);
          color: $font-777777;
        }
        dd {
          flex: 1;
          @include f20(500);
          @include rem(padding-left, 32);
        }
        & + .view-item {
        }
      }
    }
  }

  // table {
  //   border-top: 1px solid $line-000000;
  //   & + table {
  //     @include rem(margin-top, 24);
  //   }
  // }
  // th,
  // td {
  //   height: auto;
  //   @include rem(padding, 24);
  //   border-bottom: 1px solid $line-e0e0e0;
  //   text-align: left;
  //   @include f16(500);
  // }
  // th {
  //   span {
  //     position: relative;
  //     &::after {
  //       content: "";
  //       position: absolute;
  //       @include rem(top, 1);
  //       @include rem(right, -8);
  //       @include rem(width, 4);
  //       @include rem(height, 4);
  //       background-color: $bg-fe508b;
  //       border-radius: 100%;
  //     }
  //   }
  // }
  // td {
  // }
}
// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .table-wrap {
    @include rem(margin-top, 40);
    & + .table-wrap {
      @include rem(margin-top, 72);
    }
    .hgroup-component {
      &.more-view-type {
        .hgroup-tit {
          @include f24(700);
        }
      }
    }
  }
  /* component table */
  .component-table {
    // @include rem(margin-top, 24);
    // table 공통 : mo
    table {
      // @include f20;
      & + .component-btns {
        @include rem(margin-top, 48);
      }
    }

    // table 공통 - th,td 영역 : mo
    th,
    td {
      // @include f18(400);
    }
    th {
      // @include f22(700);
      // @include rem(padding, 20 10 14 10);
      // background-color: $bg-f7f8fd;
    }
    td {
      &.num {
        // color: $font-999999;
        // @include f22(700);
      }
      &.nodata {
        // color: $font-777777;
      }
      &.align-left {
        // @include f20(600);
        strong {
          // color: $font-f95a92;
        }
        .title {
          // display: block;
          // @include ellipsis(1);
        }
      }
      &.views,
      &.date {
        // @include f22(700);
      }
      // @include rem(padding, 0 10 0);
      // @include rem(height, 97);
      // border-bottom: 1px solid #e5e5e5;

      .btn {
        // @include rem(height, 30);
        // @include rem(padding, 0 12);
        .btn-txt {
          // @include f12(500);
          // color: $font-fe508b;
        }
        &.btn-square {
        }
        &.btn-primary-line {
          // border-color: $line-fe508b;
        }
      }

      .tbl-nodata {
        // @include rem(padding, 12 0);
        &::before {
          // @include rem(width, 48);
          // @include rem(height, 48);
          // @include rem(margin, 0 auto 16 auto);
          // background: url("pathImages/icon/ico_nodata.png") no-repeat center
          //   center;
          // background-size: 100%;
        }
      }
      p {
        // @include f16(500);
      }
    }

    // board-list : mo
    &.board-list {
      margin: 0;
      &.type02 {
        td.tbl-title {
          text-align: left;
        }
        .txt-ellipsis {
          justify-content: flex-start;
        }
      }
      table {
        thead {
          display: none;
        }
        colgroup {
          display: none;
        }
        tbody {
          tr {
            border-bottom: 1px solid $line-e0e0e0;
          }
        }
      }
      th,
      td {
        display: block;
        width: 100%;
        height: auto;
        @include f16(500);
        border-bottom: 0;
        text-align: left;
      }
      th {
        @include f16(500);
        @include rem(padding, 12 10);
        background-color: $bg-ffffff;
      }
      td {
        @include rem(padding, 24 8);

        &.tbl-num {
          display: none;
        }
        &.tbl-title {
          @include rem(padding-bottom, 0);
          @include f18(700);
          .txt-ellipsis {
            justify-content: flex-start;
          }
        }
        &.tbl-day {
          @include rem(padding, 8);
          @include f14(500);
          color: $font-000000;
        }
        &.tbl-answer {
          @include rem(padding-top, 24);
        }
        p {
          @include f14(500);
        }
      }
      .tbl-desc {
        position: relative;
        @include rem(margin-top, 16);
        @include rem(padding-left, 12);
        @include f14(500);
        color: $font-777777;
        &::before {
          content: "";
          position: absolute;
          @include rem(left, 5);
          top: 50%;
          transform: translateY(-50%);
          @include rem(width, 3);
          @include rem(height, 3);
          border-radius: 100%;
          background-color: $bg-777777;
        }
      }

      .tbl-top-area {
        flex-direction: column-reverse;
        align-items: flex-start;
        @include rem(margin-bottom, 35);
        .tbl-txt {
          // @include rem(margin, 40 0 0 0);
        }
      }
    }
    // mo 유지
    .keep-layout {
      overflow: auto;
      @include rem(margin, 0 24);
      table {
        width: 100%;
        thead {
          display: contents;
        }
        colgroup {
          display: contents;
        }
        tbody {
          tr {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        display: table-cell;
        width: auto;
        border-bottom: 1px solid $line-e0e0e0;
        text-align: center;
      }
      th {
        @include rem(padding, 16 0);
      }
      td {
        @include rem(padding, 22 0);
      }
    }
  }
  // table-custom : mo
  .table-custom {
    // @include rem(padding, 0 24);

    .custom-item {
      @include rem(padding, 24 8);
      display: block;

      .event-bx {
        // @include rem(margin-top, 24);
        dl {
          dt {
            @include rem(margin-right, 24);
            @include f14(500);
          }
          dd {
            @include f14(500);
            &::before {
              display: none;
            }
          }
          & + dl {
            // @include rem(margin-top, 8);
          }
        }
      }
      .label-group {
        .label-tag {
          @include rem(margin-bottom, 8);
        }
      }
      .num-bx {
        display: none;
      }
      .txt-bx {
        @include rem(width, 88%);
        @include rem(margin-left, 0);
        @include f18(700);
        .color-pink {
          color: $font-fe508b;
        }
        & + .day-bx {
          @include rem(padding-top, 32);
          @include f14(500);
          span {
            & + span {
              @include rem(margin-left, 16);
              &::before {
                @include rem(width, 1);
                @include rem(height, 12);
                @include rem(top, 50%);
                @include rem(left, -8);
                content: "";
                display: block;
                position: absolute;

                transform: translate(-50%, -50%);
                background-color: $bg-e0e0e0;
              }
            }
          }
        }
      }
      .nodata {
        @include rem(min-height, 304);
        .nodata-txt {
          @include f16(500);
          & + .btn {
            @include rem(margin-top, 48);
          }
        }
      }
    }
    &.type01 {
      .custom-item {
        display: block;
        @include rem(padding, 33 0);

        .item-left,
        .item-right {
          display: block;
          dl {
            display: flex;
            justify-content: space-between;
            & + dl {
              @include rem(margin, 12 0 0 0);
            }
          }
          .btn-area {
            display: flex;
            justify-content: flex-end;
            @include rem(margin, 32 0 0 0);
            .btn.btn-square {
              display: inline-block;
              @include rem(height, 33);
              & + .btn {
                @include rem(margin, 0 0 0 8);
              }
            }
          }
        }
        .item-right {
          @include rem(margin-top, 12);
        }

        dt {
          @include rem(margin-bottom, 0);
          @include f14(500);
          color: $font-777777;
        }
        dd {
          @include f16(400);
        }
      }
    }
    &.type02 {
      .custom-item {
        display: flex;
        align-items: center;
        .txt-bx {
          align-items: flex-start;
          flex-direction: column;
          a {
            width: 100%;
          }
          .txt {
            @include f18(700);
          }
          .day-bx {
            @include rem(margin, 8 0 0 0);
            @include f14(500);
            &::before {
              display: none;
            }
          }
        }
      }
    }
    &.type03 {
      .custom-item {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        .time-bx {
          @include rem(margin, 16 0 0 25);
          @include f14(500);
          color: $font-777777;
        }
        .info-box {
          max-width: 65%;
          @include rem(margin-left, 106);
          .info-txt {
            @include rem(width, 100%);
            @include rem(margin-left, 0);
            .txt-bx {
              @include rem(margin-bottom, 4);
              @include f16(500);
              .txt-ellipsis {
                @include rem(width, 100%);
              }
            }
            .desc-bx {
              @include f14(500);
            }
          }
          img {
            @include rem(width, 88);
            @include rem(height, 88);
            @include rem(left, 10);
            @include rem(top, 50%);
            position: absolute;
            transform: translateY(-50%);
          }
        }
      }
    }
    &.type04 {
      .custom-item {
        &:first-child {
          border: none;
        }
      }
    }
    &.type05 {
      .custom-item {
        @include rem(padding, 24 0);
        .txt-bx {
          flex-direction: column;
          align-items: flex-start;
          a {
            max-width: 100%;
            width: 100%;
            .txt-ellipsis {
              .txt {
                @include f18(700);
                @include ellipsis(1);
              }
            }
          }
          .area-day-bx {
            @include rem(margin, 8 0 24);
            display: flex;
            align-items: center;
            .area-bx {
              margin: 0;
              span {
                @include f14(500);
              }
              &::before {
                display: none;
              }
            }
            .day-bx {
              @include rem(margin-left, 16);
              span {
                @include f14(500);
              }
              &::before {
                @include rem(left, -8);
              }
            }
          }
        }
      }
    }
    &.type-card {
      ul {
        @include rem(margin-top, 8);
      }
      .custom-item {
        width: 100%;
        @include rem(padding, 24);

        .txt-ellipsis {
          .txt {
            @include f18(700);
            @include ellipsis(2);
            white-space: normal;
          }
        }

        &:nth-child(2n) {
          @include rem(margin, 16 0 0 0);
        }

        &.nodata {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
    }
    &.list-type {
      .list-vowel {
        @include rem(margin-top, 16);
        display: block;
        .list-vowel-item {
          @include rem(margin-bottom, 32);
          padding: 0;
          > a {
            position: relative;
            padding-top: 56.25%;
            @include rem(max-width, 100%);
            @include rem(max-height, 100%);
            // @include rem(width, 100%);
            @include rem(height, 100%);
            img {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
            }
          }
          .txt-bx {
            @include rem(padding, 16 0 24);
            .tit {
              overflow: hidden;
              @include f18(700);
              a {
                width: 100%;
                @include ellipsis(1);
              }
            }
            .label-group {
              flex-shrink: 0;
            }
          }
          .desc-bx {
            @include rem(padding-top, 8);
            span {
              @include f14(500);
              color: $font-999999;
            }
          }
        }
      }
    }
    .component-btns {
      & + .writing-btn-wrap {
        @include rem(margin-top, 16);
      }
    }
    .writing-btn-wrap {
      @include rem(margin-top, 48);
      position: static;
      .btn {
        min-width: 100%;
        .btn-txt {
          @include f18(700);
        }
      }
    }
  }

  // tbl-count : mo
  .tbl-count {
    flex-direction: column-reverse;
    align-items: flex-start;
    @include f14(500);
    & + ul {
      @include rem(margin-top, 16);
    }
    form {
      width: 100%;
    }
    .form-group-sup {
      .form-group + .form-group {
        // @include rem(margin, 0 0 0 16);
        .btn {
          // @include rem(margin-left, 16);
        }
      }
    }
    .component-form {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      @include rem(margin-bottom, 40);
      .component-select,
      .component-input {
        @include rem(margin-left, 0);
        @include rem(width, 100%);
      }
      .component-select {
        .select-box {
          padding: 0;
        }
      }
      .component-input {
        @include rem(margin-top, 16);
        .input-field {
          input {
            padding: 0;
            @include f16(500);
          }
        }
      }
      .btn {
        @include rem(min-width, 74);
        @include rem(height, 36);
        @include rem(padding, 6 16);
      }
      .form-frame {
        @include rem(width, 100%);
      }
    }
    .component-select {
      // @include rem(width, 200);
      // @include rem(height, 48);
    }
    .form-text-field {
      // @include rem(width, 360);
      // @include rem(height, 48);
      .input-field {
        // @include rem(border-bottom, 2px solid $line-000000);
        input {
          // @include rem(padding, 6.5 8);
          // border: 0;
        }
      }
    }
    .radio-search-btn {
      @include rem(width, 32);
      @include rem(height, 32);
      .ico-radio-search {
        @include rem(width, 32);
        @include rem(height, 32);
      }
    }
  }

  // board-write : mo
  .board-write {
    .component-form {
      & + .component-form {
        @include rem(padding-top, 40);
        @include rem(margin-top, 40);
      }
      & + .notice-wrap {
        @include rem(margin, 24 0 0 0);
      }
      .form-group-area {
        @include rem(padding, 40 0);
        &-info {
          @include f14(500);
        }
        > h3 {
          @include f20(700);
        }
      }
    }

    .notice-wrap {
      @include rem(padding-right, 24);
      & + .component-form {
        @include rem(margin-top, 40);
      }
    }
    .component-btns {
      @include rem(margin-top, 48);
    }
  }

  // board-view : mo
  .board-view {
    .hgroup-component {
      @include rem(margin-bottom, 24);
      .hgroup-sub-tit {
      }
    }
    .view-detail {
      width: 100%;
      li {
        display: block;
        padding: 0;
        border-bottom: 0;
        .view-item {
          display: flex;
          width: 100%;
          @include rem(padding, 24 0);
          border-bottom: 1px solid $line-e0e0e0;
          dt {
            @include rem(width, 100);
            @include f16(500);
          }
          dd {
            flex: 1;
            @include f16(500);
          }
          & + .view-item {
          }
        }
      }
    }
  }

  // board-write-line : mo
  .board-write-line {
    .hgroup-component {
      @include rem(margin-bottom, 24);
      .hgroup-sub-tit {
      }
    }
    .component-form {
      .component-checkbox {
        @include rem(margin, 8 0 0 0);
        width: 100%;
        flex-shrink: 0;
      }
    }
    .form-group {
      display: block;
      @include rem(padding, 24 0);
      border-bottom: 1px solid $line-e0e0e0;
      .input-label {
        width: 100%;
        margin: 0;
        .label-txt {
          @include f16(500);
          @include rem(margin-bottom, 24);
        }
        flex-shrink: 0;
        &.required {
          &::after {
            display: none;
          }
          .label-txt {
            position: relative;
            flex: none;
            &:after {
              content: "";
              position: absolute;
              @include rem(right, -8);
              @include rem(top, 0);
              @include rem(width, 4);
              @include rem(height, 4);
              border-radius: 50%;
            }
          }
        }
      }
      .form-element {
        flex: 1;
        .component-select {
          & + .component-input {
            @include rem(margin, 8 0 0 0);
          }

          // &.phone {
          //   & + .component-input {
          //     @include rem(margin, 0 0 0 8);
          //   }
          // }
        }
      }
      & + .form-group {
        margin: 0;
      }
    }

    .add-delete-area {
      display: block;
      width: 100%;
      .component-input {
        width: 100%;
        & + .component-input {
          @include rem(margin, 8 0 0 0);
        }
      }
      .btn-add {
        display: block;
        @include rem(margin, 24 auto 0 auto);
      }
    }

    .component-radio-sup {
      .component-radio {
        display: block;
        & + .component-radio {
          @include rem(margin, 24 0 0 0);
        }
      }
    }
    .tbl-top-area {
      padding: 0;
      align-items: flex-start;
    }

    .txt-info {
      display: flex;
      justify-content: flex-end;
      @include rem(margin-top, 24);
      .txt {
        @include f18(700);
      }
      .amount {
        @include f18(700);
        // @include rem(margin-left, 24);
      }
    }
  }
}
