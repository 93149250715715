@use "../abstracts" as *;

/* component pagination */
.component-pagination {
  display: flex;
  justify-content: center;
  @include rem(margin-top, 64);
  // 포럼 > 언론보도, 공지사항, 갤러리
  // pagination 공통 - item 영역
  .pagination-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include rem(min-width, 32);
    @include rem(min-height, 32);
    // @include rem(padding, 0 2);
    @include rem(margin, 0 8);
    @include rem(border-radius, 0);
    @include f16(500);
    color: $font-000000;
    background-color: $bg-ffffff;
    // @include rem(border-radius, 4);

    & + .pagination-item {
      // @include rem(margin-left, 8);
    }
    &.first {
      background-image: url("pathImages/icon/ico_arrow_first.png");
      @include rem(margin, 0 4);
    }
    &.prev {
      background-image: url("pathImages/icon/ico_prev.png");
      @include rem(margin, 0 12 0 0);
    }
    &.next {
      background-image: url("pathImages/icon/ico_next.png");
      @include rem(margin, 0 0 0 12);
    }
    &.last {
      @include rem(margin, 0 4);
      background-image: url("pathImages/icon/ico_arrow_last.png");
    }
    &.on {
      color: $font-ffffff;
      background-color: $bg-000000;
      @include rem(border-radius, 4);
    }
  }

  // pagination 공통 - 페이지 이동 영역
  .first,
  .prev,
  .next,
  .last {
    background-repeat: no-repeat;
    background-position: center;
    @include rem(background-size, 24 24);
  }
  // .first {
  //   @include ico_nav_first(black);
  // }
  // .prev {
  //   @include ico_nav_prev(black);
  // }
  // .next {
  //   @include ico_nav_next(black);
  // }
  // .last {
  //   @include ico_nav_last(black);
  // }
}

/* component pagination mini */
.component-pagination-mini {
  display: flex;
  justify-content: center;

  // pagination 공통 - item 영역
  .pagination-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include rem(min-height, 40);
    @include rem(padding, 0 2);
    @include f14;
    color: black;
  }

  // pagination 공통 - 페이지 이동 영역
  .prev,
  .next {
    @include rem(min-width, 40);
    @include rem(border-radius, 4);
    background-color: lightgray;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @include rem(background-size, 24 24);
  }
  .prev {
    @include ico_nav_prev(black);
  }
  .next {
    @include ico_nav_next(black);
  }

  // pagination 공통 - 페이지 노출 영역
  .pagination-item-group {
    @include rem(margin, 0 10);
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .component-pagination {
    @include rem(margin-top, 48);

    // pagination 공통 - item 영역
    .pagination-item {
      @include rem(margin, 0 2);
    }
  }
}
