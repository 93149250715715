@use "../abstracts" as *;

/* component swiper */
.swiper-container {
  position: relative;
  overflow: hidden;

  // swiper 공통 - 슬라이드 그룹 영역
  .swiper-wrapper {
    .swiper-item {
      &:hover,
      &:focus {
        img {
          // transform: scale(1);
        }
      }
    }
  }

  // swiper 공통 - 슬라이드 영역
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      vertical-align: top;
    }
  }
}
// Btn control
.control-exception,
.control-type01 {
  // display: flex;
  .swiper-controls {
    display: flex;
    position: relative;
    width: 100%;
  }
  .swiper-navigation {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    [class^="swiper-button"] {
      position: static;
      @include rem(width, 20);
      @include rem(height, 20);
      @include rem(padding, 6 12);
      transform: none;
      box-sizing: initial;
      @include rem(background-size, 20 20);
    }
    .swiper-button-prev {
      background-image: url("pathImages/btn/btn_swiper_sub_l.png");
    }
    .swiper-button-next {
      background-image: url("pathImages/btn/btn_swiper_sub_r.png");
    }
  }
  .swiper-autoplay-wrap {
    position: static;
    @include rem(margin-left, 8);
  }
  .swiper-pagination {
    position: static;
  }
}
// 좌, 우 버튼 기본
.swiper-navigation {
  [class^="swiper-button"] {
    @include rem(width, 64);
    @include rem(height, 64);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    margin-top: 0;
    transform: translate(0, -50%);
    // background-color: rgba($color: #000000, $alpha: 0.1);
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    @include rem(left, 0);
    right: auto;
    background-image: url("pathImages/btn/btn_swiper_arrow_l.png");
    &::after {
      display: none;
      font-family: inherit;
    }
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    @include rem(right, 0);
    left: auto;
    background-image: url("pathImages/btn/btn_swiper_arrow_r.png");
    &::after {
      display: none;
      font-family: inherit;
    }
  }
}

// swiper-progress
.swiper-progress {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  .progress-bar {
    position: relative;
    width: 100%;
    @include rem(height, 2);
    background-color: $bg-cccccc;
    .swiper-pagination-progressbar-fill,
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      @include rem(height, 2);
      background-color: $bg-000000;
    }
  }
}

// swiper-num
.swiper-pagination {
  &.swiper-pagination-fraction {
    display: block;
    @include rem(width, 72);
    @include rem(height, 32);
    @include rem(padding, 4 0);
    @include f16(500);
    @include rem(border, 1px solid $line-000000);
    @include rem(border-radius, 40);
    color: $font-000000;
    text-align: center;
    .swiper-pagination-current {
    }
    .swiper-pagination-total {
    }
  }
}

// swiper 자동설정 버튼
.swiper-autoplay {
  display: block;
  @include rem(width, 32);
  @include rem(height, 32);
  @include rem(padding, 0);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  &.play {
    background-image: url("pathImages/btn/btn_swiper_pause_round.png");
  }
  &.stop {
    background-image: url("pathImages/btn/btn_swiper_play_round.png");
  }

  // 첫 깜빡이는 현상 대체
  // &:after {
  //   content: "";
  //   background-image: url("pathImages/btn/btn_swiper_play_round.png");
  // }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // swiper-num
  .navi-num {
    @include f14(500);
    .swiper-pagination-current {
    }
    .swiper-pagination-total {
    }
  }
}
