@use "../abstracts" as *;

.h-0 {
  height: 0;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.h-svh {
  height: 100svh;
}
.h-lvh {
  height: 100lvh;
}
.h-dvh {
  height: 100dvh;
}
.h-min {
  height: min-content;
}
.h-max {
  height: max-content;
}
.h-fit {
  height: fit-content;
}
.min-h-0 {
  min-height: 0;
}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.min-h-svh {
  min-height: 100svh;
}
.min-h-lvh {
  min-height: 100lvh;
}
.min-h-dvh {
  min-height: 100dvh;
}
.min-h-min {
  min-height: min-content;
}
.min-h-max {
  min-height: max-content;
}
.min-h-fit {
  min-height: fit-content;
}
.max-h-0 {
  max-height: 0;
}
.max-h-none {
  max-height: none;
}
.max-h-full {
  max-height: 100%;
}
.max-h-screen {
  max-height: 100vh;
}
.max-h-svh {
  max-height: 100svh;
}
.max-h-lvh {
  max-height: 100lvh;
}
.max-h-dvh {
  max-height: 100dvh;
}
.max-h-min {
  max-height: min-content;
}
.max-h-max {
  max-height: max-content;
}
.max-h-fit {
  max-height: fit-content;
}
