@use "../abstracts" as *;
// .test-wrap {
//   text-align: center;
// }
.mo-show {
  display: none;
}

.txt-point {
  color: $font-fe508b;
}

.section-hide {
  display: none !important;
}

.scroll-hide {
  html::-webkit-scrollbar,
  body::-webkit-scrollbar {
    background: transparent !important;
  }

  html::-webkit-scrollbar-thumb,
  body::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
}

.scroll-custom {
  &::-webkit-scrollbar {
    @include rem(width, 4);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $bg-999999;
  }
  &::-webkit-scrollbar-track {
    background-color: $bg-e0e0e0;
  }
}

// audio player
.component-jplayer {
  // VOD

  // AOD
  &.audio-player {
    .player_control .control_bar .time_s {
      position: absolute;
      left: 55px;
      color: $font-ababab;
      right: auto;
      font-size: 14px;
      font-weight: 500;
    }
    .player_control .control_bar .speaker_s {
      position: absolute;
      top: 12px;
      right: 15px;
    }
    .full_size {
      display: none;
    }
  }
}

.component-jplayer,
.video-bx {
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
  line-height: 1;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  .player_control {
    width: 100%;
    height: 100%;
    border-top: 1px solid #303038;
    border-right: 1px solid #303038;
    border-left: 1px solid #303038;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    .control_bar {
      height: 48px;
      padding: 0 24px;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none !important;
      position: relative;

      a {
        width: 24px;
        height: 24px;
        display: block;
        font-size: 0;
      }
    }
    .full_size {
      position: absolute;
      top: 50% !important;
      right: 24px !important;
      transform: translateY(-50%);
      font-size: 14px;
      font-weight: 500;
    }
    .time_s {
      position: absolute;
      right: auto !important;
      left: 155px;
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      &.jp-current-time {
        color: #ffffff;
      }
    }
    .time_bar {
      height: 4px !important;
      background-image: none !important;
      .time_seek {
        background-image: none !important;
        background-color: #777777 !important;
      }
      .time_line {
        background-image: none !important;
        background-color: #fe508b;
      }
      .time_btn {
        display: none;
      }
    }
    .speaker_s .bg_speaker {
      background-color: #777777;
      height: 2px !important;
      .spearker_btn {
        position: absolute;
        top: -3px !important;
        right: -5px !important;
        width: 8px !important;
        height: 8px !important;
        border-radius: 50%;
        background-image: none !important;
        background-color: #fe508b;
      }
      .spearker_bg {
        position: absolute;
        top: 0 !important;
        left: 0 !important;
        height: 2px !important;
        background-color: #fe508b !important;
        background-image: none !important;
      }
    }
  }
}

.video-player-wrap {
  position: relative;
  padding-top: 56.25%;
  padding-top: 59.48%;
  .btn-player {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .component-jplayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .jp-jplayer {
      height: calc(100% - 47px);
      img {
        height: 100% !important;
      }
    }
  }
}

.iframe-zone {
  position: relative;
  padding-top: 56.25%;
  @include rem(margin, 5 0);
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

// label-tag : pc
.label-tag {
  & + .label-tag {
    @include rem(margin-left, 4);
  }
  &.block {
    background-color: $bg-000000;
  }
  &.default {
    background-color: $bg-fe508b;
  }
  &.navy {
    background-color: $bg-335891;
  }
  &.purple {
    background-color: $bg-763ba5;
  }
  &.orange {
    background-color: $bg-fea339;
  }
  &.gray {
    background-color: $bg-eff3f7;
  }
  &.box-type {
    @include rem(padding, 2 6);
    @include rem(border-radius, 4);
    @include f14(700);
    display: inline-block;
    color: $font-ffffff;
  }
  &.round-type {
    @include rem(padding, 1 8);
    @include rem(border, 1px solid $line-ffffff);
    @include rem(border-radius, 50);
    @include f14(700);
    display: inline-block;
    background-color: $bg-ffffff;
    color: $font-ffffff;
    &.block {
      border-color: $line-000000;
      color: $font-000000;
    }
    &.default {
      border-color: $line-fe508b;
      color: $font-fe508b;
    }
    &.navy {
      border-color: $line-335891;
      color: $font-335891;
    }
    &.purple {
      border-color: $line-763ba5;
      color: $font-763ba5;
    }
    &.orange {
      border-color: $line-fea339;
      color: $font-fea339;
    }
  }
  &.full-type {
    display: inline-block;
    @include rem(padding, 4 16);
    @include rem(border-radius, 64);
    @include f16(500);
    background-color: $bg-ffffff;
    color: $font-ffffff;
    &.default {
      background-color: $bg-fe508b;
    }
    &.navy {
      background-color: $bg-335891;
    }
    &.purple {
      background-color: $bg-763ba5;
    }
    &.orange {
      background-color: $bg-fea339;
    }
  }
  &.mo-show {
    display: none;
  }
}

// Live streaming
.live-streaming {
  display: flex;
  // gap:4px;
  [class*="btn-"] {
    & + [class*="btn-"] {
      @include rem(margin-left, 4);
    }
  }
}

// input type calendar
.input-calendar-btn {
  @include rem(margin-left, 16);
  @include rem(width, 40);
  @include rem(height, 40);
  @include rem(border-radius, 50%);
  border: none;
  outline: none;
  background: url("pathImages/icon/ico_calendar_white.png") no-repeat center
    center;
  background-color: $bg-363853;
  background-size: 20px 20px;
  cursor: pointer;
  overflow: hidden;
  font-size: 1px;
  line-height: 0;
  // clip:rect(1px 1px 1px 1px);
  white-space: nowrap;
  margin: -1px;
  padding: 0;
  border: 0;
}
// tooltip : dim-type : pc
.component-tooltip {
  &.dim-type {
    &[data-props-position="top"],
    &[data-props-position="bottom"] {
      .tooltip-btn-trigger.on::after {
        display: none;
      }
    }
    .tooltip-container {
      // @include rem(max-width, 400);
      @include rem(padding, 32);
      @include rem(width, auto);

      border: 0;
      color: $font-000000;
      right: 0;
      left: auto;
      transform: none;
      z-index: 101;
      &::after {
        display: none;
      }
    }
    .sharing-list {
      @include rem(margin-top, 24);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ad-sharing-item,
      .sharing-item {
        & + .sharing-item,
        & + .ad-sharing-item {
          @include rem(margin-left, 24);
        }
        img {
          @include rem(width, 48);
          @include rem(height, 48);
          @include rem(margin-bottom, 8);
        }
        span {
          @include f12(500);
          display: block;
        }
      }
    }
    .dim {
      opacity: 0;
      display: none;
    }
  }
}
.tooltip-container {
  @include rem(height, 200);
  @include rem(left, 100%);
  @include rem(padding, 32);
  @include rem(border-radius, 8);
  background-color: $bg-ffffff;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  &::before {
    display: none;
  }
  strong {
    @include f24(500);
  }
  .tooltip-btn-close {
    position: absolute;
    @include rem(top, 38);
    @include rem(right, 32);
    @include rem(width, 24);
    @include rem(height, 24);
    background: url("pathImages/btn/ad_close.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
.pageClassMain {
  &.login {
    #content {
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: url("pathImages/contents/img_login.png") no-repeat center
          center;
        background-size: 100% 100%;
        object-fit: cover;
      }
      .layout-col:nth-child(2) {
        margin-left: 266px;
      }
    }
  }
}
// nodata : pc
.nodata-wrap {
  @include rem(margin-top, 24);
  @include rem(height, 462);
  border-top: 1px solid $line-e0e0e0;
  border-bottom: 1px solid $line-e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    @include f20(500);
    color: $font-777777;
  }
}
// Loading
.loading-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;

  .loading {
    position: relative;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    transform: translate(-50%, -50%);
    z-index: 1000;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto !important;
      height: auto !important;
      transform: none;
      transition: none;
      transform: translate(-50%, -50%) !important;
    }
  }
  .loading-dimm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bg-000000;
    opacity: 0.8;
    z-index: 999;
  }
}

// [공통] Frame Layout]
.inner-comm-bx {
  position: relative;
  width: 100%;

  .inner-comm {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    @include rem(padding-top, 80);

    &.type01 {
      @include rem(width, 924);
    }

    &.type02 {
      @include rem(width, 686);
    }
    &.type03 {
      @include rem(width, 1162);
    }

    &.full {
      max-width: 1920px;
      // .hgroup-component {
      //   max-width: 1400px;
      //   margin: 0 auto;
      // }

      .component-tab {
        .tab-head {
          max-width: 1400px;
          margin: 0 auto;
        }
      }
      .component-accordion {
        max-width: 1400px;
        margin: 0 auto;
      }
    }
    // 초기화
    &.reset {
      padding: 0;
    }
  }
  // no-breadcrumb
  &.no-breadcrumb {
    .inner-comm {
      @include rem(padding-top, 160);
    }
  }
}

// tab-scroll-btn menu scroll
.tab-scroll-wrap {
  .component-tab.tab-scroll-btn {
    max-width: 1400px;
    @include rem(margin, 0 auto 80 auto);
  }

  & + .radio-wrap {
    margin-top: 0;
  }
}

// Sub Visual : pc
.sub-visual-wrap {
  overflow: hidden;
  position: relative;
  // @include rem(width, 1920);
  @include rem(height, 100%);
  margin: 0 auto;

  .img-zone {
    @include rem(max-width, 1920);
    @include rem(height, 100%);
    margin: 0 auto;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100px;
      background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(255, 255, 255, 0) 100%
      );
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 60px;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.2) 100%
      );
    }
    img {
      @include rem(width, 100%);
      @include rem(height, 100%);
    }
  }
  .sub-visual-bx {
    @include rem(width, 100%);
    @include rem(top, 236);
    position: absolute;
    left: 0;
    z-index: -1;
    &.banner-type {
      z-index: 0;
    }
    @media (max-width: 1400px) {
      &.banner-type {
        @include rem(top, 100);
        @include rem(left, 50);
      }
    }

    .sub-visual-info {
      max-width: 1400px;
      margin: 0 auto;
      .sub-visual-tit {
        @include rem(margin-bottom, 32);
        @include f40(700);
        color: $font-ffffff;
      }
      .sub-visual-txt {
        @include f24(400);
        color: $font-ffffff;
      }
    }
  }
  .btn-group {
    @include rem(width, 100%);
    @include rem(left, 50%);
    @include rem(bottom, 40);
    max-width: 1400px;
    position: absolute;
    margin: 0;
    transform: translate(-50%, 0);
    z-index: 5;
    .btn-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .home {
      @include rem(height, 24);
      @include rem(margin-right, 84);
      @include f16(700);
      display: block;
      position: relative;
      color: $font-ffffff;
      &::before {
        @include rem(top, 50%);
        @include rem(left, -30);
        @include rem(width, 20);
        @include rem(height, 20);
        content: "";
        display: block;
        position: absolute;
        background: url("pathImages/icon/ico_sub_visual_home.png") no-repeat
          center center;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .component-tooltip {
      .tooltip-btn-trigger {
        @include rem(width, 100%);
        @include rem(height, 24);
        @include f16(700);
        position: relative;
        color: $font-ffffff;
        padding: 0;
        border: none;
        background: none;
        white-space: nowrap;
        flex-shrink: 0;
        &::before {
          @include rem(top, 50%);
          @include rem(left, -30);
          @include rem(width, 20);
          @include rem(height, 20);
          content: "";
          display: block;
          position: absolute;
          background: url("pathImages/icon/ico_sub_visual_link.png") no-repeat
            center center;
          background-size: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }

  & + .inner-comm {
    @include rem(padding-top, 96);
  }
}

// [공통] list-comm-wrap] 리스트
.list-comm-wrap {
  position: relative;
  @include rem(margin, 40 auto 120 auto);
  .hgroup-component {
    .hgroup-tit {
      @include f40(700);
    }
  }
  .list-comm {
    a {
      display: block;
      outline-offset: -1px;
      span {
        display: block;
      }
    }
    .list-tit {
      @include f20(700);
    }
    .list-date {
      @include f16();
    }
  }
  // gallary-type
  .gallary-type {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // iOS Safari: grid-template-columns 10.3부터 지원
    @include rem(column-gap, 28);
    @include rem(row-gap, 50);
    // iOS Safari: gap iOS 12.2부터 지원
    .img-zone {
      img {
        vertical-align: top;
        width: 100%;
        // object-fit: none;
      }
    }
    li {
      overflow: hidden;
      flex: 1;
      & + li {
        // @include rem(margin-left, 28);
      }
      .list-tit {
        @include rem(padding, 24 31 24 0);
        @include rem(min-height, 64);
      }
    }
  }

  // board-type
  .board-type {
    // @include rem(border-top, 2px solid $line-1c1c1c);
    li {
      @include rem(padding, 24 16);
      &:first-child {
        padding-top: 0;
      }
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .list-tit {
          width: 83%;
          @include f20(500);
          @include ellipsis(1);
        }
        .list-date {
          width: auto;
          @include f18(400);
          color: $font-000000;
        }
      }
      & + li {
        border-top: 1px solid $line-e0e0e0;
      }
    }
  }

  // notice-type
  .notice-type {
    display: flex;
    li {
      flex: 1;
      width: 47.7%;
      & + li {
        @include rem(margin-left, 10);
      }
      > a {
        display: flex;
        flex-direction: column;
        min-height: 220px;
        @include rem(padding, 24 32 24 32);
        @include rem(border-radius, 0);
        background-color: $bg-f7f8fd;
        .list-tit {
          @include f16(500);
          color: $font-fe508b;
          @include rem(margin-bottom, 8);
        }
        .list-txt {
          @include f18(400);
          color: $font-000000;
          @include ellipsis(2);
          // @include rem(min-height, 94);
        }
        .list-date {
          @include f18(400);
          margin-top: auto;
        }
      }
      p {
        a {
          display: inline-block;
        }
      }
    }
  }
}

// [공통] components-layout 레이아웃
.components-layout {
  .layout-row {
    @include flex-grid((1, 1), 0); // flex-grid mixin 사용
  }
  [class^="layout-col"] {
    position: relative;
    .list-comm-wrap {
      margin-bottom: 0;
    }
    flex: 1;
    & + .layout-col {
      @include rem(margin-left, 30);
    }
  }

  .component-btns {
    @include rem(margin-top, 48);
  }
  // half-type01
  &.half-type01 {
    @include rem(margin-top, 160);
    .layout-row {
      flex-wrap: nowrap;
      @include rem(column-gap, 28);
      @include rem(row-gap, 28);
      [class^="layout-col"] {
        width: 48%;
        .hgroup-component {
          margin-bottom: 20px;
        }
        & + .layout-col {
          @include rem(margin-left, 0);
        }
      }
    }
  }
}

// [공통] Icon Play Btn
.ico-play-bx {
  position: relative;
  width: 100%;
  height: 100%;
  .ico-play {
    position: absolute;
    left: 50%;
    top: 50%;
    @include rem(width, 64);
    @include rem(height, 64);
    background: rgba($color: $bg-ffffff, $alpha: 0.5);
    border-radius: 100%;
    transition: transform 0.3s ease-out;
    transform: translate(-50%, -50%);
    background: url("pathImages/icon/ico_video_play.png") no-repeat center
      center;
    background-size: 100%;
    z-index: 1;
    pointer-events: none;
    &.sm-size {
      @include rem(width, 40);
      @include rem(height, 40);
    }
  }
  // W: 40, H: 40, Color: black
  &.type02 {
    display: flex;
    justify-content: flex-end;
    position: static;
    transform: none;
    @include rem(width, 40);
    @include rem(height, 40);
    .ico-play {
      display: flex;
      align-items: center;
      justify-content: center;
      position: static;
      width: 100%;
      height: 100%;
      border: 1px solid $line-000000;
      opacity: 1;
      background-color: transparent;
      transform: none;
      filter: blur(0.5px);
      .ico {
        @include rem(width, 10);
        @include rem(height, 12);
        @include rem(margin-left, 2);
        background: url("pathImages/icon/ico_play_black.png") no-repeat center
          center;
        background-size: 100%;
      }
    }
  }
}

// [공통] img-zone, txt-info
.img-zone {
  overflow: hidden;
  position: relative;
  img {
    vertical-align: top;
    width: 100%;
    // transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0.4s ease;
    transform: scale(1);
  }
}
a:focus,
a:hover {
  .img-zone {
    img {
      transform: scale(1.05);
    }
  }
}

.txt-info {
  color: $font-000000;
  .slogan {
    display: block;
    @include f24(500);
    @include rem(margin-bottom, 11);
  }
  .tit {
    display: block;
    @include f40(700);
    @include rem(margin-bottom, 32);
  }
  .txt {
    display: block;
  }
}

// [공통] location-zone
.location-zone {
  @include rem(margin-bottom, 96);
  .location-list {
    display: flex;
    align-items: center;
    justify-content: center;
    @include rem(margin-top, 39);
  }
  .location-list {
    li {
      display: flex;
      align-items: center;
      & + li {
        .item {
          @include f20(500);
        }
        &:before {
          display: inline-block;
          content: "";
          @include rem(width, 5);
          @include rem(height, 10);
          @include rem(margin, 0 10 0 16);
          background: url("pathImages/icon/ico_location_arrow.png") no-repeat
            center center;
          background-size: 100%;
          vertical-align: middle;
        }
      }
      &.home {
        .item {
          display: block;
          @include rem(width, 24);
          @include rem(height, 24);
          background: url("pathImages/icon/ico_location_home.png") no-repeat
            center center;
          background-size: 100%;
        }
      }
    }
  }
}

// [공통] End] completed
.inquiry-completed {
  @include rem(padding-top, 32);
  text-align: center;
  &::before {
    content: "";
    display: block;
    @include rem(width, 64);
    @include rem(height, 64);
    @include rem(margin, 0 auto 32 auto);
    background: url("pathImages/icon/ico_completed.png") no-repeat 0 0 / 100%
      auto;
  }
  .completed-msg {
    @include f32(500);
  }
  .completed-desc {
    @include rem(margin-top, 16);
    @include f20(500);
  }
  .component-btns {
    // @include rem(margin-top, 64);
  }
}

// [공통] notice-wrap
.notice-wrap {
  @include rem(margin-top, 32);
  .notice-txt {
    display: flex;
    align-items: center;
    position: relative;
    @include f16(500);
    @include rem(margin, 0 0 12 0);
    @include rem(padding-left, 32);
    &::before {
      content: "";
      position: absolute;
      left: 0;
      @include rem(top, 1);
      @include rem(width, 20);
      @include rem(height, 20);
      background: url("pathImages/icon/ico_notice.png") no-repeat 0 0 / 100%
        auto;
    }
  }
  // 예)가 붙어있는 경우
  .notice-etc {
    display: flex;
    align-items: flex-start;
    @include rem(gap, 8);
    > p {
      @include f16(500);
      color: $font-777777;
    }
  }
  .notice-list {
    li {
      @include f16(500);
      color: $font-777777;
      strong {
        font-weight: 700;
      }
    }
  }
}

// [공통] 상세 view
.details-view-wrap {
  @include rem(margin-top, 96);
  .view-info-wrap {
    position: relative;
    // display: flex;
    // align-items: flex-end;
    // justify-content: space-between;
    .view-btns {
      position: absolute;
      right: 0;
      @include rem(top, 20);

      .btn-edit {
        @include f16(700);
        .ico-edit {
          display: inline-flex;
          align-items: center;
          vertical-align: top;
          @include rem(margin-right, 4);
        }
      }
    }
    .component-btns {
      justify-content: flex-start;
      @include rem(margin-top, 48);
    }
  }
  .label {
    @include rem(margin-bottom, 16);
    @include rem(padding, 4 16);
    @include rem(border-radius, 100);
    border: 1px solid $line-fe508b;
    @include f16(500);
    display: inline-block;
    color: $font-fe508b;
  }
  .view-tit {
    @include f40(700);
    display: flex;
    align-items: center;
    word-break: break-all;

    .component-tooltip {
      .tooltip-container {
        right: 0;
        left: auto;
        transform: none;
      }
    }
  }
  .view-date {
    @include f20(500);
    @include rem(margin-top, 24);
    color: $font-777777;
    span {
      position: relative;
      & + span {
        @include rem(margin-left, 32);
        &::before {
          @include rem(width, 1);
          @include rem(height, 16);
          @include rem(top, 50%);
          @include rem(left, -16);
          content: "";
          display: block;
          position: absolute;
          transform: translateY(-50%);
          background-color: $bg-e0e0e0;
        }
      }
    }
  }

  .view-cont {
    @include f18(400);
    @include rem(padding, 80 0 160 0);
    @include rem(margin-top, 56);
    border-top: 1px solid $line-e5e5e5;
    br {
      display: block !important;
    }
    img {
      max-width: 100%;

      @include rem(margin-bottom, 64);
    }

    .iframe-zone {
      // padding-top: 51.43%;
      // @include rem(max-width, 1280);
      @include rem(margin-top, 0);
      @include rem(margin-bottom, 64);
    }
  }
  .view-pagenaition {
    border-top: 1px solid $line-e5e5e5;
    border-bottom: 1px solid $line-e5e5e5;
    li {
      display: flex;
      align-items: center;
      @include rem(padding, 32 24);
      &:first-child {
        border-bottom: 1px solid #e5e5e5;
      }
      &.no-data {
        pointer-events: none;
      }
    }
    .view-label {
      display: flex;
      align-items: center;
      @include rem(width, 118);
      flex-shrink: 0;
      @include f16(500);
      @include rem(line-height, 24);
      [class^="ico-"] {
        @include rem(margin-right, 24);
      }
      .txt {
        @include f16(500);
      }
    }
    .view-btn {
      @include rem(margin-left, 80);
      .txt {
        @include f18(400);
      }
    }
  }
}

// tbl top
.tbl-top-area {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @include rem(margin-bottom, 32);
  .tbl-txt {
    color: $font-777777;
    @include f18(400);
  }
  .tbl-user {
    @include rem(min-width, 200);
    text-align: right;
  }
}

// 하단 상담 영역
.consulting-inquiry {
  border-top: 1px solid $line-e0e0e0;
  border-bottom: 1px solid $line-e0e0e0;
  dl {
    display: flex;
    justify-content: flex-end;
    max-width: 1600px;
    margin: 0 auto;
    @include rem(padding, 32 0);
  }
  dt {
    @include f20(500);
    margin-right: auto;
  }
  dd {
    @include f20(500);
    span {
      position: relative;
      & + span {
        @include rem(margin-left, 32);
        @include rem(padding-left, 32);
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          @include rem(height, 20);
          background-color: $bg-e0e0e0;
        }
      }
    }
    & + dd {
      @include rem(margin-left, 80);
    }
  }
}

// Sub Visual Common
.sub-comm-visual {
  overflow: hidden;
  position: relative;
  @include rem(height, 960);
  @include rem(padding-top, 160);
  @include rem(margin-bottom, 160);
  .img-zone {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    @include rem(width, 1920);
    @include rem(height, 960);
  }

  .hgroup-component {
    position: relative;
    z-index: 5;
    max-width: 1400px;
    margin: 0 auto;

    @media (max-width: 1400px) {
      @include rem(padding, 0 24);
    }

    .hgroup-tit {
      @include f56(700);
      color: $font-ffffff;
    }
    .hgroup-txt {
      @include rem(margin-top, 24);
      p {
        @include f18(400);
        color: $font-ffffff;
        & + p {
          @include rem(margin-top, 16);
        }
      }
    }
    .btn-area {
      @include rem(margin-top, 72);
    }
  }

  .animated {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: green;
    display: flex;

    [class^="blind"] {
      position: absolute;
      width: 20%;
      height: 6%;
      z-index: 5;
    }
    .blind01 {
      background-color: $bg-ffffff;
    }
    .blind02 {
      left: 20%;
      background-color: $bg-ffffff;
    }
    .blind03 {
      left: 40%;
      background-color: $bg-ffffff;
    }
    .blind04 {
      left: 60%;
      background-color: $bg-ffffff;
    }
    .blind05 {
      left: 80%;
      background-color: $bg-ffffff;
    }
  }
}

// 검색결과 페이지
.search-result-zone {
  .search-area {
    @include rem(width, 924);
    margin: 0 auto;
    .component-input {
      .input-field {
        @include rem(border-bottom, 2px solid $bg-000000);
        input {
          border: 0;
          @include f18(400);
          @include rem(padding-left, 8);
        }
      }
      .input-field-btn {
        &.search {
          border-radius: 0;
          background-color: $bg-ffffff;
          width: auto;
          height: auto;
          padding: 0;
          margin: 0;
          .ico-search-header {
            @include rem(width, 40);
            @include rem(height, 40);
            background-image: url("pathImages/icon/ico_search_header.png");
          }
        }
      }
    }
    .result-msg {
      @include rem(margin-top, 80);
      @include f24(400);
      text-align: center;
      .txt-point {
        @include f24(400);
        color: $font-fe508b;
      }
    }
  }
  // 검색결과
  .search-result-list {
    @include rem(margin-top, 160);
    .txt-point {
      color: $font-fe508b;
    }
    .search-field-item {
      & + .search-field-item {
        @include rem(margin-top, 160);
      }
    }
    // .tbl-top-area {
    //   @include rem(margin-bottom, 16);
    //   .tbl-txt {
    //     color: $font-000000;
    //   }
    // }

    .list-comm-wrap {
      margin: 0;
      // 게시판
      .list-comm {
        border-top: 1px solid $line-e0e0e0;
        li {
          position: relative;
          @include rem(padding, 32 16);
          border-bottom: 1px solid $line-e0e0e0;
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              display: inline-block;
            }
            .txt-area {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 80%;
              .tit {
                @include f18(400);
                width: 70%;
              }
              .txt {
                width: 30%;
                @include f16(500);
                color: $font-777777;
                text-align: right;
              }
            }
          }
        }
      }

      // 라디오 프로그램, 영상
      .gallary-type {
        margin: 0;
        // border-top: 1px solid $line-e0e0e0;
        border-bottom: 1px solid $line-e0e0e0;
        @include rem(padding, 32 0 32 0);
        li {
          padding: 0;
          border-bottom: 0;
          .item {
            display: block;
            .img-zone {
              img {
                @include rem(height, 169);
                object-fit: cover;
              }
            }
            dl {
              @include rem(padding, 24 20 0 20);
              text-align: center;
              dt {
                @include rem(margin-bottom, 8);
                @include f24(400);
                .txt-point {
                  // @include rem(margin-left, 5);
                }
              }
              dd {
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  display: inline-block;
                  @include f16(500);
                  color: $font-999999;
                  & + span {
                    &::before {
                      content: "";
                      display: inline-block;
                      width: 1px;
                      @include rem(height, 12);
                      @include rem(margin, 0 6 0 8);
                      background-color: $bg-e0e0e0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // 설교
      .thumbnail-type {
        li {
          .item {
            .thumbnail {
              flex-shrink: 0;
              @include rem(width, 80);
              @include rem(margin-right, 40);
              img {
                width: 100%;
              }
            }
            .txt-area {
              .tit {
                @include f20(500);
              }
              .txt {
                span {
                  @include f18(400);
                  color: $font-777777;
                  & + span {
                    &::before {
                      content: "";
                      display: inline-block;
                      width: 1px;
                      @include rem(height, 12);
                      @include rem(margin, 0 16);
                      background-color: $bg-e0e0e0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 지도 + 문의방법
.contact-cont {
  display: flex;
  .map-zone {
    position: relative;
    @include rem(width, 686);
    flex-shrink: 0;

    .location-txt {
      display: block;
      @include rem(margin-top, 4);
      @include f12(500);
      color: $font-777777;
    }

    .location-info {
      [class*="btn-"] {
        position: absolute;
        .ico-location {
          @include rem(width, 32);
          @include rem(height, 32);
          background-image: url("pathImages/icon/ico_location_off.png");
        }
        &.on {
          .ico-location {
            background-image: url("pathImages/icon/ico_location_on.png");
          }
        }
      }
      .btn-seoul {
        left: 209px;
        top: 150px;
      }
      .btn-jeju {
        left: 186px;
        bottom: 86px;
      }
      .btn-daejeon {
        left: 237px;
        top: 343px;
      }
      .btn-changwon {
        left: 373px;
        bottom: 293px;
      }
      .btn-mokpo {
        left: 136px;
        bottom: 233px;
      }
      .btn-yeongdong {
        left: 385px;
        top: 82px;
      }
      .btn-pohang {
        left: 450px;
        bottom: 426px;
      }
      .btn-ulsan {
        left: 470px;
        bottom: 323px;
      }
      .btn-busan {
        left: 444px;
        bottom: 276px;
      }
      .btn-daegu {
        left: 380px;
        bottom: 366px;
      }
      .btn-gwangju {
        left: 176px;
        bottom: 261px;
      }
      .btn-jeollanam {
        left: 203px;
        bottom: 212px;
      }
      .btn-jeonbuk {
        left: 207px;
        bottom: 340px;
      }
    }
  }
  .contact-list {
    position: relative;
    flex: 1;

    @include rem(margin-left, 88);
    &::before {
      position: absolute;
      top: 0;
      content: "";
      width: calc(100% - 32px);
      height: 1px;
      background-color: $bg-000000;
    }
    .scroll-custom {
      overflow-y: auto;
      @include rem(height, 838);
      @include rem(padding-right, 32);
      transition: scroll-behavior 0.5s ease-in-out;
    }

    li {
      // > a {
      //   display: block;
      //   @include rem(padding, 40 24);
      //   border-bottom: 1px solid $line-e0e0e0;
      //   outline-offset: -2px;
      // }
      dl {
        @include rem(padding, 40 24);
        border-bottom: 1px solid $line-e0e0e0;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      dt {
        @include f18(700);
      }
      dd {
        @include f18(400);
        span {
          display: flex;
          align-items: center;
          @include rem(min-width, 215);
          &::before {
            content: "";
            display: inline-block;
            @include rem(width, 24);
            @include rem(height, 24);
            @include rem(margin-right, 16);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
          }

          // phone
          &.phone {
            a {
              pointer-events: none;
            }
            &::before {
              background-image: url("pathImages/icon/ico_call.png");
            }
          }
          // email
          &.email {
            &::before {
              background-image: url("pathImages/icon/ico_email.png");
            }
          }
          & + span {
            @include rem(margin-top, 16);
          }
        }
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  .pc-show {
    display: none !important;
  }
  .mo-show {
    display: block !important;
  }

  .video-bx {
    width: 100%;
  }

  br {
    display: none;
  }

  // [공통] img-zone, txt-info :mo
  a:focus,
  a:hover {
    .img-zone {
      img {
        transform: scale(1);
      }
    }
  }
  // tab-scroll-btn menu scroll
  .tab-scroll-wrap {
    .component-tab.tab-scroll-btn {
      // max-width: 1400px;
      @include rem(margin, 0 auto 56 auto);
    }
    & + .radio-wrap {
      @include rem(margin-top, 0);
    }
  }

  // jplayer : mo
  // VOD
  .component-jplayer {
    .player_control .control_bar .time_s {
      left: 60px;
    }
    .player_control .control_bar .full_size {
      .jp-full-screen {
        display: block !important;
      }
    }
    .player_control .control_bar .speaker_s {
      display: none;
    }

    // AOD
    &.audio-player {
    }
  }

  // VOD inline : mo
  .video-player-wrap {
    position: relative;
    padding-top: 0;
    .component-jplayer {
      position: static;
    }
  }

  // tooltip : dim-type : mo
  .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 95;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1 !important;
    display: none;
  }
  .component-tooltip {
    &.dim-type {
      &[data-props-position="top"] {
        .tooltip-container {
          right: auto;
          bottom: auto;
        }
        .tooltip-btn-trigger.on {
          &::after {
            display: none;
          }
        }
      }
      .tooltip-container {
        position: fixed;
        @include rem(top, 50%);
        @include rem(left, 50%);
        transform: translate(-50%, -50%);
        max-width: calc(100% - 48px);
        width: 100%;
        .sharing-list {
          // display: grid;
          // grid-template-columns: repeat(3, 1fr); tset
          justify-items: center;
          .ad-sharing-item,
          .sharing-item {
            @include rem(width, 100%);
            @include rem(margin-bottom, 16);
            text-align: center;
            &:nth-child(1),
            &:nth-child(4) {
              // text-align: left;
            }
            &:nth-child(2),
            &:nth-child(5) {
              text-align: center;
            }
            &:nth-child(3),
            &:nth-child(6) {
              // text-align: right;
            }
            & + .ad-sharing-item,
            & + .sharing-item {
              // @include rem(margin-left, 0);
            }
            span {
              @include f14(500);
              display: block;
            }
          }
        }
      }
    }
  }

  // [공통] Icon Play Btn : mo
  .ico-play-bx {
    .ico-play {
      @include rem(width, 32);
      @include rem(height, 32);
      &.sm-size {
      }
    }
    // W: 40, H: 40, Color: black
    &.type02 {
      // display: flex;
      // justify-content: flex-end;
      // position: static;
      // transform: none;
      // @include rem(width, 40);
      // @include rem(height, 40);
      .ico-play {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: static;
        // width: 100%;
        // height: 100%;
        // border: 1px solid $line-000000;
        // opacity: 1;
        // background-color: transparent;
        // transform: none;
        .ico {
          // @include rem(width, 10);
          // @include rem(height, 12);
          // @include rem(margin-left, 2);
          // background: url("pathImages/icon/ico_play_black.png") no-repeat center
          //   center;
          // background-size: 100%;
        }
      }
    }
  }

  // nodata-wrap : mo
  .nodata-wrap {
    @include rem(height, 416);
    @include f16(500);
    p {
      @include f16(500);
    }
  }
  // [공통] Frame Layout]  : mo
  .inner-comm-bx {
    width: 100%;
    .inner-comm {
      position: relative;
      width: 100%;
      margin: 0 auto;
      @include rem(padding, 48 24 0 24);
      &.type01 {
        width: 100%;
        @include rem(padding, 48 24 0 24);
      }
      &.type02 {
        width: 100%;
        @include rem(padding, 48 24 0 24);
      }
      &.type03 {
        @include rem(width, 100%);
      }
      &.full {
        @include rem(padding, 48 0 0 0);

        .hgroup-component {
          @include rem(margin, 0 24);
        }

        .hgroup-component {
          @include rem(margin, 0 24);
        }
        .component-tab {
          .tab-head {
            width: 100%;
            @include rem(padding, 0 24);
          }
        }
        .component-accordion {
          @include rem(margin, 32 24 0 24);
        }
      }
      &.reset {
        padding-top: 0;
      }
    }
    // no-breadcrumb
    &.no-breadcrumb {
      .inner-comm {
        @include rem(padding-top, 48);
      }
    }
  }

  // [공통] list-comm-wrap] 리스트 : mo
  .list-comm-wrap {
    .hgroup-component {
      .hgroup-tit {
      }
    }
    .list-comm {
      a {
        display: block;
        span {
          display: block;
        }
        .list-tit {
          @include f20(700);
        }
        .list-date {
          @include f16();
        }
      }
      // gallary-type
      &.gallary-type {
        display: block;
        .img-zone {
          img {
            object-fit: cover;
          }
        }
        li {
          flex: 1;
          & + li {
            @include rem(padding-top, 32);
          }

          .list-tit {
            @include rem(padding, 16 0 0 0);
            @include f16(400);
            min-height: auto;
          }
        }
      }

      // board-type : mo
      &.board-type {
        li {
          @include rem(padding, 24 0);
          &:first-child {
            @include rem(padding-top, 0);
          }
          a {
            .list-tit {
              width: 70%;
              @include f16(500);
            }
            .list-date {
              @include f16(500);
              color: $font-777777;
            }
          }
        }
      }

      // notice-type : mo
      &.notice-type {
        display: block;
        width: 100%;
        li {
          width: 100%;
          .notice-item {
            min-height: auto;
            @include rem(padding, 24);
          }
          & + li {
            @include rem(margin, 8 0 0 0);
          }
          a {
            .list-tit {
              @include rem(margin-bottom, 16);
              @include f16(500);
            }
            .list-txt {
              @include f16(500);
            }
            .list-date {
              @include rem(margin-top, 24);
              color: $font-777777;
            }
          }

          p {
            .notice-item,
            a {
              min-height: auto;
              padding: 0;
            }
          }
        }
      }
    }
  }

  // [공통] components-layout 레이아웃 : mo
  .components-layout {
    .layout-row {
      display: block;
      width: 100%;
    }
    [class^="layout-col"] {
      .list-comm-wrap {
        margin: 0;
      }
      flex: 1;
      & + .layout-col {
        @include rem(margin-left, 30);
      }
    }

    // half-type01
    &.half-type01 {
      .layout-row {
        margin: 0;
        [class^="layout-col"] {
          width: 100%;
          & + .layout-col {
            @include rem(margin-left, 0);
          }
        }
      }
    }
  }

  // [공통] End completed : mo
  .inquiry-completed {
    @include rem(padding-top, 16);
    &::before {
      @include rem(width, 48);
      @include rem(height, 51);
      @include rem(margin-bottom, 24);
      background-image: url("pathImages/icon/ico_completed_mo.png");
    }
    .completed-msg {
      @include f24(700);
    }
    .completed-desc {
      @include f16(500);
    }
    .component-btns {
      @include rem(margin-top, 48);
    }
  }

  // [공통] notice-wrap : mo
  .notice-wrap {
    @include rem(margin-top, 32);
    .notice-txt {
      &::before {
      }
    }
    //예)가 붙어있는 경우우
    .notice-etc {
      @include rem(gap, 4);
      > p {
        @include f14(500);
      }
    }
    .notice-list {
      li {
        @include f14(500);
        strong {
          font-weight: 700;
        }
      }
    }
  }

  //  [공통] view : mo
  .details-view-wrap {
    @include rem(margin-top, 40);
    .view-info-wrap {
      flex-direction: column;
      align-items: flex-start;
      .view-btns {
        position: static;
        display: flex;
        justify-content: flex-end;
        @include rem(margin, 16 0 24);
        .btn-edit {
          .ico-edit {
            @include rem(width, 20);
            @include rem(height, 20);
          }
        }
      }
    }
    .view-info {
      @include rem(width, 100%);
    }
    .label {
      @include rem(padding, 4 8);
      @include rem(margin-bottom, 8);
      @include f12(500);
    }
    .view-tit {
      @include f18(700);
      @include rem(line-height, 27);
      justify-content: space-between;
      .component-tooltip {
        .tooltip-container {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .view-date {
      @include rem(margin-top, 4);
      @include f16(500);
      color: $font-000000;
      span {
        display: inline-block;
        & + span {
          @include rem(margin-left, 16);
          &::before {
            @include rem(left, -8);
          }
        }
        &.info {
          span {
            display: inline;
            & + span {
              @include rem(margin-left, 16);
              &::before {
                @include rem(width, 1);
                @include rem(height, 16);
                @include rem(top, 50%);
                @include rem(left, -8);
                content: "";
                display: block;
                position: absolute;
                transform: translateY(-50%);
                background-color: $bg-e0e0e0;
              }
            }
          }
        }
      }
    }

    .view-cont {
      @include f16(400);
      @include rem(padding, 40 0);
      @include rem(margin-top, 16);

      // 관리자 에디터에서 오는 HTML요소
      table {
        width: 100% !important;
      }
      img {
        @include rem(width, 100%);
        height: auto;
        @include rem(margin-bottom, 40);
      }
      p {
        @include rem(padding, 0 8);
        &.sm-txt {
          @include f16(400);
        }
        &.mid-txt {
          @include f16(500);
        }
      }
      .iframe-zone {
        padding-top: 56.25%;
        @include rem(margin-bottom, 24);
      }
    }
    .view-pagenaition {
      li {
        @include rem(padding, 24 8);
      }
      .view-label {
        width: auto;
        // flex-direction: row-reverse;
        align-items: center;
        @include f14(500);
        @include rem(line-height, 21);
        [class^="ico-"] {
          @include rem(margin-right, 0);
          @include rem(width, 20);
          @include rem(height, 20);
        }
        .txt {
          @include rem(margin-left, 8);
          @include f14(500);
        }
      }
      .view-btn {
        @include rem(margin-left, 32);
        @include ellipsis(1);
        .txt {
          @include f14(500);
        }
      }
    }
  }
  // sub-visual-wrap : mo
  .sub-visual-wrap {
    width: 100%;
    .img-zone {
      width: 100%;
      img {
        @include rem(min-height, 317);
        object-fit: cover;
      }
    }
    .sub-visual-bx {
      @include rem(top, 74);
      left: 0;
      .sub-visual-info {
        @include rem(padding, 0 24);
        @include rem(width, 100%);
        margin: 0;
        .sub-visual-tit {
          @include rem(margin-bottom, 16);
          @include f24(500);
        }
        .sub-visual-txt {
          @include f16(500);
        }
      }
      &.banner-type {
        @include rem(top, 74);
        left: 0;
      }
    }
    .btn-group {
      @include rem(padding, 0 24);
      @include rem(bottom, 24);
      left: 0;
      transform: none;
      .home {
        @include rem(margin-right, 44);
        &::before {
          @include rem(width, 20);
          @include rem(height, 20);
        }
      }
      .component-tooltip {
        .tooltip-btn-trigger {
          &::before {
            @include rem(width, 20);
            @include rem(height, 20);
          }
        }
      }
    }
  }
  // label-tag : mo
  .label-tag {
    &.round-type {
      @include f14(500);
    }
    &.mo-show {
      display: inline-block !important;
    }
    &.full-type {
      @include f14(500);
    }
  }

  // tbl top : mo
  .tbl-top-area {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    @include rem(padding, 0 24);
    @include rem(margin-bottom, 16);
    .tbl-txt {
      @include f14(500);
    }
    .tbl-user {
      @include rem(min-width, 200);
      width: 100%;
    }
  }

  // 하단 상담 영역 : mo
  .consulting-inquiry {
    border-top: 1px solid $line-e0e0e0;
    border-bottom: 1px solid $line-e0e0e0;
    dl {
      display: block;
      width: 100%;
      margin: 0 auto;
      @include rem(padding, 24);
    }
    dt {
      @include f16(500);
      @include rem(margin-bottom, 32);
    }
    dd {
      @include f14(500);
      span {
        position: relative;
        & + span {
          @include rem(margin-left, 16);
          @include rem(padding-left, 16);
          &::before {
            @include rem(height, 12);
          }
        }
      }
      & + dd {
        @include rem(margin, 8 0 0 0);
      }
    }
  }

  // Sub Visual Common : mo
  .sub-comm-visual {
    height: auto;
    @include rem(padding-top, 0);
    @include rem(margin-bottom, 72);
    .img-zone {
      position: static;
      transform: none;
      width: 100%;
      height: auto;
    }

    .hgroup-component {
      position: absolute;
      @include rem(top, 80);
      @include rem(left, 24);
      @include rem(right, 24);
      z-index: 5;
      width: auto;
      margin: 0;
      padding: 0;
      .hgroup-tit {
        @include f32(700);
      }
      .hgroup-txt {
        @include rem(margin-top, 16);
        p {
          @include f14(500);
          & + p {
            @include rem(margin-top, 24);
          }
        }
      }
      .btn-area {
        @include rem(margin-top, 121);
      }
    }

    .animated {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background-color: green;
      display: flex;
      display: none;

      [class^="blind"] {
        position: absolute;
        width: 20%;
        height: 6%;
        z-index: 5;
      }
      .blind01 {
        background-color: red;
      }
      .blind02 {
        left: 20%;
        background-color: orange;
      }
      .blind03 {
        left: 40%;
        background-color: green;
      }
      .blind04 {
        left: 60%;
        background-color: aqua;
      }
      .blind05 {
        left: 80%;
        background-color: aquamarine;
      }
    }
  }

  // 검색결과 페이지 : mo
  .search-result-zone {
    .search-area {
      width: 100%;
      @include rem(margin-top, 16);
      .component-input {
        .input-field {
          @include rem(border-bottom, 2px solid $bg-000000);
          input {
            border: 0;
            @include f16(500);
            @include rem(padding-left, 0);
          }
        }
        .input-field-btn {
          &.search {
            .ico-search-header {
              @include rem(width, 20);
              @include rem(height, 20);
              background-image: url("pathImages/icon/ico_search_header_mo.png");
            }
          }
        }
      }
      .result-msg {
        @include rem(margin-top, 8);
        @include f14(500);
        text-align: left;
        .txt-point {
          @include f14(500);
        }
      }
    }
    // 검색결과
    .search-result-list {
      @include rem(margin-top, 0);
      .list-comm-wrap {
        margin: 0;
        // 게시판
        .list-comm {
          li {
            position: relative;
            @include rem(padding, 24 8);
            .item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                display: inline-block;
              }
              .txt-area {
                flex: 1;
                display: block;
                width: 80%;
                .tit {
                  @include f18(400);
                  width: 100%;
                  @include rem(margin-bottom, 24);
                }
                .txt {
                  @include f14(500);
                  text-align: left;
                }
              }
            }
          }
        }

        // 라디오 프로그램, 영상
        .gallary-type {
          display: block;
          @include rem(padding, 0 0 0 0);
          margin: 0;
          border: 0;
          li {
            padding: 0;
            border-bottom: 0;
            .item {
              display: block;
              dl {
                @include rem(padding, 16 0 0 0);
                text-align: left;
                dt {
                  @include rem(margin-bottom, 8);
                  @include f16(500);
                  .txt-point {
                    @include rem(margin-left, 5);
                  }
                }
                dd {
                  display: block;
                  span {
                    display: inline-block;
                    @include f14(500);
                    color: $font-999999;
                    & + span {
                      &::before {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        @include rem(height, 12);
                        @include rem(margin, 0 6 0 8);
                        background-color: $bg-e0e0e0;
                      }
                    }
                  }
                  .produce {
                    display: flex;
                    @include rem(margin-top, 4);
                    &::before {
                      display: none;
                    }
                    em {
                      &::after {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        @include rem(height, 12);
                        @include rem(margin, 0 6 0 8);
                        background-color: $bg-e0e0e0;
                      }
                    }
                  }
                }
              }
              .img-zone {
                @include rem(border-radius, 8);
              }
            }
            & + li {
              @include rem(margin-top, 32);
            }
          }
        }

        // 설교
        .thumbnail-type {
          li {
            @include rem(padding, 24 8);
            .item {
              .thumbnail {
                overflow: hidden;
                flex-shrink: 0;
                @include rem(width, 80);
                @include rem(margin-right, 24);
                @include rem(border-radius, 4);
                img {
                  vertical-align: top;
                }
              }
              .txt-area {
                overflow: hidden;
                .tit {
                  width: 100%;
                  @include f16(500);
                  @include rem(margin-bottom, 16);
                  // @include ellipsis(1);
                }
                .txt {
                  width: 100%;
                  span {
                    display: block;
                    @include f14(500);
                    & + span {
                      @include rem(margin-top, 2);
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .nodata-wrap {
        // display: none;
      }
      &.list-page {
        @include rem(margin, 64 0 126 0);
        .search-field-item {
          @include rem(padding, 20 0);
          border-bottom: 1px solid $line-e0e0e0;
          .hgroup-component.more-view-type {
            position: relative;
            margin: 0;
            .btn-tbl-more {
              .btn-txt {
                display: none;
              }
              &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
              }
            }
          }
          & + .search-field-item {
            margin: 0;
          }
        }
        .list-comm-wrap {
          display: none;
        }
      }
    }
    &.detail-search {
      .hgroup-component.mo-show {
        display: none !important;
      }
      .search-area {
        display: none;
      }
    }
  }

  // 지도 + 문의방법 : mo
  .contact-cont {
    display: block;
    .map-zone {
      display: none;
    }
    .contact-list {
      @include rem(margin, 24 0 0 0);
      @include rem(padding, 0 24);

      &::before {
        display: none;
      }
      .scroll-custom {
        overflow-y: hidden;
        height: auto;
        padding: 0;
        border-top: 1px solid $line-000000;
      }

      li {
        > a {
          pointer-events: none;
          display: block;
          @include rem(padding, 24 24);
        }
        dl {
          display: block;
        }
        dt {
          @include rem(margin-bottom, 24);
          @include f16(700);
        }
        dd {
          @include f16(400);
          span {
            min-width: auto;
            &::before {
              @include rem(width, 20);
              @include rem(height, 20);
            }

            // phone
            &.phone {
              a {
                pointer-events: all;
              }
              &::before {
                background-image: url("pathImages/icon/ico_call.png");
              }
            }
            // email
            &.email {
              &::before {
                background-image: url("pathImages/icon/ico_email.png");
              }
            }
            & + span {
              @include rem(margin-top, 10);
            }
          }
        }
      }
    }
  }
}

.fade-in {
  animation: fadeInAnimation 0.3s ease-in forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
