@use "../abstracts" as *;

// Bullet 영역
ul[class*="comm-bl-"] {
  // Basic margin 4px
  li {
    p {
      // color: $font-555555;
    }
    & + li {
      @include rem(margin-top, 4);
    }
    word-break: keep-all;
  }

  // Dash basic
  &.comm-bl-dot {
    > li {
      position: relative;
      @include rem(padding-left, 12);
      @include f16(500);
      color: $font-777777;
      border: 0;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        @include rem(top, 10);
        @include rem(width, 3);
        @include rem(height, 3);
        background: $bg-777777;
        border-radius: 50%;
      }
      &.bold {
        font-weight: 700;
      }
      &.no-dot {
        padding-left: 0;
        &::before {
          display: none;
        }
      }
      & + li {
        @include rem(margin-top, 16);
      }

      .primary {
        // color: $font-333333;
      }

      strong {
        font-weight: 700;
      }
    }
    // Dash Bullet
    .comm-bl-dash {
      > li {
        position: relative;
        @include rem(padding-left, 10);
        @include f14;
        &::before {
          display: block;
          content: "-";
          position: absolute;
          top: 0;
          left: 0;
        }
        &.no-dot {
          padding-left: 0;
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

// ############################################# Mo #############################################
@media (max-width: 1023px) {
  // Bullet 영역 : mo
  ul[class*="comm-bl-"] {
    // Basic margin 4px
    li {
      p {
      }
      & + li {
        @include rem(margin-top, 4);
      }
    }

    // Dash basic : mo
    &.comm-bl-dot {
      > li {
        position: relative;
        @include rem(padding-left, 11);
        @include f14(500);
        &::before {
        }
        & + li {
          @include rem(margin-top, 16);
        }

        .primary {
          // color: $font-333333;
        }

        strong {
          font-weight: 700;
        }
      }
      // Dash Bullet
      .comm-bl-dash {
        > li {
          &::before {
            top: 0;
            left: 0;
          }
          &.no-dot {
            padding-left: 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
